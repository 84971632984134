import Consts from '../../consts/Consts';
import PageTitles from '../../consts/PageTitles';
import Routes from '../../consts/Routes';

export const SATnav = isSATEnabled => {
  return [
    {
      path: 'submitaticket/submitaticket',
      url: Routes.SUBMITATICKET,
      isMFE: true,
      disabled: !isSATEnabled,
      label: PageTitles.TECH_SUPPORT,
      ignoreBreadcrumb: true,
      breadcrumbLabel: Consts.ROUTE_PARAM,
      isBreadcrumbEnabled: false,
      supportsLiteMode: true,
      childPages: isSATEnabled
        ? [
            {
              path: 'submitaticket/submitaticket',
              url: Routes.SUBMITATICKET,
              label: PageTitles.ARTICLE_TECH_SUPPORT,
              disabled: !isSATEnabled,
            },
          ]
        : null,
    },
  ];
};
