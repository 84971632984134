import axios from 'axios';
import APIs from '../../../consts/APIs';
import UserType from '../../../user/UserType';
import { successFailure } from '../../../utils/interceptorHelpers';

const handleSuccessCallback = (res, success, failure) => {
  if (res.data) {
    success(res.data);
  } else {
    failure();
  }
};

const handleFailureCallback = (err, success, failure) => {
  if (err.response && err.response.status === 400) {
    success({});
  } else {
    failure();
  }
};

export const fetch2SVEnrollment = async (success, failure) => {
  const payload = { upn: UserType.userPrincipalName };
  await axios
    .post(APIs.getAPIUrl(APIs.SECURITY_ACCESS.ENROLLMENT_2SV), payload, {
      headers: JSON.parse(
        JSON.stringify({
          ignoreGenericError: true,
          ignoreNetworkError: true,
        })
      ),
    })
    .then(res => {
      handleSuccessCallback(res, success, failure);
    })
    .catch(err => {
      handleFailureCallback(err, success, failure);
    });
};

export const fetchSSPRVEnrollmentWithPasswordInfo = async (
  success,
  failure
) => {
  const payload = { upn: UserType.userPrincipalName, domain: UserType.domain };
  await fetchSSPRData(payload, success, failure);
};

export const fetchPhishingData = async (userEmail, success, failure) => {
  const params = { userEmail: userEmail };
  const headers = JSON.parse(
    JSON.stringify({
      ignoreGenericError: true,
      ignoreNetworkError: true,
    })
  );

  await axios
    .get(APIs.getAPIUrl(APIs.SECURITY_ACCESS.PHISHING), {
      params: params,
      headers: headers,
    })
    .then(res => {
      handleSuccessCallback(res, success, failure);
    })
    .catch(err => {
      handleFailureCallback(err, success, failure);
    });
};

export const fetchSSPRData = async (payload, success, failure) => {
  await axios
    .post(APIs.getAPIUrl(APIs.SECURITY_ACCESS.ENROLLMENT_SSPR), payload, {
      headers: JSON.parse(
        JSON.stringify({
          ignoreGenericError: true,
          ignoreNetworkError: true,
        })
      ),
    })
    .then(res => {
      handleSuccessCallback(res, success, failure);
    })
    .catch(err => {
      handleFailureCallback(err, success, failure);
    });
};

export const resetAuthMethods = async (payload, success, failure) => {
  await axios
    .post(APIs.getAPIUrl(APIs.SECURITY_ACCESS.RESET_SSPR), payload, {
      headers: JSON.parse(
        JSON.stringify({
          ignoreGenericError: true,
          ignoreNetworkError: true,
        })
      ),
    })
    .then(res => {
      handleSuccessCallback(res, success, failure);
    })
    .catch(err => {
      handleFailureCallback(err, success, failure);
    });
};
