import { graphGateway } from './graphGateway';

export const fetchADInfo = async (
  requestBody,
  onSuccessWithAd,
  onSuccessWithoutAd,
  onFailure
) => {
  await graphGateway(
    requestBody.upn,
    `query Query($userPrincipalName: String!) {  getUser_v1(userPrincipalName: $userPrincipalName) {
      businessUnitType
      cn
      country
      department
      displayName
      division
      domain
      email
      employeeType
      guid
      isSeniorOrAbove
      jobCode
      location
      managerUserId
      phoneNumberOffice
      resolvedOU
      storeClubDC
      systemJobCodes
      type
      userId
      userPrincipalName
      workDepartment
      devices {
        serialNumber
        model
        operatingSystem
        manufacturer
        deviceName
        osVersion
        imei
        lastEnrolledOn
        locationGroupName
        lastSeen
        udid
        phoneNumber
      }
    }
    }`
  )
    .then(res => {
      if (res && res.data && res.data.data && res.data.data.getUser_v1) {
        const formattedData = formatADData(res.data.data.getUser_v1);
        onSuccessWithAd(formattedData);
      } else {
        onSuccessWithoutAd();
      }
    })
    .catch(error => {
      onFailure();
    });
};

const formatADData = data => {
  return {
    MyTech: {
      ActiveDirectory_Information: [
        {
          BusinessUnitType: data.businessUnitType,
          CN: data.cn,
          Country: data.country,
          Department: data.department,
          DisplayName: data.displayName,
          Division: data.division,
          Domain: data.domain,
          EmailAddress: data.email,
          EmployeeType: data.employeeType,
          GUID: data.guid,
          JobCode: data.jobCode,
          Location: data.location,
          ManagerUserId: data.managerUserId,
          PhoneNumberOffice: data.phoneNumberOffice,
          ResolvedOUName: data.resolvedOU,
          StoreClubDC: data.storeClubDC,
          SystemJobCodes: data.systemJobCodes,
          Type: data.type,
          UserId: data.userId,
          UserPrincipalName: data.userPrincipalName,
          WorkDepartment: data.workDepartment,
          isSeniorOrAbove: data.isSeniorOrAbove,
        },
      ],
      ...formatDevicesData(data),
    },
    isIDCUser: findIsIDCUser(data?.domain, data?.country),
  };
};

const findIsIDCUser = (domain, country) => {
  const formattedDomain =
    typeof domain === 'string' ? domain.toLowerCase() : '';
  const formattedCountry =
    typeof country === 'string' ? country.toLowerCase() : '';
  if (
    (formattedDomain === 'homeoffice' || formattedDomain === 'ho') &&
    formattedCountry === 'in'
  ) {
    return true;
  }
  return false;
};

export const formatDevicesData = data => {
  if (data) {
    {
      const Mac = [];
      const Airwatch = [];
      const PC = [];
      const VDI = [];
      const dat = data.devices;
      const vdidat = data.vdiDevices;
      dat?.forEach(device => {
        let obj = {};
        if (device.model.includes('MacBook')) {
          obj = {
            Model: device.model,
            ModelIdentifier: device.model,
            OSName: device.operatingSystem,
            OsVersion: device.osVersion,
            SerialNumber: device.serialNumber,
            Udid: device.serialNumber,
          };
          Mac.push(obj);
        } else if (device.operatingSystem === null) {
          obj = {
            Id: '',
            LocationGroupName: device.locationGroupName,
            Model: device.model,
            OperatingSystem: device.operatingSystem,
            Platform: device.manufacturer,
            SerialNumber: device.serialNumber,
            Udid: device.udid,
            imei: device.imei,
            PhoneNumber: device.phoneNumber,
            LastSeen: device.lastSeen,
            CellularInfo: device.cellularInfo,
          };
          Airwatch.push(obj);
        } else if (device.model.includes('VMware')) {
          obj = {
            id: device.model,
          };
        } else if (device.operatingSystem.includes('Windows')) {
          obj = {
            Make_Model: device.model,
            WorkstationName: device.deviceName,
            Model: device.model,
            OSName: device.operatingSystem,
            OsVersion: device.osVersion,
            LastEnrolledOn: device.lastEnrolledOn,
          };

          PC.push(obj);
        }
      });

      vdidat?.forEach(device => {
        VDI.push(device);
      });
      return {
        AirWatchInformation: Airwatch,
        MacInformation: Mac,
        PCInformation: PC,
        VDIInformation: VDI,
      };
    }
  }
};
