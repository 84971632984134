import { useHistory, useLocation } from 'react-router';
import MicroFrontend from '../../components/misc/MicroFrontend';
import MFEs from '../../consts/MFEs';
import UserType from '../../user/UserType';
import FlexPage from '../../components/page/FlexPage';
import { Collapse } from '@mui/material';
import Routes from '../../consts/Routes';
import AppContext from '../../app/context/AppContext';
import { useContext } from 'react';
import EnvConfigs from '../../Configurations';

const HolidayReadiness = () => {
  const location = useLocation();
  const history = useHistory();
  const { enabledMFEs } = useContext(AppContext);

  const showMFE =
    location.pathname?.startsWith(Routes.HOLIDAY_READINESS) &&
    enabledMFEs?.includes(MFEs.HOLIDAYREADINESS);

  return (
    <>
      <div className={showMFE ? 'main-content' : null}>
        <Collapse in={showMFE}>
          <FlexPage large hideHeader showHeader={false}>
            <MicroFrontend
              host={EnvConfigs.REACT_APP_HOLIDAY_READINESS_HOST}
              proxyHost={EnvConfigs.REACT_APP_HOLIDAY_READINESS_HOST}
              name={MFEs.HOLIDAYREADINESS}
              mfeEnv={window.HOLIDAY_READINESS.ENVIRONMENT}
              setMfeHost={() => {}}
              props={{
                history,
                impersonatedUser: UserType?.userData?.isImpersonated
                  ? UserType.userData.UserPrincipalName
                    ? UserType.userData.UserPrincipalName
                    : null
                  : UserType?.userData?.UserPrincipalName,
                isStandAlone: false,
                userUpn: UserType.userPrincipalName,
                userAdType: UserType.userADType,
              }}
              handleSucess={() => {}}
              handleError={() => {}}
            />
          </FlexPage>
        </Collapse>
      </div>
    </>
  );
};

export default HolidayReadiness;
