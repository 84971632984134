import React from 'react';
import Routes from '../../../consts/Routes';
import { Link } from 'react-router-dom';
import { IconButton } from '@walmart-web/livingdesign-components';
import { Gear } from '@livingdesign/icons';
import AriaLabel from '../../../consts/AriaLabel';
import Colors from '../../../consts/Colors';

// Dev Note: Did not add event listener as this is used daily by MyTech Engineers and is only shown in the lower dev environment

const customStyles = {
  iconStyle: {
    paddingLeft: '6px',
    paddingRight: '6px',
    color: Colors.WHITE,
  },
};
const HeaderAdminSite = () => {
  return (
    <Link
      tabIndex="-1" // Don't allow tab for the link, use the Button as the official tab
      to={Routes.ADMIN}
      className="is-nav headerAdminSite" /* is-nav & headerAdminSite is currently used for checkIfNavigatableElementClicked in DL and other hidding utilities. Need to review once DL has been offboarded. */
      aria-label={AriaLabel.ADMIN_ICON}
      role="button"
    >
      <IconButton
        size="large"
        a11yLabel={AriaLabel.ADMIN_ICON}
        UNSAFE_style={customStyles.iconStyle}
      >
        <Gear aria-hidden={true} />
      </IconButton>
    </Link>
  );
};

export default HeaderAdminSite;
