import MFEs from '../consts/MFEs';
import Routes from '../consts/Routes';
import Pages from '../consts/Pages';
import Consts from '../consts/Consts';

export const setPageTitle = route => {
  const currentMFEs = Object.values(MFEs);
  const pages = Pages(currentMFEs);
  let pageInfo;

  //create route that will match with help center topic articles' URLs in Pages.js
  let helpCenterRoute;
  try {
    if (route.includes('/pc/')) {
      helpCenterRoute = route.replace(
        '/pc/',
        `/:name${Consts.ARTICLE_PARAM_RESTRICTION}/`
      );
    } else if (route.includes('/mac/')) {
      helpCenterRoute = route.replace(
        '/mac/',
        `/:name${Consts.ARTICLE_PARAM_RESTRICTION}/`
      );
    }

    //Find base of route to search MFE routes for page titles
    const mfeBaseRoutes = [
      Routes.ORDERING,
      Routes.APPS,
      Routes.HOLIDAY_READINESS,
    ];
    const pathBase = `/${route.split('/')[1]}`;

    if (mfeBaseRoutes.includes(pathBase)) {
      const parentPage = pages.find(page => {
        return page.url === pathBase;
      });
      pageInfo = parentPage?.childPages?.find(page => {
        return page.url === route;
      });
    } else {
      pageInfo = pages.find(page => {
        return page.url === route || page.url === helpCenterRoute;
      });
    }

    const pageLabel = pageInfo?.label
      ? pageInfo?.label
      : pageInfo?.breadcrumbLabel; //Some help center pages use this name instead of 'label'
    const pageTitle = pageLabel ? `MyTech | ${pageLabel}` : 'MyTech';
    document.title = pageTitle;
  } catch {
    document.title = 'MyTech';
  }
};
