import MTLD3Button from '../button/MTLD3Button';
import { Share, Check } from '@livingdesign/icons';
import { copyTextToClipboard } from '../../utils/helpers';
import { trackEvent } from '../../utils/appInsight';
import Consts from '../../consts/Consts';
import Config from '../../utils/Config';
import useVideoCarousel from '../../customHooks/useVideoCarousel';
import Events from '../../consts/Events';
import Errors from '../../consts/Errors';
import ErrorHandler from '../../utils/ErrorHandler';

/**
 *
 * @param videoId - Id for the associated videoo
 * @param videoUrl - Url for the associated video
 * @param isHidden - Boolean for if the share button should not be shown
 * @returns - React Component share button for the video carousel items
 */

const MTVideoCarouselShareButton = ({
  videoId,
  videoUrl,
  isHidden,
  onFocus,
}) => {
  if (
    !Config.showVideoCarouselShareButton ||
    !Config.isVideoCarouselRouteSearchParamEnabled ||
    isHidden
  ) {
    // If feature is not enabled dont render the button
    return null;
  }

  videoId = videoId ? videoId : window.location?.search.split('=')[1]; // Get video ID from passed param else get it from search param
  const myTechVideoUrl = videoUrl ? videoUrl : window.location.href; // Obtain the full url for the video in MyTech via param else get it from the location
  const { isCopied, setIsCopied } = useVideoCarousel();
  const buttonText = isCopied ? 'Copied' : 'Share';

  return (
    <MTLD3Button
      onFocus={onFocus}
      onClick={() => {
        trackEvent(
          Events.VIDEO_CAROUSEL(Events.VIDEO_CAROUSEL_SHARE_BUTTON_CLICKED)
        );
        MTVideoCarouselShareButton.handleCopyURL(
          myTechVideoUrl,
          videoId,
          setIsCopied
        );
      }}
      leading={<Share size="small" aria-hidden={true} />}
      trailing={
        isCopied ? <Check size="small" aria-hidden={true} /> : Consts.EMPTY
      }
      size="small"
    >
      {buttonText}
    </MTLD3Button>
  );
};

/**
 *
 * @param url - URL string to be copied to the clipboard
 * @param itemId - Id for the item associated to the button
 * @param callBack - Callback function
 */
MTVideoCarouselShareButton.handleCopyURL = (url, itemId, callBack) => {
  // Copies the url to the clipboard
  copyTextToClipboard(url)
    .then(() => {
      trackEvent(
        Events.VIDEO_CAROUSEL(Events.VIDEO_CAROUSEL_SHARE_BUTTON_COPY(url))
      );
      callBack(itemId);
    })
    .catch(err => {
      ErrorHandler.trackError(Errors.VIDEO_CAROUSEL, {
        message: Errors.VIDEO_CAROUSEL_SHARE_BUTTON_ERROR(url, err),
      });
    });
};

export default MTVideoCarouselShareButton;
