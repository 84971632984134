import React from 'react';
import PropTypes from 'prop-types';
import Colors from '../../consts/Colors';
import { useLocation } from 'react-router-dom';
import QuestionButton from '../button/QuestionButton';
import { trackEvent } from '../../utils/appInsight';
import PageHeaderBanner from './PageHeaderBanner';
import CSS from '../../consts/CSS';
import Consts from '../../consts/Consts';

// Contains page title, subtitle and paragrapgh text at the top of a page
const PageHeader = ({
  icon,
  title,
  subTitle,
  isMobileReplacementCarrierPage,
  reduceMarginBottom = false,
  increaseMarginTop = false,
  titleForTrackingOnly = false,
  setShowInfoDialog = null,
  bannerProps,
  pageHeaderAsBanner = false,
  svgImage,
  mobileSvgImage,
  leftAlignHeader = false,
}) => {
  const location = useLocation();
  return (
    <section className="pageHeader">
      {pageHeaderAsBanner ? (
        <PageHeaderBanner
          title={title}
          subTitle={subTitle}
          bannerProps={bannerProps}
          svgImage={svgImage}
          mobileSvgImage={mobileSvgImage}
        />
      ) : (
        <>
          {icon ? <div className="pageHeader-icon">{icon}</div> : null}
          {!titleForTrackingOnly && <h1>{title}</h1>}
          {subTitle && (
            <h2 className={isMobileReplacementCarrierPage ? 'red-text' : null}>
              {subTitle}
              {setShowInfoDialog && (
                <QuestionButton
                  onClick={() => {
                    trackEvent(
                      `PageHeader info dialog opened - ${location.pathname}`
                    );

                    setShowInfoDialog(true);
                  }}
                />
              )}
            </h2>
          )}
        </>
      )}
      <style jsx>{`
        section {
          margin: ${pageHeaderAsBanner
            ? '12px auto 20px'
            : leftAlignHeader
            ? '5px 30px 5px 0'
            : '5px auto 30px'};
          ${increaseMarginTop ? 'margin-top: 30px;' : Consts.EMPTY};
          ${reduceMarginBottom ? 'margin-bottom: 20px;' : Consts.EMPTY};
          max-width: ${pageHeaderAsBanner ? '100%' : '565px'};
          box-sizing: border-box;
        }

        h1,
        h2 {
          text-align: ${leftAlignHeader ? 'left' : 'center'};
          margin: 0;
        }

        h1 {
          font-size: 32px;
          line-height: 40px;
        }

        h2 {
          font-size: 16px;
          font-weight: 400;
        }

        @media (max-width: ${CSS.STANDARD_MAX_MOBILE_PHONE_VIEW}) {
          h1 {
            font-size: 24px;
            line-height: 30px;
          }
          h2 {
            font-size: 14px;
          }
        }

        .pageHeader-icon {
          text-align: center;
          margin-bottom: 30px;
        }

        .red-text {
          color: ${Colors.ERROR_RED};
        }
      `}</style>
    </section>
  );
};

export default PageHeader;

PageHeader.propTypes = {
  // the main h1 for the page
  title: PropTypes.string,
  // optional h2 subtitle
  subTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
  ]),
  // optional paragraph text just under the title and/or subtitle
  subCopy: PropTypes.string,
  // boolean that adds larger padding to the header
  extraMargin: PropTypes.bool,
};

PageHeader.defaultProps = {
  extraMargin: true,
};
