import React from 'react';
import PropTypes from 'prop-types';
import Colors from '../../../consts/Colors';

//NOTE: Even for a single radio button, it must be wrapped by MTCheckboxes.

const MTCheckbox = ({
  field, //Will be added at runtime by MTCheckboxes
  id, //Will be added at runtime by MTCheckboxes
  groupName, //Will be added at runtime by MTCheckboxes
  value,
  className,
  label = value,
  disabled = false,
  color = Colors.BLUE,
}) => {
  return (
    <span className="mtCheckbox">
      <input
        {...field}
        type="checkbox"
        id={id}
        name={groupName}
        value={value}
        checked={field && field.value && field.value.indexOf(value) !== -1}
        className={className}
        disabled={disabled}
      />
      <label htmlFor={id}>{label}</label>

      <style jsx>
        {`
          input {
            position: relative;
            appearance: none;
            height: 20px;
            width: 20px;
            background-color: #fff;
            border: 1px solid ${Colors.BLACK};
            border-radius: 2px;
            margin-left: 0;
            margin-right: 0;
          }

          input:checked {
            border: none;
            background-color: ${color};
          }

          input:checked:after {
            content: '';
            position: absolute;
            top: 2px;
            left: 7px;
            width: 5px;
            height: 10px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }

          input[disabled] {
            background-color: ${Colors.BUTTON_DISABLED};
          }

          label {
            font-size: 14px;
            position: relative;
            top: -4px;
            padding-left: 10px;
            margin-right: 20px;
          }
        `}
      </style>
    </span>
  );
};

MTCheckbox.propTypes = {
  field: PropTypes.object,
  id: PropTypes.string,
  groupName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default MTCheckbox;
