import { useLocation, useHistory } from 'react-router-dom';
import Consts from '../consts/Consts';
import Events from '../consts/Events';
import { trackEvent } from '../utils/appInsight';

/* custom hook that handles side nav related states. utilizes global context to handle the open state so it is 
only rendering the useContext once and uses useState for the selected field based on the passed url compaired to 
location pathname. also handles the logic around pages for the routing */

const useMTLD3Button = initializationProps => {
  const history = useHistory();
  const location = useLocation();

  if (!initializationProps?.buttonType) {
    initializationProps = {
      ...initializationProps,
      buttonType: 'Unspecified Button',
    };
  }

  const handleClick = event => {
    if (initializationProps?.onClick) {
      initializationProps.onClick(event);
    }
    if (initializationProps?.track) {
      initializationProps.track();
    }
    if (initializationProps.to) {
      trackEvent(
        Events.MTLD3BUTTON_CLICK(
          initializationProps.buttonType,
          initializationProps.to,
          location.pathname
        )
      );
      history.push(initializationProps.to, {
        ...initializationProps?.toState,
      });
    }
  };

  const isNewPageAncor =
    initializationProps?.newTab && initializationProps?.href;
  const target = isNewPageAncor ? '_blank' : Consts.EMPTY;
  const rel = isNewPageAncor ? 'noopener noreferrer' : Consts.EMPTY;
  // If href prop is passed, add the ancor props to properly style to a link in LD3
  const ancorProps = initializationProps?.href
    ? {
        href: initializationProps?.href,
        target: target,
        rel: rel,
      }
    : {};

  // Determine if spinner should be gray or white based on the button/ancor varient
  const isPrimaryOrDestructive =
    initializationProps?.variant === 'primary' ||
    initializationProps?.variant === 'destructive';
  const spinnerColor = isPrimaryOrDestructive ? 'white' : 'gray';

  return { handleClick, ancorProps, spinnerColor };
};

export default useMTLD3Button;
