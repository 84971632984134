import Config from './Config';
import PulseService from '../services/PulseService';
import SessionService from '../services/SessionService';
import EnvConfigs from '../Configurations';
import Consts from '../consts/Consts';

export const initializeMyTechInsights = () => {
  PulseService.initialize(EnvConfigs.REACT_APP_NAME, () => {
    SessionService.createSession();
    return {
      sessionId: SessionService.getSessionId(),
    };
  });
};

export const getCustomDimensions = () => {
  PulseService.getCustomDimensions();
};
export const trackPage = pageName => {
  pageName.uri = window?.location?.href;
  PulseService.trackPageView(pageName);
  if (Config.isAdobeAnalyticsEnabled) {
    if (window._satellite && window._satellite.track) {
      window._satellite.track('page-view', { pageName: pageName });
    }
  }
};
export const trackEvent = (eventName, data) => {
  PulseService.trackEvent(eventName, data);
  if (Config.isAdobeAnalyticsEnabled) {
    if (window._satellite && window._satellite.track) {
      window._satellite.track('event-trigger', {
        eventName: eventName,
        data: data,
      });
    }
  }
};

export const trackError = (message, properties) => {
  PulseService.trackError(message, properties);
  if (Config.isAdobeAnalyticsEnabled) {
    if (window._satellite && window._satellite.track) {
      window._satellite.track('event-error', { message, properties });
    }
  }
};

export const setUserContext = userId => {
  PulseService.addCustomDimensions({
    [Consts.JWT_FIELD_NAMES.UPN]: userId,
  });
};
