import React from 'react';
import Errors from '../../consts/Errors';
import URLs from '../../consts/URLs';
import ErrorPage from './ErrorPage';
import MTLink from '../../components/button/MTLink';
import Config from '../../utils/Config';

const WeDownErrorPage = ({
  title,
  subTitle = Config.isLiteModeSupportEnabled
    ? Errors.LITE_MODE_WE_DOWN_SUB_TEXT
    : Errors.WE_DOWN_SUB_TEXT,
  children,
  errorBoundaryError = false,
  errorData = null,
}) => {
  return (
    <ErrorPage
      title={Errors.WE_DOWN}
      subTitle={subTitle}
      errorBoundaryError={errorBoundaryError}
      errorData={errorData}
    >
      <div className="error-link-wrapper">
        <MTLink
          onClick={() => {
            window.location.reload();
          }}
        >
          Refresh
        </MTLink>
        <MTLink href={URLs.CONTACT_US}>Contact Us</MTLink>
      </div>
    </ErrorPage>
  );
};

export default WeDownErrorPage;
