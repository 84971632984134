import React from 'react';
import Colors from '../../../consts/Colors';
import Consts from '../../../consts/Consts';

const HeaderUserIntials = ({ initial, size, scheme }) => {
  const diameter = size === 'large' ? '97px' : '34px';
  const bgColor = scheme === 'dark' ? Colors.DARK_BLUE : Colors.WHITE;
  const textColor = scheme === 'dark' ? Colors.WHITE : Colors.DARK_BLUE;

  return (
    <span className={`bg ${size ? size : Consts.EMPTY}`}>
      <span className="initial">{initial}</span>

      <style jsx>
        {`
          .bg {
            display: block;
            width: ${diameter};
            height: ${diameter};
            border-radius: 50%;
            background-color: ${bgColor};
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .initial {
            font-size: 20px;
            font-weight: 500;
            color: ${textColor};
          }

          .large .initial {
            font-size: 42px;
          }
        `}
      </style>
    </span>
  );
};

export default HeaderUserIntials;
