import Consts from '../consts/Consts';
import Urls from '../consts/URLs';

const generateHyperLink = (link, text) => {
  return `<a href="${link}" target="_blank">${text || link}</a>`;
};

const ssprHelpLink = generateHyperLink(Urls.SSPR_HELP);
const ssprSetupLink = generateHyperLink(Urls.MANAGE_SETTINGS_SSPR);
const ssprOnboardSnowLink = generateHyperLink(Urls.SSPR_ONBOARD);
const ssprOptOutLink = generateHyperLink(Urls.SSPR_OPT_OUT);
const ssprMethodReset = generateHyperLink(Urls.SSPR_METHOD_RESET_URL);
const ssprFAQLink = generateHyperLink(Urls.SSPR_FAQ);

const isExternalUser = upn => {
  return upn.toLowerCase().endsWith('#ext#@walmart.onmicrosoft.com');
};

const SUPPORT_USER_HELP_TEXTS = {
  PRIMARY: {
    HEALTH_WELLNESS: () => `This user is identified as Health & Wellness and has not yet been onboarded as part of the new SSPR deployment. 
    Please have them reach out to their helpdesk or manager to find out when their store is scheduled to be onboarded to SSPR.
    <br><br>Also advise them how to:<br/><ul><li>SSPR Frequently Asked Questions: ${ssprFAQLink} </li></ul>`,

    METHOD_OPT_OUT: () => `This user has previously elected to opt out of using SSPR / MFA by going to ${ssprOptOutLink}. 
    To opt in again have them visit ${ssprOnboardSnowLink}, then after waiting 24 hours have them visit ${ssprSetupLink} to 
    register (ideally) three methods.<br><br>Also advise them how to:<br/><ul><li>SSPR Frequently Asked Questions: ${ssprFAQLink}</li>
    <li>To Opt out, or back into SSPR: ${ssprMethodReset}</li></ul>`,

    EXCLUDE: () => `This user’s account has been specifically excluded from SSPR for an unknown reason.  If they believe this is in error, 
    on behalf of the user, reach out to your escalation contact or Cloud Identity for further support
    <br><br>Also advise them how to:<br/><ul><li>SSPR Frequently Asked Questions: ${ssprFAQLink} </li></ul>`,

    ONBOARDING: upn => `This user has been onboarded.  Advise them their User Principal Name (UPN) is <b>${upn}</b>, which is what they should use 
    whenever configuring or using SSPR.<br><br>Also advise them how to:<br/><ul><li>Configure methods (recommend all three): ${ssprSetupLink} 
    while ON the Walmart network</li><li>Clear methods : ${ssprMethodReset} while ON the Walmart network</li>
    <li>SSPR Frequently Asked Questions: ${ssprFAQLink} </li></ul>`,

    BACKEND_OVERRIDE_INCLUDE: () => `This user has been forced to the old Health & Wellness “Back End” configuration. Advise the user you will need to escalate to find out why, 
    and reach out to your escalation contact or Cloud Identity for further support<br><br>Also advise them how to:<br/><ul>
    <li>SSPR Frequently Asked Questions: ${ssprFAQLink} </li></ul>`,

    OUT_OF_SCOPE: () => `User is out of scope for SSPR.  Examples include SMART or Distribution Center users, Process IDs and some elevated accounts.  
    If they believe this is in error, on behalf of the user, reach out to your contact in Cloud Identity for further support.<br><br>
    Also advise them how to:<br/><ul><li>SSPR Frequently Asked Questions: ${ssprFAQLink} </li></ul>`,

    POST_CONVERGE: upn => `This user has been onboarded by virtue of the fact that their account was created after 02-02-2022. Advise them their User 
    Principal Name (UPN) is <b>${upn}</b>, which is what they should use whenever configuring or using SSPR.<br><br>Also advise them how to:<br/><ul>
    <li>Configure methods (recommend all three): ${ssprSetupLink} while ON the Walmart network</li>
    <li>Clear methods : ${ssprMethodReset} while ON the Walmart network</li>
    <li>SSPR Frequently Asked Questions: ${ssprFAQLink} </li></ul><br/>`,

    IN_SCOPE: upn => `This user has been onboarded to the SSPR solution. 
    Advise them their User Principal Name (UPN) is <b>${upn}</b>, which is what they should use whenever configuring or using SSPR. <br><br>Also advise them how to:<br/><ul>
    <li>Configure methods (recommend all three): ${ssprSetupLink} while ON the Walmart network</li>
    <li>Clear methods : ${ssprMethodReset} while ON the Walmart network</li>
    <li>SSPR Frequently Asked Questions: ${ssprFAQLink} </li></ul><br/>If they are still having problems, advise them to reach out to their local 
    helpdesk as Health & Wellness processes are different to the rest of the Walmart community.`,

    NULL_OR_NOT_RECOGNIZED: () => `Users has an error condition that means SSPR cannot determine their state. Advise the user you will need to reach escalate to find 
    out why, and reach out to your escalation contact or Cloud Identity for further support.<br><br>Also advise them how to:<br/>
    <ul><li>SSPR Frequently Asked Questions: ${ssprFAQLink} </li></ul>`,

    SMART_ASSOCIATE: () => `This user is SMART Associate and out of scope for SSPR. If they believe this is in error, on behalf of the user, reach out to your contact 
    in Cloud Identity for further support.<br><br>Also advise them how to:<br/><ul><li>SSPR Frequently Asked Questions: ${ssprFAQLink} </li></ul>`,

    EXTERNAL_USER: () =>
      `This user is external to Walmart and as such will not be able to use Walmart SSPR. Advise the user to contact their helpdesk for help with password reset.
      <br><br>Also advise them how to:<br/><ul><li>SSPR Frequently Asked Questions: ${ssprFAQLink} </li></ul>`,
  },
  CM: {
    ONBOARD: upn => `This user also has a CM- account <b>${upn}</b> that is onboarded for SSPR.  
    To manage SSPR methods for this account, associate need to open an InPrivate/Incognito browser session and go to ${ssprSetupLink}.<br/><br/>`,

    OPT_OUT: upn => `This user also has a CM- account <b>${upn}</b>. User has previously elected to opt out of using SSPR / MFA by going to ${ssprOptOutLink}. 
    To opt in again have them visit ${ssprOnboardSnowLink}, then after waiting 24 hours have them visit ${ssprSetupLink} to register (ideally) three methods.<br/><br/>`,

    ELIGIBLE: upn => `This user also has a CM- account <b>${upn}</b> that is eligible for SSPR. To onboard, associate needs to submit the Service 
    Now request ${ssprOnboardSnowLink}, and once onboarded, click on “Manage Settings”, or visit ${ssprSetupLink} to register SSPR validation methods.<br/><br/>`,

    INELIGIBLE: upn => `This user also has a CM- account <b>${upn}</b> that is not eligible for SSPR at this time. We are working to bring SSPR to as many Walmart users as 
    possible, to check back here again, or watch for corporate announcements.<br/><br/>`,
  },
  CMCN: {
    ONBOARD: upn => `This user also has a CMCN- account <b>${upn}</b> that is onboarded for SSPR.  
    To manage SSPR methods for this account, associate need to open an InPrivate/Incognito browser session and go to ${ssprSetupLink}.<br/><br/>`,

    OPT_OUT: upn => `This user also has a CMCN- account <b>${upn}</b>. User has previously elected to opt out of using SSPR / MFA by going to ${ssprOptOutLink}. 
    To opt in again have them visit ${ssprOnboardSnowLink}, then after waiting 24 hours have them visit ${ssprSetupLink} to register (ideally) three methods.<br/><br/>`,

    ELIGIBLE: upn => `This user also has a CMCN- account <b>${upn}</b> that is eligible for SSPR. To onboard, associate needs to submit the Service 
    Now request ${ssprOnboardSnowLink}, and once onboarded, click on “Manage Settings”, or visit ${ssprSetupLink} to register SSPR validation methods.<br/><br/>`,

    INELIGIBLE: upn => `This user also has a CMCN- account <b>${upn}</b> that is not eligible for SSPR at this time. We are working to bring SSPR to as many Walmart users as 
    possible, to check back here again, or watch for corporate announcements.<br/><br/>`,
  },
};

const USER_HELP_TEXTS = {
  PRIMARY: {
    ONE_METHOD: () => `You only have one method registered, but the minimum required to use SSPR is two (or preferably three).
    In order to be fully registered so you can use SSPR, please click on the “Manage Settings” button above and register one or more new methods.<br/><br/>`,

    ONBOARD: upn =>
      `Your User Principal Name (UPN) is <b>${upn}</b>. Please make a note of this as you will present this whenever configuring or using SSPR.<br/><br/>`,

    OPT_OUT: () => `You are eligible to use SSPR but have previously opted out as you preferred not to use your personal device for validation.
    If you would like to opt back in, simply request to do so using the Service Now request ${ssprOnboardSnowLink},
    and once opted back in, return here and click on “Manage Settings”, or visit ${ssprSetupLink} to register your SSPR validation methods.
    More information can be found at ${ssprHelpLink} <br/><br/>`,

    ELIGIBLE: upn => `Your User Principal Name (UPN) is <b>${upn}</b>. Please make a note of this as you will present this whenever setting up or using SSPR.
    You are eligible to use SSPR but have not been onboarded yet. To onboard yourself simply submit the Service Now request ${ssprOnboardSnowLink},
    and once onboarded, return here and click on “Manage Settings”, or visit ${ssprSetupLink} to register your SSPR validation methods.
    More information can be found at ${ssprHelpLink} <br/><br/>`,

    INELIGIBLE: () => `Your account is not eligible for SSPR at this time. We are working to bring SSPR to as many Walmart users as possible, to check back here again, 
    or watch for corporate announcements.<br/><br/>`,
  },
  CM: {
    ONBOARD: upn => `You also have a CM- account <b>${upn}</b> that is onboarded for SSPR. Please make a note of this as you will present this whenever configuring or using SSPR.
    Please make a note of this as you will present this whenever configuring or using SSPR.<br/><br/>`,

    OPT_OUT: upn => `You also have a CM- account <b>${upn}</b> that is eligible to use SSPR but have previously opted out as you preferred not to use your personal 
    device for validation.If you would like to opt back in, simply request to do so using the Service Now request ${ssprOnboardSnowLink},
    and once opted back in, return here and click on “Manage Settings”, or visit ${ssprSetupLink} to register your SSPR validation methods.
    More information can be found at ${ssprHelpLink} <br/><br/>`,

    ELIGIBLE: upn => `You also have a CM- account <b>${upn}</b> that is eligible for SSPR.  Once you have opted back in for your primary account, 
    be sure to register your validation methods for this account also.<br/><br/>`,

    INELIGIBLE: upn => `You also have a CM- account <b>${upn}</b> that is not eligible for SSPR at this time. We are working to bring SSPR to as many Walmart users 
    as possible, to check back here again, or watch for corporate announcements.<br/><br/>`,
  },
  CMCN: {
    ONBOARD: upn => `You also have a CMCN- account <b>${upn}</b> that is onboarded for SSPR. Please make a note of this as you will present this whenever configuring or using SSPR.
    Please make a note of this as you will present this whenever configuring or using SSPR.<br/><br/>`,

    OPT_OUT: upn => `You also have a CMCN- account <b>${upn}</b> that is eligible to use SSPR but have previously opted out as you preferred not to use your personal 
    device for validation.If you would like to opt back in, simply request to do so using the Service Now request ${ssprOnboardSnowLink},
    and once opted back in, return here and click on “Manage Settings”, or visit ${ssprSetupLink} to register your SSPR validation methods.
    More information can be found at ${ssprHelpLink} <br/><br/>`,

    ELIGIBLE: upn => `You also have a CMCN- account <b>${upn}</b> that is eligible for SSPR.  Once you have opted back in for your primary account, 
    be sure to register your validation methods for this account also.<br/><br/>`,

    INELIGIBLE: upn => `You also have a CMCN- account <b>${upn}</b> that is not eligible for SSPR at this time. We are working to bring SSPR to as many Walmart users 
    as possible, to check back here again, or watch for corporate announcements.<br/><br/>`,
  },
};

const getSupportUserMessage = (ssprAccountData, acType, isSMARTAssociate) => {
  const upn = ssprAccountData.upn;
  const ssprGroup = ssprAccountData.group;
  const ssprStatus = ssprAccountData.ssprStatus;
  if (isExternalUser(upn)) {
    return SUPPORT_USER_HELP_TEXTS.PRIMARY.EXTERNAL_USER();
  }
  if (
    ssprGroup === Consts.SECURITY_ACCESS.SSPR_GROUP.OUT_OF_SCOPE &&
    isSMARTAssociate
  ) {
    return SUPPORT_USER_HELP_TEXTS.PRIMARY.SMART_ASSOCIATE();
  }

  if (acType === Consts.SECURITY_ACCESS.SSPR_AC_TYPES.PRIMARY) {
    switch (ssprGroup) {
      case Consts.SECURITY_ACCESS.SSPR_GROUP.HEALTH_WELLNESS:
        return SUPPORT_USER_HELP_TEXTS.PRIMARY.HEALTH_WELLNESS();
      case Consts.SECURITY_ACCESS.SSPR_GROUP.METHOD_OPT_OUT:
        return SUPPORT_USER_HELP_TEXTS.PRIMARY.METHOD_OPT_OUT();
      case Consts.SECURITY_ACCESS.SSPR_GROUP.EXCLUDE:
        return SUPPORT_USER_HELP_TEXTS.PRIMARY.EXCLUDE();
      case Consts.SECURITY_ACCESS.SSPR_GROUP.OVERRIDE_INCLUDE:
      case Consts.SECURITY_ACCESS.SSPR_GROUP.ONBOARDING:
        return SUPPORT_USER_HELP_TEXTS.PRIMARY.ONBOARDING(upn);
      case Consts.SECURITY_ACCESS.SSPR_GROUP.BACKEND_OVERRIDE_INCLUDE:
        return SUPPORT_USER_HELP_TEXTS.PRIMARY.BACKEND_OVERRIDE_INCLUDE();
      case Consts.SECURITY_ACCESS.SSPR_GROUP.OUT_OF_SCOPE:
        return SUPPORT_USER_HELP_TEXTS.PRIMARY.OUT_OF_SCOPE();
      case Consts.SECURITY_ACCESS.SSPR_GROUP.POST_CONVERGE:
        return SUPPORT_USER_HELP_TEXTS.PRIMARY.POST_CONVERGE(upn);
      case Consts.SECURITY_ACCESS.SSPR_GROUP.IN_SCOPE:
        return SUPPORT_USER_HELP_TEXTS.PRIMARY.IN_SCOPE(upn);
      case Consts.SECURITY_ACCESS.SSPR_GROUP.NULL_OR_NOT_RECOGNIZED:
        return SUPPORT_USER_HELP_TEXTS.PRIMARY.NULL_OR_NOT_RECOGNIZED();
      default:
        return '';
    }
  } else if (acType === Consts.SECURITY_ACCESS.SSPR_AC_TYPES.CM) {
    switch (ssprStatus) {
      case Consts.SECURITY_ACCESS.SSPR_STATUS.ONBOARD:
        return SUPPORT_USER_HELP_TEXTS.CM.ONBOARD(upn);
      case Consts.SECURITY_ACCESS.SSPR_STATUS.OPT_OUT:
        return SUPPORT_USER_HELP_TEXTS.CM.OPT_OUT(upn);
      case Consts.SECURITY_ACCESS.SSPR_STATUS.ELIGIBLE:
        return SUPPORT_USER_HELP_TEXTS.CM.ELIGIBLE(upn);
      case Consts.SECURITY_ACCESS.SSPR_STATUS.INELIGIBLE:
        return SUPPORT_USER_HELP_TEXTS.CM.INELIGIBLE(upn);
      default:
        return '';
    }
  } else if (acType === Consts.SECURITY_ACCESS.SSPR_AC_TYPES.CMCN) {
    switch (ssprStatus) {
      case Consts.SECURITY_ACCESS.SSPR_STATUS.ONBOARD:
        return SUPPORT_USER_HELP_TEXTS.CMCN.ONBOARD(upn);
      case Consts.SECURITY_ACCESS.SSPR_STATUS.OPT_OUT:
        return SUPPORT_USER_HELP_TEXTS.CMCN.OPT_OUT(upn);
      case Consts.SECURITY_ACCESS.SSPR_STATUS.ELIGIBLE:
        return SUPPORT_USER_HELP_TEXTS.CMCN.ELIGIBLE(upn);
      case Consts.SECURITY_ACCESS.SSPR_STATUS.INELIGIBLE:
        return SUPPORT_USER_HELP_TEXTS.CMCN.INELIGIBLE(upn);
      default:
        return '';
    }
  }
};

export const getSSPRInfoMessage = (
  ssprAccountData,
  acType,
  isOnlyOneAuthMethod,
  isSupportUser,
  isSMARTAssociate = false
) => {
  const upn = ssprAccountData.upn;
  const ssprStatus = ssprAccountData.ssprStatus;
  if (isSupportUser) {
    return getSupportUserMessage(ssprAccountData, acType, isSMARTAssociate);
  }
  if (acType === Consts.SECURITY_ACCESS.SSPR_AC_TYPES.PRIMARY) {
    switch (ssprStatus) {
      case Consts.SECURITY_ACCESS.SSPR_STATUS.ONBOARD:
        return (
          USER_HELP_TEXTS.PRIMARY.ONBOARD(upn) +
          (isOnlyOneAuthMethod ? USER_HELP_TEXTS.PRIMARY.ONE_METHOD() : '')
        );
      case Consts.SECURITY_ACCESS.SSPR_STATUS.OPT_OUT:
        return USER_HELP_TEXTS.PRIMARY.OPT_OUT();
      case Consts.SECURITY_ACCESS.SSPR_STATUS.ELIGIBLE:
        return USER_HELP_TEXTS.PRIMARY.ELIGIBLE(upn);
      case Consts.SECURITY_ACCESS.SSPR_STATUS.INELIGIBLE:
        return USER_HELP_TEXTS.PRIMARY.INELIGIBLE();
      default:
        return '';
    }
  } else if (acType === Consts.SECURITY_ACCESS.SSPR_AC_TYPES.CM) {
    switch (ssprStatus) {
      case Consts.SECURITY_ACCESS.SSPR_STATUS.ONBOARD:
        return USER_HELP_TEXTS.CM.ONBOARD(upn);
      case Consts.SECURITY_ACCESS.SSPR_STATUS.OPT_OUT:
        return USER_HELP_TEXTS.CM.OPT_OUT(upn);
      case Consts.SECURITY_ACCESS.SSPR_STATUS.ELIGIBLE:
        return USER_HELP_TEXTS.CM.ELIGIBLE(upn);
      case Consts.SECURITY_ACCESS.SSPR_STATUS.INELIGIBLE:
        return USER_HELP_TEXTS.CM.INELIGIBLE(upn);
      default:
        return '';
    }
  } else if (acType === Consts.SECURITY_ACCESS.SSPR_AC_TYPES.CMCN) {
    switch (ssprStatus) {
      case Consts.SECURITY_ACCESS.SSPR_STATUS.ONBOARD:
        return USER_HELP_TEXTS.CMCN.ONBOARD(upn);
      case Consts.SECURITY_ACCESS.SSPR_STATUS.OPT_OUT:
        return USER_HELP_TEXTS.CMCN.OPT_OUT(upn);
      case Consts.SECURITY_ACCESS.SSPR_STATUS.ELIGIBLE:
        return USER_HELP_TEXTS.CMCN.ELIGIBLE(upn);
      case Consts.SECURITY_ACCESS.SSPR_STATUS.INELIGIBLE:
        return USER_HELP_TEXTS.CMCN.INELIGIBLE(upn);
      default:
        return '';
    }
  }
};
