import React from 'react';
import { trackEvent } from '../../utils/appInsight';
import Consts from '../../consts/Consts';
import Events from '../../consts/Events';
import MTLD3Button from '../button/MTLD3Button';
import { ExternalLink } from '@livingdesign/icons';

/**
 * Per ACE and Level Access team we should avoid Aria-Labels here and make sure the link label is direct on the action
 * @param href - route to be used when the link is clicked
 * @param label - Label to be shown for the action link
 * @param isCurrent - Is this link for the current selected alert
 * @returns Action link for the alert carousel content
 */

const AlertBannerActionLink = ({
  href = Consts.EMPTY,
  label = Consts.EMPTY,
  isCurrent = true,
  ...props
}) => {
  const firstStringChar = 0;
  const isInternalRouteHref = href[firstStringChar] === '/';
  const trackingEventToBeUsed = isInternalRouteHref
    ? trackEvent(Events.ALERTBANNER.ACTION_LINK(href, false))
    : trackEvent(Events.ALERTBANNER.ACTION_LINK(href, true));
  const toProp = isInternalRouteHref ? href : undefined;
  const hrefProp = !isInternalRouteHref ? href : undefined;
  const showInTabIndex = isCurrent ? null : -1;

  return (
    <MTLD3Button
      variant="tertiary"
      href={hrefProp}
      newTab={!isInternalRouteHref}
      to={toProp}
      track={trackingEventToBeUsed}
      tabIndex={showInTabIndex}
      leading={!isInternalRouteHref ? <ExternalLink /> : null}
      {...props}
    >
      {label}
    </MTLD3Button>
  );
};

export default AlertBannerActionLink;
