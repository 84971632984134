import axios from 'axios';
import {
  interceptorSuccessHandler,
  interceptorErrorHandler,
  addTokenToHeader,
  addTorbitHeader,
  addSessionHeader,
} from './interceptorHelpers';

export const interceptRequests = (setHasError, setErrorType) => {
  axios.interceptors.request.use(addTokenToHeader);
  axios.interceptors.request.use(addTorbitHeader);
  axios.interceptors.request.use(addSessionHeader);

  axios.interceptors.response.use(
    response => {
      return interceptorSuccessHandler(response, setHasError, setErrorType);
    },
    error => {
      return interceptorErrorHandler(error, setHasError, setErrorType);
    }
  );
};

export const fetchMFEHeaders = instance => {
  instance.interceptors.request.use(req => addTokenToHeader(req));
  instance.interceptors.request.use(req => addTorbitHeader(req));
  instance.interceptors.request.use(req => addSessionHeader(req));
  instance.interceptors.response.use(
    _response => _response,
    error => error
  );
};
