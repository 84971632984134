import React from 'react';
import MTLogoutButton from '../../../components/misc/MTLogoutButton';
import Colors from '../../../consts/Colors';
import Consts from '../../../consts/Consts';

const HeaderUserInfo = ({ name, upn }) => {
  return (
    <div className="wrapper">
      <div className="name">{name}</div>
      <div className="upn">{upn.replace(/@.*$/, Consts.EMPTY)}</div>

      <MTLogoutButton />

      <style jsx>
        {`
          .wrapper {
            margin-left: 13px;
          }

          .name {
            max-width: 150px;
            word-wrap: break-word;
            font-size: 16px;
            font-weight: 500;
            color: ${Colors.BLACK};
          }

          .upn {
            font-size: 14px;
            color: ${Colors.TEXT_LIGHT_GREY};
          }

          .wrapper :global(.mtButton) {
            margin-top: 20px;
            display: inline-block;
          }
        `}
      </style>
    </div>
  );
};

export default HeaderUserInfo;
