import { ChevronLeft, ChevronRight } from '@livingdesign/icons';
import { Grid } from '@walmart-web/livingdesign-components';
import AriaLabel from '../../consts/AriaLabel';
import Events from '../../consts/Events';
import { trackEvent } from '../../utils/appInsight';
import Consts from '../../consts/Consts';
import MTLD3IconButton from '../button/MTLD3IconButton';
import MTLD3Button from '../button/MTLD3Button';

const customStyles = {
  button: {
    height: '1rem',
    width: '1rem',
    borderRadius: '50%',
    marginRight: '5px',
    marginLeft: '5px',
    padding: 'unset',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

// Note: used with react-multi-carousel props
const AlertBannerCustomButtons = ({
  alerts,
  next,
  previous,
  goToSlide,
  ...rest
}) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  const firstAlert = 0;
  const lastAlert = alerts.length - 1;
  const diablePreviousNavigationButton = currentSlide === firstAlert;
  const diableNextNavigationButton = currentSlide === lastAlert;

  return (
    <Grid
      UNSAFE_style={customStyles.container}
      UNSAFE_className="carousel-button-group" // carousel related class
      role={Consts.ARIA_ROLES.GROUP}
      aria-label={AriaLabel.GROUPS.ALERT_CAROUSEL_NAVIGATION}
    >
      <MTLD3IconButton
        disabled={diablePreviousNavigationButton}
        onClick={previous}
        track={() => {
          trackEvent(Events.ALERTBANNER.PREVIOUS_CHEVRON_CLICKED);
        }}
        a11yLabel={AriaLabel.ALERT_BANNER_PREVIOUS_NAVIGATION_CHEVERONS}
      >
        <ChevronLeft aria-hidden={true} />
      </MTLD3IconButton>
      {alerts.map((alert, i) => {
        const { title } = alert;
        const slideId = i;
        const navButtonId = i + 1; // adjusts for user to not get confused by seeing 0
        const currentDotToSlide = currentSlide === slideId;
        return (
          <MTLD3Button
            key={slideId}
            style={customStyles.button}
            variant={currentDotToSlide ? 'primary' : 'secondary'}
            aria-label={AriaLabel.ALERT_BANNER_SLIDE_NAVIGATION_CIRCLE(
              navButtonId,
              alerts.length
            )}
            onClick={() => {
              goToSlide(slideId);
            }}
            track={() => {
              trackEvent(Events.ALERTBANNER.CONTENT_CIRCLE_CLICKED(title));
            }}
            aria-current={currentDotToSlide ? true : false}
          />
        );
      })}
      <MTLD3IconButton
        disabled={diableNextNavigationButton}
        onClick={next}
        track={() => {
          trackEvent(Events.ALERTBANNER.NEXT_CHEVRON_CLICKED);
        }}
        a11yLabel={AriaLabel.ALERT_BANNER_NEXT_NAVIGATION_CHEVERONS}
      >
        <ChevronRight aria-hidden={true} />
      </MTLD3IconButton>
    </Grid>
  );
};

export default AlertBannerCustomButtons;
