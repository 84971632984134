import React, { useEffect } from 'react';
import ErrorHandler from '../../utils/ErrorHandler';
import { isProd } from '../../utils/helpers';
import Page from '../page/Page';
import { ReactComponent as GenError } from '../../assets/svg/GenError.svg';
import Events from '../../consts/Events';

const ErrorPage = ({
  title,
  subTitle,
  children,
  badRouteError = false,
  errorBoundaryError = false,
  errorData = null,
  image = null,
  includeImage = true,
  includeErrorIcon = true,
  small = false,
}) => {
  useEffect(() => {
    //JP: title check excludes all this?
    if (title) {
      const errorInfo = { message: title };
      if (badRouteError) {
        errorInfo.invalidRoute = String(window.location);
      }
      if (errorBoundaryError) {
        errorInfo.errorBoundaryError = true;
      }
      if (errorData) {
        errorInfo.errorData = errorData;
      }

      ErrorHandler.trackError(Events.ERRORS.TITLE.ERROR_PAGE, errorInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="error-page-wrapper">
      <Page
        title={title}
        subTitle={subTitle}
        includeErrorIcon={includeErrorIcon}
      >
        {children}
        {includeImage && (
          <div className="error-img-wrapper">
            {image ? image : <GenError />}
          </div>
        )}
        {!isProd && errorBoundaryError && (
          <>
            <div>
              <b>ErrorBoundary Error</b>
            </div>
            <div>{errorData}</div>
          </>
        )}
      </Page>
      <style jsx>
        {`
          .error-page-wrapper {
            width: 100%;
          }

          .error-img-wrapper {
            display: flex;
            justify-content: center;
          }

          .error-page-wrapper :global(.pageHeader) {
            max-width: none;
          }

          .error-page-wrapper :global(.error-link-wrapper) {
            width: 100%;
            display: inline-block;
            text-align: center;
            font-size: 20px;
            margin-bottom: 20px;
          }

          .error-page-wrapper :global(.error-link-wrapper a) {
            padding: 0 10px;
          }

          .error-img-wrapper :global(svg) {
            width: ${small ? 546 : 728}px;
            height: ${small ? 309 : 412}px;
          }

          @media only screen and (max-width: 550px) {
            .error-img-wrapper :global(svg) {
              width: 528px;
              height: 212px;
            }
          }

          @media only screen and (max-width: 338px) {
            .error-img-wrapper :global(svg) {
              width: 248px;
              height: 132px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default ErrorPage;
