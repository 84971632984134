import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import Consts from '../../../consts/Consts';
import Colors from '../../../consts/Colors';
import MTFormItem from '../item/MTFormItem';
import MTRadioButton from './MTRadioButton';

//NOTE: Use initialValue, not selected attribute.

let mtRadioButtonIdCounter = 1;

//Any component that includes this, must define "name" and "initialValue" as defaultProps/attributes and optionally "yup", so they can be read by <MTForm> to setup <Formik> and Yup validationSchema.
const MTRadioButtons = ({
  name,
  yup,
  label,
  halfWidth,
  initialValue, //WARNING: For form validation, this must match any the initially selected value, including an empty string if blank.
  children,
  buttons = [
    /* Optional param to pass button objects, instead of passing children. NOTES: Ignored if has children. Also supports label prop if diff from value.
    { value: 'Verizon' },
    { value: 'AT&T'} */
  ],
  showLabel,
  twoColumn,
  className,
  note,
  largeNote,
}) => {
  const _className = `mtRadioButtons`;
  const isRequired = yup && yup._exclusive && Boolean(yup._exclusive.required);
  const checkedError = `Error: For MTRadioButtons, please use initialValue prop, not the checked prop/attribute. name: ${name}`;

  const getChildren = field => {
    return children
      ? React.Children.map(children, child => {
          return React.cloneElement(child, {
            ...field,
            field: field,
            id: `mtRadioButton${mtRadioButtonIdCounter++}`,
            key: `mtRadioButton${child.props.value}`,
            value: child.props.value,
            label: child.props.label,
            groupName: name,
            disabled: child.props.disabled,
          });
        })
      : buttons.length
      ? buttons.map(button => {
          if (button.checked) {
            throw Error(checkedError);
          }

          return (
            <MTRadioButton
              {...field}
              field={field}
              id={`mtRadioButton${mtRadioButtonIdCounter++}`}
              key={`mtRadioButton${button.value}`}
              value={button.value}
              label={button.label}
              groupName={name}
              disabled={button.disabled}
              showWarning={button.showWarning}
            />
          );
        })
      : null;
  };

  React.Children.forEach(children, button => {
    if (button.props.checked) {
      throw Error(checkedError);
    }
  });

  return (
    <MTFormItem
      id={name}
      name={name}
      label={label}
      className={`${_className} ${className ? className : Consts.EMPTY}`}
      halfWidth={halfWidth}
      isRequired={isRequired}
      showLabel={showLabel}
      twoColumn={twoColumn}
      note={note}
      largeNote={largeNote}
    >
      <Field name={name}>
        {({ field }) => {
          return (
            <span className="radioButtonsInner">{getChildren(field)}</span>
          );
        }}
      </Field>

      <style jsx>
        {`
          :global(.${_className}.error input) {
            border-color: ${Colors.ERROR_RED} !important;
          }

          :global(.${_className}.error label) {
            color: ${Colors.ERROR_RED} !important;
          }

          :global(.${_className} > span) {
            display: flex;
            margin-top: 10px;
          }
        `}
      </style>
    </MTFormItem>
  );
};

export default MTRadioButtons;

MTRadioButtons.defaultProps = {
  showLabel: true,
};

MTRadioButtons.propTypes = {
  name: PropTypes.string.isRequired,
  yup: PropTypes.object,
  label: PropTypes.string.isRequired,
  halfWidth: PropTypes.bool,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired, //WARNING: Must match any pre-checked value for form validation
  children: PropTypes.node,
  buttons: PropTypes.array,
};
