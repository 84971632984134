import axios from 'axios';
import React, { useEffect, useState } from 'react';
import APIs from '../../consts/APIs';
import Consts from '../../consts/Consts';
import UserType from '../../user/UserType';
import { trackEvent, trackPage } from '../../utils/appInsight';
import Config from '../../utils/Config';
import Dialog from '../../components/dialog/Dialog';
import MTForm from '../../components/form/MTForm';
import MTRadioButtons from '../../components/form/controls/MTRadioButtons';
import MTOtherTextArea from '../../components/form/components/MTOtherTextArea';
import MTCustomRadioButton from '../../components/form/controls/MTCustomRadioButton';
import * as Yup from 'yup';
import Messages from '../../consts/Messages';
import Events from '../../consts/Events';
import { ReactComponent as HowDidYouHearAboutUsIcon } from '../../assets/svg/howDidYouHearAboutUs.svg';
import FormErrors from '../../consts/FormErrors';

const HowDidYouHearAboutUsDialog = ({
  isHowDidYouHearAboutUsOpen,
  setHowDidYouHearAboutUsOpen,
}) => {
  const [selectedOption, setSelectedOption] = useState(Messages.OTHER);

  useEffect(() => {
    if (isHowDidYouHearAboutUsOpen) {
      trackPage({
        name: Events.HOW_DID_YOU_HEAR_ABOUT_US.LANDING,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitHowDidYouHearAboutUsInfo = async (userUpn, option) => {
    const mutation = `mutation saveHowDidYouHearAboutUs($howDidYouHearAboutUs: HowDidYouHearAboutUs!) {
          saveHowDidYouHearAboutUs(howDidYouHearAboutUs: $howDidYouHearAboutUs) {
            id
            status
          }
        }`;

    return await axios.post(
      APIs.getAPIUrl(APIs.GRAPHQL),
      {
        query: mutation,
        variables: {
          howDidYouHearAboutUs: {
            userEmail: userUpn,
            option: option.option,
            otherAnswer: option.otherText ? option.otherText : Consts.EMPTY,
          },
        },
      },
      {
        headers: {
          ignoreGraphQLError: true,
          ignoreGenericError: true,
        },
      }
    );
  };

  return (
    <React.Fragment>
      <Dialog
        isDialogOpen={isHowDidYouHearAboutUsOpen}
        closeDialog={() => {
          trackEvent(Events.HOW_DID_YOU_HEAR_ABOUT_US.CANCELLED);
          setHowDidYouHearAboutUsOpen(false);
        }}
      >
        <div className="icon">
          <HowDidYouHearAboutUsIcon />
        </div>
        <p>Please take a moment and tell us how you heard about MyTech</p>
        <MTForm
          onSubmit={async formikValues => {
            trackEvent(Events.HOW_DID_YOU_HEAR_ABOUT_US.SUBMITTED, {
              userUpn: UserType.userPrincipalName,
              option: formikValues,
            });

            setHowDidYouHearAboutUsOpen(false);

            await submitHowDidYouHearAboutUsInfo(
              UserType.userPrincipalName,
              formikValues
            );
          }}
          showRequired={false}
        >
          <MTRadioButtons
            name="option"
            showLabel={false}
            initialValue=""
            label="Options"
            yup={Yup.string().required(FormErrors.HOW_DID_YOU_HEAR)}
          >
            {Config.HowDidYouHearAboutUsOptions.map(option => {
              return (
                <MTCustomRadioButton
                  key={option}
                  groupName={'option'}
                  value={option}
                  setSelectedOption={setSelectedOption}
                  selectedOption={selectedOption}
                />
              );
            })}
          </MTRadioButtons>
          {selectedOption === Messages.OTHER ? <MTOtherTextArea /> : null}
        </MTForm>
      </Dialog>
      <style jsx>
        {`
          div.icon {
            text-align: center;
            margin: 10px 0;
          }

          p {
            font-size: 26px;
            width: 80%;
            margin: 0 auto;
            text-align: center;
          }

          :global(#mytech-dialog .content-wrapper) {
            max-width: 540px;
          }

          p + :global(.mtForm .mtRadioButtons .formError) {
            width: 60%;
            margin: 0 auto;
          }

          @media screen and (max-width: 480px) {
            :global(div.icon svg) {
              transform: scale(0.6);
            }

            p {
              width: 95%;
              font-size: 20px;
            }

            p + :global(.mtForm .mtRadioButtons .formError) {
              width: 95%;
            }
          }
        `}
      </style>
    </React.Fragment>
  );
};

export default HowDidYouHearAboutUsDialog;
