import React from 'react';
import { ReactComponent as SpinningSpark } from '../../assets/svg/spinningSpark.svg';
import Colors from '../../consts/Colors';

const LoadingIcon = ({ color = 'primary' }) => {
  return (
    <section className="loadingicon-container">
      <div className="loadingicon-loading">
        <SpinningSpark />
      </div>

      <style jsx>{`
        section {
          width: 100%;
          height: calc(100vh - 70px);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        div {
          height: 140px;
          width: 140px;
        }

        div :global(.cls-1) {
          fill: ${color === 'secondary' ? Colors.YELLOW : Colors.TEXT_GREY};
        }

        div :global(.spinningSpark) {
          padding: 10px;
        }

        div :global(.spark) {
          -webkit-animation: rotation 3.75s 1 ease-in,
            rotation 2.25s infinite linear 3.75s;
          animation: rotation 3.75s 1 ease-in,
            rotation 2.25s infinite linear 3.75s;
          transform-origin: 50% 50%;
        }

        @keyframes rotation {
          from {
            -webkit-transform: rotate(0deg);
          }
          to {
            -webkit-transform: rotate(359deg);
          }
        }
      `}</style>
    </section>
  );
};

export default LoadingIcon;
