const CSS = {
  PAGE_PADDING_LEFT_RIGHT: '20px',
  PAGE_CONTENT_MAX_WIDTH: '791px',
  PAGE_CONTENT_MAX_WIDTH_MEDIUM: '912px',
  PAGE_CONTENT_MAX_WIDTH_LARGE: '1150px',
  STANDARD_MAX_MOBILE_VIEW: '1140px', //DL's 900px min-width + LEFT_COLUMN_WIDTH
  STANDARD_MAX_MOBILE_PHONE_VIEW: '600px',
  STANDARD_MAX_TABLET_VIEW: '1024px',
  HEADER_HEIGHT: '64px',
  LEFT_COLUMN_WIDTH: '225px',
  FILTERS_COLUMN_WIDTH: '240px',
  BORDER_RADIUS: '8px',
  COMPONENT_BORDER_RADIUS: '4px',
  BUTTON_WIDTH_LARGE: '179px',
  TWO_ACROSS_MIN_WIDTH: '375px',
  TWO_ACROSS_MAX_WIDTH: '599px',
  APPS_TWO_ACROSS_MIN_WIDTH: '600px',
  APPS_TWO_ACROSS_MAX_WIDTH: '1199px',
  CARD_DROP_SHADOW_DIMENSIONS: '0px 1px 2px 1px',
  NAV_MAX_WIDTH_DESKTOP: '225px',
  NAV_MAX_WIDTH_MOBILE: '350px',
  columnProps: {
    column1Basis: '65%',
    column2Basis: '35%',
    column2MarginLeft: '75px',
    breakpoint: '850px',
  },
  MOBILE_CARD_SKELETON: {
    MOBILE: {
      WIDTH: '100%',
      HEIGHT: '267px',
    },
    TABLET: {
      WIDTH: '728px',
      HEIGHT: '150px',
    },
    DESKTOP: {
      WIDTH: '265px',
      HEIGHT: '405px',
    },
  },
};

export const addPixel = size => {
  return `${parseInt(size) + 1}px`;
};

export const subtractPixel = size => {
  return `${parseInt(size) - 1}px`;
};

export default CSS;
