import Consts from '../consts/Consts';

export default class OrderStatus {
  static isTrackedOrder = status => {
    return (
      OrderStatus.isNew(status) ||
      OrderStatus.isDelivered(status) ||
      OrderStatus.isApproved(status) ||
      OrderStatus.isRejected(status) ||
      OrderStatus.isReturned(status) ||
      OrderStatus.isExtended(status) ||
      OrderStatus.isClosed(status)
    );
  };

  static isNew = status => {
    return status === OrderStatus.NEW;
  };
  static isDelivered = status => {
    return status === OrderStatus.DELIVERED;
  };
  static isApproved = status => {
    return status === OrderStatus.APPROVED;
  };
  static isRejected = status => {
    return status === OrderStatus.REJECTED;
  };
  static isReturned = status => {
    return status === OrderStatus.RETURNED;
  };
  static isExtended = status => {
    return status === OrderStatus.EXTENDED;
  };
  static isCompleted = status => {
    return status === OrderStatus.COMPLETED;
  };
  static isClosed = status => {
    return status === OrderStatus.CLOSED;
  };

  static getTrackedOrdersArray = orderData => {
    return orderData.filter(item => {
      return (
        OrderStatus.isTrackedOrder(item.status) &&
        item.activeFlow !== Consts.REQUEST_TYPES.INTERNATIONAL_PLAN
      );
    });
  };
}

OrderStatus.NEW = 'New';
OrderStatus.DELIVERED = 'delivered';
OrderStatus.APPROVED = 'approved';
OrderStatus.REJECTED = 'rejected';
OrderStatus.RETURNED = 'returned';
OrderStatus.EXTENDED = 'Extended';
OrderStatus.SAVED = 'Saved';
OrderStatus.COMPLETED = 'Completed';
OrderStatus.CLOSED = 'closed';
