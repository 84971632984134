import React from 'react';
import Routes from '../../../consts/Routes';
import { Link } from 'react-router-dom';
import Consts from '../../../consts/Consts';
import Colors from '../../../consts/Colors';
import { IconButton } from '@walmart-web/livingdesign-components';
import { Warning } from '@livingdesign/icons';
import AriaLabel from '../../../consts/AriaLabel';

// Dev Note: Did not add event listener as this is used daily by MyTech Engineers and is only shown in the lower dev environment

const HeaderAdminUserType = ({ adminUserType }) => {
  const isImpersonatedType =
    adminUserType === Consts.ADMIN_USER_TYPES.IMPERSONATED;
  const redirectRoute = isImpersonatedType
    ? Routes.ADMIN_IMPERSONATE_MYSELF
    : Routes.ADMIN_USERS;

  const customStyles = {
    iconStyle: {
      color: isImpersonatedType ? Colors.RED : Colors.YELLOW,
      paddingLeft: '6px',
      paddingRight: '6px',
    },
  };

  return (
    <Link
      tabIndex="-1" // Don't allow tab for the link, use the Button as the official tab
      to={redirectRoute}
      className="is-nav headerAdminUserType" /* is-nav is currently used for checkIfNavigatableElementClicked in DL and other hidding utilities. Need to review once DL has been offboarded. */
      aria-label={AriaLabel.ADMIN_USER_ICON(isImpersonatedType)}
      role="button"
    >
      <IconButton
        a11yLabel={AriaLabel.ADMIN_USER_ICON(isImpersonatedType)}
        size="large"
        UNSAFE_style={customStyles.iconStyle}
      >
        <Warning aria-hidden={true} />
      </IconButton>
    </Link>
  );
};

export default HeaderAdminUserType;
