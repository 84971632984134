const Colors = {
  PINK: '#dd1385',
  WHITE: '#fff',
  LIGHT_WHITE: '#cacccb',
  LIGHT_GREY: '#f6f6f6',
  MEDIUM_GREY: '#dadce0',
  DARK_GREY: '#333',
  DARKER_GREY: '#292929',
  GREY_BORDER: '#515357',
  BLUE: '#0071DC',
  LIGHT_BLUE: '#F2F8FD',
  MEDIUM_BLUE: '#004F9A',
  DARK_BLUE: '#041F41',
  LIGHTER_BLUE: '#0072DC',
  VERY_LIGHT_BLUE: '#E6F1FC',
  DARKER_BLUE: '#002242',
  SHADED_BLUE: '#004e99',
  BLACK: '#000',
  MEDIUM_BLACK: '#171819',
  ERROR_RED: '#e62716',
  COMPONENT_LIGHT_GREY: '#909196',
  BUTTON_BORDER: '#2E2F32',
  TEXT_DEFAULT: '#2E2F32',
  BUTTON_DISABLED: '#BABBBE',
  TEXT_GREY: '#74767C',
  TEXT_LIGHT_GREY: '#666',
  PLACEHOLDER_GREY: '#74767C',
  DIVIDER_GREY: '#545454',
  LIGHTEST_GREY: '#E3E4E5',
  CONNECTOR_GREY: '#333333',
  RED: '#FF0000',
  YELLOW: '#ffc220',
  GREEN: '#25cc54',
  YELLOW_BG: '#fff9e9',
  YELLOW_TXT: '#662b0d',
  YELLOW_BORDER: '#995213',
  DARK_YELLOW: '#ffe49d',
  GREEN_BORDER: '#95C381',
  GREEN_BG: '#EAF3E6',
  GREEN_TXT: '#53863D',
  DARK_GREEN: '#2a8703',
  LIGHT_GREEN: '#95c381',
  LIGHTER_GREEN: '#F4F9F2',
  RED_BORDER: '#DE1D24',
  RED_BG: '#FCE8E8',
  RED_TXT: '#A22628',
  LIGHT_RED: '#EF8E92',
  LIGHTER_RED: '#FDF4F4',
  LIGHT_RED_BG: '#FCE8E9',
  ORANGE: '#B36A16',
  BLACK_TXT: '#2e2f32',
  VERY_LIGHT_GREY: '#f8f8f8',
};

export const LightTheme = {
  htmlColor: Colors.BLACK,
  htmlBG: Colors.WHITE,
  headerBG: Colors.DARK_BLUE,
  headerMenuBG: Colors.WHITE,
  formLabelColor: Colors.TEXT_GREY,
  navBG: Colors.WHITE,
  navShadowColor: 'rgba(0, 0, 0, 0.15)',
  cardOffWhiteBG: '#f2f8fd',
  dialogBG: Colors.WHITE,
  dialogUnderlayBG: 'rgba(51, 51, 51, 0.4)',
  cardDropShadowColor: 'rgba(0, 0, 0, 0.15)',
  border: Colors.MEDIUM_GREY,
  tableHeaderBG: '#f8f8f8',
};

export default Colors;
