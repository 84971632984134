import axios from 'axios';
import APIs from '../consts/APIs';
import UserType from '../user/UserType';
import { successFailure } from '../utils/interceptorHelpers';

export const fetchFeedbackForUser = async (
  hasRequestedFeedbackData,
  hasUserSubmittedFeedback,
  collection
) => {
  if (!hasRequestedFeedbackData) {
    const payload = {
      query: `{
        isFeedbackSubmitted(userEmail: "${UserType.userPrincipalName}",
        collection: "${collection}")
      }`,
    };
    await axios
      .post(APIs.getAPIUrl(APIs.GRAPHQL), payload, {
        headers: JSON.parse(
          JSON.stringify({
            ignoreGenericError: true,
            ignoreNetworkError: true,
          })
        ),
      })
      .then(res => {
        if (res.data && res.data.data) {
          if (successFailure(res)) {
            hasUserSubmittedFeedback = false;
            return hasUserSubmittedFeedback;
          } else {
            hasUserSubmittedFeedback = res.data.data.isFeedbackSubmitted;
            return hasUserSubmittedFeedback;
          }
        } else {
          hasUserSubmittedFeedback = false;
          return hasUserSubmittedFeedback;
        }
      })
      .catch(err => {
        hasUserSubmittedFeedback = false;
        return hasUserSubmittedFeedback;
      });
  }

  return hasUserSubmittedFeedback;
};

export const submitFeedbackForUser = async (
  feedbackResponse,
  collection,
  success,
  failure
) => {
  const mutation = `mutation saveFeedbackV2($feedback: FeedbackV2!) {
    saveFeedbackV2(feedback: $feedback) {
      id
      status
    }
  }`;
  const variables = {
    feedback: {
      userEmail: UserType.userPrincipalName,
      isUseful: feedbackResponse.isUseful,
      detailedFeedback: feedbackResponse.detailedFeedback,
      collection: collection,
    },
  };
  await axios
    .post(
      APIs.getAPIUrl(APIs.GRAPHQL),
      {
        query: mutation,
        variables: variables,
      },
      {
        headers: JSON.parse(
          JSON.stringify({
            ignoreGenericError: true,
            ignoreNetworkError: true,
          })
        ),
      }
    )
    .then(res => {
      const saveFeedback =
        res.data && res.data.data && res.data.data.saveFeedback;
      if (saveFeedback && saveFeedback.id) {
        if (successFailure(res)) {
          failure();
        } else {
          success();
        }
      } else {
        failure();
      }
    })
    .catch(err => {
      failure();
    });
};
