import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WeDownErrorPage from './WeDownErrorPage';
import ErrorPage from './ErrorPage';
import Errors from '../../consts/Errors';

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    isVista: PropTypes.bool.isRequired,
  };

  state = {
    hasError: false,
    error: null,
    data: null,
  };

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      error: error,
      data: info,
    });
  }

  render() {
    if (this.state.hasError) {
      return this.props.isVista ? (
        <ErrorPage
          title={Errors.VISTA_ERROR}
          errorBoundaryError={true}
          errorData={{
            error: this.state.error,
            data: this.state.data,
            description: 'ErrorBoundary.js errorBoundary error for Vista',
          }}
        />
      ) : (
        <WeDownErrorPage
          subTitle="We've reported the error. Please try again."
          errorBoundaryError={true}
          errorData={{
            error: this.state.error,
            data: this.state.data,
            description: 'ErrorBoundary.js errorBoundary error',
          }}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
