import React from 'react';
import MTForm from '../../../components/form/MTForm';
import FilterCheckboxes from './FilterCheckboxes';
import MTAccordion from '../../../components/layout/MTAccordion';

const filterIdCounter = 1;

const Filter = ({
  filter,
  changesToInitialValuesList,
  setChangesToInitialValuesList,
  currentValuesList,
  setCurrentValuesList,
}) => {
  return (
    <>
      <MTAccordion
        className="filter"
        title={filter.displayName}
        idCount={filterIdCounter}
      >
        <MTForm showSubmitButton={false} onSubmit={formikValues => {}}>
          <FilterCheckboxes
            name={filter.id}
            initialValue={Filter.getInitialValues(filter.facets)}
            facets={filter.facets}
            filterIdCounter={filterIdCounter}
            changesToInitialValuesList={changesToInitialValuesList}
            setChangesToInitialValuesList={setChangesToInitialValuesList}
            currentValuesList={currentValuesList}
            setCurrentValuesList={setCurrentValuesList}
          />
        </MTForm>
      </MTAccordion>

      <style jsx>
        {`
          :global(.filter .mtForm) {
            padding: 0;
            min-width: 0;
            max-width: inherit;
          }

          :global(.filter .mtCheckbox) {
            display: block;
          }

          :global(.filter .mtCheckbox + .mtCheckbox) {
            margin-top: 10px;
          }
        `}
      </style>
    </>
  );
};

export default Filter;

Filter.getInitialValues = facets => {
  return facets
    .map(facet => {
      return !facet.uncheckedOnLoad ? facet.id : null;
    })
    .filter(facet => {
      return !!facet;
    })
    .sort();
};
