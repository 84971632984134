import { useContext, useEffect, useState } from 'react';
import Consts from '../consts/Consts';
import { useHistory, useLocation } from 'react-router-dom';
import { trackEvent } from '../utils/appInsight';
import Events from '../consts/Events';
import AppContext from '../app/context/AppContext';

const _locationSearchAndStateInit = {
  search: Consts.EMPTY,
  state: { video: Consts.EMPTY },
};

let _returnFocusId = undefined;

/**
 *
 * @returns Video Carousel related functions and values
 */
const useVideoCarousel = () => {
  const history = useHistory();
  const location = useLocation();
  const {
    showVideoCarouselDialog,
    _setShowVideoCarouselDialog,
    videoCarouselCurrentVideo,
    _setVideoCarouselCurrentVideo,
  } = useContext(AppContext);

  /**
   * Handles actions needed to close the video in the dialog
   */
  const _closeVideoInDialog = () => {
    _setShowVideoCarouselDialog(false);
    _clearCurrentVideoDetails();
  };
  /**
   * Handles actions needed to open the video in the dialog
   * @param {*} src - Video src for loading the current video
   * @param {*} title - Title of the current video
   * @param {*} searchError - Was there any video search errors
   */
  const openVideoInDialog = (src, title, searchError) => {
    _setVideoCarouselCurrentVideo({ src, title, searchError });
    _setShowVideoCarouselDialog(true);
  };
  /**
   * Handles interaction to occur when exiting or closing the video dialog
   * @param allowVideoSearch - Boolean for allowing for the video searched in URL flow
   */
  const closeVideoDialog = allowVideoSearch => {
    if (allowVideoSearch) {
      _clearVideoParam();
    }
    _closeVideoInDialog();
    /*
     * Developer Note: When using document.getElementById alone the element was not returning focus.
     * Based off Stake Overflow it appears that something is either stealing focus or tabIndex is pending
     * a change. Wrapping the method in a timeout will allow for the focus to properly return. I assume
     * that with hoow the LD3 modal is made it will change the tabIndex and when we try to return focus
     * there is a brief moment that we needd to wait to allow for the tabIndex to update before we apply
     * the focus back. */
    if (_returnFocusId) {
      window.setTimeout(function () {
        document.getElementById(_returnFocusId).focus();
      }, 0);
    }
  };

  const [isCopied, setIsCopied] = useState(Consts.EMPTY);
  useEffect(() => {
    // Sets a timer to reset isCopied to its default enmpty state
    const timer = setTimeout(() => {
      setIsCopied(Consts.EMPTY);
    }, 2000);
    return () => clearTimeout(timer);
  }, [isCopied]);

  /**
   *
   * @param id - Value for identifying the video
   * @returns - Formatted search string
   */
  const _formatVideoSearchParam = id => {
    if (!id) {
      return Consts.EMPTY;
    }
    return `?video=${id}`;
  };

  /**
   *
   * @param id - Value for identifying the video
   * @returns Formatted routing location state object
   */
  const _createVideoSearchLocationObject = id => {
    id = id || Consts.EMPTY;
    return {
      search: _formatVideoSearchParam(id),
      state: { video: id },
    };
  };

  /**
   *
   * @param {*} currentIndex - Current index number of the selected video in the carousel
   * @param {*} videoCount - Full number of videos available in the carousel
   * @param {*} callbackFunction - Callback function to return any values that may be needed for carousel operations
   * @returns - returns the value of the callback function if appropriate params are met
   */
  const loadOnSlideChange = (currentIndex, videoCount, callbackFunction) => {
    if (currentIndex < videoCount) {
      const nextIndex = currentIndex + 1;
      return callbackFunction(nextIndex);
    }
  };

  /**
   * Handles gathering the Id needed to find and set the video details need for the dialog when it is searched in the url
   */
  const handleVideoSearchedDirectly = () => {
    const videoIdFromSearch = location?.search?.split('=')[1];
    trackEvent(
      Events.VIDEO_CAROUSEL(
        Events.VIDEO_CAROUSEL_DIRECT_SEARCH(videoIdFromSearch)
      )
    );
    history.push(_createVideoSearchLocationObject(videoIdFromSearch));
  };

  /**
   * Handles what should happen if the video was navigated to from the carousel
   * @param filteredVideoArray - Array of video objects
   * @param openVideoDialog - Callback function for opening the video dialog
   */
  const handleVideoSearchedViaCarousel = (
    filteredVideoArray,
    openVideoDialog
  ) => {
    const video = filteredVideoArray.find(
      video => video.id === location?.state?.video
    );
    if (video) {
      openVideoDialog(video.src, video.title, false);
    } else {
      trackEvent(
        Events.VIDEO_CAROUSEL(
          Events.VIDEO_CAROUSEL_INTERNAL_SEARCH(location?.state?.video)
        )
      );
      openVideoDialog(Consts.EMPTY, Consts.SINGLE_SPACE, true); // Open dialog with Error
    }
  };

  /**
   * Intakes a video object and sets the current video state and pushes it to the browser history
   * @param {*} video - Video object to set the current vieo to and push to history
   */
  const _setVideoParam = video => {
    if (video && video.id && video.src && video.title) {
      const { id, src, title } = video;
      _setVideoCarouselCurrentVideo({ src, title, searchError: false });
      history.push(_createVideoSearchLocationObject(id));
    }
  };

  /**
   * Resets the current video to the initial state
   */
  const _clearCurrentVideoDetails = () => {
    _setVideoCarouselCurrentVideo(
      Consts.VIDEO_CAROUSEL_CURRENT_VIDEO_INIT_STATE
    );
  };

  /**
   * clears the curent video to the initial state and updates the history to use initial video inital state
   */
  const _clearVideoParam = () => {
    history.push(_locationSearchAndStateInit);
    _clearCurrentVideoDetails();
  };

  /**
   *
   * @param item - Video Carousel item (Video) object
   * @param allowVideoSearch - Boolean for allowing for the video searched in URL flow
   * @returns empty if there is no item or a required item key value is not provided else it runs the appropriate open video in dialog flow
   */
  const itemOnClick = (item, allowVideoSearch) => {
    // Handles interaction to occur when clicking on a carousel item
    if (!item || !item.src || !item.title || !item.id) {
      return;
    }
    const { src, title, id } = item;
    _setReturnFocusId(id); // set the id for the return focus when the modal is closed
    if (allowVideoSearch) {
      // If we allow for videos to be searched via the browser use it's appropriate flow
      _setVideoParam(item);
    } else {
      // If we don't let videos be searched in the browser, just open it in the dialog
      openVideoInDialog(src, title, false);
    }
  };

  /**
   * Sets the return focus Id to the associated id so focus can later be returned when the video dialog is closed
   * @param id - Video Id that is associated to the button that opens the video dialog
   */
  const _setReturnFocusId = id => {
    _returnFocusId = id;
  };

  return {
    // Internal use functions & variables
    _formatVideoSearchParam,
    _createVideoSearchLocationObject,
    _setVideoCarouselCurrentVideo,
    _setVideoParam,
    _clearCurrentVideoDetails,
    _clearVideoParam,
    _setReturnFocusId,
    _setShowVideoCarouselDialog,
    _closeVideoInDialog,
    _locationSearchAndStateInit,
    _returnFocusId,

    // External use functions & variables
    setIsCopied,
    loadOnSlideChange,
    itemOnClick,
    handleVideoSearchedDirectly,
    handleVideoSearchedViaCarousel,
    openVideoInDialog,
    closeVideoDialog,
    isCopied,
    videoCarouselCurrentVideo,
    showVideoCarouselDialog,
  };
};
export default useVideoCarousel;
