import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import Auth from '../../auth/Auth';
import LoadingIcon from '../../components/misc/LoadingIcon';
import Consts from '../../consts/Consts';

function Login({ search, setHasError, setIsAuthenticated }) {
  const [isLoading, setIsLoading] = useState(true);
  const [successRedirectPage, setSuccessRedirectPage] = useState(Consts.EMPTY);
  useEffect(() => {
    Auth.handlePostLogin(
      search,
      landingUrl => {
        setIsAuthenticated(true);
        setSuccessRedirectPage(landingUrl);
        setIsLoading(false);
      },
      () => {
        setHasError(true);
        setIsLoading(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isLoading ? (
    <LoadingIcon />
  ) : successRedirectPage ? (
    <Redirect to={successRedirectPage} />
  ) : (
    <></>
  );
}

export default Login;
