const AdminSite = {
  CARD_TITLE_1: 'Mock Data - Myself',
  CARD_TITLE_2: 'Mock Data - Another',
  CARD_TITLE_3: 'Orders',
  CARD_TITLE_4: 'Features',
  CARD_TITLE_5: 'QA Users',
  CARD_TITLE_6: 'Impersonate - Myself',
};

export default AdminSite;
