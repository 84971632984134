import React from 'react';
import Consts from '../../consts/Consts';
import Colors from '../../consts/Colors';
import CSS from '../../consts/CSS';
import { useHistory, useLocation } from 'react-router-dom';
import { trackEvent } from '../../utils/appInsight';
import LoadingIcon from '../misc/LoadingIcon';

const MTButton = ({
  children,
  label,
  onClick,
  className,
  openURL,
  track,
  to,
  linkState,
  disabled = false,
  type = 'button',
  darkButton = true,
  largeButton = false,
  largeAutoButton = false,
  isSubmitting,
}) => {
  const history = useHistory();
  const location = useLocation();

  const click = event => {
    if (onClick) {
      onClick(event);
    }
    if (openURL) {
      window.open(openURL, '_blank', 'noopener');
    }
    if (track) {
      track();
    }
    if (to) {
      trackEvent(`MTButton redirected to ${to} - ${location.pathname}`);
      history.push(to, { ...linkState });
    }
  };

  return (
    <span
      className={`mtButton ${className ? className : Consts.EMPTY} ${
        largeButton ? 'large' : Consts.EMPTY
      } ${largeAutoButton ? 'largeAuto' : Consts.EMPTY} ${
        isSubmitting ? 'isSubmitting' : Consts.EMPTY
      }`}
    >
      <button
        className={darkButton ? 'darkButton' : null}
        onClick={click}
        type={type}
        disabled={disabled || isSubmitting}
      >
        {isSubmitting && <LoadingIcon />}
        <span className="buttonLabel">
          {label ? label : children ? children : null}
        </span>
      </button>
      <style jsx>
        {`
          .mtButton + :global(.mtButton) {
            margin-left: ${CSS.PAGE_PADDING_LEFT_RIGHT};
          }

          button {
            background-color: ${darkButton ? Colors.BLUE : Colors.WHITE};
            height: 37px; /* Override in case the button is in a parent with flex set. */
            padding: 7px 24px;
            font-size: 14px;
            color: ${darkButton ? Colors.WHITE : Colors.BUTTON_BORDER};
            border: 1px solid ${darkButton ? Colors.BLUE : Colors.BUTTON_BORDER};
            border-radius: 20px;
            cursor: pointer;
            font-weight: bold;
            white-space: nowrap;
          }

          button:hover,
          button:focus {
            background-color: ${darkButton ? Colors.DARK_BLUE : Colors.WHITE};
            color: ${darkButton ? Colors.WHITE : Colors.BUTTON_BORDER};
            border-color: ${darkButton
              ? Colors.DARK_BLUE
              : Colors.BUTTON_BORDER};
            border-width: ${darkButton ? '1px' : '2px'};
            padding: ${darkButton ? '7px 24px' : '6px 23px'};
          }

          button:disabled {
            pointer-events: none;
            background-color: #babbbe;
            border-color: #babbbe;
          }

          .large button {
            width: ${CSS.BUTTON_WIDTH_LARGE};
          }

          .largeAuto button {
            max-width: ${CSS.BUTTON_WIDTH_LARGE};
            width: 100%;
          }

          .isSubmitting :global(.loadingicon-container) {
            position: absolute;
            top: calc(50% - 12px);
            left: 7px;
          }

          .isSubmitting :global(.loadingicon-container),
          .isSubmitting :global(.loadingicon-loading) {
            width: 24px;
            height: 24px;
          }

          .isSubmitting .darkButton :global(.cls-1) {
            fill: ${Colors.WHITE};
          }

          .isSubmitting > button {
            position: relative;
          }

          .isSubmitting .buttonLabel {
            position: relative;
            left: 10px;
          }
        `}
      </style>
    </span>
  );
};

export default MTButton;
