import { ThemeProvider } from '@mui/material';
import React from 'react';
import createLd3Theme from './ld3Theme';

function Ld3ThemeProvider({ children }) {
  const theme = createLd3Theme();
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default Ld3ThemeProvider;
