import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, useField } from 'formik';
import MTFormItem from '../item/MTFormItem';
import MTCharacterCount from '../misc/MTCharacterCount';
import Consts from '../../../consts/Consts';
import Colors from '../../../consts/Colors';
import CSS from '../../../consts/CSS';

let mtTextInputIdCounter = 1;

//Any component that includes this, must define "name" and "initialValue" as defaultProps/attributes and optionally "yup", so they can be read by <MTForm> to setup <Formik> and Yup validationSchema.
const MTTextarea = ({
  name,
  yup,
  label,
  halfWidth,
  initialValue,
  placeholder,
  maxLength = 500,
  showCharCount = true,
  showLabel,
  twoColumn,
  minHeight = 132,
  note,
}) => {
  const className = 'mtTextarea';
  const id = `${className}${mtTextInputIdCounter++}`;
  const isRequired = yup && yup._exclusive && Boolean(yup._exclusive.required);
  const [shiftLabel, setShiftLabel] = useState(false);
  const classNameList = `${className} ${
    shiftLabel ? Consts.SHIFT_LABEL_CLASS : Consts.EMPTY
  }`;
  const [field] = useField(name);

  useEffect(() => {
    if (!shiftLabel && field.value) {
      setShiftLabel(true);
    }
  }, [field.value]);

  return (
    <>
      <MTFormItem
        id={id}
        name={name}
        label={label}
        className={classNameList}
        halfWidth={halfWidth}
        isRequired={isRequired}
        showLabel={showLabel}
        twoColumn={twoColumn}
        shouldMoveLabel
        note={note}
      >
        <>
          <Field
            id={id}
            as="textarea"
            name={name}
            value={field.value}
            maxLength={maxLength}
            placeholder={placeholder}
            onBlur={e => {
              if (field.onBlur) {
                field.onBlur(e);
              }
              if (!field.value) {
                setShiftLabel(false);
              }
            }}
            onFocus={e => {
              if (field.onFocus) {
                field.onFocus(e);
              }
              if (!field.value) {
                setShiftLabel(true);
              }
            }}
          />
          {showCharCount && (
            <MTCharacterCount
              totalCharCount={field.value ? field.value.length : 0}
              maxLength={maxLength}
            />
          )}
        </>
      </MTFormItem>
      <style jsx>
        {`
          :global(.${className}) :global(textarea) {
            border: 1px solid ${Colors.COMPONENT_LIGHT_GREY};
            border-radius: ${CSS.COMPONENT_BORDER_RADIUS};
            padding: 16px;
            color: inherit;
            min-height: ${minHeight}px;
            font-size: 18px;
            line-height: 1.11;
            width: 100%;
            box-sizing: border-box;
            resize: vertical;
            background-color: transparent;
            display: block; /* Fixes and issue where some browsers add a few extra pixels after diplay: inline <img> or here an inline <textarea> */
          }

          :global(.${className}.error) :global(textarea) {
            border-color: ${Colors.ERROR_RED} !important;
          }

          :global(.${className}) :global(label) {
            margin-bottom: 8px;
          }
        `}
      </style>
    </>
  );
};

export default MTTextarea;

MTTextarea.defaultProps = {
  initialValue: Consts.EMPTY,
  showLabel: true,
};

MTTextarea.propTypes = {
  name: PropTypes.string.isRequired,
  yup: PropTypes.object,
  label: PropTypes.string.isRequired,
  halfWidth: PropTypes.bool,
  initialValue: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  showCharCount: PropTypes.bool,
};
