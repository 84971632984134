import axios from 'axios';
import APIs from '../consts/APIs';
import UserType from '../user/UserType';
import { successFailure } from './interceptorHelpers';
import {
  getLocalStorageItemWithTTL,
  setLocalStorageItemWithTTL,
} from './helpers';
import { graphGateway } from '../utils/graphGateway';
import { formatDevicesData } from './fetchADInfo';

const expiryTime = 3 * 60 * 60 * 1000; //3 hours

export const fetchUserData = async (upn, errorCallback) => {
  const cachedUserData = getLocalStorageItemWithTTL(APIs.USER_DATA);

  if (cachedUserData) {
    const userObject = getUserDataObject(cachedUserData, upn, errorCallback);
    document.cookie = `userData=${JSON.stringify(
      userObject
    )}; domain=.walmart.com; path=/`;
    document.cookie = `userData=${JSON.stringify(
      userObject
    )}; domain=.walmart.net; path=/`;
    document.cookie = `userData=${JSON.stringify(
      userObject
    )}; domain=.dev.walmart.com; path=/`;
    UserType.init(cachedUserData.data, userObject);
    return;
  }

  return axios
    .post(
      APIs.getAPIUrl(APIs.USER_DATA),
      {
        upn: upn,
        isCAPPRoute: false, // EOM userdata call expects this to be passed
      },
      {
        headers: {
          ignoreGenericError: true,
          ignoreNetworkError: true,
        },
      }
    )
    .then(res => {
      const userObject = getUserDataObject(res, upn, errorCallback);
      const cacheData = { data: res.data };
      document.cookie = `userData=${JSON.stringify(
        res.data.MyTech.ActiveDirectory_Information[0]
      )}; domain=.dev.walmart.com; path=/`;
      document.cookie = `userData=${JSON.stringify(
        res.data.MyTech.ActiveDirectory_Information[0]
      )}; domain=.walmart.com; path=/`;
      document.cookie = `userData=${JSON.stringify(
        res.data.MyTech.ActiveDirectory_Information[0]
      )}; domain=.walmart.net; path=/`;
      setLocalStorageItemWithTTL(APIs.USER_DATA, cacheData, expiryTime);
      UserType.init(res.data, userObject);
    })
    .catch(err => {
      errorCallback();
      console.error(
        'User object seems to be corrupted. Actual error caught -',
        err
      );
    });
};

const getUserDataObject = (res, upn, errorCallback) => {
  // If user does not have AD Data, send to proererror page
  if (
    res.data &&
    res.data.MyTech &&
    (!res.data.MyTech.ActiveDirectory_Information ||
      res.data.MyTech.ActiveDirectory_Information.length === 0)
  ) {
    errorCallback();
    return;
  }

  const userObject = {
    ...res.data.MyTech.ActiveDirectory_Information[0],
    manager:
      res.data.managerData && Object.keys(res.data.managerData).length
        ? res.data.managerData
        : null,
    maac: res.data.Maac,
    moad: res.data.Moad,
    encryptedInfo: res.data.encryptedInfo,
    isSIMUser: res.data.isSIMUser,
    isNewHire: res.data.isNewHire,
    loadUsersRealDevices: res.data.loadUsersRealDevices ? true : false,
  };

  if (
    res.data.MyTech.ActiveDirectory_Information &&
    res.data.MyTech.ActiveDirectory_Information.length !== 0 &&
    res.data.MyTech.ActiveDirectory_Information[0].UserPrincipalName &&
    res.data.MyTech.ActiveDirectory_Information[0].UserPrincipalName.toLowerCase() !==
      upn.toLowerCase()
  ) {
    userObject.isImpersonated = true;
    userObject.originalUPN = upn;
  }

  return userObject;
};

export const fetchDeviceDataGraph = async (upn, success, failure) => {
  let devicesData = {
    AirWatchInformation: [],
    MacInformation: [],
    PCInformation: [],
    VDIInformation: [],
  };
  await graphGateway(
    upn,
    `query Query($userPrincipalName: String!) {  getUser_v1(userPrincipalName: $userPrincipalName) {
      userPrincipalName
      devices {
        serialNumber
        model
        operatingSystem
        manufacturer
        deviceName
        osVersion
        imei
        lastEnrolledOn
        locationGroupName
        lastSeen
        udid
        phoneNumber
        cellularInfo
      }
      vdiDevices {
        Machine
        DesktopPool
        DnsName
        UserName
        Host
        vCenter
        AgentVersion
        Status
        PowerState
        Source
        Type
        CreateTime
        InMaintenanceMode
        MissingInVCenter
        Notes
        CurrentPowerState
        NumCpu
        MemoryGB
        HddSizeGB
        AnnualCost
        id
        _rid
        _self
        _etag
        _attachments
        _ts
      }
    }
    }`
  )
    .then(res => {
      let devicesData = {};
      if (res && res.data && res.data.data) {
        devicesData = formatDevicesData(res.data.data.getUser_v1);
      }
      success(devicesData);
    })
    .catch(err => {
      console.error('Error fetching user`s devices. ', err);
      failure(err);
    });
};

export const fetchUserVDIData = async (userId, success, failure) => {
  const cachedDwsData = getLocalStorageItemWithTTL(APIs.VDI_DATA);
  if (cachedDwsData) {
    const vdiDevicesData = getVDIDevicesData(cachedDwsData);
    success(vdiDevicesData);
    return;
  }

  return axios
    .post(
      APIs.getAPIUrl(APIs.VDI_DATA),
      {
        username: userId,
      },
      {
        headers: {
          ignoreGenericError: true,
          ignoreNetworkError: true,
        },
      }
    )
    .then(res => {
      const vdiDevicesData = getVDIDevicesData(res);
      const cacheData = { data: res.data };
      setLocalStorageItemWithTTL(APIs.VDI_DATA, cacheData, expiryTime);
      success(vdiDevicesData);
    })
    .catch(err => {
      console.error('Error fetching user`s VDI devices. ', err);
      failure(err);
    });
};

const getVDIDevicesData = res => {
  let devicesData = {
    DWSInformation: [],
  };

  if (res && res.data && res.data.MyTech) {
    const sanitizedDevices = res.data.MyTech.DWSInformation.filter(
      d => d !== null
    );
    devicesData = {
      DWSInformation: sanitizedDevices ? [...sanitizedDevices] : [],
    };
  }

  return devicesData;
};

export const fetchOrderData = async (upn, success, failure) => {
  const query = {
    query: `{
      order(userEmail: "${upn}"){
        id
        status
        userEmail
        displayName
        email
        deviceType
        deviceSelected
        activeFlow
        requestorName
        requestorUPN
        requestorEmail
        accessories {
          name
          imgUrl
        }
        services {
          name
          imgUrl
        }
        deliveryType
        pickupLocation {
          id
          building
          address
          city
          state
          zip
          phone
          notes
        }
        orderForUser{
          name
          value
        }
        deliveryLocation {
          building
          address
          city
          state
          zip
          phone
          notes
        }
        shipping
        requestType
        shippingNotes
        notes
        mgrApprovalDate
        orderReceivedDate
        orderFillingDate
        readyForPickupDate
        resolvedOUName
        savedOrderDate
        createdAt
        updatedOrderDate
        photoURL
        serviceNowNumber
        orderStageHistory {
          status
          statusTime
        }
        readyForPickupDate
        areaCode
        mailStop
        employeeType
        zeroUsageAgreed
        hotspotdevice
        returnDate
        returnDateStr
        carrier
        deviceDetails {
          serialNumber
          status
          ownerType
          state
          activation {
            begin
            end
            extension
          }
        }
        phoneNumber
        international
        department
        division
        managerUPN
        serviceNowNumber
        ritmsysid
        additionalDetails{
          name
          value
        }
        projectName
        storeNumber
      }
    }`,
  };

  await axios
    .post(APIs.getAPIUrl(APIs.GRAPHQL), query, {
      headers: {
        ignoreGenericError: true,
        ignoreNetworkError: true,
        ignoreGraphQLError: true,
      },
    })
    .then(res => {
      if (successFailure(res)) {
        failure(res);
      } else {
        success(res.data.data);
      }
    })
    .catch(error => {
      failure(error);
    });
};

export const fetchServiceNowTickets = async (
  upn,
  filterData,
  success,
  failure,
  cancelToken
) => {
  const ticketsData = {
    tickets: [],
    errors: [],
  };
  return axios
    .post(
      APIs.getAPIUrl(APIs.TICKETS),
      {
        upn: upn,
        filterData: filterData,
      },
      {
        headers: {
          ignoreGenericError: true,
          ignoreNetworkError: true,
        },
        cancelToken: cancelToken,
      }
    )
    .then(res => {
      if (res && res.data) {
        ticketsData.tickets = res.data.data.tickets;
        ticketsData.errors = res.data.data.errors;
      }
      success(ticketsData);
    })
    .catch(err => {
      console.error('Error fetching user`s serviceNow Tickets. ', err);
      failure(err);
    });
};

export const lookupUserData = async (searchCriteriaArr, success, failure) => {
  return axios
    .post(
      APIs.getAPIUrl(APIs.LOOKUP_USER_DATA),
      {
        searchCriteriaArr: searchCriteriaArr,
      },
      {
        headers: {
          ignoreGenericError: true,
          ignoreNetworkError: true,
        },
      }
    )
    .then(res => {
      success(res.data);
    })
    .catch(err => {
      failure(err);
    });
};

export const validatePhoneNumber = async (upn, phoneNumber, returnIndex) => {
  return axios
    .post(
      APIs.getAPIUrl(APIs.VALIDATE_PHONE_NUMBER),
      {
        upn,
        phoneNumber,
        returnIndex,
      },
      {
        headers: {
          ignoreGenericError: true,
          ignoreNetworkError: true,
        },
      }
    )
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};
export const rehydratePhoneNumber = async (upn, phoneNumberLast4) => {
  return axios
    .post(
      APIs.getAPIUrl(APIs.REHYDRATE_PHONE_NUMBER),
      {
        upn,
        phoneNumberLast4,
      },
      {
        headers: {
          ignoreGenericError: true,
          ignoreNetworkError: true,
        },
      }
    )
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const fetchUserProducts = async guid => {
  return axios
    .get(APIs.getAPIUrl(APIs.USER_PRODUCTS), {
      headers: {
        ignoreGenericError: true,
      },
      params: {
        guid: guid,
      },
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.log(err);
    });
};
