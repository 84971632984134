import { useField } from 'formik';
import React, { useEffect } from 'react';
import MTCheckbox from '../../../components/form/controls/MTCheckbox';
import MTCheckboxes from '../../../components/form/controls/MTCheckboxes';
import { shallowEqualArrays } from 'shallow-equal';

const FilterCheckboxes = ({
  facets,
  filterIdCounter,
  name,
  initialValue,
  changesToInitialValuesList, //NOTE: This doesn't contain a list of "applied" filters. It's a list of any facet thats current value is different from its initialValue.
  setChangesToInitialValuesList,
  currentValuesList,
  setCurrentValuesList,
}) => {
  const [field] = useField(name);

  const updateCurrentValuesList = () => {
    const newList = currentValuesList.filter(item => {
      return item.id !== name;
    });
    if (field.value && field.value.length) {
      newList.push({ id: name, facets: field.value });
    }
    setCurrentValuesList(newList);
  };

  useEffect(() => {
    FilterCheckboxes.updateInitialValuesChangesList(
      name,
      field,
      initialValue,
      setChangesToInitialValuesList,
      changesToInitialValuesList
    );
    updateCurrentValuesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  return (
    <MTCheckboxes
      name={name}
      initialValue={initialValue}
      label={name}
      showLabel={false}
    >
      {facets
        .map(facet => {
          return !facet.disabled ? (
            <MTCheckbox
              id={`facet${facet.id}`}
              key={`facet${facet.id}`}
              value={facet.id}
              label={facet.displayName}
              groupName={`facetGroup${filterIdCounter}`}
              color={facet.color}
            />
          ) : null;
        })
        .filter(item => {
          return !!item;
        })}
    </MTCheckboxes>
  );
};

export default FilterCheckboxes;

FilterCheckboxes.updateInitialValuesChangesList = (
  name,
  field,
  initialValue,
  setChangesToInitialValuesList,
  changesToInitialValuesList
) => {
  const valueList = field.value ? field.value : [];
  const sameAsInitial = shallowEqualArrays(valueList.sort(), initialValue);
  if (sameAsInitial) {
    //Remove any previously added entries, since we're back to the initial selections.
    setChangesToInitialValuesList(
      changesToInitialValuesList.filter(item => {
        return !item.startsWith(`${name}-`);
      })
    );
  } else {
    setChangesToInitialValuesList(
      changesToInitialValuesList
        .filter(item => {
          //Remove any previously added entries
          return !item.startsWith(`${name}-`);
        })
        .concat(
          valueList //Loop over currently selected facets
            .map(facetId => {
              //Add any selected facets that aren't included in initialValues, since they are changes to the initially selected facets
              if (!(initialValue && initialValue.includes(facetId))) {
                return `${name}-${facetId}`;
              } else {
                return null;
              }
            })
            .filter(item => {
              return !!item;
            })
        )
        .concat(
          initialValue && Array.isArray(initialValue)
            ? initialValue //Loop over initially selected facets
                .map(facetId => {
                  //Add any initial facets that aren't included in field.value, since they are changes to the initially selected facets too
                  if (!(field.value && field.value.includes(facetId))) {
                    return `${name}-${facetId}`;
                  } else {
                    return null;
                  }
                })
                .filter(item => {
                  return !!item;
                })
            : null
        )
    );
  }
};
