import URLs from './URLs';

const FormErrors = {
  REQUIRED_KEY: 'Required fields',
  EMAIL: 'Valid Email is required',
  PHONE: 'Valid Phone Number is required',
  EXTENSION: 'Digits-only required, no alphabets, spaces or special characters',
  DEVICE_PHONE: 'More Numbers are required',
  WRONG_PHONE_NUMBER:
    'The phone number you entered does not match our records.',
  ZIP_CODE: 'Please enter 5 or 9 digit zip',
  STATE: 'State is required',
  CITY: 'Valid City is required',
  ADDRESS: 'Address could not be verified, please try again and check spelling',
  ADDRESS_EMPTY: 'Valid Address is required',
  ADDRESS_PO_BOX: 'Address cannot be a PO Box',
  ADDRESS_SPECIAL_CHARS: 'Address cannot contain special characters',
  IMEI: '15 digits required, no alphabets, spaces or special characters',
  SIM_NUMBER: '20 digits required, no alphabets, spaces or special characters',
  SIM_SIZE: 'Please select a SIM size',
  DUPLICATE_SIM: 'Same SIM number cannot be entered again',
  DUPLICATE_IMEI: 'Same IMEI number cannot be entered again',
  STORE_NUMBER:
    '5 or less digits required, no alphabets, spaces or special characters',
  PROJECT: 'Please select a project',
  PROJECT_NAME: 'Please enter a project name',
  DEVICE_MODEL: 'Please enter the device model',
  DEVICE_TYPE: 'Please select a device type',
  CARRIER: 'Carrier is required',
  POLE: 'Pole no is required',
  BUSINESS_JUSTIFICATION: 'Business reasoning is required',
  BUSINESS_UNIT: 'Business unit is required',
  FULL_NAME: 'First and Last Name are required',
  HOW_DID_YOU_HEAR: 'Option is required',
  CHANGE_2FA_CURRENT: 'Current 2FA Method is required',
  CHANGE_2FA_NEW: 'New 2FA Method is required',
  OTHER_TEXT: 'Other is required',
  AREA_CODE: 'Valid Area Code is required',
  ZERO_USAGE_POLICY: 'Please Agree to continue',
  MOBILE_TYPE: 'Device Type is required',
  DATE: 'Date is required',
  USER_SEARCH: 'Valid User is required',
  MANAGER_SEARCH: 'Valid Manager is required',
  NO_CORPORATE_DEVICE:
    'According to our records, this associate does not have a Walmart Corporate phone.',
  DELIVERY_TYPE: 'Delivery Type is required',
  PICKUP_LOCATION: 'Pickup Location is required',
  MAILSTOP: 'Valid Mailstop is required',
  ADDRESS_TYPE: 'Address Type is required',
  BUSINESS_NAME: 'Business Name is required',
  LOANER_HAS_DEVICE:
    'Looks like the associate already has a Walmart Loaner mobile phone or has placed an order for one. Walmart policy does not allow multiple Loaner phones.',
  LOANER_NOT_ELIGIBLE:
    'Not eligible - Loaner devices can be ordered ONLY for Homeoffice associates.',
  IDC_NOT_ELIGIBLE:
    'Not eligible - Mobile and iPad devices cannot be ordered for IDC associates.',
  INTERNATIONAL_NOT_ELIGIBLE:
    'Not eligible - Mobile and iPad devices cannot be ordered for international associates.',
  STORE_CLUB_NOT_ELIGIBLE:
    'Not eligible - Mobile and iPad devices cannot be ordered for Store and Club associates',
  NO_AD_DATA:
    "Sorry, but this user doesn't have the required Active Directory information.",
  AD_NETWORK_ERROR: `Sorry, there was a problem loading the user's data, please try again later.`,
  AD_DATA_NOT_LOADED: 'Please wait till data is loaded',
  HOTSPOT_MANAGER_AGREEMENT: 'Please Confirm to continue',
  QA_USERS: 'Valid QA users input required',
  NO_LOANER_ORDER_EXTEND_DATE: 'Please select a extension date to continue',
  CORP_ANOTHER_HAS_REPLACEMENT:
    'Looks like the selected user has already placed a Replacement device request in the past 30 days.',
  CORP_ANOTHER_HAS_DEVICE: `Looks like the associate already has a Walmart Corporate phone or has placed an order for one. Walmart policy does not allow multiple Corporate devices. If you would still like to place an order, please submit a request at <a target="_blank" rel="noopener noreferrer" href="${URLs.ADE_HELP}">here</a>.`,
  CORP_ANOTHER_HAS_IPAD: `Looks like the associate already has a Walmart Corporate iPad or has placed an order for one. Walmart policy does not allow multiple Corporate iPad devices. If you would still like to place an order, please submit a request at <a target="_blank" rel="noopener noreferrer" href="${URLs.ADE_HELP}">here</a>.`,
  JOB_POSITION: `Job Position is required`,
  FACILITY_TYPE: `Facility Type is required`,
  FACILITY_NUMBER: `Facility Number is required`,
  MARKET_NUMBER: `Market Number is required`,
  IPAD_TRACKING_NUMBER: `iPad Tracking Number is required`,
  BUSINESS_POINT_OF_CONTACT: `Business Point Of Contact is required`,
  DIVISION: `Division Number is required`,
  DEPARTMENT: `Department Number is required`,
  BUSINESS_SEGMENT: `Business Segment is required`,
  DEPARTMENT_NAME: `Department Name is required`,
  QUANTITY: `Quantity is required`,
  SITE_NUMBER: 'Site Number is required',
  IPAD_MAX_QUANTITY: `Quantity cannot be above 1000`,
  IPAD_STORECLUB_MAX_QUANTITY: 'Quantity cannot be above 10',
  IPAD_STORECLUB_HVAC_MAX_QUANTITY: 'Quantity cannot be above 50',
  ONLY_NUMBERS: `Must only contain number characters`,
  DEDICATED_OR_SHARED: `Please select one option`,
  IPAD_DEVICE_MODEL: `Device Model is required`,
  IPAD_DEVICE_MODEL_OTHERS_ERROR: `iPads not listed in the dropdown are considered special orders. Please <a target="_blank" rel="noopener noreferrer" href="${URLs.PROCURE_IT_URL}">click here</a> to be routed to Procure-It.`,
  IPAD_JOB_POSITION_NOT_HOMEOFFICE_POSITION: `Must choose a Home Office job position based on selected recipient's current role`,
  IPAD_JOB_POSITION_NOT_STORE_POSITION: `Must choose a Store job position based on selected recipient's current role`,
  IPAD_JOB_POSITION_NOT_CLUB_POSITION: `Must choose a Club job position based on selected recipient's current role`,
  USER_CANNOT_BE_DIRECTOR: `Recipient cannot be the approving director`,
  USER_CANNOT_BE_MANAGER: `Recipient cannot be the approving manager`,
  IPAD_USER_GEC_ASSOCIATE_PROCURE_IT: `GEC Associates are required to use Procure-It when ordering Corporate iPads. Please <a target="_blank" rel="noopener noreferrer" href="${URLs.PROCURE_IT_URL}">click here</a> to be routed to Procure-It.`,
  PRIMARY_OFFICE_LOCATION: 'Primary Office Location is required',
  PHONE_OPTIONS: 'Please select an option',
  ACQUIRED_BRAND_TOGGLE: 'Please specify if you are part of an acquired brand',
  ACQUIRED_BRAND: 'Please specify acquired brand name',
  ACQUIRED_BRAND_OTHER: 'Please specify acquired brand name',
  WORK_HOURS: 'Work Schedule is required',
  COMPUTER_TYPE: 'Computer type is required',
  COMPUTER_NAME: 'Computer Name is required',
  COMPUTER_MODEL: 'Computer Model is required',
  COMPUTER_NAME_MODEL: 'Computer name and model is required',
  MOBILE_TYPE_CORP_BYOD: 'Mobile type is required',
  MAKE_AND_MODEL: 'Device Make & Model is required',
  SERIAL_NUMBER: 'Serial Number is required',
  IMEI_NUMBER: 'IMEI Number is required',
  TECH_SUPPORT_REQUESTOR: 'Requestor is required',
  VENDOR_EMAIL: 'Non-Walmart email address is required',
  ISSUE_DESC: 'Issue Description is required',
  REQUEST_TYPE: 'Request Type is required',
  DURATION: 'Duration is required',
  USERS_NAME: `Requestor's Name is required`,
  USERS_UPN: `Requestor's User Principal Name is required`,
  USERS_UPN_FORMAT: 'Please enter a valid User Principal Name',
  TICKET_COMMENT: 'Comment is required',
  MANAGER_UPN: `Manager's User Principal Name is required`,
  RECIPIENT_CANNOT_BE_MANAGER: `Recipient cannot be the Manager`,
  VIDEO_CONF_ISSUE: 'Please describe the issue',
  LOCATION: 'Location is required',
  BUILDING: 'Building is required',
  FLOOR: 'Floor is required',
  VIDEO_CONF_ROOM: 'Conference Room Name is required',
  VIDEO_CONF_ROOM_NAME: 'Room Name is required',
  IP_ADDRESS: 'IP Address is required',
  ONLY_ALPHANUMERIC: 'Cannot contain special characters',
  IPAD_PREVIOUS_TRACKING_NUMBER: 'Must contain a vaild tracking number',
  TICKET_TYPE: 'Please specify the ticket type',
  TECH_SUPPORT_STORE_ASSOCIATE_REQUIRED:
    "Please confirm if you're a Store Associate",
  TECH_SUPPORT_STORE_ASSOCIATE_INFO: `Please <a target="_blank" rel="noopener noreferrer" href="${URLs.STORE_ASSOCIATE_TECH_SUPPORT}">click here</a> to be routed to Store Associates support.`,
  TECH_SUPPORT_INTERNATIONAL_ASSOCIATE: `Tickets cannot be opened for international associates at this time.`,
  VDI_CONFIG_SELECT: 'Please select a machine type',
  VDI_2FA: 'Please select the 2FA method',
  VDI_NO_RECIPIENTS:
    'Please search for and select at least one valid recipient.',
  VDI_NO_AD_DATA:
    "This user's data could not be found at this time. Please try again later.",
  EAGLE_AT_HOME_NOT_QUALIFIED:
    'Sorry, looks like you do not qualify for Eagle@Home',
  POINT_OF_CONTACT: 'Point of contact is required.',
  MUST_BE_STORE_CLUB_USER:
    'Point of contact must be a store or club associate.',
  INVALID_SITE_NUMBER: 'Please enter a valid site number.',
  LOANER_COUNTRY: 'Country or list of countries is required.',
  COST_CENTER_NUMBER: 'Cost Center Number is required',
  CORPORATE_PHONE_HOURLY_ASSOCIATE:
    'Selected user is hourly and is not eligible for a Corporate Phone.',
  SELECT_FROM_DROPDOWN: 'Please select an option from the dropdown.',
};

export default FormErrors;
