import { useHistory, useLocation } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import AppContext from '../../app/context/AppContext';
import MicroFrontend from '../../components/misc/MicroFrontend';
import FlexPage from '../../components/page/FlexPage';
import EnvConfigs from '../../Configurations';
import MFEs from '../../consts/MFEs';
import Routes from '../../consts/Routes';
import UserType from '../../user/UserType';
import { Box } from '@mui/material';
import Config from '../../utils/Config';

function AppStore() {
  const history = useHistory();
  const location = useLocation();

  const { enabledMFEs, setEnabledMFEs, ticketData, setTicketData } = useContext(
    AppContext
  );

  const showMFE =
    location.pathname?.startsWith(Routes.APPS) &&
    enabledMFEs?.includes(MFEs.APPSTORE);

  return (
    <>
      <div className={showMFE ? 'main-content' : null}>
        <Box
          sx={{
            height: showMFE ? '100%' : 0,
            visibility: showMFE ? 'visible' : 'hidden',
          }}
        >
          <FlexPage large hideHeader showHeader={false}>
            <MicroFrontend
              host={EnvConfigs.REACT_APP_APPSTORE_HOST}
              proxyHost={EnvConfigs.REACT_APP_PROXY_APPSTORE_HOST}
              mfeEnv={window.APPSTORE.ENVIRONMENT}
              setMfeHost={host => {
                window.APPSTORE.MFE_HOST = host;
              }}
              name={MFEs.APPSTORE}
              props={{
                history,
                impersonatedUser:
                  UserType?.userData?.isImpersonated &&
                  UserType.userData.UserPrincipalName
                    ? UserType.userData.UserPrincipalName
                    : null,
                ADData: {
                  ...UserType?.userData,
                  employeeType: UserType?.employeeType,
                },
                ticketData,
                setTicketData,
              }}
              handleSucess={() => {
                if (!enabledMFEs?.includes(MFEs.APPSTORE)) {
                  setEnabledMFEs([...enabledMFEs, MFEs.APPSTORE]);
                }
              }}
              handleError={() => {
                if (enabledMFEs?.includes(MFEs.APPSTORE)) {
                  setEnabledMFEs(
                    enabledMFEs?.filter(fe => fe !== MFEs.APPSTORE)
                  );
                  history.replace(Routes.APPS); // redirect to main apps page
                }
              }}
              disableOffNetwork={!Config.isAppStoreOffNetworkEnabled}
            />
          </FlexPage>
        </Box>
        <style jsx>
          {`
            :global(main.mfe-container) {
              height: 100%;
            }
          `}
        </style>
      </div>
    </>
  );
}

export default AppStore;
