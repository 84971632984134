import Consts from '../../consts/Consts';
import PageTitles from '../../consts/PageTitles';
import Routes from '../../consts/Routes';

export const holidayReadinessNav = isHolidayReadinessMFEEnabled => {
  return [
    {
      path: Consts.EMPTY,
      url: Routes.HOLIDAY_READINESS,
      isMFE: isHolidayReadinessMFEEnabled,
      disabled: !isHolidayReadinessMFEEnabled,
      label: PageTitles.HOLIDAY_READINESS,
      ignoreBreadcrumb: true,
      isBreadcrumbEnabled: false,
      supportsLiteMode: true,
      childPages: isHolidayReadinessMFEEnabled
        ? [
            {
              path: 'holidayreadiness/holidayReadiness',
              url: `${Routes.HOLIDAY_READINESS}`,
              label: PageTitles.HOLIDAY_READINESS,
              supportsLiteMode: true,
              disabled: !isHolidayReadinessMFEEnabled,
            },
          ]
        : null,
    },
  ];
};
