import Consts from './Consts';

const Messages = {
  WALMART: 'Walmart',
  OTHER: 'Other',
  OTHER_HOME_OFFICE: 'Other Home Office Associate',
  OTHER_SUPPY_CHAIN: 'Other Supply Chain Associate',
  OTHERS: 'Others',
  I_PHONE: 'iPhone',
  I_PAD: 'iPad',
  SAMSUNG: 'Samsung',
  COMING_SOON: 'Coming soon',
  NOT_ELIGIBLE: "You're not eligible",
  BUSINESS: 'Business',
  MYSELF: 'Myself',
  ANOTHER: 'Another Associate',
  STORE_CLUB_DEPARTMENT: 'Store/Club Department',
  HOME_OFFICE: 'Home Office',
  DC_SUPPLY_CHAIN: 'DC/Supply Chain',
  ORDER: 'Order',
  MANAGE: 'Manage',
  DEFAULT_SELECT_LABEL: 'Select one',
  DATA_NOT_AVAILABLE: 'Data Not Available',
  FIND_INSTRUCTIONS: 'Find Instructions',
  MYSELF_ANOTHER_LABEL: 'Who are you requesting this device for?',
  HOMEPAGE_GREETING_SUBTITLE:
    'Welcome to MyTech - A platform you can rely on to help you manage all your technology.',
  CORPORATE: {
    TITLE: 'Information',
    SUBTITLE_MYSELF:
      "This request will need your manager's approval. Please be aware as you proceed with the request.",
    SUBTITLE_ANOTHER:
      "This request will need the associate's manager's approval. Please be aware as you proceed with the request.",
    SHIPPING_METHOD: 'Standard',
    SHIPPING_TIME: '3-5 Business Days',
    SHIPPING:
      'Devices will be sent via Standard Shipping (3-5 Business Days) and can be shipped to either a residential or business address. Pickup is not an option, nor is shipping to P.O. Boxes.',
    CARRIER_INFO:
      'Includes the Personal Hotspot/Wi-Fi feature and syncs to email/outlook.',
  },
  NEW_HIRE: {
    GET_STARTED_SUBTITLE:
      'Onboard and explore recources to stay in the know about your technology.',
    SECURITY_PASSWORD_STRENGTH:
      'Personalize a strong password to secure your account.',
    SECURITY_SETTINGS:
      'Enroll in recovery password and 2 step verification methods.',
    VIDEO_CAROUSEL_TITLE: 'Onboarding Videos',
  },
  SIM: {
    NEW_SIM_CARD: 'New SIM Card',
    NEW_SIM_TITLE: 'New SIM Card Request',
    NEW_SIM_SUBTEXT:
      'Please complete the form below to submit a request for new SIM cards.',
    SIM_CARD_ACTIVATION: 'SIM Card Activation',
    ACTIVATION_SIM_TITLE: 'SIM Card Activation',
    ACTIVATION_SIM_SUBTEXT:
      'Please complete the form below to submit a SIM card activation request.',
  },
  TECH_SUPPORT: {
    HOME_OFFICE: 'Home Office Technical Support',
    VDI: 'VDI Technical Support',
    VIDEO_CONF: 'Video Conference Technical Support',
    STORE_ASSOCIATE: 'Store Associate Support',
  },
  CORPORATE_IPAD: 'Corporate iPad',
  TRACKING_NUMBER_NOTE:
    'If a return tracking number from the managers iPad cannot be provided, please input “none” or “NA” & explain why in Business Justification.',
  CORPORATE_IPAD_SUBTEXT: `Please complete the form below with information about the associate who will receive or use the Corporate iPad. This request will require approval from user's Director and Tech Support. Walmart does not have an iPad loaner program.`,
  CORPORATE_IPAD_STORECLUB_SUBTEXT: `Please complete the form below with information about the associate who will receive or use the Corporate iPad.`,
  CORPORATE_IPAD_STORECLUBDC_DISABLED:
    'Please reach out to your Market Coordinator to place an iPad order.',
  CORPORATE_HOTSPOT_DEVICE: 'Corporate Hotspot device',
  CORPORATE_HOTSPOT_ORDER: 'Hotspot order',
  SECURITY: {
    PHISHING_HIGH_PERCENTAGE: 'Helping us catch phish',
    PHISHING_ADVERAGE_PERCENTAGE: 'Sometimes helping us catch phish',
    PHISHING_LOW_PERCENTAGE: 'Not helping us catch phish',
  },
  MAC_OR_PC: 'Select a device to continue.',
  USER_LOOKUP: {
    ELIGIBLE:
      'This associate with job code(s) <jobcode> has finished the digital tools agreement and is eligible to place an order for a Connected Associate Device.',
    JOBCODE_NOT_ELIGIBLE:
      'This associate has at least one job code (<jobcode>) that is not eligible for Connected Associate Device ordering.',
    NO_JOBCODE_NOT_ELIGIBLE:
      'This associate does not have an eligible job code for Connected Associate Device ordering.',
    AGREEMENT_NOT_ELIGIBLE:
      'This associate is yet to finish the Digital tools agreement in-order to be eligible to place a Connected Associate Device order.',
    TEMP_ASSOCIATE_NOT_ELIGIBLE:
      "This user is a Temporary Associate and isn't eligible for Connected Associate Device ordering.",
  },
  VDI: {
    REQUESTS: 'Requests',
    TOOLTIP: {
      INDIVIDUAL: 'The cost shown is not charged to the assigned recipient.',
      MULTIPLE: 'The cost shown is not charged to the assigned recipients.',
    },
  },
  SHIPMENT_TRACKER: {
    ERROR: 'Sorry, there was an error fetching this data.',
    NO_SHIPMENTS: "You don't currently have any shipments",
    NO_REQUESTS:
      'Sorry, no requests were found that match these filters. Try adjusting your selections to find what you are looking for.',
    DEVICES_ERROR:
      'Device tracking information could not be fetched at this time.',
    NO_DEVICES: 'Device tracking information is not available for this order.',
    ALL_REQUESTS_SEARCH_MESSAGE:
      'Search by associate, manager or joining date above to see requests.',
  },
  STORE_LOCATION: {
    ERROR: 'Sorry, there was an error fetching this data.',
  },
  STORE_CLUB_HVAC_IPAD: {
    NO_UNIT_SELECTED: 'Please select a unit to determine the iPad model',
    NINTH_GENERATION_CELLULAR: '9th Gen Cellular', //can remove in future depending on the updates to hvac segment
  },
  TECH_BAR_LOCATION: {
    SEARCH_PLACEHOLDER: 'Search for a location by name or address',
    OPEN_LOCATIONS: 'Show open locations only',
    EMPTY_LOCATIONS:
      'Sorry, we couldn’t find any tech bar locations, please try adjusting your search or filters above.',
    EMPTY_OPEN_LOCATIONS:
      'Sorry, it looks like no locations are open at this time to assist you.',
  },
  CORPORATE_REPLACEMENT_CARRIER_NOTE: (
    <>
      Please select the same carrier as the device you are trying to replace
      has. Choosing a different carrier than the current device{' '}
      <strong>will cause delays in your replacement order process</strong>.
    </>
  ),
};

export default Messages;
