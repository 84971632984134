import React from 'react';
import URLs from '../../../consts/URLs';
import cookie from 'react-cookies';
import Colors from '../../../consts/Colors';
import AriaLabel from '../../../consts/AriaLabel';
import { IconButton } from '@walmart-web/livingdesign-components';
import { Note } from '@livingdesign/icons';

// Dev Note: Did not add event listener as this is used daily by MyTech Engineers and is only shown in the lower dev environment

const customStyles = {
  linkStyle: {
    display: 'flex',
    padding: '0px 6p',
  },
  iconButtonStyle: {
    color: Colors.WHITE,
  },
};

const HeaderLocalDev = () => {
  const jwtToken = cookie.load('jwtToken');
  return (
    <IconButton
      a11yLabel={AriaLabel.LOCAL_DEV_ICON}
      size="large"
      UNSAFE_style={customStyles.iconButtonStyle}
      UNSAFE_className="is-nav headerLocalDev"
      onClick={() => {
        window.open(URLs.getDevLocal(jwtToken), '_self');
      }}
    >
      <Note />
    </IconButton>
  );
};

export default HeaderLocalDev;
