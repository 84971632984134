import moment from 'moment';
import Consts from '../consts/Consts';
import EnvConfigs from '../Configurations';
import { ReactComponent as AppsIcon } from '../assets/svg/appsGrid.svg';
import { ReactComponent as EmailIcon } from '../assets/svg/email.svg';
import { ReactComponent as PhoneIcon } from '../assets/svg/mobile.svg';
import { getSSPRInfoMessage } from '../utils/SSPRInfoMessageUtil';

const ENROLLMENT_TYPES_SSPR_CONFIG = {
  PHONE_AUTH_METHOD: {
    alias: 'Phone',
    key: 'PHONE_AUTH_METHOD',
    propKey: 'phoneNumber',
  },
  EMAIL_AUTH_METHOD: {
    alias: 'Email',
    key: 'EMAIL_AUTH_METHOD',
    propKey: 'emailAddress',
  },
  MICROSOFT_AUTH_METHOD: {
    alias: 'Authenticator App (Microsoft)',
    key: 'MICROSOFT_AUTH_METHOD',
    propKey: 'displayName',
  },
  SOFTWARE_OAUTH_METHOD: {
    alias: 'Authenticator App (Other)',
    key: 'SOFTWARE_OAUTH_METHOD',
    propKey: 'displayName',
  },
  NO_AUTH_APP_METHOD: {
    alias: 'Authenticator App',
    key: 'NO_AUTH_APP_METHOD',
  },
};

export const EXPIRY_WARNING_DAY_COUNT = 14;

export const set2SVWarning = data => {
  if (!data) {
    return false;
  }
  const enrollmentOptions = ['sms', 'voice', 'mobile'];
  let showWarning = true;
  enrollmentOptions.forEach(option => {
    if (data[option] && data[option]['isEnrolled']) {
      showWarning = false;
    }
  });
  return showWarning;
};

export const isToShowSSPRWarning = ssprData => {
  if (!ssprData) {
    return true;
  }
  const authMethodsGroupByAuthMethodType =
    ssprData.primaryUserAccountData?.authMethods &&
    getSSPRAuthMethodsGroupByAuthMethodType(
      ssprData.primaryUserAccountData.authMethods
    );
  const checkArray = [
    ENROLLMENT_TYPES_SSPR_CONFIG.PHONE_AUTH_METHOD,
    ENROLLMENT_TYPES_SSPR_CONFIG.EMAIL_AUTH_METHOD,
  ];
  // no warning if both phone and email methods are registered
  return !(
    authMethodsGroupByAuthMethodType &&
    checkArray.every(ai =>
      authMethodsGroupByAuthMethodType.hasOwnProperty(ai.key)
    )
  );
};

export const setPasswordWarning = data => {
  let showPasswordExpiryWarning = false;
  /*
   * Warning : Don't use the BE value for daysUntilExpiry as it drops the time to consider all times are 12AM UTC.
   * Calculate daysUntilExpiry from passwordExpiry to avoid timezone inaccuracies.
   */
  const expiryDateUTC = formatDateToUTCStartOfDay(data.passwordExpiry);
  const nowUTC = moment.utc();
  const daysUntilExpiry = expiryDateUTC.diff(nowUTC, 'days', true);
  if (daysUntilExpiry <= EXPIRY_WARNING_DAY_COUNT) {
    showPasswordExpiryWarning = true;
  }
  return showPasswordExpiryWarning;
};
export const formatDateToUTCStartOfDay = date => {
  return moment.utc(date).startOf('day');
};

export const getISOStringFromUTCDate = (dateStr, dateFormat) => {
  return moment.utc(dateStr, dateFormat).startOf('day').toISOString();
};

export const getSSPRAuthMethods = (ssprAuthMethods, getIconFunc) => {
  const allAuthMethodTypes = Object.keys(ENROLLMENT_TYPES_SSPR_CONFIG);
  const ssprAuthMethodsGrouped = getSSPRAuthMethodsGroupByAuthMethodType(
    ssprAuthMethods
  );
  let ssprAuthMethodsOrdered = [];
  let isNoAuthAppRegistered = true;
  allAuthMethodTypes.forEach(ssprAuthMethodType => {
    //Gets authMethod configurations
    const authMethodConfig = ENROLLMENT_TYPES_SSPR_CONFIG[ssprAuthMethodType];
    const ssprAuthMethodsArray = ssprAuthMethodsGrouped[ssprAuthMethodType]; //an array, grouped with all similar auth methods

    if (!ssprAuthMethodsArray || !ssprAuthMethodsArray.length) {
      if (
        ssprAuthMethodType ===
          ENROLLMENT_TYPES_SSPR_CONFIG.PHONE_AUTH_METHOD.key ||
        ssprAuthMethodType ===
          ENROLLMENT_TYPES_SSPR_CONFIG.EMAIL_AUTH_METHOD.key ||
        (ssprAuthMethodType ===
          ENROLLMENT_TYPES_SSPR_CONFIG.NO_AUTH_APP_METHOD.key &&
          isNoAuthAppRegistered)
      ) {
        //add default if no authMethods found
        const mapObject = {
          type: authMethodConfig.alias,
          status: Consts.SECURITY_ACCESS.ENROLLMENT_STATUS.NOT_ENROLLED,
        };
        mapObject.icon = getIconFunc(mapObject.type);
        ssprAuthMethodsOrdered.push(mapObject);
      }
    } else {
      if (
        ssprAuthMethodType ===
          ENROLLMENT_TYPES_SSPR_CONFIG.MICROSOFT_AUTH_METHOD.key ||
        ssprAuthMethodType ===
          ENROLLMENT_TYPES_SSPR_CONFIG.SOFTWARE_OAUTH_METHOD.key
      ) {
        isNoAuthAppRegistered = false;
      }
      ssprAuthMethodsOrdered = ssprAuthMethodsOrdered.concat(
        ssprAuthMethodsArray
          .filter(ssprAuthMethod => {
            if (ssprAuthMethod.authMethod === 'PHONE_AUTH_METHOD') {
              return ssprAuthMethod.phoneType === 'mobile';
            }
            return true;
          })
          .map(ssprAuthMethod => {
            const mapObject = {
              type: authMethodConfig.alias,
              status:
                ssprAuthMethod[authMethodConfig.propKey] ||
                Consts.SECURITY_ACCESS.ENROLLMENT_STATUS.ENROLLED,
            };
            mapObject.icon = getIconFunc(mapObject.type);
            return mapObject;
          })
      );
    }
  });
  return ssprAuthMethodsOrdered;
};

const getSSPRAuthMethodsGroupByAuthMethodType = ssprAuthMethods => {
  //Groups ssprAuthMethods with authMethod type
  return ssprAuthMethods.reduce((map, ssprAuthMethod) => {
    if (!map[ssprAuthMethod.authMethod]) {
      map[ssprAuthMethod.authMethod] = [];
    }
    map[ssprAuthMethod.authMethod].push(ssprAuthMethod); //stores similar type of auth method in an array
    return map;
  }, {});
};

export const isSSPREnrolled = data => {
  return data.ssprData?.primaryUserAccountData?.authMethods?.length > 0;
};

export const generateMockSSPRData = (authMethodsFormData, upn) => {
  const ssprData = {};
  if (authMethodsFormData && authMethodsFormData.length) {
    const primaryUserAccountData = { upn };
    primaryUserAccountData.ssprStatus =
      Consts.SECURITY_ACCESS.SSPR_STATUS.ONBOARD;
    const authMethods = [];
    if (
      authMethodsFormData.includes(
        Consts.SECURITY_ACCESS.SSPR_MOCK_FORM_VALUES.PHONE
      )
    ) {
      authMethods.push({
        authMethod: ENROLLMENT_TYPES_SSPR_CONFIG.PHONE_AUTH_METHOD.key,
        phoneNumber: `+${Math.floor((Math.random() + 0.1) * 10000000000)}`,
        phoneType: 'mobile',
      });
    }
    if (
      authMethodsFormData.includes(
        Consts.SECURITY_ACCESS.SSPR_MOCK_FORM_VALUES.EMAIL
      )
    ) {
      authMethods.push({
        authMethod: ENROLLMENT_TYPES_SSPR_CONFIG.EMAIL_AUTH_METHOD.key,
        emailAddress: upn,
      });
    }
    if (
      authMethodsFormData.includes(
        Consts.SECURITY_ACCESS.SSPR_MOCK_FORM_VALUES.AUTHENTICATOR_APP
      )
    ) {
      const authMethod = getRandomAuthMethodType().key;
      const authMethodDetails = { authMethod };
      if (
        authMethod === ENROLLMENT_TYPES_SSPR_CONFIG.MICROSOFT_AUTH_METHOD.key
      ) {
        authMethodDetails.displayName = 'Mock Device';
      }
      authMethods.push(authMethodDetails);
    }
    primaryUserAccountData.authMethods = authMethods;
    ssprData.primaryUserAccountData = primaryUserAccountData;
  }
  return ssprData;
};

const getRandomAuthMethodType = () => {
  const authMethodTypes = [
    ENROLLMENT_TYPES_SSPR_CONFIG.SOFTWARE_OAUTH_METHOD,
    ENROLLMENT_TYPES_SSPR_CONFIG.MICROSOFT_AUTH_METHOD,
  ];
  return authMethodTypes[Math.floor(Math.random() * authMethodTypes.length)];
};

export const generateSSPRSectionData = (
  ssprData,
  getIconFunc,
  isSupportUser
) => {
  const normalizedSSPRData = {};
  normalizedSSPRData.primaryUserAccountData = getNormalizedSSPRData(
    ssprData.primaryUserAccountData,
    Consts.SECURITY_ACCESS.SSPR_AC_TYPES.PRIMARY,
    getIconFunc,
    isSupportUser,
    ssprData.isSMARTAssociate
  );
  normalizedSSPRData.cmUserAccountData = getNormalizedSSPRData(
    ssprData.cmUserAccountData,
    Consts.SECURITY_ACCESS.SSPR_AC_TYPES.CM,
    getIconFunc,
    isSupportUser,
    ssprData.isSMARTAssociate
  );
  normalizedSSPRData.cmcnUserAccountData = getNormalizedSSPRData(
    ssprData.cmcnUserAccountData,
    Consts.SECURITY_ACCESS.SSPR_AC_TYPES.CMCN,
    getIconFunc,
    isSupportUser,
    ssprData.isSMARTAssociate
  );
  return normalizedSSPRData;
};

export const generatePasswordData = passwordData => {
  const passwordInfo = {};
  passwordInfo.primaryUserAccountData = getNormalisedPasswordData(
    passwordData.primaryUserAccountData
  );
  passwordInfo.cmUserAccountData = getNormalisedPasswordData(
    passwordData.cmUserAccountData
  );
  passwordInfo.cmcnUserAccountData = getNormalisedPasswordData(
    passwordData.cmcnUserAccountData
  );
  return passwordInfo;
};

const getNormalisedPasswordData = passwordData => {
  if (!passwordData) {
    return null;
  }
  let passwordStrength;
  if (passwordData.passwordComplexity === null && passwordData.adGroupError) {
    passwordStrength = Consts.SECURITY_ACCESS.DATA_NOT_AVAILABLE;
  } else if (
    passwordData.passwordComplexity === false &&
    passwordData.sspr404Error
  ) {
    passwordStrength = Consts.SECURITY_ACCESS.DATA_NOT_AVAILABLE;
  } else if (passwordData.passwordComplexity) {
    passwordStrength = Consts.SECURITY_ACCESS.PASSWORD_STRENGTH.STRONG;
  } else {
    passwordStrength = Consts.SECURITY_ACCESS.PASSWORD_STRENGTH.NOT_STRONG;
  }
  passwordData.passwordStrength = passwordStrength;
  return passwordData;
};

const getNormalizedSSPRData = (
  ssprAccountData,
  acType,
  getIconFunc,
  isSupportUser,
  isSMARTAssociate
) => {
  if (!ssprAccountData) {
    return null;
  }
  const ssprDataGeneralised = {};
  const ssprStatus = ssprAccountData.ssprStatus;
  const isOnlyOneAuthMethod =
    ssprAccountData.authMethods && ssprAccountData.authMethods.length === 1;
  ssprDataGeneralised.message = getSSPRInfoMessage(
    ssprAccountData,
    acType,
    isOnlyOneAuthMethod,
    isSupportUser,
    isSMARTAssociate
  );
  if (ssprStatus === Consts.SECURITY_ACCESS.SSPR_STATUS.ONBOARD) {
    ssprDataGeneralised.authMethods = getSSPRAuthMethods(
      ssprAccountData.authMethods,
      getIconFunc
    );
  }
  if (isSupportUser) {
    ssprDataGeneralised.group = ssprAccountData.group;
  }
  ssprDataGeneralised.ssprStatus = ssprStatus;
  ssprDataGeneralised.upn = ssprAccountData.upn;
  return ssprDataGeneralised;
};

export const getSSPRMethodIcon = iconName => {
  switch (iconName) {
    case 'Phone':
      return <PhoneIcon />;
    case 'Email':
      return <EmailIcon />;
    default:
      return <AppsIcon />;
  }
};
