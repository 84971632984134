import { createTheme } from '@mui/material/styles';
import Colors, { LightTheme } from '../../consts/Colors';
import CSS from '../../consts/CSS';

const makePalette = () => ({
  mode: 'light',
  primary: {
    main: Colors.BLUE,
  },
  secondary: {
    main: Colors.TEXT_GREY,
  },
  text: {
    primary: Colors.TEXT_DEFAULT,
    secondary: Colors.TEXT_GREY,
    disabled: 'unset',
  },
  error: {
    light: ColorVariants.error.light,
    main: ColorVariants.error.main,
    dark: ColorVariants.error.dark,
    contastText: ColorVariants.error.dark,
  },
  success: {
    light: ColorVariants.success.light,
    main: ColorVariants.success.main,
    dark: ColorVariants.success.dark,
    contrastText: ColorVariants.success.dark,
  },
  warning: {
    light: ColorVariants.warning.light,
    main: ColorVariants.warning.main,
    dark: ColorVariants.warning.dark,
    contrastText: ColorVariants.warning.dark,
  },
  info: {
    light: ColorVariants.info.light,
    main: ColorVariants.info.main,
    dark: ColorVariants.info.dark,
    contrastText: ColorVariants.info.dark,
  },
});

const typography = {
  fontFamily: 'Bogle, Helvetica Neue, Helvetica, Arial, sans-serif',
  h1: {
    // matches LD3 "Display" text component
    fontSize: '48px',
    lineHeight: '60px',
    fontWeight: 700,
    letterSpacing: 'unset',
  },
  h2: {
    fontSize: '32px',
    lineHeight: '40px',
    fontWeight: 700,
    letterSpacing: 'unset',
  },
  h3: {
    // matches LD3 "Heading" text component
    fontSize: '20px',
    lineHeight: '26px',
    fontWeight: 700,
    letterSpacing: 'unset',
  },
  h4: {
    // matches LD3 "Subheading" text component
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 700,
    letterSpacing: 'unset',
  },
  caption: {
    // matches LD3 "Caption" component
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: 'unset',
  },
  body1: {
    // matches LD3 "Body" component
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: 'unset',
  },
  button: {
    textTransform: 'initial',
    fontWeight: 700,
    fontSize: '14px',
    letterSpacing: 'unset',
  },
};

const makeOverrides = () => ({
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: '24px',
      },
      text: {
        fontWeight: 400,
        textDecoration: 'underline',
        '&:disabled': {
          color: Colors.TEXT_GREY,
        },
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        '&.MuiPaper-elevation': {
          boxShadow: `${CSS.CARD_DROP_SHADOW_DIMENSIONS} ${LightTheme.cardDropShadowColor}`,
          backgroundImage: 'unset',
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: Colors.BLACK_TXT,
        '&.MuiCheckbox-root.Mui-checked': {
          color: Colors.BLACK_TXT,
        },
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: '24px',
        '&.MuiDialogContent-dividers': {
          borderTop: 'none',
        },
      },
    },
  },
  MuiDrawer: {
    defaultProps: {
      ModalProps: {
        [`@media (max-width: ${CSS.STANDARD_MAX_MOBILE_VIEW})`]: {
          keepMounted: true, // Better open performance on mobile.
        },
      },
    },
    styleOverrides: {
      root: {
        width: CSS.NAV_MAX_WIDTH_DESKTOP,
        '& .MuiDrawer-paper': {
          top: `${CSS.HEADER_HEIGHT}`,
          boxSizing: 'border-box',
        },
        '& .MuiBackdrop-root': {
          top: CSS.HEADER_HEIGHT,
        },
        [`@media (max-width: ${CSS.STANDARD_MAX_MOBILE_VIEW})`]: {
          top: `${CSS.HEADER_HEIGHT}`,
          width: CSS.NAV_MAX_WIDTH_MOBILE,
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundColor: Colors.WHITE,
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        color: Colors.BLACK_TXT,
        '&.Mui-checked': {
          color: Colors.BLACK_TXT,
        },
      },
    },
  },
  MuiRating: {
    styleOverrides: {
      icon: {
        color: Colors.BLACK,
        '&.MuiRating-iconEmpty': {
          color: Colors.BLACK,
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        borderBottom: `solid 1px ${Colors.LIGHTEST_GREY}`,
      },
    },
  },

  MuiBreadcrumbs: {
    styleOverrides: {
      li: {
        color: Colors.TEXT_GREY,
        '&:hover': {
          color: Colors.BLACK,
        },
      },
      separator: {
        color: Colors.TEXT_GREY,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        fontWeight: 400,
        color: Colors.BLACK,
        'text-transform': 'initial',
        '&.Mui-selected': {
          color: Colors.BLACK,
          fontWeight: 600,
        },
        '&.Mui-disabled': {
          color: Colors.TEXT_GREY,
        },
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        '& .MuiFormHelperText-root': {
          textAlign: 'right',
        },
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      paragraph: {
        color: Colors.BLACK,
        margin: '16px 0',
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        color: Colors.BLACK,
      },
      underlineAlways: {
        textDecoration: 'underline',
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      root: {
        backgroundColor: 'inherit',
        '&.Mui-disabled': {
          color: Colors.TEXT_GREY,
        },
        '&:hover': {
          backgroundColor: 'inherit',
          border: `1px solid ${Colors.LIGHTER_BLUE}`,
        },
      },
      today: {
        '&:not(.Mui-selected)': {
          border: `1px solid ${Colors.LIGHTER_BLUE}`,
          backgroundColor: 'inherit',
        },
      },
    },
  },
});

const ColorVariants = {
  success: {
    variant: 'success',
    light: '#EAF3E6',
    dark: '#1D5F02',
    main: '#2A8703',
    contrastText: '#1D5F02',
  },
  warning: {
    variant: 'warning',
    main: '#FFC220',
    light: '#FFF9E9',
    dark: '#662B0D',
    contrastText: '#662B0D',
  },
  error: {
    variant: 'error',
    main: '#DE1C24',
    light: '#FCE8E9',
    dark: '#9B1419',
    contrastText: '#590B0E',
  },
  info: {
    variant: 'info',
    main: Colors.BLUE,
    light: '#e6f1fc',
    dark: '#23272b',
    contrastText: '#2E2F32',
  },
};

const createLd3Theme = () =>
  createTheme({
    palette: makePalette(),
    typography: typography,
    components: makeOverrides(),
    spacing: 8,
  });

export default createLd3Theme;
