import React, { useState } from 'react';
import HeaderUserMenu from './HeaderUserMenu';
import UserType from '../../../user/UserType';
import { IconButton } from '@walmart-web/livingdesign-components';
import AriaLabel from '../../../consts/AriaLabel';
import { User } from '@livingdesign/icons';
import Colors from '../../../consts/Colors';
import { trackEvent } from '../../../utils/appInsight';
import Events from '../../../consts/Events';

/*
Dev Notes:
- Need a Avatar coomponent from LD3. They have this in the backlog see https://jira.walmart.com/browse/LD-3758.
*/

const customStyles = {
  iconButtonStyle: {
    marginLeft: '6px',
    marginRight: '2px',
    color: Colors.WHITE,
  },
};

const HeaderUser = () => {
  const [showProfileMenu, setShowProfileMenu] = useState(false);

  const name = UserType.preferredName
    ? UserType.preferredName
    : UserType.displayName;
  const initial = name?.slice(0, 1);
  const upn = UserType.userPrincipalName;

  return (
    <>
      <IconButton
        a11yLabel={AriaLabel.USER_SETTINGS_ICON}
        aria-haspopup="dialog"
        onClick={e => {
          e.preventDefault();
          trackEvent(Events.USER_SETTINGS_ICON);
          setShowProfileMenu(true);
        }}
        UNSAFE_className="headerUser" // Currently used for checkIfNavigatableElementClicked in DL and other hidding utilities. Need to review once DL has been offboarded.
        UNSAFE_style={customStyles.iconButtonStyle}
        size="large"
        id="headerUserButton"
        data-testid="headerUserButton"
      >
        <User aria-hidden={true} />
      </IconButton>

      {showProfileMenu && (
        <HeaderUserMenu
          initial={initial}
          name={name}
          upn={upn}
          setShowProfileMenu={setShowProfileMenu}
          showProfileMenu={showProfileMenu}
        />
      )}
    </>
  );
};

export default HeaderUser;
