import { trackError } from '../utils/appInsight';

export default class ErrorHandler {
  static GENERIC_ERROR_MESSAGE = 'Server Error Occurred';

  static trackError = (errorMessage, data) => {
    console.error(errorMessage, data);
    trackError(errorMessage, data);
  };
}
