import React from 'react';
import PropTypes from 'prop-types';
import Colors from '../../../consts/Colors';
import WarningIcon from '../../../components/misc/WarningIcon';
import Tooltips from '../../../consts/Tooltips';
//NOTE: Even for a single radio button, it must be wrapped by MTRadioButtons.

const MTRadioButton = ({
  field, //Will be added at runtime by MTRadioButtons
  id, //Will be added at runtime by MTRadioButtons
  groupName, //Will be added at runtime by MTRadioButtons
  value,
  className,
  label = value,
  disabled = false,
  showWarning = false,
}) => {
  return (
    <div>
      <input
        {...field}
        type="radio"
        id={id}
        name={groupName}
        value={value}
        checked={field && field.value === value}
        className={className}
        disabled={disabled}
      />
      <label htmlFor={id}>{label}</label>
      {showWarning ? (
        <WarningIcon
          className="mailstopWarning"
          tooltipMessage={Tooltips.SEND_TO_MAILTOP_WARNING}
        />
      ) : null}
      <style jsx>
        {`
          /* Add :global(html) to have a higher specificity than DL */
          :global(html) input[type='radio'] {
            position: relative;
            appearance: none;
            height: 20px;
            width: 20px;
            background-color: #fff;
            border: 2px solid ${Colors.TEXT_GREY};
            border-radius: 50%;
            margin-left: 0;
            margin-right: 0;
            cursor: default;
            min-width: 0;
          }

          :global(html) input[type='radio']:before {
            content: none;
          }

          :global(html) input[type='radio']:checked:after {
            content: '';
            position: absolute;
            top: 2px;
            left: 2px;
            width: 12px;
            height: 12px;
            background-color: ${Colors.BLUE};
            border-radius: 50%;
          }

          :global(html) input[type='radio'][disabled] {
            border: 2px solid ${Colors.COMPONENT_LIGHT_GREY} !important;
            cursor: not-allowed !important;
          }

          label {
            font-size: 14px;
            position: relative;
            top: -4px;
            padding-left: 10px;
            margin-right: 20px;
          }

          div > :global(.mailstopWarning) {
            display: inline;
          }

          :global(html) input[type='radio'][disabled] + label {
            color: ${Colors.COMPONENT_LIGHT_GREY} !important;
          }
        `}
      </style>
    </div>
  );
};

MTRadioButton.propTypes = {
  field: PropTypes.object,
  id: PropTypes.string,
  groupName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
};

export default MTRadioButton;
