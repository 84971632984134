import axios from 'axios';
import APIs from '../consts/APIs';
import PageTitles from '../consts/PageTitles';
import fetchDynamicTextData from '../utils/fetchDynamicTextData';
import MFEs from '../consts/MFEs';

const RESERVED_CLASS_KEYWORD = 'name';

export default class Config {
  static ConfigGroups = {
    App: { name: 'App Management', list: [] },
    Security: { name: PageTitles.SECURITY, list: [] },
    DL: { name: PageTitles.DISTRIBUTION_LIST, list: [] },
    Mobile: { name: PageTitles.MOBILE, list: [] },
    Replacements: { name: 'Corporate Replacements', list: [] },
    MyDevices: { name: PageTitles.DEVICES, list: [] },
    TechSupport: { name: PageTitles.HELP_CENTER, list: [] },
    Tickets: { name: PageTitles.TICKETS, list: [] },
    FeedbackDelay: { name: 'FeedbackDelay', list: [] },
    Search: { name: 'Search', list: [] },
    AppStoreV2: {
      name: 'AppStoreV2',
      list: [],
    },
    TMobile: {
      name: 'TMobile',
      list: [],
    },
    HardwareShipmentTracker: {
      name: 'HardwareShipmentTracker',
      list: [],
    },
  };

  static broadcastMessage = '';
  static HowDidYouHearAboutUsOptions = [];
  static qaUsers = '';

  static fetchAppConfig = async () => {
    const response = await axios.post(
      APIs.getAPIUrl(APIs.APP_CONFIG.FETCH),
      {}
    );

    const appConfig = response.data;
    //Dynamically create configs from response
    for (const [configKey, configValue] of Object.entries(appConfig)) {
      if (Config.ConfigGroups[configKey] !== undefined) {
        Config.ConfigGroups[configKey].list = configValue;
        if (Array.isArray(configValue)) {
          configValue.forEach(groupedConfig => {
            Reflect.defineProperty(Config, groupedConfig.name, {
              value: groupedConfig.value,
            });
          });
        }
      }

      if (
        Config[configKey] !== undefined &&
        configKey !== RESERVED_CLASS_KEYWORD
      ) {
        Config[configKey] = configValue;
      }
    }

    return response;
  };

  static fetchGuideToggles = async upn => {
    const response = await axios.get(
      `${APIs.getAPIUrl(APIs.GUIDE_TOGGLES)}?upn=${upn}`,
      undefined,
      {
        headers: {
          ignoreGenericError: true,
          ignoreGraphQLError: true,
          ignoreNetworkError: true,
        },
      }
    );
    if (response && Array.isArray(response.data)) {
      response.data.forEach(toggle => (Config[toggle.name] = true));
    }
  };

  static fetchQAUsers = async () => {
    if (Config.qaUsers === '') {
      await fetchDynamicTextData('QA_USERS', qaUsers => {
        Config.qaUsers = qaUsers;
      });
    }
  };
}

// helper function creates an array of the micro front ends enabled for the user
export const getEnabledMFEs = () => {
  const mfes = [];
  if (Config.isAppStoreModuleEnabled) {
    mfes.push(MFEs.APPSTORE);
  }
  if (Config.isOrderingModuleEnabled) {
    mfes.push(MFEs.ORDERING);
  }
  if (Config.isHolidayReadinessModuleEnabled) {
    mfes.push(MFEs.HOLIDAYREADINESS);
  }
  return mfes;
};
