import axios from 'axios';
import Consts from '../consts/Consts';
import { successFailure } from '../utils/interceptorHelpers';
import { trackEvent } from '../utils/appInsight';
import UserType from '../user/UserType';
import APIs from '../consts/APIs';
import Events from '../consts/Events';

const MTOrder = {
  _defaultObject: {
    displayName: null,
    requestorName: null,
    userEmail: null,
    email: null,
    requestType: null,
    managerUPN: null,
    division: '',
    department: '',
    storeClubDC: null,
    resolvedOUName: null,
    domain: null,
    status: 'New',
    carrier: null,
    notes: null,
    phoneNumber: null,
    isSeniorOrAbove: false,
  },

  _additionalSimObject: {
    projectName: null,
    storeNumber: null,
    recipient: null,
    deviceType: null,
    modelType: null,
    additionalDetails: null,
    deliveryLocation: null,
  },

  _additionalInternationalObject: {
    deviceDetails: null,
    additionalDetails: null,
    deviceSelected: null,
    employeeType: null,
    hotspotdevice: false,
    moad: false,
  },

  _additionalCancelCorporateObject: {
    additionalDetails: null,
    deviceType: null,
  },

  _additionalCorporateObject: {
    areaCode: null,
    deliveryLocation: null,
    deliveryType: 'remote',
    deviceSelected: null,
    deviceType: 'mobile',
    employeeType: null,
    international: null,
    photoURL: null,
    shipping: 'Standard',
    hotspotdevice: null,
    moad: false,
    notes: null,
    zeroUsageAgreed: null,
  },

  _additionalLoanerObject: {
    deliveryLocation: null,
    returnDate: null,
    returnDateStr: null,
    deliveryType: null,
    employeeType: null,
    shipping: 'Standard',
    deviceType: 'mobile',
    hotspotdevice: false,
    moad: false,
  },

  _additionalIpadObject: {
    modelType: null,
    deliveryLocation: null,
    deliveryType: 'remote',
    shipping: 'Standard',
    deviceType: null,
    notes: null,
    additionalDetails: null,
    returnDate: null,
  },

  _additionalHotspotObject: {
    deliveryLocation: null,
    additionalDetails: null,
    duration: null,
  },

  _additionalEagleAtHomeObject: {
    deliveryLocation: null,
    additionalDetails: null,
    deliveryType: null,
  },

  //Try to use same prop names in code that BE expects, so the props object you pass can have as few prop/mappings as possible.
  //Pass isForAnother as true to add requestor props
  //If user.manager is null, pass the data as the manager prop. This is done, so we don't add manager data original user data object.
  getNewOrderObject: (
    user,
    type,
    overrideProps = null,
    isForAnother = false,
    manager = null
  ) => {
    let retVal = {
      ...MTOrder._defaultObject,
      ...MTOrder.getUserData(user, manager),
    };
    let badKeys;

    switch (type) {
      case MTOrder.TYPE_SIM:
        retVal = { ...retVal, ...MTOrder._additionalSimObject };
        break;
      case MTOrder.TYPE_INTERNATIONAL_ANOTHER:
        retVal = {
          ...retVal,
          ...MTOrder._additionalInternationalObject,
          employeeType: Consts.ANOTHER,
        };
        break;
      case MTOrder.TYPE_INTERNATIONAL_MYSELF:
        retVal = {
          ...retVal,
          ...MTOrder._additionalInternationalObject,
          employeeType: Consts.MYSELF,
          moad: UserType.moad,
        };
        break;
      case MTOrder.TYPE_CANCEL_CORPORATE:
        // remove requestType and managerUPN as they are not used in cancelService
        badKeys = ['requestType', 'managerUPN'];
        badKeys.forEach(key => {
          delete retVal[key];
        });

        retVal = {
          ...retVal,
          status: 'Cancelled',
          ...MTOrder._additionalCancelCorporateObject,
        };
        break;
      case MTOrder.TYPE_LOANER:
        retVal = {
          ...retVal,
          ...MTOrder._additionalLoanerObject,
          moad: UserType.moad,
        };
        break;
      case MTOrder.TYPE_CORPORATE:
        retVal = {
          ...retVal,
          ...MTOrder._additionalCorporateObject,
          moad: UserType.moad,
        };
        break;
      case MTOrder.TYPE_IPAD:
        // remove requestType and managerUPN as they are not used in cancelService
        badKeys = ['phoneNumber'];
        badKeys.forEach(key => {
          delete retVal[key];
        });

        retVal = {
          ...retVal,
          ...MTOrder._additionalIpadObject,
          moad: UserType.moad,
        };
        break;
      case MTOrder.TYPE_HOTSPOT:
        // remove carrier as it's not used in hotspot
        badKeys = ['carrier'];
        badKeys.forEach(key => {
          delete retVal[key];
        });
        retVal = {
          ...retVal,
          ...MTOrder._additionalHotspotObject,
          moad: UserType.moad,
        };
        break;
      case MTOrder.TYPE_EAGLE_HOME_DEVICE:
        badKeys = ['carrier'];
        badKeys.forEach(key => {
          delete retVal[key];
        });
        retVal = {
          ...retVal,
          ...MTOrder._additionalEagleAtHomeObject,
          moad: UserType.moad,
        };
        break;
      default:
        break;
    }

    retVal = { ...retVal, ...overrideProps };
    if (isForAnother) {
      retVal.requestorEmail = UserType.userEmail;
      retVal.requestorUPN = UserType.userPrincipalName;
    }
    return retVal;
  },

  getUserData: (user, manager) => {
    const managerData = user.manager || manager;
    const managerUPN = managerData
      ? MTOrder.dropUPNDomain(managerData.userPrincipalName)
      : null;

    return {
      displayName: user.DisplayName ? user.DisplayName : null,
      requestorName: UserType.displayName ? UserType.displayName : null,
      userEmail: user.UserPrincipalName ? user.UserPrincipalName : null,
      email: user.EmailAddress ? user.EmailAddress : null,
      managerUPN: managerUPN,
      division: user.Division ? String(user.Division) : Consts.EMPTY,
      department: user.Department ? String(user.Department) : Consts.EMPTY,
      storeClubDC: user.StoreClubDC ? user.StoreClubDC : null,
      resolvedOUName: user.ResolvedOUName ? user.ResolvedOUName : null,
      domain: user.Domain ? user.Domain : null,
      isSeniorOrAbove: user.isSeniorOrAbove || false,
    };
  },

  dropUPNDomain: email => {
    return email ? email.split('@')[0] : null;
  },

  getADInfo: data => {
    return data.MyTech.ActiveDirectory_Information[0];
  },

  getRequestType: orderFlow => {
    switch (orderFlow) {
      case Consts.REQUEST_TYPES.INTERNATIONAL_PLAN:
        return Consts.REQUEST_TYPES.INTERNATIONAL;
      case Consts.REPLACEMENT_TYPES.LOST_STOLEN:
        return Consts.REQUEST_TYPES.LOST_STOLEN_REPLACEMENT;
      case Consts.REPLACEMENT_TYPES.POWER:
        return Consts.REQUEST_TYPES.POWER_REPLACEMENT;
      case Consts.REPLACEMENT_TYPES.DAMAGED:
        return Consts.REQUEST_TYPES.DAMAGE_REPLACEMENT;
      case Consts.REQUEST_TYPES.IPAD:
        return Consts.REQUEST_TYPES.IPAD;
      case Consts.REQUEST_TYPES.HOTSPOT:
        return Consts.REQUEST_TYPES.HOTSPOT;
      case Consts.REQUEST_TYPES.EAGLE_HOME:
        return Consts.REQUEST_TYPES.EAGLE_HOME;
      default:
        return orderFlow;
    }
  },

  getCarrier: carrierString => {
    if (/at&?t/gi.test(carrierString)) {
      return Consts.CARRIER_TYPES.ATT;
    }
    if (/verizon/gi.test(carrierString)) {
      return Consts.CARRIER_TYPES.VERIZON;
    }
    if (/t-?mobile/gi.test(carrierString)) {
      return Consts.CARRIER_TYPES.T_MOBILE;
    }
    return carrierString;
  },

  getDeliveryLocation: (address, city, state, zip, phone, notes, building) => {
    return {
      address: address ? address : null,
      city: city ? city : null,
      state: state ? state : null,
      zip: zip ? zip : null,
      phone: phone ? phone : null,
      notes: notes ? notes : null,
      building: building ? building : null,
    };
  },

  getAdditionalDetails: (name, value) => {
    return {
      name: name ? name : null,
      value: value ? value : null,
    };
  },

  getDeviceActivation: (begin, end, extension) => {
    return {
      begin: begin ? begin : Consts.EMPTY,
      end: end ? end : Consts.EMPTY,
      extension: extension ? extension : Consts.EMPTY,
    };
  },

  getDeviceDetails: device => {
    return {
      serialNumber: device.SerialNumber ? device.SerialNumber : null,
      status: 'New',
      ownerType: 'corporate',
      state: 'new',
      activation: MTOrder.getDeviceActivation(),
    };
  },

  getGenericADInfo: (displayName, mail, userPrincipalName, fullUpn) => {
    return {
      MyTech: {
        ActiveDirectory_Information: [
          {
            DisplayName: displayName,
            EmailAddress: mail,
            UserPrincipalName: userPrincipalName,
            fullUpn: fullUpn,
          },
        ],
      },
    };
  },

  compareUPNs: (managerData, selectedUserData) => {
    if (!managerData || !selectedUserData) {
      return false;
    } else {
      const userUPN = MTOrder.getADInfo(selectedUserData);
      const managerUPN = MTOrder.getADInfo(managerData);
      return userUPN.UserPrincipalName === managerUPN.UserPrincipalName;
    }
  },

  submitOrder: async (order, success, failure) => {
    const request = {
      query: `mutation submitOrder($order: OrderInput!) {
        createOrder(order: $order){
          status
          orderId
      }
      }`,
      variables: { order: order },
    };

    await axios
      .post(APIs.getAPIUrl(APIs.GRAPHQL), request, {
        headers: JSON.parse(
          JSON.stringify({
            ignoreGenericError: true,
            ignoreGraphQLError: true,
            ignoreNetworkError: true,
          })
        ),
      })
      .then(res => {
        if (successFailure(res)) {
          failure(res);
        } else {
          success(res);
          trackEvent(Events.MOBILE_COMMON.ORDER_PLACED, order);
        }
      })
      .catch(err => {
        failure(err);
      });
  },

  cancelService: async (order, success, failure) => {
    const request = {
      query: `mutation cancelService($serviceData: ServiceDetailsInput!) {
        cancelService(serviceData: $serviceData){
          status
          orderId
      }
      }`,
      variables: { serviceData: order },
    };

    await axios
      .post(APIs.getAPIUrl(APIs.GRAPHQL), request, {
        headers: JSON.parse(
          JSON.stringify({
            ignoreGenericError: true,
            ignoreGraphQLError: true,
            ignoreNetworkError: true,
          })
        ),
      })
      .then(res => {
        if (successFailure(res)) {
          failure(res);
        } else {
          success(res);
          trackEvent(Events.CANCELLATION.ORDER_PLACED, order);
        }
      })
      .catch(err => {
        failure(err);
      });
  },

  isOrderAllowed: async (userEmail, requestType, isHotSpot) => {
    const hotspotParam = isHotSpot ? ', isHotspot: true' : Consts.EMPTY;

    const query = {
      query: `{
          isOrderAllowed(userEmail: "${userEmail}", requestType: ${MTOrder.getRequestType(
        requestType
      )}${hotspotParam})}`,
    };

    return axios.post(APIs.getAPIUrl(APIs.GRAPHQL), query);
  },

  fetchManager: async userEmail => {
    return axios.post(
      APIs.getAPIUrl(APIs.MANAGER_DATA),
      {
        upn: userEmail,
      },
      { headers: { ignoreGenericError: true } }
    );
  },

  fetchCostCenter: async upn => {
    return axios.get(`${APIs.getAPIUrl(APIs.COST_CENTER_DATA)}?upn=${upn}`, {
      headers: { ignoreGenericError: true },
    });
  },

  isReplacementAllowed: async userEmail => {
    const query = {
      query: `{
        eligibleForReplacementRequest(userEmail: "${userEmail}")
      }`,
    };

    return axios.post(APIs.getAPIUrl(APIs.GRAPHQL), query);
  },

  TYPE_SIM: 'sim',
  TYPE_LOANER: 'loaner',
  TYPE_CORPORATE: 'corporate',
  TYPE_CANCEL_CORPORATE: 'cancelCorporate',
  TYPE_INTERNATIONAL_ANOTHER: 'internationalAnother',
  TYPE_INTERNATIONAL_MYSELF: 'internationalMyself',
  TYPE_IPAD: 'ipad',
  TYPE_HOTSPOT: 'hotspot',
  TYPE_EAGLE_HOME_DEVICE: 'eagleAtHome',
  ADDITIONAL_DETAILS_IMEI: 'IMEINUMBERS',
  ADDITIONAL_DETAILS_NUMBER_OF_SIM_CARDS: 'NumberOfSimCards',
  ADDITIONAL_DETAILS_BUSINESS_POC: 'BUSINESSPOINTOFCONTACT',
  ADDITIONAL_DETAILS_DEPARTMENT_NAME: 'DEPARTMENTNAME',
  ADDITIONAL_DETAILS_BUSINESS_SEGMENT: 'BUSINESSSEGMENT',
  ADDITIONAL_DETAILS_BUSINESS_UNIT: 'BUSINESSUNIT',
  ADDITIONAL_DETAILS_JOB_POSITION: 'JOBPOSITION',
  ADDITIONAL_DETAILS_QUANTITY: 'QUANTITY',
  ADDITIONAL_DETAILS_FACILITY_TYPE: 'FACILITYTYPE',
  ADDITIONAL_DETAILS_IPAD_REQUEST_TYPE: 'IPADREQUESTTYPE',
  ADDITIONAL_DETAILS_FACILITY_NUMBER: 'FACILITYNUMBER',
  ADDITIONAL_DETAILS_MARKET_NUMBER: 'MARKETNUMBER',
  ADDITIONAL_DETAILS_IPAD_TRACKING_NUMBER: 'IPADTRACKINGNUMBER',
  ADDITIONAL_DETAILS_DIRECTOR: 'DIRECTOR',
  ADDITIONAL_DETAILS_REQUESTED_DELIVERY_DATE: 'REQUESTEDDELIVERYDATE',
  ADDITIONAL_DETAILS_MODEL_TYPE: 'MODELTYPE',
  ADDITIONAL_DETAILS_SIM_NUMBERS: 'SIMNUMBERS',
  ADDITIONAL_DETAILS_SIM_SIZE: 'SIMSIZE',
  ADDITIONAL_DETAILS_USERS: 'USERS',
  ADDITIONAL_DETAILS_REQUESTOR_DOMAIN: 'REQUESTORDOMAIN',
  ADDITIONAL_DETAILS_REQUESTOR_RESOLVED_OU: 'REQUESTORRESOLVEDOU',
  ADDITIONAL_DETAILS_REQUESTOR_STORE_CLUB_DC: 'REQUESTORSTORECLUBDC',
  ADDITIONAL_DETAILS_REQUESTOR_DEPARTMENT: 'REQUESTORDEPARTMENT',
  ADDITIONAL_DETAILS_REQUESTOR_DIVISION: 'REQUESTORDIVISION',
  ADDITIONAL_DETAILS_HOTSPOT_REQUEST_TYPE: 'HOTSPOTREQUESTTYPE',
  ADDITIONAL_DETAILS_COST_CENTER: 'COSTCENTER',
  ADDITIONAL_DETAILS_MANAGER: 'MANAGER',
};

export default MTOrder;
