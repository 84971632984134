import EnvConfigs from '../Configurations';

const URLs = {
  MANAGE_SETTINGS_2SV: 'https://wmlink/2step',
  MANAGE_SETTINGS_SSPR: 'https://wmlink/SSPRSetup',
  SSPR_HELP: 'https://wmlink/SSPRHelp',
  SSPR_FAQ: 'https://wmlink/SSPRFAQ',
  SSPR_METHOD_RESET_URL: 'https://wmlink/SSPRMethodReset',
  SSPR_ONBOARD: 'https://wmlink/SSPROnboard',
  SSPR_OPT_OUT: 'https://wmlink/SSPROptOut',
  ESS: 'https://walmart.service-now.com/ess',
  EDLP: 'https://fds.wal-mart.com/edlp/',
  BYOD_UNENROLL: 'https://mobile.wal-mart.com/MyDevice/',
  SUMBIT_A_TICKET: 'https://wmlink.wal-mart.com/submitaticket',
  ADE_HELP:
    'https://walmartglobal.service-now.com/wm_sp?id=search&t=&q=adehelp',
  LOST_STOLEN:
    'https://walmartglobal.service-now.com/wm_sp?id=search&t=&q=adehelp',
  CONTACT_IT: 'https://wmlink/contactit',
  CHANGE_OF_INFORMATION_URL: 'https://changeofinfo-prod.wal-mart.com/',
  HOTSPOT_SELF_SERVICE_URL: 'https://feedbackally.walmart.com/survey/4sqevoXeO',
  BYOD_ENROLL_URL:
    'https://one.walmart.com/content/uswire/en_us/company/byod.html',
  MISSING_DEVICE_URL: 'https://feedbackally.walmart.com/survey/7hpWixMD-',
  APPLE_SUPPORT_CHARGING: 'https://support.apple.com/en-us/HT201569',
  SPS: 'https://wmlink/SPS',
  CONTACT_US:
    'https://walmart.workplace.com/groups/458958251229610/?fref=ts&epa=SEARCH_BOX',
  getUPSTracking: trackingNumber => {
    return `https://www.ups.com/track?loc=en_US&tracknum=${trackingNumber}&requester=WT`;
  },
  getSNowTracking: trackingNumber => {
    return `${EnvConfigs.REACT_APP_SERVICE_NOW_URL}/wm_sp?id=ticket&table=sc_req_item&sys_id=${trackingNumber}`;
  },
  SUPPORT_CHAT: 'https://wmlink/supportchat',
  TECH_TIPS: 'https://wmlink/techtips',
  TECH_BAR:
    'https://walmartglobal.service-now.com/wm_sp/?id=walkup_online_checkin&location_id=a574a2d91bc7185060f6639bbc4bcb0f',
  VIRTUAL_TECH_BAR:
    ' https://walmartglobal.service-now.com/wm_sp?id=walkup_online_checkin&location_id=00ba4806dbf3e050ef763a8f29961979',
  MYTECH_APPS_ISSUE: 'https://feedbackally.walmart.com/survey/UXID4J_4050L18d',
  STORE_ASSOCIATE_TECH_SUPPORT: 'https://fixit.walmart.com/fixit/',
  OLD_TICKETS_URL: 'https://walmartglobal.service-now.com/wm_sp/?id=my_items',
  PHISHING_TIPS: 'https://wmlink.wal-mart.com/7tipstocatchaphish',
  EMAIL_ABUSE_MAILTO: 'mailto:inapprop@wal-mart.com',
  HARDWARE_ACCESSORIES: 'https://wmlink/servicenow',
  PC_SOFTWARE: 'https://wmlink/edlp',
  MOBILE_HOTSPOT: 'https://mytech.walmart.com/mobile',
  BYOD_URL: 'https://wmlink/byod',
  DIGITAL_WORK_SPACE:
    'https://teams.wal-mart.com/sites/DWS/SitePages/DWS-Request-Form.aspx',
  CISCO_ANYCONNECT:
    'https://walmartglobal.service-now.com/wm_sp?sys_kb_id=8537f69cdb1518d02bc56033ca961936&id=kb_article_view&sysparm_rank=1&sysparm_tsqueryId=3a6dcf7c8738595cfa7776a6cebb350e',
  ZOOM_URL:
    'https://one.walmart.com/content/associate/home/product_lines/whattousewhen/zoom.html',
  OUTLOOK_URL: 'https://wmlink/office',
  DEV_ORIGIN: 'https://mytech.dev.walmart.com',
  PROCURE_IT_URL:
    'https://web.procureit.prod.walmart.com/procureit-web/#/login?sessionTimeOut=true',
  HELP_CENTER_SUPPORT_CHAT: 'https://supportchat.walmart.com/home',
  TOPIC_EAGLE_AT_HOME: 'https://saone.walmart.com/control/eagleathome',
  VDI_LOGIN: 'https://dws.workspaceoneaccess.com/',
  WORKPLACE: 'https://walmart.workplace.com/groups/186811895362206',
  DIGITAL_DEVICES_HELP:
    'https://one.walmart.com/content/uswire/en_us/work1/operations/digital-tools/ddf.html',
  getDevLocal: jwtToken => {
    return `http://localhost:3000/login?responseCode=200&jwtToken=${jwtToken}`;
  },
  MYTECH_PROD: 'https://mytech.walmart.com',
  IDC_REQUEST_VDI: 'https://wmlink/idcvpcrequest',
  GOOGLE_MAPS_LOCATION: 'https://www.google.com/maps/search/?api=1&query=',
  VC_DEVICE_NETWORK_ISSUES:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=795ef09e1b839914d86ba865624bcb35',
  VC_DGJ_CAPABILITIES:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=f436d8411b92dd5cf59855f2604bcba1',
  VC_GENERAL_ISSUES:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=f838f8121b8f5914d86ba865624bcbe1',
  VC_PRESENTATION_ISSUES:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=c7ae7c521bc39914d86ba865624bcbbb',
  VC_ROOM_SCHEDULING:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=e7eeb4161bc39914d86ba865624bcb0a',
  VC_AUDIO_ISSUES:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=ee8c341e1b439914d86ba865624bcbea',
  VC_CAMERA_ISSUES:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=9ba71c661b4b5154d86ba865624bcb18',
  VC_TOUCH_PANEL_PAIRING:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=21f6dd599755edd43e3e32d71153af61',
  VC_TV_ISSUES:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=559fb89a1bc39914d86ba865624bcb05',
  VC_VIDEO_CONFERENCING:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=440418411b12dd5cf59855f2604bcbc5',
  AVC_CISCO_PANEL_PAIRING:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=ea3b8c621b0b1154d86ba865624bcb73',
  AVC_DAMAGED_DEFECTIVE_DEVICES:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=7103c4de1bef1194d86ba865624bcb88',
  AVC_DEVICE_NETWORKING:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=240114ea1b8f1154d86ba865624bcbfd',
  WMHVC_DEVICE_NETWORKING:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=5c2a188d1b56dd5cf59855f2604bcb84',
  WMHVC_TOUCH_PANEL_PAIRING:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=d02ad48d1b56dd5cf59855f2604bcb5a',
  ZOOM_NETWORK:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=7ec2ed3d1b4f5d90d86ba865624bcbc8',
  ZOOM_DISPLAY_CHECKIN:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=9076199d9715edd43e3e32d71153af34',
  ZOOM_ACCOUNT:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=852a988d1b56dd5cf59855f2604bcb3b',
  ZOOM_ACCOUNT_ACTIVATION:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=2aa88a0f1b3041143163cb33604bcb50',
  ZOOM_MOBILE_ISSUES:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=e54d110d4732d9d074c16433846d43f4',
  ZOOM_RECORDING:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=70b4dc0d1b12dd5cf59855f2604bcb7b',
  ZOOM_OUTLOOK_SCHEDULING_ISSUES:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=3b0e7a311b0355d0d86ba865624bcbc1',
  ZOOM_FREEZE:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=c2d205dc1b5461dc52108665624bcbaa',
  TEAMS_VOICE_AND_VIDEO:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=246cea2b1bbe919cf59855f2604bcbff',
  MSTEAMS_MOBILE_APP:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=dc3a6db11bc39d90d86ba865624bcb4d',
  MSTEAMS_APPLICATION_ISSUES:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=04ef0a791b0351d0d86ba865624bcb10',
  MSTEAMS_AUDIO_ISSUES:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=6514a8651b0b5d50d86ba865624bcb54',
  MSTEAMS_LOGIN_ISSUE:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=aabf76ce1b471114d86ba865624bcbdf',
  MSTEAMS_NETWORK_LATENCY:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=654008c71b5259d06f092064604bcb16',
  MSTEAMS_LOOP_COMPONENTS:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=0a553c6b1bec1158d86ba865624bcb88',
  MSTEAMS_INSTALL_ON_WINDOWS:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=939d3b7a1b9e19d06f092064604bcb4d',
  MSTEAMS_MEETING_SCHEDULING:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=b1e651599755edd43e3e32d71153afc9',
  MSTEAMS_VIDEO_ISSUES:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=246cea2b1bbe919cf59855f2604bcbff',
  WP_DESKTOP_ISSUES:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=45cfa8c31bd6d9d06f092064604bcb40',
  WP_MOBILE_LOGIN:
    'https://walmartglobal.service-now.com/wm_sp?id=kb_article_view&sys_kb_id=5af84c9d1b8b91509556a758b04bcbb7',
  VDI_VENDOR_MSOFFICE_LICENSE:
    'https://wmlink.wal-mart.com/VendorOfficeLicense',
  LAPTOP_SAFETY: 'https://wmlink/laptopsafety',
  ULEARN_US: 'https://wmlink/ulearn',
  ULEARN_INTL: 'https://wmlink/ulearnintl',
  ULEARN_VENDOR:
    'https://supplier-moodle-azure-prod.cloud.prod.us.walmart.net/my/',
  ULEARN_INFO: 'https://wmlink.wal-mart.com/InfoSecTraining',
  FIND_MY_WIN_NUMBER:
    'https://one.walmart.com/content/uswire/en_us/me/apps/winfinder.html',
  CHANGE_OUTLOOK_INFO: 'https://changeofinfo-prod.wal-mart.com/',
  WORKDAY: 'https://wd5.myworkday.com/walmart/d/home.htmld',
  ONE_WALMART: 'https://one.walmart.com/',
  OFFICE_TOOLS: 'https://wmlink/learningpathways',
  USER_PROFILE_AND_AD_GROUP: 'https://wmlink/whois',
  HOW_TO_USE_WMLINKS: 'https://vimeo.com/manage/videos/823158527/18de94efba',
  EQUIPMENT_ORDERING: 'https://wmlink/servicenow',
  ELEVATED_ACCESS_PC: 'https://wmlink/devaccount',
  ELEVATED_ACCESS_MAC: 'https://wmlink/macadmin',
  DOWNLOAD_RIGHTS: 'https://wmlink/downloadrights',
  AVT_HELP_HOMEPAGE: 'https://wmlink/avthelp',
  HOST_ACCESS: 'https://wmlink/raptr',
  HOW_TO_MAP_PRINTER_PC: 'https://vimeo.com/manage/videos/846475207/a8482ede96',
  HOW_TO_MAP_PRINTER_MAC:
    'https://vimeo.com/manage/videos/846475153/b20495607a',
  HOW_TO_SSPR: 'https://vimeo.com/manage/videos/823143965/b392edd785',
  CHANGE_YOUR_PASSWORD_VIDEO:
    'https://vimeo.com/manage/videos/882307768/8bab54f692',
  TWO_FA:
    'https://teams.wal-mart.com/sites/Info2FA/2FA%20Home%20Page/Getting%20Started.aspx',
  HOW_TO_GOOGLE_AUTH: 'https://vimeo.com/manage/videos/823158472/37eb9775dd',
  HOW_TO_YUBI: 'https://vimeo.com/manage/videos/829176710/feff3e9d26',
  ORDER_YUBI_KEY:
    'https://walmartglobal.service-now.com/wm_sp?id=sc_category&sys_id=482824e7db83efc0cb717f698c961970',
  ONE_DRIVE: 'https://teams.wal-mart.com/sites/M365/SitePages/OneDrive.aspx',
  ONE_DRIVE_VIDEO_WALKTHROUGH:
    'https://vimeo.com/manage/videos/824119836/48bbb78895',
  SHARE_POINT:
    'https://teams.wal-mart.com/sites/M365/SitePages/SharePoint.aspx',
  HOW_TO_VPN: 'https://vimeo.com/manage/videos/824157186/09870bf18e',
  HOW_TO_EAGLE_NETWORK: 'https://vimeo.com/manage/videos/844753175/ba703a9461',
  TEAMS_PAGE_MYTECH:
    'https://mytech.walmart.com/helpcenter/conference-collaboration-tools/teams',
  TEAMS_TRAINING:
    'https://teams.microsoft.com/_#/apps/041a693e-45a3-4f68-8c5c-7e09826e5f25/sections/201911011330',
  ZOOM_PAGE_MYTECH:
    'https://mytech.walmart.com/helpcenter/conference-collaboration-tools/zoom',
  SLACK_ACTIVATION: 'https://wmlink/slacktivate',
  SLACK_REQUEST: 'https://wmlink/slack',
  SLACK_HELP: 'https://slack.com/help',
  OUTLOOK_ONLINE: 'https://outlook.wal-mart.com/mail/',
  OUTLOOK_FAQ:
    'https://teams.wal-mart.com/sites/OutlookSupport/Lists/OLKFAQ/AllItems.aspx',
  DINE_OPTIONS: 'https://bentonvillewalmart.buy-ondemand.com/',
  CAMPUS_REQUEST_HUB: 'https://campus.walmart.com/campus/report-issue',
  ME_AT_CAMPUS:
    'https://one.walmart.com/content/uswire/en_us/me/support-materials/support-videos/me-campus-mobile.html',
  ZOOM_EXPERIENCE:
    'https://one.walmart.com/content/associate/home/product_lines/whattousewhen/zoom.html',
  CHANGE_YOUR_PASSWORD_DOC_MAC:
    'https://dx.walmart.com/documents/product/Mac@Walmart/Changing-your-password-from-a-Mac-w2awy804i4',
  GETTING_STARTED_MAC:
    'https://dx.walmart.com/documents/product/Mac@Walmart/Getting-Started-on-your-Mac-5vboq9qlb4',
  MAC_PRINTER_DOCUMENT:
    'https://dx.walmart.com/documents/product/Mac@Walmart/Manually-install-network-printers-on-macOS-gt1mei5kr6',
  MAC_WORKPLACE_CHANNEL:
    'https://walmart.workplace.com/groups/187347105665996/',
  MAC_FREQ_QUESTIONS:
    'https://dx.walmart.com/documents/product/Mac@Walmart/Getting-Started-on-your-Mac-5vboq9qlb4',
  MAC_TEAM_BLOG: 'https://dx.walmart.com/blogs/blogstag/?tag=mac-team',
  WINDOWS_ASK_QUESTIONS:
    'https://dx.walmart.com/windows11/documentation/helpdocs/overview',
  WINDOWS_HELLO_BUSINESS: 'https://teams.wal-mart.com/sites/HelloforBusiness',
  WINDOWS_WORKSPACE: 'https://walmart.workplace.com/groups/209268739699310/',
  WINDOWS_INSIDER_PROGRAM:
    'https://teams.wal-mart.com/sites/WindowsInsiderProgram',
  CORP_DEVICE_ORDERING_VIDEO:
    'https://vimeo.com/manage/videos/870349954/3c2af22b20',
  CORP_DEVICE_ORDERING_PAGE: 'https://mytech.walmart.com/mobile',
  SETUP_CORP_DEVICE:
    'https://one.walmart.com/content/uswire/en_us/work1/operations/digital-tools/ddf.html',
  CORP_DEVICE_AND_HOTSPOT_ORDERING_VIDEO:
    'https://vimeo.com/manage/videos/824121055/b66294cd1b',
  BYOD_RESOURCE:
    'https://one.walmart.com/content/uswire/en_us/company/byod.html',
  BYOD_ENROLL_VIDEO: 'https://vimeo.com/manage/videos/877200914/a25ec223ff',
  BYOD_SETUP_VIDEO: 'https://vimeo.com/manage/videos/822139464/b21db9fa2a',
  GNFR:
    'https://pga01.wal-mart.com/gnfrlite/index.html?sap-locale=en_US#/Dashboard,%20true',
};

export default URLs;
