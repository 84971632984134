import { useEffect, useState, useContext, useMemo } from 'react';
import Routes from '../consts/Routes';
import { useLocation } from 'react-router-dom';
import AppContext from '../app/context/AppContext';
import MFEs from '../consts/MFEs';
import Pages from '../consts/Pages';

/* custom hook that handles side nav related states. utilizes global context to handle the open state so it is 
only rendering the useContext once and uses useState for the selected field based on the passed url compaired to 
location pathname. also handles the logic around pages for the routing */

const useSideNavigation = pageUrl => {
  const location = useLocation();
  const { enabledMFEs, isSideNavOpen, setIsSideNavOpen } = useContext(
    AppContext
  );

  const navigationPages = useMemo(() => {
    return Pages(enabledMFEs);
  });

  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    const isAppStoreMFEEnabled = enabledMFEs?.includes(MFEs.APPSTORE);
    const isPageHome = pageUrl === Routes.HOMEPAGE;
    const isPageAppStore = isAppStoreMFEEnabled && pageUrl === Routes.APPS;

    if (isPageHome || isPageAppStore) {
      setIsSelected(location.pathname === pageUrl);
    } else {
      setIsSelected(location.pathname.startsWith(pageUrl));
    }
  }, [location.pathname]);

  return {
    isSideNavOpen,
    setIsSideNavOpen,
    isSelected,
    navigationPages,
  };
};

export default useSideNavigation;
