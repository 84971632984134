import {
  fetchFeedbackForUser,
  submitFeedbackForUser,
} from '../../../services/FeedbackService';

export let hasUserSubmittedOrderingFeedback = false;
export let hasUserClosedOrderingFeedback = false;

let feedbackCollection = '';
const hasRequestedFeedbackData = false;

export const setFeedbackCollection = collection => {
  feedbackCollection = collection;
};

export const setUserClosedOrderingFeedback = bool => {
  return (hasUserClosedOrderingFeedback = bool);
};

export const fetchOrderingFeedbackForUser = async collection => {
  hasUserSubmittedOrderingFeedback = await fetchFeedbackForUser(
    hasRequestedFeedbackData,
    hasUserSubmittedOrderingFeedback,
    collection
  );
  return hasUserSubmittedOrderingFeedback;
};

export const submitOrderingFeedbackForUser = async (
  feedbackResponse,
  success,
  failure
) => {
  return await submitFeedbackForUser(
    feedbackResponse,
    feedbackCollection,
    success,
    failure
  );
};
