import Consts from '../../../consts/Consts';
import PageTitles from '../../../consts/PageTitles';
import Routes from '../../../consts/Routes';
import UserType from '../../../user/UserType';
import Config from '../../../utils/Config';

export const MobileOrderingPages = (isIDCMobileDisabled = false) => {
  return [
    {
      path: 'mobile/Mobile',
      url: Routes.MOBILE,
      redirect: [
        { url: `${Routes.MOBILE}${Consts.ALL_FOLDERS}` },
        { url: '/ordering/mobileordering' }, //MyTech V1, redirect to new /mobile page
      ],
      label: PageTitles.MOBILE,
      supportsLiteMode: true,
      disabled:
        isIDCMobileDisabled ||
        UserType.isMX() ||
        !Config.isCoreOrderingFlowEnabled,
    },
  ];
};
