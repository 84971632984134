import React, { useContext, useEffect, useRef, useState } from 'react';
import HeaderLogo from './HeaderLogo';
import Colors, { LightTheme } from '../../consts/Colors';
import CSS from '../../consts/CSS';
import HeaderAdminSite from './admin/HeaderAdminSite';
import HeaderUser from './user/HeaderUser';
import Config from '../../utils/Config';
import HeaderNav from './HeaderNav';
import HeaderAdminUserType from './admin/HeaderAdminUserType';
import UserType from '../../user/UserType';
import Consts from '../../consts/Consts';
import WarningIcon from '../../components/misc/WarningIcon';
import AppContext from '../context/AppContext';
import HeaderLocalDev from './admin/HeaderLocalDev';
import URLs from '../../consts/URLs';
import EnvConfigs from '../../Configurations';
import MTSearchFeature from '../../pages/search/form/MTSearchFeature';
import useSideNavigation from '../../customHooks/useSideNavigation';
import MTLD3Button from '../../components/button/MTLD3Button';

let timeoutId;

export const focusToMainContent = () => {
  // List of focusable elements to find to focus on in main content
  const focusableElements =
    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
  // For MFE pages, they will be wrapped in a div with the main-content class
  let mainContentElement = document.querySelector('.main-content');
  // If not MFE page, main content will be in the last element with the page class
  if (!mainContentElement) {
    const pageElements = document.querySelectorAll('.content .page');
    mainContentElement = pageElements[pageElements.length - 1];
  }
  const firstFocusableElement = mainContentElement.querySelector(
    focusableElements
  );
  // Check if focusable element exists in content, otherwise move focus to navigation item
  if (firstFocusableElement) {
    firstFocusableElement.focus({ focusVisible: true });
  } else if (mainContentElement?.querySelector('iframe')) {
    // If using iframe to embed app, post mesage to trigger focus to main content functionality
    const iframe = mainContentElement?.querySelector('iframe');
    iframe.contentWindow.postMessage(
      'focusToMainContent',
      EnvConfigs.REACT_APP_SAT_HOST
    );
  } else {
    document.querySelector('.is-nav').focus();
  }
};

export const Header = ({ setSearchResult, setShowSearchFeedback }) => {
  const { isInLiteMode } = useContext(AppContext);
  const [isTooltipOpen, setIsTooltipOpen] = useState(true);
  const warningIconRef = useRef(null);
  const [searchIsFocused, setSearchIsFocused] = useState(false);
  const [hideHeaderIcons, setHideHeaderIcons] = useState(false);

  const isLiteMode = Config.isLiteModeSupportEnabled && isInLiteMode;

  const adminUserType =
    UserType.userData && UserType.userData.isImpersonated
      ? Consts.ADMIN_USER_TYPES.IMPERSONATED
      : UserType.isQAUser()
      ? Consts.ADMIN_USER_TYPES.QA_USER
      : Consts.ADMIN_USER_TYPES.NON_QA_USER;

  useEffect(() => {
    if (isLiteMode) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        //If mouse is over the header icon, don't close the tooltip
        if (!warningIconRef.current.matches(':hover')) {
          setIsTooltipOpen(false);
        }
      }, 6000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isLiteMode]);

  const isDevOrigin = window.location.origin === URLs.DEV_ORIGIN;
  const isDevEnvironmentAndOrigin =
    EnvConfigs.REACT_APP_IS_DEV === 'true' && isDevOrigin;

  return (
    <header
      className={`${isLiteMode ? 'liteMode' : Consts.EMPTY} ${
        hideHeaderIcons || searchIsFocused ? 'hidden' : Consts.EMPTY
      }`}
    >
      <div className="skip-to-content" data-testid="skipToContentDiv">
        <MTLD3Button
          data-testid="skipToContentBtn"
          onClick={focusToMainContent}
          tabIndex={0}
        >
          Skip to main content
        </MTLD3Button>
      </div>
      <div className="headerLeft">
        <HeaderNav />
        <HeaderLogo />
      </div>
      {Config.isFeatureSearchEnabled && (
        <MTSearchFeature
          setSearchResult={setSearchResult}
          setShowSearchFeedback={setShowSearchFeedback}
          searchIsFocused={searchIsFocused}
          setSearchIsFocused={setSearchIsFocused}
          setHideHeaderIcons={setHideHeaderIcons}
        />
      )}
      <div className="headerRight">
        {Config.isLiteModeSupportEnabled && isInLiteMode && (
          <div className="warningIcon" ref={warningIconRef}>
            <WarningIcon
              open={isTooltipOpen}
              onOpen={() => setIsTooltipOpen(true)}
              onClose={() => setIsTooltipOpen(false)}
              tooltipMessage={
                <>
                  <div>
                    Some MyTech features are currently unavailable due to a data
                    outage. Please refresh after a few minutes to check if all
                    services are restored.
                  </div>
                </>
              }
            />
          </div>
        )}
        {isDevEnvironmentAndOrigin ? <HeaderLocalDev /> : null}
        {Config.isAdminSiteEnabled && !isInLiteMode && (
          <>
            {adminUserType !== Consts.ADMIN_USER_TYPES.NON_QA_USER && (
              <HeaderAdminUserType adminUserType={adminUserType} />
            )}
            <HeaderAdminSite />
          </>
        )}
        <HeaderUser />
      </div>

      <style jsx>
        {`
          header {
            z-index: 1000;
            background-color: ${LightTheme.headerBG};
            padding: 0 15px;
            height: ${CSS.HEADER_HEIGHT};
            position: sticky;
            top: 0;
          }

          header.liteMode:after,
          header.liteMode + :global(main:after) {
            content: '';
            background-color: ${Colors.ERROR_RED};
            height: 4px;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }

          header.liteMode + :global(main:after) {
            top: auto;
            bottom: 0;
            position: fixed;
            z-index: 1000;
          }

          header,
          .headerLeft,
          .headerRight {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          }

          .headerRight .warningIcon :global(.tooltip-ref-wrapper) {
            height: ${CSS.HEADER_HEIGHT};
            padding-left: 6px;
            padding-right: 6px;
            display: flex;
            align-items: center;
          }

          .headerRight
            .warningIcon
            :global(.tooltip-ref-wrapper svg path:first-child) {
            fill: ${Colors.ERROR_RED};
          }

          header :global(.mtForm) {
            min-width: 0px;
          }

          @media (max-width: ${CSS.STANDARD_MAX_MOBILE_PHONE_VIEW}) {
            header.hidden :global(.headerAdminSite),
            header.hidden :global(.headerLogo),
            header.hidden :global(.headerNav),
            header.hidden :global(.headerUser),
            header.hidden :global(.headerAdminUserType),
            header.hidden :global(.headerLocalDev) {
              display: none;
            }
          }

          .skip-to-content:focus-within {
            position: absolute;
            left: 6px;
            top: 10px;
            z-index: 999;
            box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
          }
          .skip-to-content {
            position: absolute;
            left: 6px;
            top: -99px;
            transition: top 0.2s ease-in-out;
          }

          @media (min-width: ${CSS.STANDARD_MAX_MOBILE_PHONE_VIEW}) {
            .headerRight {
              width: 165px;
            }

            .headerLeft {
              justify-content: flex-start;
            }

            .headerRight {
              justify-content: flex-end;
            }

            header :global(.featureSearch) {
              flex-grow: 1;
            }
          }
        `}
      </style>
    </header>
  );
};
