import React from 'react';
import SecurityProvider from '../../pages/securityV2/context/SecurityContext';
import Config from '../../utils/Config';

// As the module-specific context gets broken out of Core, add the module's Context Provider here
// if the entire application needs access
const ModuleProviders = ({ children }) => {
  const getSecurityProvider = children =>
    Config.isSecurityAccessEnabled && Config.isSecurityDashboardV2Enabled ? (
      <SecurityProvider>{children}</SecurityProvider>
    ) : (
      children
    );
  return getSecurityProvider(children);
};

export default ModuleProviders;
