import APIs from './APIs';
import Consts from './Consts';

const Errors = {
  WE_DOWN: 'Sorry, we appear to be down.',
  WE_DOWN_SUB_TEXT: 'Please check back shortly.',
  LITE_MODE_WE_DOWN_SUB_TEXT:
    'We may be experiencing a data outage, please refresh below and MyTech Lite may be able to help.',
  LITE_MODE_ACTIVE_WE_DOWN_SUB_TEXT: `We're experiencing a data outage, please refresh after a few minutes to check if all services are restored.`,
  NO_SEARCH_RESULTS: 'Sorry, no results found.',
  NO_SEARCH_RESULTS_SUB_TEXT:
    'We are continuously adding new features to MyTech, please check back again later.',
  TOKEN_401: 'Your session has timed out. Please refresh the page to continue.',
  AD_FAILURE:
    'There was a problem with your order. Please refresh and try again later.',
  NETWORK_ERROR_TITLE: 'Network Error',
  NETWORK_ERROR:
    "Sorry, we can't connect to the server. Please check your internet/VPN connections.",
  VISTA_ERROR:
    'Windows Vista is not supported. Please try again from a more recent Windows OS version.',
  ORDER_DIALOG_SERVER_ERROR_TITLE: 'Unknown Error',
  ORDER_DIALOG_SERVER_ERROR:
    'An unknown error occurred when trying to submit. Please try again.',
  ALREADY_CORPORATE_DEVICE:
    'Looks like you already have a Walmart Corporate phone or have placed an order for one. Walmart policy does not allow multiple Corporate devices. If you would like to Submit a ticket, please use the link below.',
  ALREADY_CORPORATE_IPAD:
    'Looks like you already have a Walmart Corporate iPad or have placed an order for one. Walmart policy does not allow multiple Corporate iPad devices. If you would still like to place an order, please submit a request below.',
  ALREADY_HOTSPOT_DEVICE:
    'Looks like you already have a Walmart Hotspot device or have placed an order for one. Walmart policy does not allow multiple Hotspot devices.',
  ALREADY_LOANER_DEVICE:
    'Looks like you already have a Walmart Loaner mobile phone or have placed an order for one. Walmart policy does not allow multiple Loaner phones.',
  ALREADY_REPLACEMENT_PLACED:
    'Looks like you have already placed a replacement device request in the past 30 days.',
  REPLACEMENT_ANOTHER_ASSOCIATE:
    'At this time, replacement mobile devices cannot be ordered on behalf of another associate.',
  NO_CORPORATE_DEVICE:
    'According to our records, this associate does not have a Walmart Corporate phone.',
  UNKNOWN_ROUTE: "Sorry, the page you're looking for can't be found.",
  LITE_MODE_UNSUPPORTED_ROUTE: 'Sorry, this page is currently unavailable.',
  SECURITY_2SV_FAILED_FETCH: 'Sorry, there was an error loading your 2SV data.',
  SECURITY_SSPR_FAILED_FETCH:
    'Sorry, there was an error loading your SSPR data.',
  SECURITY_PASSWORD_DATA_FAILED_FETCH:
    'Sorry, there was an error loading your password data.',
  MY_DATA_LOAD_ERROR: `Sorry, we couldn't load your data, please try again later.`,
  CONFIG_LOAD_ERROR: 'Sorry, there was a problem, please try again later.',
  VIDEO_LOAD_ERROR:
    'Sorry, there was a problem loading the videos, please try again later.',
  NO_CORP_DEVICES: "It looks like you don't have a Corporate device yet.",
  NO_DEVICES: "It looks like you don't have any devices yet.",
  NO_VDI_REQUESTS: "It looks like you don't have any VDI requests yet.",
  NO_TICKETS: "It looks like you don't have any open tickets currently.",
  CORP_ANOTHER_HAS_REPLACEMENT:
    'Looks like the selected user has already placed a Replacement device request in the past 30 days.',
  CORP_ANOTHER_HAS_HOTSPOT:
    'Looks like the associate already has a Walmart Hotspot device or has placed an order for one. Walmart policy does not allow multiple Hotspot devices.',
  CORP_ANOTHER_HAS_DEVICE: `Looks like the associate already has a Walmart Corporate device (phone or iPad) or has placed an order for one. Walmart policy does not allow multiple Corporate devices. If you would still like to place an order, please submit a request at <a target="_blank" rel="noopener noreferrer" href="${APIs.ADE_HELP}">here</a>.`,
  NO_TABLET_DATE: `Delivery Date is required`,
  TABLET_BUSINESS_JUSTIFICATION: `Business Justification is required`,
  TABLET_SEARCH: 'Associate Name, User ID or Email is required',
  DIRECTOR_SEARCH: 'Director Name, User ID or Email is required',
  MANAGER_SEARCH: 'Manager Name, User ID or Email is required',
  PRODUCT_COST: 'Product cost is required',
  AD_ERROR_TITLE: 'AD Error',
  UPN_ERROR_TITLE: 'UPN Error',
  UPN_ERROR_MESSAGE: `Please check the Requestor's User Principal Name that was entered is correct and that it follows the expected format (e.g. userid@homeoffice.wal-mart.com).`,
  DEVICE_RETURN_ERROR_TITLE: 'Device Recover/Reissue Error',
  DEVICE_RETURN_ERROR_MESSAGE:
    'Sorry, there was a problem, please try again later.',
  MFE_LOAD_ERROR:
    'Sorry, there is a problem with this page, please try again later.',
  LICENSE_ONBOARDING_ERROR: (errorProducts, productSuiteId) => {
    return `Sorry, there was an issue onboarding your product suite. We could not onboard the following products: ${errorProducts.join(
      ', '
    )}. Please try again.${
      productSuiteId ? ` Product Suite ID: ${productSuiteId}` : Consts.EMPTY
    }`;
  },
  VIDEO_CAROUSEL: 'Video Carousel - Error',
  VIDEO_CAROUSEL_SHARE_BUTTON_ERROR: (url, err) => {
    return `Failed to copy ${url} to clipboard ::: ${err}`;
  },

  getSubmitErrorTitle: type => {
    switch (type) {
      case Consts.NETWORK_ERROR:
        return Errors.NETWORK_ERROR_TITLE;
      default:
        return Errors.ORDER_DIALOG_SERVER_ERROR_TITLE;
    }
  },
  getSubmitErrorText: type => {
    switch (type) {
      case Consts.NETWORK_ERROR:
        return Errors.NETWORK_ERROR;
      default:
        return Errors.ORDER_DIALOG_SERVER_ERROR;
    }
  },
  FILEPOND_UPLOAD: {
    ATTACHMENT_FAILED: 'Attachment not uploaded',
  },
  FILEPOND: {
    UNSUPPORTED_FILE_TYPE: 'Unsupported file type Error',
    MAX_FILE_LIMIT: 'Max File Limit Error',
    MAX_FILE_SIZE_LIMIT: 'File size too large Error',
  },
};

export default Errors;
