import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from './/Dialog';
import MTForm from '../form/MTForm';
import MTRadioButtons from '../form/controls/MTRadioButtons';
import MTFeedbackSuggestion from '../form/components/MTFeedbackSuggestion';
import MTRadioButton from '../form/controls/MTRadioButton';
import * as Yup from 'yup';
import Events from '../../consts/Events';
import Consts from '../../consts/Consts';
import { trackPage, trackEvent } from '../../utils/appInsight';
import UserType from '../../user/UserType';
import {
  Box,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import LDDialog from '../../LDComponents/dialog/LDDialog';

const TEXT = {
  TITLE: dashboard => `Please take a moment to provide feedback for the ${dashboard}
                dashboard`,
  QUESTION: dashboard =>
    `Did you find the information provided on the ${dashboard} dashboard useful?`,
  FEEDBACK_LABEL: 'Please provide detailed feedback/suggestions',
};

const MAX_CHARS = 300;

const DashboardFeedbackDialog = ({
  title,
  question,
  isDialogOpen,
  closeDialog,
  submitFeedback,
  dashboard,
  isV2Design,
}) => {
  const [answer, setAnswer] = useState();
  const [details, setDetails] = useState();
  const [disabled, toggleDisabled] = useState(false);
  const [submitting, toggleSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    toggleDisabled(!answer || details?.length > 300);
  }, [details, answer]);

  useEffect(() => {
    if (isDialogOpen) {
      trackPage({
        name: Events.DASHBOARD_FEEDBACK(
          dashboard,
          Consts.DASHBOARD_FEEDBACK.LANDING
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseDialog = () => {
    trackEvent(
      Events.DASHBOARD_FEEDBACK(dashboard, Consts.DASHBOARD_FEEDBACK.CANCELLED)
    );
    closeDialog();
  };

  const handleSubmitFeedback = async feedbackResponse => {
    await submitFeedback(
      feedbackResponse,
      () => {},
      () => {}
    );
  };

  const handleV2SubmitClick = async () => {
    toggleSubmitting(true);
    const formattedAnswer = answer === 'yes' ? 'Yes' : 'No';
    trackEvent(
      Events.DASHBOARD_FEEDBACK(dashboard, Consts.DASHBOARD_FEEDBACK.SUBMITTED),
      {
        userUpn: UserType.userPrincipalName,
        isUseful: formattedAnswer,
        detailedFeedback: details,
      }
    );
    const formValues = {
      isUseful: formattedAnswer,
      detailedFeedback: details,
      version: 'v2',
    };
    handleSubmitFeedback(formValues);
    toggleSubmitting(false);
    setSubmitted(true);
  };

  return (
    <>
      {isV2Design ? (
        <Box>
          <LDDialog
            isOpen={isDialogOpen}
            closeDialog={handleCloseDialog}
            title={
              !submitted
                ? title || TEXT.TITLE(dashboard)
                : Consts.APPS_FEEDBACK.SUBMITTED_TITLE
            }
            primaryButton={
              !submitted
                ? {
                    label: 'Submit',
                    onClick: handleV2SubmitClick,
                    disabled: disabled,
                    loading: submitting,
                  }
                : {
                    label: Consts.APPS_FEEDBACK.CLOSE_ON_SUBMIT,
                    onClick: closeDialog,
                  }
            }
            hideSecondaryButton={submitted}
          >
            {!submitted ? (
              <FormGroup>
                <Box pr={2}>
                  <Typography>
                    {question ? question : TEXT.QUESTION(dashboard)}
                  </Typography>
                  <Box mt={1}>
                    <RadioGroup
                      aria-labelledby="dashboard-feedback-dialog"
                      name="radio-buttons-group"
                      value={answer}
                      onChange={e => setAnswer(e.target.value)}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="other"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </Box>
                </Box>
                <Box mt={3} width="100%">
                  <TextField
                    id="outlined-textarea"
                    label={TEXT.FEEDBACK_LABEL}
                    multiline
                    fullWidth
                    rows={4}
                    value={details}
                    onChange={e => setDetails(e.target.value)}
                    variant="outlined"
                    error={details?.length > MAX_CHARS}
                    helperText={
                      <Typography variant="caption">{`${
                        details?.length || 0
                      }/${MAX_CHARS}`}</Typography>
                    }
                  />
                </Box>
              </FormGroup>
            ) : (
              <Box width="100%" pb={1}>
                <Typography>{Consts.APPS_FEEDBACK.SUBMITTED_TEXT}</Typography>
              </Box>
            )}
          </LDDialog>
        </Box>
      ) : (
        <React.Fragment>
          <Dialog
            isDialogOpen={isDialogOpen}
            closeDialog={handleCloseDialog}
            className="dashboard-feedback"
          >
            <p>{TEXT.TITLE(dashboard)}</p>
            <MTForm
              onSubmit={async formikValues => {
                trackEvent(
                  Events.DASHBOARD_FEEDBACK(
                    dashboard,
                    Consts.DASHBOARD_FEEDBACK.SUBMITTED
                  ),
                  {
                    userUpn: UserType.userPrincipalName,
                    isUseful: formikValues.isUseful,
                    detailedFeedback: formikValues.detailedFeedback,
                  }
                );
                await handleSubmitFeedback(formikValues);
                closeDialog();
              }}
              cancelLabel="Close"
              showRequired={false}
              cancelButtonOnClick={() => {
                handleCloseDialog();
              }}
            >
              <MTRadioButtons
                name={'isUseful'}
                initialValue={''}
                label={TEXT.QUESTION(dashboard)}
                yup={Yup.string().required()}
              >
                <MTRadioButton value={'Yes'} />
                <MTRadioButton value={'No'} />
              </MTRadioButtons>
              <MTFeedbackSuggestion />
            </MTForm>
          </Dialog>
          <style jsx>
            {`
              p {
                font-size: 26px;
                width: 90%;
                margin: 0 auto;
                padding: 20px 0px 40px;
                text-align: center;
              }
              p + :global(.mtForm .mtRadioButtons .formError) {
                width: 60%;
                margin: 0 auto;
              }
              @media screen and (max-width: 480px) {
                p {
                  width: 95%;
                  font-size: 20px;
                }
                p + :global(.mtForm .mtRadioButtons .formError) {
                  width: 95%;
                }
              }
            `}
          </style>
        </React.Fragment>
      )}
    </>
  );
};

export default DashboardFeedbackDialog;

DashboardFeedbackDialog.propTypes = {
  isDialogOpen: PropTypes.bool,
  closeDialog: PropTypes.func,
  submitFeedback: PropTypes.func,
  dashboard: PropTypes.string,
  name: PropTypes.string,
};
