import UserType from '../user/UserType';
import Config from '../utils/Config';
import Consts from './Consts';
import Messages from './Messages';
import PageTitles from './PageTitles';
import Routes, { getPageByRoute, updateRouteWithName } from './Routes';
import AdminSite from '../consts/AdminSite';
import MFEs from './MFEs';
import { orderingPages } from '../pages/ordering/services/OrderingPages';
import { appstorePages } from '../pages/appstore/services/AppStorePages';
import { holidayReadinessNav } from '../pages/holidayreadiness/HRNav';
import EnvConfigs from '../Configurations';
import { ExternalLink } from '@livingdesign/icons';
import { SATnav } from '../pages/submitaticket/SATNav';

//If label is missing, it's left out of the Left  nav
//Order of objects that have a label, controls the order in the left nav
//path should the relative path and name from /pages
//If disabled, the route won't be created, nor will it be added to the left nav
//Pass redirect: {url: URL_TO_REDIRECT_TO, exact: TRUE_IF_EXACT_ROUTE_ONLY} (i.e. if the page's object url is /a, it would match /a/b when exact is false, but not if true)
const Pages = enabledMFEs => {
  //Checking condition for loaner only if user's domain is HOMEOFFICE or HO and not a GEC Associate from user object
  const loanerDomainCheck = UserType.isHomeOffice() && !UserType.isGEC();

  //Checking condition for corporate and international if user isDomain === HOMEOFFICE, US, WMSC Associate from user object
  const corporateIntlDomainCheck =
    UserType.isHomeOffice() || UserType.isUS() || UserType.isWMSC();

  const simHasAvailableFlow =
    Config.simActivationEnabled || Config.simNewEnabled;

  const isSIMAvailable =
    Config.isSIMEnabled &&
    simHasAvailableFlow &&
    (UserType.isStoreAssociate() || UserType.isSIMUser) &&
    !UserType.isIDCUser;

  const isHomeOfficeNewHireOrHomeOffice =
    UserType.isHomeOfficeNewHire || UserType.isHomeOffice();

  const isNewHireEnabled =
    Config.isNewHireEnabled && isHomeOfficeNewHireOrHomeOffice;

  const isReturnToOfficeTopicEnabled =
    Config.isReturnToOfficeTopicEnabled && UserType.isHomeOffice();

  const isHolidayReadinessTopicEnabled =
    Config.isHolidayReadinessTopicEnabled && UserType.isHomeOffice();

  //check condition for isHourlyEmployee to disable ordering corporate devices
  const isHourlyEmployee = UserType.isHourlyEmployee();

  const isCoreOrderingFlowEnabled = Config.isCoreOrderingFlowEnabled;

  const isIDCMobileDisabled =
    UserType.isIDCUser && !Config.isIDCUserMobileEnabled;
  const isMobileLoanerDisabled =
    !loanerDomainCheck ||
    isHourlyEmployee ||
    isIDCMobileDisabled ||
    !Config.isCoreLoanerDeviceOrderingEnabled ||
    !isCoreOrderingFlowEnabled;
  const isMobileCorporateDisabled =
    UserType.isStoreAssociate() ||
    !corporateIntlDomainCheck ||
    isHourlyEmployee ||
    isIDCMobileDisabled ||
    !Config.isCoreCorporatePhoneOrderingEnabled ||
    !isCoreOrderingFlowEnabled;
  const isMobileTabletDisabled =
    !Config.isTabletOrderingEnabled ||
    isIDCMobileDisabled ||
    !isCoreOrderingFlowEnabled;
  const isMobileHotspotDisabled =
    UserType.isStoreClubDC() ||
    !Config.isHotspotEnabled ||
    !Config.isHotspotOrderingEnabled ||
    isIDCMobileDisabled ||
    !isCoreOrderingFlowEnabled;
  const isMobileInternationalDisabled =
    !corporateIntlDomainCheck ||
    isIDCMobileDisabled ||
    !Config.isCoreInternationalDataPlanOrderingEnabled ||
    !isCoreOrderingFlowEnabled;
  const isMobileCancelDisabled =
    isIDCMobileDisabled ||
    !Config.isCoreCancelServiceOrderingEnabled ||
    !isCoreOrderingFlowEnabled;
  const isEagleHomeDeviceDisabled =
    isIDCMobileDisabled ||
    !Config.isEagleHomeDeviceEnabled ||
    !UserType.isEagleOrderAllowed ||
    !isCoreOrderingFlowEnabled;
  const isOrderingMFEEnabled = enabledMFEs?.includes(MFEs.ORDERING);
  const isAppStoreMFEEnabled = enabledMFEs?.includes(MFEs.APPSTORE);
  const isHolidayReadinessMFEEnabled = enabledMFEs?.includes(
    MFEs.HOLIDAYREADINESS
  );

  const isStoreNotAllowedToOrderIpads =
    Config.enableStoreClubIpadOrdering && UserType.isStoreClubDC();

  const isSSPRSupportPageDisabled =
    !UserType.isSSPRSupport || !Config.isSSPRSupportPageEnabled;

  return [
    {
      path: 'homepage/Homepage',
      url: Routes.HOMEPAGE,
      label: PageTitles.HOMEPAGE,
      redirect: !Config.isTalkToExpertEnabled && {
        url: Routes.HELP_CENTER_TALK_TO_EXPERT,
      },
      supportsLiteMode: true,
    },
    {
      path: 'newhire/NewHire',
      url: Routes.NEW_HIRE,
      redirect: null, //Handled by <App>, since only should run once on page load.
      label: UserType.isHomeOfficeNewHire ? PageTitles.NEW_HIRE : null,
      disabled: !isNewHireEnabled,
      breadcrumbLabel: PageTitles.NEW_HIRE,
    },
    {
      path: 'newhire/security/pages/DeviceType',
      url: Routes.NEW_HIRE_SECURITY_ONBOADRING,
      disabled: !isNewHireEnabled,
      breadcrumbLabel: PageTitles.MAC_OR_PC,
    },
    {
      path: 'newhire/security/pages/MacPasswordStrength',
      url: Routes.NEW_HIRE_SECURITY_ONBOADRING_MAC_PASSWORD,
      disabled: !isNewHireEnabled,
      breadcrumbLabel: PageTitles.NEW_HIRE_SECURITY_PASSWORD_STRENGTH,
    },
    {
      path: 'newhire/security/pages/WindowsPasswordStrength',
      url: Routes.NEW_HIRE_SECURITY_ONBOADRING_WINDOWS_PASSWORD,
      disabled: !isNewHireEnabled,
      breadcrumbLabel: PageTitles.NEW_HIRE_SECURITY_PASSWORD_STRENGTH,
    },
    {
      path: 'newhire/security/pages/SecuritySettings',
      url: Routes.NEW_HIRE_SECURITY_ONBOADRING_SECURITY_SETTINGS,
      disabled: !isNewHireEnabled,
      breadcrumbLabel: PageTitles.NEW_HIRE_SECURITY_SETTINGS,
    },
    {
      path: 'newhire/NewHireGallery',
      url: Routes.NEW_HIRE_GALLERY,
      disabled: !isNewHireEnabled || !Config.isNewHireHintsTipsEnabled,
      breadcrumbLabel: PageTitles.NEW_HIRE_GALLERY,
    },
    {
      path: 'newhire/NewHireImage',
      url: Routes.NEW_HIRE_GALLERY_IMAGE,
      disabled: !isNewHireEnabled,
      breadcrumbLabel: Consts.ROUTE_PARAM,
    },
    {
      path: 'newhire/articles/shared/ArticleCiscoAnyConnect',
      url: Routes.NEW_HIRE_CISCO_ANYCONNECT,
      disabled: !isNewHireEnabled,
      breadcrumbLabel: PageTitles.NEW_HIRE_ARTICLE_CISCO_ANYCONNECT,
    },
    {
      path: 'devices/Devices',
      url: Routes.DEVICES,
      supportsLiteMode: true,
      breadcrumbLabel: PageTitles.DEVICES,
    },
    {
      path: 'mobile/Mobile', //for the initial render
      url: Routes.MOBILE,
      redirect: [
        { url: `${Routes.MOBILE}${Consts.ALL_FOLDERS}` },
        { url: '/ordering/mobileordering' }, //MyTech V1, redirect to new /mobile page
      ],
      breadcrumbLabel: PageTitles.MOBILE,
      supportsLiteMode: true,
      disabled:
        isIDCMobileDisabled ||
        UserType.isMX() ||
        !Config.isCoreOrderingFlowEnabled,
    },
    ...orderingPages(isOrderingMFEEnabled, isIDCMobileDisabled),
    {
      path: 'mobile/corporate/MobileCorporate',
      url: Routes.MOBILE_CORPORATE,
      redirect: { url: `${Routes.MOBILE_CORPORATE}${Consts.ALL_FOLDERS}` },
      disabled: isMobileCorporateDisabled,
      breadcrumbLabel: PageTitles.SELECT_PERSONA,
    },
    {
      path: 'mobile/corporate/MobileCorporateMyself',
      url: Routes.MOBILE_CORPORATE_MYSELF,
      disabled: isMobileCorporateDisabled,
      breadcrumbLabel: PageTitles.ORDER_CORPORATE_DEVICE_TITLE,
    },
    {
      path: 'mobile/corporate/MobileCorporateAnother',
      url: Routes.MOBILE_CORPORATE_ANOTHER,
      disabled: isMobileCorporateDisabled,
      breadcrumbLabel: PageTitles.ORDER_CORPORATE_DEVICE_TITLE,
    },
    {
      path: 'mobile/loaner/MobileLoaner',
      url: Routes.MOBILE_LOANER,
      redirect: { url: `${Routes.MOBILE_LOANER}${Consts.ALL_FOLDERS}` },
      disabled: isMobileLoanerDisabled,
      breadcrumbLabel: PageTitles.SELECT_PERSONA,
    },
    {
      path: 'mobile/loaner/MobileLoanerMyself',
      url: Routes.MOBILE_LOANER_MYSELF,
      disabled: isMobileLoanerDisabled,
      breadcrumbLabel: PageTitles.ORDER_LOANER_DEVICE_TITLE,
    },
    {
      path: 'mobile/loaner/MobileLoanerAnother',
      url: Routes.MOBILE_LOANER_ANOTHER,
      disabled: isMobileLoanerDisabled,
      breadcrumbLabel: PageTitles.ORDER_LOANER_DEVICE_TITLE,
    },
    {
      path: 'mobile/tablet/MobileTablet',
      url: Routes.MOBILE_TABLET,
      redirect: { url: `${Routes.MOBILE_TABLET}${Consts.ALL_FOLDERS}` },
      disabled: isMobileTabletDisabled || isStoreNotAllowedToOrderIpads,
      breadcrumbLabel: PageTitles.SELECT_PERSONA,
    },
    {
      path: 'mobile/tablet/MobileTabletSupplyChain',
      url: Routes.MOBILE_TABLET_SUPPLY_CHAIN,
      disabled: isMobileTabletDisabled || isStoreNotAllowedToOrderIpads,
      breadcrumbLabel: Messages.CORPORATE_IPAD,
    },
    {
      path: 'mobile/tablet/MobileTabletHomeOffice',
      url: Routes.MOBILE_TABLET_HOME_OFFICE,
      disabled: isMobileTabletDisabled || isStoreNotAllowedToOrderIpads,
      breadcrumbLabel: Messages.CORPORATE_IPAD,
    },
    {
      path: 'mobile/tablet/MobileTabletStoreClubDepartment',
      url: Routes.MOBILE_TABLET_STORE_CLUB_DEPARTMENT,
      redirect: { url: `${Routes.MOBILE}${Consts.ALL_FOLDERS}` },
      disabled:
        isMobileTabletDisabled ||
        UserType.isStoreClubDC() ||
        !Config.enableStoreClubIpadOrdering,
      breadcrumbLabel: Messages.CORPORATE_IPAD,
    },
    {
      path: 'mobile/hotspot/MobileHotspot',
      url: Routes.MOBILE_HOTSPOT,
      redirect: { url: `${Routes.MOBILE_HOTSPOT}${Consts.ALL_FOLDERS}` },
      disabled: isMobileHotspotDisabled,
      supportsLiteMode: true,
    },
    {
      path: 'mobile/hotspot/MobileHotspotMyself',
      url: Routes.MOBILE_HOTSPOT_MYSELF,
      disabled: isMobileHotspotDisabled,
      supportsLiteMode: true,
      breadcrumbLabel: PageTitles.ORDER_HOTSPOT_DEVICE_TITLE,
    },
    {
      path: 'mobile/eagleHomeDevice/MobileEagleHomeDevice',
      url: Routes.MOBILE_EAGLE_HOME_DEVICE,
      redirect: {
        url: `${Routes.MOBILE_EAGLE_HOME_DEVICE}${Consts.ALL_FOLDERS}`,
      },
      disabled: isEagleHomeDeviceDisabled,
      supportsLiteMode: true,
    },
    {
      path: 'mobile/eagleHomeDevice/MobileEagleHomeDeviceMyself',
      url: Routes.MOBILE_EAGLE_HOME_DEVICE_MYSELF,
      disabled: isEagleHomeDeviceDisabled,
      breadcrumbLabel: PageTitles.ORDER_EAGLE_HOME_DEVICE,
      supportsLiteMode: true, // yet to ask
    },
    {
      path: 'mobile/international/MobileInternational',
      url: Routes.MOBILE_INTERNATIONAL,
      redirect: { url: `${Routes.MOBILE_INTERNATIONAL}${Consts.ALL_FOLDERS}` },
      disabled: isMobileInternationalDisabled,
      breadcrumbLabel: PageTitles.SELECT_PERSONA,
    },
    {
      path: 'mobile/international/MobileInternationalMyself',
      url: Routes.MOBILE_INTERNATIONAL_MYSELF,
      disabled: isMobileInternationalDisabled,
      breadcrumbLabel: PageTitles.INTERNATIONAL_DATA_PLAN_MYSELF,
    },
    {
      path: 'mobile/international/MobileInternationalAnother',
      url: Routes.MOBILE_INTERNATIONAL_ANOTHER,
      disabled: isMobileInternationalDisabled,
      breadcrumbLabel: PageTitles.INTERNATIONAL_DATA_PLAN_ANOTHER,
    },
    {
      path: 'mobile/cancel/MobileCancel',
      url: Routes.MOBILE_CANCEL,
      redirect: { url: `${Routes.MOBILE_CANCEL}${Consts.ALL_FOLDERS}` },
      disabled: isMobileCancelDisabled,
      breadcrumbLabel: PageTitles.SELECT_PERSONA,
    },
    {
      path: 'mobile/cancel/MobileCancelMyself',
      url: Routes.MOBILE_CANCEL_MYSELF,
      disabled: isMobileCancelDisabled,
      breadcrumbLabel: PageTitles.CANCEL_CORPORATE_DEVICE_MYSELF,
    },
    {
      path: 'mobile/cancel/MobileCancelAnother',
      url: Routes.MOBILE_CANCEL_ANOTHER,
      disabled: isMobileCancelDisabled,
      breadcrumbLabel: PageTitles.CANCEL_CORPORATE_DEVICE_ANOTHER,
    },
    {
      path: 'vdi/VDIs',
      url: Routes.VDI,
      label: PageTitles.VDI,
      disabled: !Config.isVDIEnabled,
    },
    {
      path: 'vdi/VDIRequestForm',
      url: Routes.VDI_REQUEST_FORM,
      breadcrumbLabel: PageTitles.VDI_REQUEST,
      disabled: !Config.isVDIEnabled,
    },
    {
      path: 'vdi/VDIRequestCompletion',
      url: Routes.VDI_REQUEST_COMPLETION,
      breadcrumbLabel: 'Confirmation',
      disabled: !Config.isVDIEnabled,
    },
    {
      path: 'sim/SIM',
      url: Routes.SIM,
      redirect: { url: `${Routes.SIM}${Consts.ALL_FOLDERS}` },
      label: PageTitles.SIM,
      disabled: !isSIMAvailable,
    },
    {
      path: 'sim/SIMOrdering',
      url: Routes.SIM_ORDERING,
      disabled: !isSIMAvailable || !Config.simNewEnabled,
      breadcrumbLabel: Messages.SIM.NEW_SIM_TITLE,
    },
    {
      path: 'sim/SIMActivation',
      url: Routes.SIM_ACTIVATION,
      disabled: !isSIMAvailable || !Config.simActivationEnabled,
      breadcrumbLabel: Messages.SIM.ACTIVATION_SIM_TITLE,
    },
    {
      path: 'shipmenttracker/ShipmentTracker',
      url: Routes.SHIPMENT_TRACKER,
      label: PageTitles.SHIPMENT_TRACKER,
      disabled: !Config.isShipmentTrackerEnabled || !UserType.isIDCUser,
    },
    ...appstorePages(isAppStoreMFEEnabled),
    {
      path: 'helpcenter/HelpCenter',
      url: Routes.HELP_CENTER,
      label: PageTitles.HELP_CENTER,
      supportsLiteMode: true,
    },
    {
      path: 'licenses/OnboardLicense',
      url: Routes.ONBOARD_LICENSE,
      label: PageTitles.ONBOARD_LICENSE,
      disabled: !Config.isProductOnboardingFormEnabled,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/callUs/HelpCenterCallUs',
      url: Routes.HELP_CENTER_CALL_US,
      breadcrumbLabel: PageTitles.HELP_CENTER_CALL_US,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/techBarLocations/HelpCenterTechBarLocations',
      url: Routes.HELP_CENTER_TALK_TO_EXPERT,
      breadcrumbLabel: PageTitles.HELP_CENTER_TALK_TO_EXPERT,
      supportsLiteMode: true,
      disabled: !Config.isTalkToExpertEnabled || UserType.isIDCUser,
    },
    {
      path: 'tickets/Tickets',
      url: Routes.TICKETS,
      redirect: [
        { url: '/tickets' }, //MyTech V2 My Tickets, redirect to new Tickets page
        { url: '/orders' }, //MyTech V2 My Orders, redirect to new Tickets page
        { url: `/orders${Consts.ALL_FOLDERS}` }, //MyTech V1 My Orders sub pages
        { url: '/ordering/orders' }, //MyTech V1 My Orders, redirect to new /orders page
      ],
      breadcrumbLabel: PageTitles.TICKETS,
      supportsLiteMode: true,
    },
    {
      path: 'tech/TechSupport',
      url: Routes.TICKETS_SUBMIT,
      redirect: [
        { url: `/techsupport` }, //MyTech V2 Tech Support, redirect to new shared form page
        { url: `/techsupport${Consts.ALL_FOLDERS}` }, //MyTech V2 Tech Support forms, redirect to new shared form page
        {
          url: '/helpcenter/tickets/submit',
          to: Routes.TICKETS_SUBMIT,
        },
      ],
      breadcrumbLabel: PageTitles.TICKETS_SUBMIT,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/topics/TopicWindows',
      url: Routes.TOPIC_WINDOWS,
      redirect: { url: `${Routes.HELP_CENTER}/${Consts.WINDOWS}`, exact: true },
      breadcrumbLabel: PageTitles.TOPIC_WINDOWS,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/topics/TopicMac',
      url: Routes.TOPIC_MAC,
      breadcrumbLabel: PageTitles.TOPIC_MAC,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/topics/TopicMobile',
      url: Routes.TOPIC_MOBILE,
      breadcrumbLabel: PageTitles.TOPIC_MOBILE,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/topics/TopicConferenceCollaborationTools',
      url: Routes.TOPIC_HOME_TOPIC_CONFERENCE_COLLABORATION_TOOLS,
      label: PageTitles.TOPIC_CONFERENCE_COLLABORATION_TOOLS,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/topics/TopicConferenceCollaborationTools',
      url: Routes.TOPIC_CONFERENCE_COLLABORATION_TOOLS,
      redirect: {
        // Soft Redirect as users knew this brand previously as hybrid meetings
        url: Routes.TOPIC_HYBRID_MEETINGS,
        to: Routes.TOPIC_CONFERENCE_COLLABORATION_TOOLS,
        exact: true,
      },
      breadcrumbLabel: PageTitles.TOPIC_CONFERENCE_COLLABORATION_TOOLS,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/topics/TopicMicrosoftTeams',
      url: Routes.TOPIC_MICROSOFT_TEAMS,
      redirect: {
        // Soft Redirect as users knew this brand previously as hybrid meetings
        url: Routes.TOPIC_HYBRID_MEETINGS_MICROSOFT_TEAMS,
        to: Routes.TOPIC_MICROSOFT_TEAMS,
        exact: true,
      },
      breadcrumbLabel: PageTitles.TOPIC_MICROSOFT_TEAMS,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/topics/TopicZoom',
      url: Routes.TOPIC_ZOOM,
      redirect: {
        // Soft Redirect as users knew this brand previously as hybrid meetings
        url: Routes.TOPIC_HYBRID_MEETINGS_ZOOM,
        to: Routes.TOPIC_ZOOM,
        exact: true,
      },
      breadcrumbLabel: PageTitles.TOPIC_ZOOM,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/topics/TopicVideoConferencing',
      url: Routes.TOPIC_VIDEO_CONFERENCING,
      redirect: {
        // Soft Redirect as users knew this brand previously as hybrid meetings
        url: Routes.TOPIC_HYBRID_MEETINGS_VIDEO_CONFERENCING,
        to: Routes.TOPIC_VIDEO_CONFERENCING,
        exact: true,
      },
      breadcrumbLabel: PageTitles.TOPIC_VIDEO_CONFERENCING,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/topics/TopicWorkplace',
      url: Routes.TOPIC_WORKPLACE,
      redirect: {
        // Soft Redirect as users knew this brand previously as hybrid meetings
        url: Routes.TOPIC_HYBRID_MEETINGS_VIDEO_WORKPLACE,
        to: Routes.TOPIC_WORKPLACE,
        exact: true,
      },
      breadcrumbLabel: PageTitles.TOPIC_WORKPLACE,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/topics/TopicWorkInOffice',
      url: Routes.TOPIC_WORK_IN_OFFICE,
      breadcrumbLabel: PageTitles.TOPIC_WORK_IN_OFFICE,
    },
    {
      path: 'helpcenter/topics/TopicWorkFromHome',
      url: Routes.TOPIC_WORK_FROM_HOME,
      breadcrumbLabel: PageTitles.TOPIC_WORK_FROM_HOME,
    },
    {
      path: 'helpcenter/topics/returnToOffice/TopicReturnToOffice',
      url: Routes.TOPIC_RETURN_TO_OFFICE,
      breadcrumbLabel: PageTitles.TOPIC_RETURN_TO_OFFICE,
      supportsLiteMode: true,
      disabled: !isReturnToOfficeTopicEnabled,
    },
    {
      path: 'helpcenter/topics/returnToOffice/TopicRTOWindows',
      url: Routes.TOPIC_RETURN_TO_OFFICE_WINDOWS,
      breadcrumbLabel: PageTitles.TOPIC_WINDOWS,
      supportsLiteMode: true,
      disabled: !isReturnToOfficeTopicEnabled,
    },
    {
      path: 'helpcenter/topics/returnToOffice/TopicRTOMac',
      url: Routes.TOPIC_RETURN_TO_OFFICE_MAC,
      breadcrumbLabel: PageTitles.TOPIC_MAC,
      supportsLiteMode: true,
      disabled: !isReturnToOfficeTopicEnabled,
    },
    {
      path: 'helpcenter/topics/holidayReadiness/TopicHolidayReadiness',
      url: Routes.TOPIC_HOLIDAY_READINESS,
      breadcrumbLabel: PageTitles.TOPIC_HOLIDAY_READINESS,
      supportsLiteMode: true,
      disabled: !isHolidayReadinessTopicEnabled,
    },
    {
      path: 'helpcenter/articles/shared/ArticleTechSupport',
      url: Routes.ARTICLE_TECH_SUPPORT,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_TECH_SUPPORT,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_TECH_SUPPORT,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_TECH_SUPPORT,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticleTechBar',
      url: Routes.ARTICLE_TECH_BAR,
      redirect: {
        url: updateRouteWithName(Routes.ARTICLE_TECH_BAR, Consts.WINDOWS, true),
        to: updateRouteWithName(
          Routes.ARTICLE_TECH_BAR,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_TECH_BAR,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticleTechLinks',
      url: Routes.ARTICLE_LINKS,
      redirect: {
        url: updateRouteWithName(Routes.ARTICLE_LINKS, Consts.WINDOWS, true),
        to: updateRouteWithName(Routes.ARTICLE_LINKS, Consts.WINDOWS_PC, true),
      },
      breadcrumbLabel: PageTitles.ARTICLE_LINKS,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticleTechLinks',
      url: Routes.ARTICLE_HELPFUL_LINKS,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_HELPFUL_LINKS,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_HELPFUL_LINKS,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.HELPFUL_LINKS,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticleTechLinks',
      url: Routes.ARTICLE_ADMIN_ACCESS_REQUEST,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_ADMIN_ACCESS_REQUEST,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_ADMIN_ACCESS_REQUEST,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ADMIN_ACCESS_REQUEST,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticleTechLinks',
      url: Routes.ARTICLE_PRINT_SUPPORT,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_PRINT_SUPPORT,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_PRINT_SUPPORT,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.PRINT_SUPPORT,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticleTechLinks',
      url: Routes.ARTICLE_PASSWORD_AND_ACCOUNT,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_PASSWORD_AND_ACCOUNT,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_PASSWORD_AND_ACCOUNT,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.PASSWORD_AND_ACCOUNT,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticleTechLinks',
      url: Routes.ARTICLE_CLOUD_STORAGE,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_CLOUD_STORAGE,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_CLOUD_STORAGE,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.CLOUD_STORAGE,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticleTechLinks',
      url: Routes.ARTICLE_CONNECTIVITY,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_CONNECTIVITY,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_CONNECTIVITY,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.CONNECTIVITY,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticleTechLinks',
      url: Routes.ARTICLE_MESSAGING_AND_EMAIL,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_MESSAGING_AND_EMAIL,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_MESSAGING_AND_EMAIL,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.MESSAGING_AND_EMAIL,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/topics/TopicWorkInOffice',
      url: Routes.TOPIC_WORK_IN_OFFICE,
      breadcrumbLabel: PageTitles.TOPIC_WORK_IN_OFFICE,
    },
    {
      path: 'helpcenter/topics/TopicWorkFromHome',
      url: Routes.TOPIC_WORK_FROM_HOME,
      breadcrumbLabel: PageTitles.TOPIC_WORK_FROM_HOME,
    },
    {
      path: 'helpcenter/articles/shared/ArticleTechContacts',
      url: Routes.ARTICLE_CONTACTS,
      redirect: {
        url: updateRouteWithName(Routes.ARTICLE_CONTACTS, Consts.WINDOWS, true),
        to: updateRouteWithName(
          Routes.ARTICLE_CONTACTS,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_CONTACTS,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticleSharePointGeneral',
      url: Routes.ARTICLE_SHAREPOINT_GENERAL,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_SHAREPOINT_GENERAL,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_SHAREPOINT_GENERAL,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_SHAREPOINT_GENERAL,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticleSharePointSharing',
      url: Routes.ARTICLE_SHAREPOINT_SHARING,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_SHAREPOINT_SHARING,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_SHAREPOINT_SHARING,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_SHAREPOINT_SHARING,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticleSharePointPermissions',
      url: Routes.ARTICLE_SHAREPOINT_PERMISSIONS,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_SHAREPOINT_PERMISSIONS,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_SHAREPOINT_PERMISSIONS,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_SHAREPOINT_PERMISSIONS,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticleSharePointMobile',
      url: Routes.ARTICLE_SHAREPOINT_MOBILE,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_SHAREPOINT_MOBILE,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_SHAREPOINT_MOBILE,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_SHAREPOINT_MOBILE,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticleSharePointRecover',
      url: Routes.ARTICLE_SHAREPOINT_RECOVER,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_SHAREPOINT_RECOVER,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_SHAREPOINT_RECOVER,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_SHAREPOINT_RECOVER,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticleOneDriveAbout',
      url: Routes.ARTICLE_ONEDRIVE_ABOUT,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_ONEDRIVE_ABOUT,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_ONEDRIVE_ABOUT,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_ONEDRIVE_ABOUT,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticleOneDriveTips',
      url: Routes.ARTICLE_ONEDRIVE_TIPS,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_ONEDRIVE_TIPS,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_ONEDRIVE_TIPS,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_ONEDRIVE_TIPS,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticleOneDriveVsSharePoint',
      url: Routes.ARTICLE_ONEDRIVE_VS_SHAREPOINT,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_ONEDRIVE_VS_SHAREPOINT,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_ONEDRIVE_VS_SHAREPOINT,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_ONEDRIVE_VS_SHAREPOINT,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticleOneDriveRecover',
      url: Routes.ARTICLE_ONEDRIVE_RECOVER,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_ONEDRIVE_RECOVER,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_ONEDRIVE_RECOVER,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_ONEDRIVE_RECOVER,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticleOneDriveRestore',
      url: Routes.ARTICLE_ONEDRIVE_RESTORE,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_ONEDRIVE_RESTORE,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_ONEDRIVE_RESTORE,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_ONEDRIVE_RESTORE,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticleOneDriveSignIn',
      url: Routes.ARTICLE_ONEDRIVE_SIGN_IN,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_ONEDRIVE_SIGN_IN,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_ONEDRIVE_SIGN_IN,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_ONEDRIVE_SIGN_IN,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticleOneDriveSharing',
      url: Routes.ARTICLE_ONEDRIVE_SHARING,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_ONEDRIVE_SHARING,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_ONEDRIVE_SHARING,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_ONEDRIVE_SHARING,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/mac/ArticleOneDriveSyncMac',
      url: Routes.ARTICLE_ONEDRIVE_SYNC_MAC,
      breadcrumbLabel: PageTitles.ARTICLE_ONEDRIVE_SYNC_MAC,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticleOfficePrompts',
      url: Routes.ARTICLE_OFFICE_PROMPTS,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_OFFICE_PROMPTS,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_OFFICE_PROMPTS,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_OFFICE_PROMPTS,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticleOutlookZoom',
      url: Routes.ARTICLE_OUTLOOK_ZOOM,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_OUTLOOK_ZOOM,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_OUTLOOK_ZOOM,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_OUTLOOK_ZOOM,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticleOutlookLogin',
      url: Routes.ARTICLE_OUTLOOK_LOGIN,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_OUTLOOK_LOGIN,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_OUTLOOK_LOGIN,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_OUTLOOK_LOGIN,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/mac/ArticleOutlookMapMailboxMac',
      url: Routes.ARTICLE_OUTLOOK_MAP_MAILBOX_MAC,
      breadcrumbLabel: PageTitles.ARTICLE_OUTLOOK_MAP_MAILBOX_MAC,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/windows/ArticleOutlookMapMailboxWindows',
      url: Routes.ARTICLE_OUTLOOK_MAP_MAILBOX_WINDOWS,
      redirect: {
        url: Routes.ARTICLE_OUTLOOK_MAP_MAILBOX_WINDOWS.replace(
          '/pc/',
          '/windows/'
        ),
        to: Routes.ARTICLE_OUTLOOK_MAP_MAILBOX_WINDOWS,
      },
      breadcrumbLabel: PageTitles.ARTICLE_OUTLOOK_MAP_MAILBOX_WINDOWS,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticleOutlookNotSyncing',
      url: Routes.ARTICLE_OUTLOOK_NOT_SYNCING,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_OUTLOOK_NOT_SYNCING,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_OUTLOOK_NOT_SYNCING,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_OUTLOOK_NOT_SYNCING,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticleConnectivityWFH',
      url: Routes.ARTICLE_CONNECTIVITY_WFH,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_CONNECTIVITY_WFH,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_CONNECTIVITY_WFH,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_CONNECTIVITY_WFH,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticleConnectivityEthernet',
      url: Routes.ARTICLE_CONNECTIVITY_ETHERNET,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_CONNECTIVITY_ETHERNET,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_CONNECTIVITY_ETHERNET,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_CONNECTIVITY_ETHERNET,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticleConnectivityVPN',
      url: Routes.ARTICLE_CONNECTIVITY_VPN,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_CONNECTIVITY_VPN,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_CONNECTIVITY_VPN,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_CONNECTIVITY_VPN,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/windows/ArticleConnectivityWifiIssueWindows',
      url: Routes.ARTICLE_CONNECTIVITY_WIFI_ISSUE_WINDOWS,
      redirect: {
        url: Routes.ARTICLE_CONNECTIVITY_WIFI_ISSUE_WINDOWS.replace(
          '/pc/',
          '/windows/'
        ),
        to: Routes.ARTICLE_CONNECTIVITY_WIFI_ISSUE_WINDOWS,
      },
      breadcrumbLabel: PageTitles.ARTICLE_CONNECTIVITY_WIFI_ISSUE_WINDOWS,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/windows/ArticleConnectivityVPNIssueWindows',
      url: Routes.ARTICLE_CONNECTIVITY_VPN_ISSUE_WINDOWS,
      redirect: {
        url: Routes.ARTICLE_CONNECTIVITY_VPN_ISSUE_WINDOWS.replace(
          '/pc/',
          '/windows/'
        ),
        to: Routes.ARTICLE_CONNECTIVITY_VPN_ISSUE_WINDOWS,
      },
      breadcrumbLabel: PageTitles.ARTICLE_CONNECTIVITY_VPN_ISSUE_WINDOWS,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/mac/ArticlePasswordChangeMac',
      url: Routes.ARTICLE_PASSWORD_CHANGE_MAC,
      breadcrumbLabel: PageTitles.ARTICLE_PASSWORD_CHANGE_MAC,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/windows/ArticlePasswordChangeWindows',
      url: Routes.ARTICLE_PASSWORD_CHANGE_WINDOWS,
      redirect: {
        url: Routes.ARTICLE_PASSWORD_CHANGE_WINDOWS.replace(
          '/pc/',
          '/windows/'
        ),
        to: Routes.ARTICLE_PASSWORD_CHANGE_WINDOWS,
      },
      breadcrumbLabel: PageTitles.ARTICLE_PASSWORD_CHANGE_WINDOWS,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticlePasswordSSPR',
      url: Routes.ARTICLE_PASSWORD_SSPR,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_PASSWORD_SSPR,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_PASSWORD_SSPR,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_PASSWORD_SSPR,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticlePasswordRequirements',
      url: Routes.ARTICLE_PASSWORD_REQUIRMENTS,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_PASSWORD_REQUIRMENTS,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_PASSWORD_REQUIRMENTS,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_PASSWORD_REQUIRMENTS,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticlePasswordSSPRFAQs',
      url: Routes.ARTICLE_PASSWORD_SSPR_FAQS,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_PASSWORD_SSPR_FAQS,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_PASSWORD_SSPR_FAQS,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_PASSWORD_SSPR_FAQS,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/windows/ArticlePasswordExpiredWindows',
      url: Routes.ARTICLE_PASSWORD_EXPIRED_WINDOWS,
      redirect: {
        url: Routes.ARTICLE_PASSWORD_EXPIRED_WINDOWS.replace(
          '/pc/',
          '/windows/'
        ),
        to: Routes.ARTICLE_PASSWORD_EXPIRED_WINDOWS,
      },
      breadcrumbLabel: PageTitles.ARTICLE_PASSWORD_EXPIRED_WINDOWS,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticleChromeCache',
      url: Routes.ARTICLE_CHROME_CACHE,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_CHROME_CACHE,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_CHROME_CACHE,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_CHROME_CACHE,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/windows/ArticleChromeDefaultWindows',
      url: Routes.ARTICLE_CHROME_DEFAULT_WINDOWS,
      redirect: {
        url: Routes.ARTICLE_CHROME_DEFAULT_WINDOWS.replace('/pc/', '/windows/'),
        to: Routes.ARTICLE_CHROME_DEFAULT_WINDOWS,
      },
      breadcrumbLabel: PageTitles.ARTICLE_CHROME_DEFAULT_WINDOWS,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/ArticleThirdPartyTableau',
      url: Routes.ARTICLE_THIRD_PARTY_TABLEAU,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_THIRD_PARTY_TABLEAU,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_THIRD_PARTY_TABLEAU,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_THIRD_PARTY_TABLEAU,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/windows/ArticleWindowsComputerName',
      url: Routes.ARTICLE_WINDOWS_COMPUTER_NAME,
      redirect: {
        url: Routes.ARTICLE_WINDOWS_COMPUTER_NAME.replace('/pc/', '/windows/'),
        to: Routes.ARTICLE_WINDOWS_COMPUTER_NAME,
      },
      breadcrumbLabel: PageTitles.ARTICLE_WINDOWS_COMPUTER_NAME,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/windows/ArticleWindowsBitlocker',
      url: Routes.ARTICLE_WINDOWS_BITLOCKER,
      redirect: {
        url: Routes.ARTICLE_WINDOWS_BITLOCKER.replace('/pc/', '/windows/'),
        to: Routes.ARTICLE_WINDOWS_BITLOCKER,
      },
      breadcrumbLabel: PageTitles.ARTICLE_WINDOWS_BITLOCKER,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/windows/ArticleWindowsMapPrinter',
      url: Routes.ARTICLE_WINDOWS_MAP_PRINTER,
      redirect: {
        url: Routes.ARTICLE_WINDOWS_MAP_PRINTER.replace('/pc/', '/windows/'),
        to: Routes.ARTICLE_WINDOWS_MAP_PRINTER,
      },
      breadcrumbLabel: PageTitles.ARTICLE_WINDOWS_MAP_PRINTER,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/windows/ArticleWindowsMapDrive',
      url: Routes.ARTICLE_WINDOWS_MAP_DRIVE,
      redirect: {
        url: Routes.ARTICLE_WINDOWS_MAP_DRIVE.replace('/pc/', '/windows/'),
        to: Routes.ARTICLE_WINDOWS_MAP_DRIVE,
      },
      breadcrumbLabel: PageTitles.ARTICLE_WINDOWS_MAP_DRIVE,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/mac/ArticleMacSelfService',
      url: Routes.ARTICLE_MAC_SELF_SERVICE,
      breadcrumbLabel: PageTitles.ARTICLE_MAC_SELF_SERVICE,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/mac/ArticleMacMapPrinter',
      url: Routes.ARTICLE_MAC_MAP_PRINTER,
      breadcrumbLabel: PageTitles.ARTICLE_MAC_MAP_PRINTER,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/mac/ArticleMacAdmin',
      url: Routes.ARTICLE_MAC_ADMIN,
      breadcrumbLabel: PageTitles.ARTICLE_MAC_ADMIN,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/Article2FAYubikey',
      url: Routes.ARTICLE_2FA_YUBIKEY,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_2FA_YUBIKEY,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_2FA_YUBIKEY,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_2FA_YUBIKEY,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/Article2FAYubikeyErrors',
      url: Routes.ARTICLE_2FA_YUBIKEY_ERRORS,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_2FA_YUBIKEY_ERRORS,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_2FA_YUBIKEY_ERRORS,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_2FA_YUBIKEY_ERRORS,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/Article2FAYubikeyEnrollment',
      url: Routes.ARTICLE_2FA_YUBIKEY_ENROLLMENT,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_2FA_YUBIKEY_ENROLLMENT,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_2FA_YUBIKEY_ENROLLMENT,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_2FA_YUBIKEY_ENROLLMENT,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/Article2FASoftToken',
      url: Routes.ARTICLE_2FA_SOFT_TOKEN,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_2FA_SOFT_TOKEN,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_2FA_SOFT_TOKEN,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_2FA_SOFT_TOKEN,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/Article2FAVPN',
      url: Routes.ARTICLE_2FA_VPN,
      redirect: {
        url: updateRouteWithName(Routes.ARTICLE_2FA_VPN, Consts.WINDOWS, true),
        to: updateRouteWithName(
          Routes.ARTICLE_2FA_VPN,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_2FA_VPN,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/Article2FASetup',
      url: Routes.ARTICLE_2FA_SETUP,
      redirect: {
        url: updateRouteWithName(
          Routes.ARTICLE_2FA_SETUP,
          Consts.WINDOWS,
          true
        ),
        to: updateRouteWithName(
          Routes.ARTICLE_2FA_SETUP,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_2FA_SETUP,
      supportsLiteMode: true,
    },
    {
      path: 'helpcenter/articles/shared/Article2FAFAQs',
      url: Routes.ARTICLE_2FA_FAQS,
      redirect: {
        url: updateRouteWithName(Routes.ARTICLE_2FA_FAQS, Consts.WINDOWS, true),
        to: updateRouteWithName(
          Routes.ARTICLE_2FA_FAQS,
          Consts.WINDOWS_PC,
          true
        ),
      },
      breadcrumbLabel: PageTitles.ARTICLE_2FA_FAQS,
      supportsLiteMode: true,
    },
    {
      path: 'dl/DistributionList',
      url: EnvConfigs.REACT_APP_GUIDE_GROUPS_URL,
      isNewTab: true,
      label: PageTitles.DISTRIBUTION_LIST,
      disabled: !Config.isDLEnabled || UserType.isStoreAssociate(),
      leadingIcon: <ExternalLink />,
    },
    {
      path: Config.isSecurityDashboardV2Enabled
        ? 'securityV2/Security'
        : 'security/Security',
      url: Routes.SECURITY,
      redirect: { url: `${Routes.SECURITY}${Consts.ALL_FOLDERS}` },
      label: PageTitles.SECURITY,
      disabled: !Config.isSecurityAccessEnabled,
      supportsLiteMode: true,
    },
    {
      path: 'admin/Admin',
      url: Routes.ADMIN,
      redirect: { url: `${Routes.ADMIN}${Consts.ALL_FOLDERS}` },
      disabled: !Config.isAdminSiteEnabled,
      breadcrumbLabel: PageTitles.ADMIN,
    },
    {
      path: 'admin/AdminMockData',
      url: Routes.ADMIN_MOCK_DATA_MYSELF,
      disabled: !Config.isAdminSiteEnabled,
      props: { isMyself: true, user: UserType.userData },
      breadcrumbLabel: AdminSite.CARD_TITLE_1,
    },
    {
      path: 'admin/AdminMockData',
      url: Routes.ADMIN_MOCK_DATA_ANOTHER,
      disabled: !Config.isAdminSiteEnabled,
      props: { isMyself: false, user: UserType.userData },
      breadcrumbLabel: AdminSite.CARD_TITLE_2,
    },
    {
      path: 'admin/AdminOrders',
      url: Routes.ADMIN_ORDERS,
      disabled: !Config.isAdminSiteEnabled,
      breadcrumbLabel: AdminSite.CARD_TITLE_3,
    },
    {
      path: 'admin/AdminConfigs',
      url: Routes.ADMIN_CONFIGS,
      disabled: !Config.isAdminSiteEnabled,
      breadcrumbLabel: AdminSite.CARD_TITLE_4,
    },
    {
      path: 'admin/AdminUsers',
      url: Routes.ADMIN_USERS,
      disabled: !Config.isAdminSiteEnabled,
      breadcrumbLabel: AdminSite.CARD_TITLE_5,
    },
    {
      path: 'admin/AdminImpersonateUser',
      url: Routes.ADMIN_IMPERSONATE_MYSELF,
      disabled: !Config.isAdminSiteEnabled,
      props: { user: UserType.userData },
      breadcrumbLabel: AdminSite.CARD_TITLE_6,
    },
    {
      path: 'userlookup/UserLookup',
      url: Routes.USER_LOOKUP,
      disabled: !Config.isUserLookupEnabled,
      breadcrumbLabel: PageTitles.USER_LOOKUP,
    },
    {
      path: 'ssprsupport/SSPRSupportPage',
      url: Routes.SSPR_SUPPORT,
      label: PageTitles.SSPR_SUPPORT,
      disabled: isSSPRSupportPageDisabled,
      breadcrumbLabel: PageTitles.SSPR_SUPPORT,
    },
    {
      path: 'managerdashboard/ManagerDashboard',
      url: Routes.MANAGER_DASHBOARD,
      disabled: !Config.isManagerDashboardEnabled,
      label: 'Manager Dashboard',
    },
    ...holidayReadinessNav(isHolidayReadinessMFEEnabled),
    ...SATnav(Config.isSubmitATicketEnabled && !UserType.isIDCUser),
  ];
};

export default Pages;

Pages.supportsLiteMode = (route, enabledMFEs) => {
  const page = getPageByRoute(Pages(enabledMFEs), route);
  return page && page.supportsLiteMode ? true : false;
};
