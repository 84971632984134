import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import Consts from '../../consts/Consts';
import { LightTheme } from '../../consts/Colors';
import { ExpandMore } from '@mui/icons-material';

const MTAccordion = ({ title, children, idCount, className }) => {
  return (
    <div className={`mtAccordion ${className ? className : Consts.EMPTY}`}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls={`panel${idCount}a-content`}
          id={`panel${idCount}a-header`}
          className="summary"
        >
          <span className="heading">{title}</span>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>

      <style jsx>
        {`
          .heading {
            font-weight: bold;
            font-size: 15px;
          }

          .mtAccordion
            + .mtAccordion
            :global(.MuiAccordion-root > [role='button']) {
            border-top: 1px solid ${LightTheme.border};
          }

          /* MUI overrides/styles */
          .mtAccordion
            :global(.MuiCollapse-container .MuiAccordionDetails-root) {
            display: block;
          }

          .mtAccordion :global(.MuiAccordion-root:before) {
            content: none !important;
          }

          .mtAccordion :global(.MuiPaper-root) {
            background-color: transparent;
          }

          .mtAccordion :global(.MuiPaper-root),
          .mtAccordion :global(.MuiIconButton-root) {
            color: inherit !important;
          }

          .mtAccordion :global(.MuiAccordion-root.Mui-expanded) {
            margin: 0 !important;
          }

          .mtAccordion :global(.summary) {
            min-height: 48px !important;
            padding-right: 8px;
          }

          .mtAccordion :global(.summary > .MuiAccordionSummary-content) {
            margin: 12px 0 !important;
          }

          .mtAccordion :global(.MuiPaper-elevation1) {
            box-shadow: none !important;
          }
        `}
      </style>
    </div>
  );
};

export default MTAccordion;

MTAccordion.propTypes = {
  /** Count used to create unique ids for ADA (see Filter.js example)*/
  idCount: PropTypes.number.isRequired,
};
