import React from 'react';
import Page from './Page';

function FlexPage(props) {
  return (
    <>
      <div className="flex-container">
        <Page {...props} flex />
      </div>
      <style jsx>
        {`
          .flex-container {
            display: flex;
            align-items: stretch;
            flex-direction: column;
            width: 100%;
            height: 100%;
          }

          .flex-container :global(.page) {
            flex: 8;
            width: 100%;
          }
        `}
      </style>
    </>
  );
}

export default FlexPage;
