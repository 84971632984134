import React from 'react';
import MTTextarea from '../controls/MTTextarea';
import Consts from '../../../consts/Consts';

const MTFeedbackSuggestion = ({ name, initialValue, halfWidth, yup }) => {
  return (
    <MTTextarea
      label="Please provide detailed feedback/suggestions"
      showLabel={true}
      name={name}
      initialValue={initialValue}
      halfWidth={halfWidth}
      maxLength={300}
      yup={yup}
      minHeight={100}
    />
  );
};

MTFeedbackSuggestion.defaultProps = {
  name: 'detailedFeedback',
  initialValue: Consts.EMPTY,
};

export default MTFeedbackSuggestion;
