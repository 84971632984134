import React, { useContext } from 'react';
import Colors from '../../consts/Colors';
import CSS from '../../consts/CSS';
import { ReactComponent as Spark } from '../../assets/svg/spark.svg';
import { Link } from 'react-router-dom';
import Routes from '../../consts/Routes';
import Config from '../../utils/Config';
import AppContext from '../context/AppContext';
import { trackEvent } from '../../utils/appInsight';
import { Grid, Heading } from '@walmart-web/livingdesign-components';
import { useTheme, useMediaQuery } from '@mui/material';
import AriaLabel from '../../consts/AriaLabel';
import Events from '../../consts/Events';

const HeaderLogo = () => {
  const theme = useTheme();
  const tabletViewPort = useMediaQuery(theme.breakpoints.down('md'));
  const { isInLiteMode } = useContext(AppContext);

  const customStyles = {
    liteStyle: {
      color: Colors.RED,
      marginLeft: '6px',
    },
    logoMyTechStyle: {
      display: tabletViewPort ? 'none' : 'unset',
      marginLeft: '6px',
      color: Colors.WHITE,
    },
  };

  return (
    <Grid UNSAFE_className="headerlogo-wrapper">
      <Link
        to={Routes.HOMEPAGE}
        className="is-nav" // Currently used for checkIfNavigatableElementClicked in DL and other hidding utilities. Need to review once DL has been offboarded.
        aria-label={AriaLabel.MYTECH_LOGO(isInLiteMode)}
        onClick={() => {
          trackEvent(Events.HEADER.MYTECH_LOGO(isInLiteMode, Routes.HOMEPAGE));
        }}
      >
        <Spark aria-hidden={true} />
        <Heading
          UNSAFE_className="headerlogo-mytech"
          UNSAFE_style={customStyles.logoMyTechStyle}
        >
          MyTech
        </Heading>
        {isInLiteMode && (
          <Heading UNSAFE_style={customStyles.liteStyle}>Lite</Heading>
        )}
      </Link>

      <style>
        {`
            .headerlogo-wrapper a {
              height: ${CSS.HEADER_HEIGHT};
              padding-left: 5px;
              padding-right: 10px !important;
              text-decoration: none;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
            }

            .headerlogo-wrapper a > svg {
              width: 32px;
              padding-right: 6px;
            }
          `}
      </style>
    </Grid>
  );
};

export default HeaderLogo;
