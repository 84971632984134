import * as React from 'react';
import { Spinner } from '@walmart-web/livingdesign-components';

const customStyles = {
  containerStyles: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
};

const LDLoading = () => {
  return (
    <div style={customStyles.containerStyles}>
      <Spinner color="gray" size="large" />
    </div>
  );
};

export default LDLoading;
