import React, { useRef, useState } from 'react';
import Alert from './Alert';
import CSS from '../../consts/CSS';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import AlertBannerCustomButtons from './AlertBannerCustomButtons';
import UserType from '../../user/UserType';
import Consts from '../../consts/Consts';
import { Button } from '@walmart-web/livingdesign-components';
import AriaLabel from '../../consts/AriaLabel';
import Events from '../../consts/Events';
import { trackEvent } from '../../utils/appInsight';
import { Pause, Play } from '@livingdesign/icons';

/*
Dev Note: 
I do not advise using infinate mode with carusel for the following reasons:
- react-multi-carousel re applies alerts to the start and end for their solution for a smooth transition.
this makes it difficult to get the custom buttons to track what alert it is connected to and hard to
translate for the aria-labels
- infinate mode can be dificult for screen readers as it continues through the array of alerts and could get
confusing if the user is trying to find a specific alert they may have passed using assistive technologies.
*/

const DEFAULT_AUTOPLAY_SPEED = 4000;

const customStyles = {
  singleAlert: {
    paddingBottom: '10px',
  },
  autoplayButton: {
    paddingLeft: 'unset',
    marginBottom: '5px',
  },
};

const AlertBanner = ({
  alerts = [],
  alertAutoplaySpeed = DEFAULT_AUTOPLAY_SPEED,
}) => {
  const carouselRef = useRef();
  const [currentAlert, setCurrentAlert] = useState(0);
  const [isAutoPlaying, setAutoPlaying] = useState(true);

  // Filter Alerts
  const filteredAlerts = AlertBanner.handleAlertFiltering(alerts);

  // If no alerts don't render anything
  if (alerts.length === 0 || filteredAlerts.length === 0) {
    return null;
  }

  // If only one alert, Don't render Carousel and just show the single alert
  if (filteredAlerts.length === 1) {
    return <Alert style={customStyles.singleAlert} alert={filteredAlerts[0]} />;
  }

  const responsive = {
    desktopXL: {
      breakpoint: {
        max: 3000,
        min: parseInt(CSS.PAGE_CONTENT_MAX_WIDTH_LARGE, 10),
      },
      items: 1,
    },
    desktop: {
      breakpoint: {
        max: parseInt(CSS.PAGE_CONTENT_MAX_WIDTH_LARGE, 10),
        min: parseInt(CSS.PAGE_CONTENT_MAX_WIDTH, 10),
      },
      items: 1,
    },
    tablet: {
      breakpoint: { max: parseInt(CSS.PAGE_CONTENT_MAX_WIDTH, 10), min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const autoPlayButtonContent = isAutoPlaying ? (
    <>
      <Pause /> Pause Autoplay
    </>
  ) : (
    <>
      <Play /> Resume Autoplay
    </>
  );

  return (
    <section
      role={Consts.ARIA_ROLES.REGION}
      aria-label={AriaLabel.REGIONS.ALERT_CAROUSEL}
    >
      {/* role and label for section lets screen readers know they are in the carousel */}
      <Button
        variant="tertiary"
        size="small"
        aria-label={AriaLabel.ALERT_BANNER_AUTOPLAY_BUTTON(isAutoPlaying)}
        onClick={() => {
          setAutoPlaying(!isAutoPlaying);
          trackEvent(Events.ALERTBANNER.AUTOPLAY_TOGGLE_CLICKED(isAutoPlaying));
        }}
        UNSAFE_style={customStyles.autoplayButton}
      >
        {autoPlayButtonContent}
      </Button>

      <Carousel
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsive}
        arrows={false}
        keyBoardControl={true}
        transitionDuration={500}
        autoPlaySpeed={alertAutoplaySpeed}
        autoPlay={isAutoPlaying}
        minimumTouchDrag={80}
        customButtonGroup={
          <AlertBannerCustomButtons
            alerts={filteredAlerts}
            setAutoPlaying={setAutoPlaying}
            isAutoPlaying={isAutoPlaying}
          />
        }
        renderButtonGroupOutside={true}
        ref={carouselRef}
        afterChange={() => {
          setCurrentAlert(carouselRef?.current?.state?.currentSlide);
        }}
      >
        {filteredAlerts.map((alert, i) => {
          alert.slideId = i + 1;
          alert.isCurrent = currentAlert === i;
          return (
            <Alert key={i} alert={alert} alertsLength={filteredAlerts.length} />
          );
        })}
      </Carousel>
      <style>{`
        .react-multi-carousel-list {
          padding-bottom: 10px; // Adds space at bottom so cheveron buttons don't get cut off
        }
      `}</style>
    </section>
  );
};

export default AlertBanner;

AlertBanner.handleArrayContent = alertsArray => {
  let formattedAlertsArray = [];

  // Apply UserType based alerts
  if (UserType.isIDCUser) {
    const idcAlerts = alertsArray.filter(alert => {
      return alert.audience?.includes(Consts.ALERT_AUDIENCE_TYPES.IDC);
    });
    formattedAlertsArray = [...formattedAlertsArray, ...idcAlerts];
  }
  if (UserType.isHomeOffice()) {
    const homeofficeAlerts = alertsArray.filter(alert => {
      return alert.audience?.includes(Consts.ALERT_AUDIENCE_TYPES.HO);
    });
    formattedAlertsArray = [...formattedAlertsArray, ...homeofficeAlerts];
  }
  if (UserType.isHomeOfficeNewHire) {
    const newhireAlerts = alertsArray.filter(alert => {
      return alert.audience?.includes(Consts.ALERT_AUDIENCE_TYPES.NEW_HIRE);
    });
    formattedAlertsArray = [...formattedAlertsArray, ...newhireAlerts];
  }
  if (UserType.isUS()) {
    const usAlerts = alertsArray.filter(alert => {
      return alert.audience?.includes(Consts.ALERT_AUDIENCE_TYPES.US);
    });
    formattedAlertsArray = [...formattedAlertsArray, ...usAlerts];
  }
  if (UserType.isMX()) {
    const mxAlerts = alertsArray.filter(alert => {
      return alert.audience?.includes(Consts.ALERT_AUDIENCE_TYPES.MX);
    });
    formattedAlertsArray = [...formattedAlertsArray, ...mxAlerts];
  }
  if (UserType.isWMSC()) {
    const wmscAlerts = alertsArray.filter(alert => {
      return alert.audience?.includes(Consts.ALERT_AUDIENCE_TYPES.WMSC);
    });
    formattedAlertsArray = [...formattedAlertsArray, ...wmscAlerts];
  }
  if (UserType.isStoreClubDC()) {
    const storeClubDCAlerts = alertsArray.filter(alert => {
      return alert.audience?.includes(
        Consts.ALERT_AUDIENCE_TYPES.STORE_CLUB_DC
      );
    });
    formattedAlertsArray = [...formattedAlertsArray, ...storeClubDCAlerts];
  }
  if (UserType.isGEC()) {
    const gecAlerts = alertsArray.filter(alert => {
      return alert.audience?.includes(Consts.ALERT_AUDIENCE_TYPES.GEC);
    });
    formattedAlertsArray = [...formattedAlertsArray, ...gecAlerts];
  }
  if (UserType.isStoreAssociate()) {
    const storeAlerts = alertsArray.filter(alert => {
      return alert.audience?.includes(Consts.ALERT_AUDIENCE_TYPES.STORE);
    });
    formattedAlertsArray = [...formattedAlertsArray, ...storeAlerts];
  }

  // Apply all audience
  const allAudienceAlerts = alertsArray.filter(alert => {
    return (
      alert.audience?.includes(Consts.ALERT_AUDIENCE_TYPES.ALL) ||
      !alert.audience
    );
  });
  formattedAlertsArray = [...formattedAlertsArray, ...allAudienceAlerts];

  // Filter out duplicate alert objects
  const uniqueAlerts = new Set();
  formattedAlertsArray = formattedAlertsArray.filter(alert => {
    const isDuplicate = uniqueAlerts.has(alert.title);
    uniqueAlerts.add(alert.title);
    if (!isDuplicate) {
      return true;
    }
    return false;
  });

  return formattedAlertsArray;
};

AlertBanner.handleAlertFiltering = (alerts = []) => {
  let filteredArray;
  // Remove any disabled alerts
  filteredArray = alerts.filter(alert => {
    return !alert.disabled;
  });
  // Filter audience based alerts
  filteredArray = AlertBanner.handleArrayContent(filteredArray);

  return filteredArray;
};
