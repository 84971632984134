import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
const _JSXStyle = require('styled-jsx/style').default;
if (typeof global !== 'undefined') {
  Object.assign(global, { _JSXStyle });
}

import '@livingdesign/bogle/dist/Bogle.css';
import '@walmart-web/livingdesign-components/index.esm.css';
import { LivingDesignProvider } from '@walmart-web/livingdesign-components';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <LivingDesignProvider>
        <App />
      </LivingDesignProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
