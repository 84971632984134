import React from 'react';
import { trackEvent } from '../../utils/appInsight';
import { useHistory, useLocation } from 'react-router-dom';
import MTLD3Button from './MTLD3Button';
import Events from '../../consts/Events';

/**
 *
 * @param {object} props - React props object
 * @param {boolean} props.disabled - LD3 Prop for disabling the button
 * @returns MyTech button for going back from the history location
 */
const MTBackButton = ({ disabled }) => {
  const history = useHistory();
  const location = useLocation();
  return (
    <MTLD3Button
      data-testid="backButton"
      disabled={disabled}
      onClick={() => history.goBack()}
      track={trackEvent(Events.MTBACKBUTTON_CLICK(location.pathname))}
    >
      Back
    </MTLD3Button>
  );
};

MTBackButton.defaultProps = {
  disabled: false,
};

export default MTBackButton;
