import UserType from '../../../../user/UserType';

export const filterSearchResultsByUserType = searchFeatureList => {
  if (UserType.isStoreAssociate()) {
    return searchFeatureList.filter(
      result => result?.isStoreNotAllowed !== true
    );
  } else if (UserType.isIDCUser) {
    return searchFeatureList.filter(result => result?.isIDCNotAllowed !== true);
  } else if (UserType.country === UserType.US) {
    return searchFeatureList.filter(result => result?.isHONotAllowed !== true);
  } else if (UserType.isInternationalAssociate()) {
    return searchFeatureList.filter(
      result => result?.isInternationalNotAllowed !== true
    );
  }
};
