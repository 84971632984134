import { createContext, useContext, useEffect, useState, useRef } from 'react';

const SecurityContext = createContext({});

export const useSecurityContext = () => useContext(SecurityContext);
export const SecurityState = SecurityContext.Consumer;

function SecurityProvider({ children }) {
  const [securityNavWarning, toggleSecurityNavWarning] = useState(false);
  const [loadingSSPR, toggleLoadingSSPR] = useState(true); // this includes sspr and password info
  const [loadingTwoSV, toggleLoadingTwoSV] = useState(true);
  const [loadingPhishing, toggleLoadingPhishing] = useState(true);
  const [isWeakPassword, setIsWeakPassword] = useState(false); // undefined if strong, true means weak password warning
  const [ssprAuthMethods, setSsprAuthMethods] = useState([]);
  const [passwordExpiryWarning, setPasswordExpiryWarning] = useState(undefined); // undefined if no warning, otherwise will be a # of days
  const [twoSVMethods, setTwoSVMethods] = useState([]);
  const [phishingScore, setPhishingScore] = useState(undefined);
  const securityWarningsCount = useRef(null);
  const [cards, setCards] = useState({
    PASSWORD: {},
    SSPR: {},
    TWOSV: {},
    PHISHING: {},
  });

  useEffect(() => {
    const fetchData = async () => {
      const updatedCards = cards;
      const securityNavWarning = false;
      if (loadingSSPR) {
        // TODO fetch SSPR and password info and set it in updatedCards
        toggleLoadingSSPR(false);
      }
      if (loadingTwoSV) {
        // TODO fetch twoSV data and set it in updatedCards
        toggleLoadingTwoSV(false);
      }

      if (loadingPhishing) {
        // TODO fetch phishing data and set it in updatedCards
        toggleLoadingPhishing(false);
      }

      toggleSecurityNavWarning(securityNavWarning);
      setCards(updatedCards);
    };
    fetchData();
  }, []);

  return (
    <SecurityContext.Provider
      value={{
        securityNavWarning,
        cards,
        passwordExpiryWarning,
        isWeakPassword,
        ssprAuthMethods,
        twoSVMethods,
        phishingScore,
        securityWarningsCount: securityWarningsCount.current,
      }}
    >
      {children}
    </SecurityContext.Provider>
  );
}

export default SecurityProvider;
