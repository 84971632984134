import React from 'react';
import Colors from '../../consts/Colors';
import { IconButton } from '@walmart-web/livingdesign-components';
import { Menu as MenuIcon } from '@livingdesign/icons';
import AriaLabel from '../../consts/AriaLabel';
import { useTheme, useMediaQuery } from '@mui/material';
import { trackEvent } from '../../utils/appInsight';
import Events from '../../consts/Events';
import useSideNavigation from '../../customHooks/useSideNavigation';

const HeaderNav = () => {
  const theme = useTheme();
  const tabletViewPort = useMediaQuery(theme.breakpoints.down('md'));
  const { setIsSideNavOpen, isSideNavOpen } = useSideNavigation();

  const customStyles = {
    iconButtonStyle: {
      paddingRight: '1px',
      color: Colors.WHITE,
      display: !tabletViewPort ? 'none' : 'unset',
    },
  };

  return (
    <IconButton
      a11yLabel={AriaLabel.MOBILE_NAV_MENU_ICON}
      UNSAFE_style={customStyles.iconButtonStyle}
      size="large"
      onClick={e => {
        e.preventDefault();
        trackEvent(Events.MOBILE_NAV_MENU);
        setIsSideNavOpen(!isSideNavOpen);
      }}
    >
      <MenuIcon />
    </IconButton>
  );
};

export default HeaderNav;
