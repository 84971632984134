import React from 'react';
import Colors, { LightTheme } from '../../../consts/Colors';
import MTRequired from '../misc/MTRequired';

const MTFormItemLabel = ({ htmlFor, label, isRequired }) => {
  return (
    <>
      <label htmlFor={htmlFor}>
        {label}
        {isRequired ? <MTRequired /> : null}
      </label>

      <style jsx>
        {`
          label {
            background: transparent;
            box-sizing: border-box;
            display: block;
            color: ${LightTheme.formLabelColor};
            font-size: 16px;
            left: 12px;
            line-height: 24px;
            padding-bottom: 3px;
          }

          :global(.mtFormItem.error) label {
            color: ${Colors.ERROR_RED} !important;
          }
        `}
      </style>
    </>
  );
};

export default MTFormItemLabel;
