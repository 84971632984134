import React from 'react';
import Colors from '../../../consts/Colors';
import Consts from '../../../consts/Consts';
import MTFormItem from '../item/MTFormItem';

let mtFormTextIdCounter = 0;

const MTFormText = ({
  className,
  halfWidth,
  children,
  center,
  darkText = false,
  reduceTopMargin,
  formHeader = false,
  id,
}) => {
  return (
    <MTFormItem
      name="mtFormText"
      className={`mtFormText mtFormText${++mtFormTextIdCounter} ${
        className ? className : Consts.EMPTY
      }${reduceTopMargin ? ' reduceTopMargin' : Consts.EMPTY} ${
        formHeader ? 'formHeader' : Consts.EMPTY
      }`}
      halfWidth={halfWidth}
      showLabel={false}
      id={id}
    >
      {children}

      <style jsx>
        {`
          :global(.mtFormText${mtFormTextIdCounter}) {
            text-align: ${center ? 'center' : 'initial'};
            ${darkText ? '' : `color: ${Colors.DARK_GREY};`}
            font-size: 12px;
            ${reduceTopMargin ? `margin-top: 3px !important` : ''}
          }

          :global(.mtFormText:not(.requiredLabel)
              + .mtFormText.reduceTopMargin) {
            padding-top: 6px;
          }

          :global(.mtFormText${mtFormTextIdCounter}.formHeader > h3) {
            margin-top: 0;
          }

          :global(.mtFormText${mtFormTextIdCounter}.formHeader) {
            margin-top: 50px !important;
          }

          :global(.requiredLabel
              + .mtFormText${mtFormTextIdCounter}.formHeader) {
            margin-top: 25px !important;
          }
        `}
      </style>
    </MTFormItem>
  );
};

export default MTFormText;
