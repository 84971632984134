import cookie from 'react-cookies';
class CookieService {
  static clearCookie = name => {
    cookie.remove(name);
  };

  static getCookie = name => {
    return cookie.load(name);
  };

  static setCookie = (name, value, expiresAt) => {
    cookie.save(name, value, {
      path: '/',
      expires: expiresAt,
    });
  };
}

export default CookieService;
