import React from 'react';
import Colors from '../../../consts/Colors';

const MTRequired = props => {
  return (
    <>
      <span>*</span>
      <style jsx>
        {`
          span {
            color: ${Colors.TEXT_GREY};
            font-size: 18px;
            line-height: 0;
            padding-left: 4px;
          }
        `}
      </style>
    </>
  );
};

export default MTRequired;
