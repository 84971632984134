import React, { Suspense, useContext, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import ErrorPage from '../../components/error/ErrorPage';
import LiteModeErrorPage from '../../components/error/LiteModeErrorPage';
import CSS from '../../consts/CSS';
import Errors from '../../consts/Errors';
import Routes, { getRedirects, getRoutes } from '../../consts/Routes';
import AppStore from '../../pages/appstore/AppStore';
import Ordering from '../../pages/ordering/Ordering';
import HolidayReadiness from '../../pages/holidayreadiness/holidayReadiness';
import Config from '../../utils/Config';
import AppContext from '../context/AppContext';
import Nav from '../nav/Nav';
import useSideNavigation from '../../customHooks/useSideNavigation';
import { useMediaQuery, useTheme } from '@mui/material';

const Main = ({
  searchResult,
  setSearchResult,
  showSearchFeedback,
  setShowSearchFeedback,
}) => {
  const { isInLiteMode } = useContext(AppContext);
  const { navigationPages } = useSideNavigation();
  const theme = useTheme();
  const mobileNav = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );

  const location = useLocation();
  const isLicenseOnboardRoute = location.pathname.includes(
    `${Routes.APPS}${Routes.ONBOARD_LICENSE}`
  );
  useEffect(() => {
    if (!showSearchFeedback) {
      setSearchResult(null);
    }
    setShowSearchFeedback(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className="main-container">
      {!isLicenseOnboardRoute && <Nav />}
      <div className="content">
        {Config.isAppStoreModuleEnabled &&
          location.pathname.includes(Routes.APPS) && <AppStore />}
        {Config.isOrderingModuleEnabled &&
          location.pathname.includes(Routes.ORDERING) && <Ordering />}
        {Config.isHolidayReadinessModuleEnabled &&
          location.pathname.includes(Routes.HOLIDAY_READINESS) && (
            <HolidayReadiness />
          )}

        <Suspense fallback={<div />}>
          <Switch>
            {getRoutes(navigationPages)}
            {getRedirects(navigationPages)}
            {/* If any of the above exact Routes or Redirects don't match, this Route will send to the error page. */}
            <Route>
              {isInLiteMode ? (
                <LiteModeErrorPage />
              ) : (
                <ErrorPage title={Errors.UNKNOWN_ROUTE} badRouteError={true} />
              )}
            </Route>
          </Switch>
        </Suspense>
      </div>

      <style jsx>
        {`
          .main-container {
            display: flex;
            min-height: calc(100vh - ${CSS.HEADER_HEIGHT});
            box-sizing: border-box;
            position: relative;
          }
          .content {
            width: 100%;
            flex-basis: 100%;
            margin: 0 auto;
            box-sizing: border-box;
            max-width: ${mobileNav || isLicenseOnboardRoute
              ? '100%'
              : `calc(100% - ${CSS.LEFT_COLUMN_WIDTH}`};
          }
        `}
      </style>
    </div>
  );
};

export default Main;
