import axios from 'axios';
import cookie from 'react-cookies';
import qs from 'qs';
import Routes from '../consts/Routes';
import APIs from '../consts/APIs';
import EnvConfigs from '../Configurations';
import { clearUserItemsInLocalStorage } from '../utils/helpers';

const COOKIE_NAMES = {
  TOKEN: 'jwtToken',
};

const PARAM_NAMES = {
  TOKEN: 'jwtToken',
  CODE: 'responseCode',
};

const SSO_LOGIN_URL = EnvConfigs.REACT_APP_SSO_LOGIN_URL;
const SSO_LOGOUT_URL = EnvConfigs.REACT_APP_SSO_LOGOUT_URL;

const setCookie = (name, value, expiresAt) => {
  cookie.save(name, value, {
    path: Routes.HOMEPAGE,
    expires: expiresAt,
  });
};

const clearCookies = () => {
  cookie.remove(COOKIE_NAMES.TOKEN);
};

const isLocalEnvironment = EnvConfigs.REACT_APP_IS_LOCAL === true;
export default class Auth {
  static handlePostLogin = (queryString, onSuccess, onError) => {
    try {
      //this will be set in axios interceptor
      const pageToLand =
        sessionStorage.getItem('landingUrl') || Routes.HOMEPAGE;
      const queryParams = queryString
        ? qs.parse(queryString.split('?')[1])
        : null;
      const token = queryParams && queryParams[PARAM_NAMES.TOKEN];
      const code = queryParams && queryParams[PARAM_NAMES.CODE];
      if (isLocalEnvironment && token) {
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        const isTokenNotExpired = !Auth.isTokenExpired(decodedToken)
          ? true
          : false;
        if (isTokenNotExpired) {
          const expiresAt = new Date(decodedToken.exp * 1000);

          setCookie(COOKIE_NAMES.TOKEN, token, expiresAt);
          return onSuccess(pageToLand);
        } else {
          Auth.redirectToSSO(pageToLand);
          return 'Redirecting...';
        }
      } else if (Auth.isSessionActive()) {
        //Reset the landingUrl
        sessionStorage.setItem('landingUrl', '');
        return onSuccess(pageToLand);
      } else if (code !== '200') {
        return onError();
      } else {
        //Initiate SSO if token expired or not present or when directly hitting login page with query string
        Auth.redirectToSSO(pageToLand);
        return 'Redirecting...';
      }
    } catch (err) {
      return onError();
    }
  };
  static isSSODown = (pathname, search) => {
    if (pathname === Routes.LOGIN) {
      const queryParams = search ? qs.parse(search.split('?')[1]) : null;
      const code = queryParams && queryParams[PARAM_NAMES.CODE];
      if (code === '500') {
        return true;
      }
    }
    return false;
  };
  static redirectToSSO = (
    pageToLand = Routes.HOMEPAGE,
    clearSession = true
  ) => {
    if (clearSession) {
      clearCookies();
      clearUserItemsInLocalStorage();
    }
    //Store the current pathname to enable to resume where the app was left
    sessionStorage.setItem('landingUrl', pageToLand);
    window.location.href = SSO_LOGIN_URL;
  };

  static getUser = () => {
    const token = Auth.getToken();
    const user = JSON.parse(atob(token.split('.')[1]));
    return user;
  };

  static getToken = (preventRedirect = false) => {
    const token = cookie.load(COOKIE_NAMES.TOKEN);
    if (!token || Auth.isTokenExpired(token, true)) {
      //Expired, so redirecting
      if (!preventRedirect) {
        Auth.redirectToSSO(window.location.pathname + window.location.search);
      }
      return null;
    }
    return token;
  };

  static isSessionActive = (preventRedirect = false) => {
    let token = null;
    try {
      token = Auth.getToken(preventRedirect);
    } catch (e) {
      return false;
    }
    return token ? true : false;
  };

  static isTokenExpired = (token, isNotDecoded) => {
    let decodedToken, isExpired;
    try {
      if (isNotDecoded) {
        decodedToken = JSON.parse(atob(token.split('.')[1]));
      } else {
        decodedToken = token;
      }
      const expDate = decodedToken.exp;
      isExpired = expDate
        ? Math.ceil(new Date().getTime() / 1000) >= expDate
        : true;
    } catch (err) {
      isExpired = true;
    }
    return isExpired;
  };

  static logout = () => {
    //Clear cookies and redirect to sso logout url irrespective of the response status for account safety
    axios
      .post(APIs.getAPIUrl(APIs.LOGOUT), null, {
        headers: {
          overrideErrorHandling: true,
        },
      })
      .then(response => {
        //Do nothing
      })
      .catch(err => {
        //Have empty catch block to not to propogate the promise reject further
      })
      .finally(() => {
        //clear cookie and redirect to sso logout url
        clearCookies();
        clearUserItemsInLocalStorage();
        window.location.href = SSO_LOGOUT_URL;
      });
  };
}
