import React, { useContext, useMemo } from 'react';
import { Breadcrumbs, Typography, useTheme, Box } from '@mui/material';
import LinkRouter from '../button/LinkRouter';
import AppContext from '../../app/context/AppContext';
import Consts from '../../consts/Consts';
import Pages from '../../consts/Pages';
import PageTitles from '../../consts/PageTitles';
import Colors from '../../consts/Colors';
import Routes, {
  getPageByRoute,
  getParentPagesByRoute,
  updateRouteWithName,
} from '../../consts/Routes';
import { getImageByName } from '../../utils/helpers';

//currentRoute comes from withRouter match.path, which will have params (e.g. /apps/:name)
//currentURL will have full, current URL.
//breadcrumbLabel can override label's value. It can also pass the name of the param (e.g. :name)
const LDBreadcrumb = ({ currentRoute, currentURL, currentParams }) => {
  const { newHireTipsConfig, enabledMFEs } = useContext(AppContext);
  const theme = useTheme();

  let breadcrumbList;
  const pages = Pages(enabledMFEs);
  const currentPage = useMemo(
    () => (pages ? getPageByRoute(pages, currentRoute) : null),
    [pages, currentRoute]
  );
  const currentParents = useMemo(
    () =>
      pages
        ? getParentPagesByRoute(pages, currentRoute, currentURL, currentParams)
        : null,
    [pages, currentRoute, currentURL]
  );

  if (currentParents && currentParents.length) {
    breadcrumbList = [...currentParents, currentPage];
  }

  const isNewHireGallery = currentRoute.startsWith(Routes.NEW_HIRE_GALLERY);
  const isHelpCenter = currentRoute.startsWith(Routes.HELP_CENTER);

  return (
    <Box
      sx={{
        maxWidth: '1150px',
        margin: '0 auto',
      }}
      data-testid="breadcrumb"
    >
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          margin: breadcrumbList && '32px auto 4px',
          px: 2.5,
        }}
      >
        {breadcrumbList?.map((page, index) => {
          let url = page.url;
          let label = !page.ignoreBreadcrumb
            ? page.breadcrumbLabel
              ? page.breadcrumbLabel
              : page.label
            : undefined;
          const route = currentParams.name;

          //Check if the label is a param name (e.g :name)
          if (label && label === Consts.ROUTE_PARAM) {
            //JP: If we add additional dymanically named routes, we'll need to add here or ideally update/share how we get the display names.
            //Maybe create a map of page_name's (e.g. newHireGallery_slide1, etc.) that MTBreadcrumb can look thru and expect displayName..?
            if (isNewHireGallery) {
              const image = getImageByName(newHireTipsConfig, route);
              label = image.title;
            } else if (isHelpCenter) {
              if (route === Consts.WINDOWS_PC) {
                label = PageTitles.TOPIC_WINDOWS;
              } else if (route === Consts.MAC) {
                label = PageTitles.TOPIC_MAC;
              } else if (route === Consts.MOBILE) {
                label = PageTitles.TOPIC_MOBILE;
              }
            }
          }

          //Check if the route has :name in it
          if (url && url.includes(Consts.ROUTE_PARAM)) {
            url = updateRouteWithName(url, route);
          }
          if (index < breadcrumbList.length - 1) {
            return (
              <LinkRouter
                route={url}
                props={{
                  underline: 'hover',
                  color: Colors.LIGHT_WHITE,
                }}
                key={index}
              >
                <Typography variant="caption">{label}</Typography>
              </LinkRouter>
            );
          } else {
            return (
              <Typography
                variant="caption"
                color={theme.palette.text.primary}
                key={index}
              >
                {label}
              </Typography>
            );
          }
        })}
      </Breadcrumbs>
    </Box>
  );
};

export default LDBreadcrumb;
