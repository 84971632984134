import React from 'react';
import { ReactComponent as Spark } from '../../assets/svg/spinningSpark.svg';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Close } from '@mui/icons-material';

const LDDialog = ({
  title,
  closeDialog,
  showClose = true,
  primaryButton,
  secondaryButton,
  dividers = true,
  children,
  maxWidth = 'sm',
  hideSecondaryButton,
  xlButton, //for button label with long text
}) => {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const mdScreen = useMediaQuery(theme.breakpoints.down('md'));
  secondaryButton = secondaryButton
    ? secondaryButton
    : {
        label: 'Cancel',
        onClick: () => closeDialog(),
      };

  return (
    <Dialog
      open
      onClose={closeDialog}
      onBackdropClick={closeDialog}
      fullWidth
      maxWidth={maxWidth}
    >
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Box sx={{ pt: 1, pr: 1 }}>
            <Typography variant="h3">{title}</Typography>
          </Box>
          {showClose ? (
            <IconButton aria-label="close" onClick={closeDialog} edge="end">
              <Close />
            </IconButton>
          ) : null}
        </Box>
      </DialogTitle>
      <DialogContent dividers={dividers} sx={{ paddingTop: 0 }}>
        {children}
      </DialogContent>
      {primaryButton ? (
        <DialogActions>
          <Box
            sx={{
              width: '100%',
              p: 2,
              boxSizing: 'border-box',
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: smScreen || xlButton ? 'column-reverse' : 'row',
              alignItems: 'center',
            }}
          >
            {!hideSecondaryButton && (
              <Button
                variant="text"
                onClick={secondaryButton.onClick}
                fullWidth={smScreen}
                color="secondary"
              >
                {secondaryButton.label}
              </Button>
            )}
            <Box
              pl={smScreen ? 0 : 1}
              sx={{
                '& .cls-1': {
                  fill: 'white',
                },
                pb: (smScreen || xlButton) && 1,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={primaryButton.onClick}
                size="large"
                fullWidth={smScreen}
                disabled={primaryButton.disabled}
                startIcon={primaryButton.loading ? <Spark /> : null}
                sx={{
                  lineHeight: xlButton && 1.2,
                }}
              >
                {primaryButton.label}
              </Button>
            </Box>
          </Box>
        </DialogActions>
      ) : null}
    </Dialog>
  );
};

export default LDDialog;
