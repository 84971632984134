import { LightTheme } from '../../../consts/Colors';
import CSS from '../../../consts/CSS';
import HeaderUserInfo from './HeaderUserInfo';
import HeaderUserIntials from './HeaderUserIntials';
import AriaLabel from '../../../consts/AriaLabel';
import Consts from '../../../consts/Consts';
import { FocusTrap } from '@walmart-web/livingdesign-components';

const HeaderUserMenu = ({
  initial,
  name,
  upn,
  setShowProfileMenu,
  showProfileMenu,
}) => {
  const onKeyDown = event => {
    if (event.keyCode === Consts.KEY_CODES.ESCAPE_KEY) {
      document.getElementById('headerUserButton')?.focus();
      setShowProfileMenu(false);
    }
  };

  return (
    <>
      <span
        className="headerUserMenuBG"
        onClick={() => {
          setShowProfileMenu(false);
        }}
      />
      <FocusTrap autoFocus={true} disabled={!showProfileMenu}>
        <div
          id="headerUserMenu"
          data-testid="headerUserMenu"
          className="headerUserMenu"
          role={Consts.ARIA_ROLES.DIALOG}
          aria-label={AriaLabel.USER_SETTINGS_DIALOG}
          tabIndex={-1}
          aria-modal={true}
          onKeyDown={onKeyDown}
          aria-hidden={true}
        >
          <HeaderUserIntials initial={initial} size="large" scheme="dark" />
          <HeaderUserInfo name={name} upn={upn} />
        </div>
      </FocusTrap>

      <style jsx>
        {`
          div {
            display: flex;
            position: absolute;
            width: 255px;
            top: ${CSS.HEADER_HEIGHT};
            right: ${CSS.PAGE_PADDING_LEFT_RIGHT};
            background-color: ${LightTheme.headerMenuBG};
            box-shadow: 0px 1px 2px 1px ${LightTheme.navShadowColor};
            padding: 28px 21px;
            text-align: left;
            border-radius: ${CSS.BORDER_RADIUS};
          }

          div:after /* CSS Triangle - Outside/after to avoid box-shadow */ {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 9.5px 10px 9.5px;
            border-color: transparent transparent ${LightTheme.headerMenuBG}
              transparent;
            position: absolute;
            bottom: 100%;
            right: 5px;
          }

          .headerUserMenuBG {
            background-color: transparent;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
        `}
      </style>
    </>
  );
};

export default HeaderUserMenu;
