import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import React from 'react';

const LinkRouter = ({ route, children, props = {} }) => {
  return (
    <Link component={RouterLink} to={route} {...props}>
      {children}
    </Link>
  );
};

export default LinkRouter;
