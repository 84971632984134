import React from 'react';
import { ReactComponent as FAQIcon } from '../../assets/svg/faq.svg';
import { addEnterListener } from '../../utils/helpers';

const QuestionButton = ({ onClick }) => {
  return (
    <span
      className="questionIcon"
      onClick={onClick}
      onKeyDown={e => {
        addEnterListener(e);
      }}
      tabIndex="0"
    >
      <FAQIcon />

      <style jsx>
        {`
          span.questionIcon {
            padding: 3px 3px 0 0;
            cursor: pointer;
            position: relative;
            top: 3px;
            margin-left: 8px;
          }
        `}
      </style>
    </span>
  );
};

export default QuestionButton;
