import { IconButton } from '@walmart-web/livingdesign-components';
import useMTLD3Button from '../../customHooks/useMTLD3Button';

const BUTTON_TYPE = 'IconButton';

// See: https://livingdesign.walmart.com/components/button/react/ for LD3 IconButton Details
/**
 * Below props object that can be passed
 * @param children - LD3 Prop for the content of the icon button
 * @param href - LD3 Prop for new page ancor redirection
 * @param disabled - LD3 Prop for disabling the button
 * @param size - LD3 Prop for buttoon size
 * @param a11yLabel - LD3 Prop for aria-label
 * @param className - UNSAFE LD3 Prop
 * @param style - UNSAFE LD3 Prop
 * @param onClick - HTML BUTTON Prop
 * @param newTab - MyTech Prop used with href LD3 Prop to open in a new page
 * @param track - MyTech Prop used for TrackEvents
 * @param to - MyTech Prop used for internal site navigation
 * @param toState - MyTech Prop used for passing state via react-router-dom
 * @returns  MyTech feature embedded LD3 Icon Button
 */
const MTLD3IconButton = props => {
  const { handleClick, ancorProps } = useMTLD3Button({
    ...props,
    buttonType: BUTTON_TYPE,
  });
  const { children, disabled, size, a11yLabel, className, style } = props;

  return (
    <IconButton
      children={children}
      disabled={disabled}
      a11yLabel={a11yLabel}
      size={size}
      UNSAFE_className={className}
      UNSAFE_style={style}
      onClick={handleClick}
      {...ancorProps}
      {...props}
    />
  );
};

MTLD3IconButton.defaultProps = {
  disabled: false,
  size: 'medium',
  isSubmitting: false,
};

export default MTLD3IconButton;
