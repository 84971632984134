import React, { useEffect, useState } from 'react';
import fetchDynamicTextData from '../utils/fetchDynamicTextData';

export default function useFetchDynamicTextData(
  textID,
  setError,
  initialValue = null,
  errorValue = true,
  dependencies = [],
  fetchCondition = true,
  parseResponse = x => false
) {
  const [data, setData] = useState(initialValue);

  useEffect(() => {
    if (fetchCondition) {
      fetchDynamicTextData(
        textID,
        res => {
          if (!parseResponse(res)) {
            setData(res);
          } else {
            parseResponse(res);
          }
        },
        err => {
          if (Array.isArray(errorValue)) {
            errorValue[1].error = err;
            setError(...errorValue);
          } else {
            setError(errorValue);
          }
        }
      );
    }
  }, dependencies);

  return [data, setData];
}
