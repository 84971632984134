import React from 'react';
import Colors from '../../../consts/Colors';
import { ReactComponent as ErrorIcon } from '../../../assets/svg/errorIcon.svg';
import { ReactComponent as Warning } from '../../../assets/svg/warningTriangleOutlineSmall.svg';

/**
 *
 * @param {object} props - Props to be passed into the component
 * @param {React.ReactNode} props.children - Child to be shown in the error
 * @param {boolean} props.showErrorIcon - Should the icon be shown
 * @default true
 * @param {boolean} props.warning - Should the error be considered a warning
 * @default false
 * @param {string} props.id - Id for the error message
 * @returns React component for Form item errors in current custom Formik solution
 */
const MTFormItemError = ({
  children,
  showErrorIcon = true,
  warning = false,
  id,
}) => {
  const errorMessage = children?.hideErrorPrefix
    ? `Note: ${String(children.message)}`
    : `${String(children)}`;
  return (
    <div className="errorMessage" id={id}>
      <span
        className="formError"
        dangerouslySetInnerHTML={{
          __html: errorMessage,
        }}
      />
      {showErrorIcon ? warning ? <Warning /> : <ErrorIcon /> : null}
      <style jsx>{`
        .errorMessage {
          color: ${warning ? Colors.ORANGE : Colors.ERROR_RED};
          margin-top: ${warning ? 0 : '3px'};
          font-size: 12px;
        }

        .errorMessage :global(svg) {
          padding-left: 5px;
        }

        .formError :global(a) {
          color: inherit;
        }
      `}</style>
    </div>
  );
};

export default MTFormItemError;
