const Tooltips = {
  CHANGE_PASSWORD:
    'Change password to prevent your account from being locked. This warning will go away after you change your password.',
  TWOSV_WARNING:
    '2SV enrollment is required to access key Walmart applications and services.',
  SSPR_WARNING:
    'Please enroll in BOTH phone and email methods to avoid any disruption to SSPR in the future.',
  SEND_TO_MAILTOP_WARNING:
    'Deliver to Mailstop option is temporarily unavailable',
};

export default Tooltips;
