import Consts from './Consts';

const AriaLabel = {
  LOCAL_DEV_ICON: 'Local development environment',
  MYTECH_LOGO: isInLiteMode => {
    return `MyTech ${isInLiteMode ? 'Lite ' : Consts.EMPTY}'home page'`;
  },
  ADMIN_USER_ICON: isImpersonated => {
    return `${isImpersonated ? 'Impersonation applied.' : 'QA user applied.'}`;
  },
  ADMIN_ICON: 'Admin tools',
  USER_SETTINGS_DIALOG: `User settings menu`,
  USER_SETTINGS_ICON: `User settings`,
  SEARCH_FEATURE: 'Search MyTech pages and resources.',
  SEARCH_RESULT: (resultDisplayValue, resultType, osLabel = null) => {
    return `${resultDisplayValue}, on click routes to ${resultDisplayValue} ${resultType}${
      osLabel ? ` for ${osLabel}` : ''
    }`.replace(/(<([^>]+)>)/gi, '');
  },
  SEARCH_NO_RESULT: 'No results found for search.',
  SEARCH_ERROR: "There's a problem loading your search results.",
  MOBILE_NAV_MENU_ICON: 'Navigation menu',
  SUPPORT_TICKET_SECTION_SUBMITATICKET_LINK:
    'Redirect to support submit a ticket page.',
  SUPPORT_CALLUS_SECTION_CONTACTUS_LINK:
    'Redirect to support phone number listings page.',
  SUPPORT_GETSUPPORT_SECTION_GOTOFIXIT_LINK:
    'Open new tab  to Fix It support site.',
  SUPPORT_TALKTOAEXPERT_SECTION_LINK: isTalkToExpertEnabled => {
    return isTalkToExpertEnabled
      ? 'Redirect to support tech bar listings page.'
      : 'Open new tab to Service Now Walk-up Experience form.';
  },
  VIRTUAL_DEVICES_VIEW_ALL_LINK: deviceCount => {
    return `View all virtual devices (${deviceCount})`;
  },
  MY_TICKETS_VIEW_ALL_LINKs: ticketCount => {
    return `View all open tickets (${ticketCount})`;
  },
  PHYSICAL_DEVICES_VIEW_ALL_LINK: deviceCount => {
    return `View all physical devices (${deviceCount})`;
  },
  PHYSICAL_DEVICES_REPORT_MISSING_DEVICE: `Open new tab to MyTech's Feedback Ally Survey to report missing or incorrect device data.`,
  ALERT_BANNER_PREVIOUS_NAVIGATION_CHEVERONS: 'Previous alert banner content.',
  ALERT_BANNER_NEXT_NAVIGATION_CHEVERONS: 'Next alert banner content.',
  ALERT_BANNER_SLIDE_NAVIGATION_CIRCLE: (slideId, slideLength) => {
    return `Alert carousel slide ${slideId} of ${slideLength}.`;
  },
  ALERT_BANNER_AUTOPLAY_BUTTON: isPlaying => {
    return `${isPlaying ? 'Pause' : 'Resume'} alert carousel autoplay.`;
  },
  DATA_LIST_ITEM_GROUP: (dataListTitle, currentItem, itemsLength) => {
    return `${dataListTitle} item ${currentItem} of ${itemsLength}.`;
  },
  HELP_CENTER_LANDING_PAGE_CATEGORY_CARD: (to, href) => {
    const toParamString = `to ${to} in MyTech`;
    const hrefParamString = `new tab to ${href}`;
    return `On click opens ${to ? toParamString : hrefParamString}.`;
  },
  VIDEO_CAROUSEL_NEXT_BUTTON: 'Show next video item.',
  VIDEO_CAROUSEL_PREVIOUS_BUTTON: 'Show previous video item.',
  VIDEO_CAROUSEL_ITEM_CLICK: title => {
    return `Opens ${title} video in a dialog.`;
  },
  REGIONS: {
    PAGE_BANNER: 'Hero Banner',
    ALERT_CAROUSEL: 'Alert Carousel',
    VIDEO_CAROUSEL: 'Video carousel',
    HOME_PAGE_MY_TICKETS: 'My Tickets',
    HOME_PAGE_MY_DEVICES: 'My Devices.',
    SUPPORT_CHAT_BANNER: 'Support Chat Banner',
    SUPPORT_FOOTER: 'Additional support',
    HELP_CENTER_LANDING_PAGE_MY_DEVICES: 'My devices help center content cards',
    HELP_CENTER_LANDING_PAGE_HELPFUL_INFO:
      'Additional helpful information help center content cards',
    HELP_CENTER_LANDING_PAGE_WAYS_OF_WORKING:
      'Ways of working help center content cards',
    HELP_CENTER_LANDING_PAGE_CONTACT_US:
      'Additional support help center information',
  },
  GROUPS: {
    HOME_PAGE_OPEN_TICKETS: 'Open Tickets',
    HOME_PAGE_MY_DEVICES_PHYSICAL_DEVICES: 'Physical Devices',
    HOME_PAGE_MY_DEVICES_VIRTUAL_DEVICES: 'Virtual Devices',
    ALERT_BANNER_ALERT_GROUP: (alertId, alertsLength) => {
      return `Slide ${alertId} of ${alertsLength}.`;
    },
    ALERT_CAROUSEL_NAVIGATION: 'Alert carousel navigation',
    VIDEO_CRAOUSEL_NAVIGATION: 'Video carousel navigation',
    SUPPORT_FOOTER_TALK_TO_A_EXPERT: 'Talk to a Expert Information',
    SUPPORT_FOOTER_SUPPORT_TICKET: 'Support Ticket Information',
    SUPPORT_FOOTER_FIXIT: 'Fix It app Information',
    SUPPORT_FOOTER_CONTACT_US: 'Support Contact Information',
    VIDEO_CAROUSEL_VIDEO_ITEM: (index, itemsLength) => {
      return `Item ${index} of ${itemsLength}.`;
    },
  },
  SEARCH_CLEAR: 'Clear search',
  FEEDBACK_STAR_ANNOUNCEMENT: (currentStar, numberOfStars) => {
    return `Rating set to ${currentStar} out of ${numberOfStars}.`;
  },
  FEEDBACK_STAR: (currentStar, numberOfStars) => {
    return `Feedback Star ${currentStar} of ${numberOfStars}.`;
  },
  TECH_BAR_QUEUE_REDIRECT:
    'Redirect to ServiceNow Virtual Techbar Join Queue form',
};

export default AriaLabel;
