import { SideNavigationItem } from '@walmart-web/livingdesign-components';
import useSideNavigation from '../../customHooks/useSideNavigation';
import Events from '../../consts/Events';
import { trackEvent } from '../../utils/appInsight';
import { useMediaQuery, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';
import CSS from '../../consts/CSS';

const MTSideNavigationItem = ({ label, url, isNewTab, leadingIcon }) => {
  const theme = useTheme();
  const mobileNav = useMediaQuery(theme.breakpoints.down('md'));
  const { isSelected, setIsSideNavOpen } = useSideNavigation(url);
  const history = useHistory();

  const sideNavWidth = mobileNav
    ? CSS.NAV_MAX_WIDTH_MOBILE
    : CSS.NAV_MAX_WIDTH_DESKTOP;

  const customStyles = {
    navItemStyle: {
      minWidth: sideNavWidth,
    },
  };

  const handleClick = e => {
    const isMiddleClick = e.button === 1;
    const isRightClick = e.button === 2;
    //Ctrl - Windows, Shift - Common OS, Command/Meta - Mac OS
    const isCtrlOrShiftOrCommandClick = e.ctrlKey || e.shiftKey || e.metaKey;
    if (!isMiddleClick && !isRightClick && !isCtrlOrShiftOrCommandClick) {
      trackEvent(Events.SIDE_NAV.NAV_ITEM(label));
      setIsSideNavOpen(false);
      if (!isNewTab) {
        e.preventDefault();
        history.push(url);
      }
    }
  };

  return (
    <SideNavigationItem
      UNSAFE_style={customStyles.navItemStyle}
      isCurrent={isSelected}
      onClick={handleClick}
      href={url}
      {...MTSideNavigationItem.newTabProps(isNewTab)}
      leading={leadingIcon}
    >
      {label}
    </SideNavigationItem>
  );
};

export default MTSideNavigationItem;

MTSideNavigationItem.newTabProps = isNewTab => {
  if (isNewTab) {
    return {
      target: '_blank',
      rel: 'noopener noreferrer',
    };
  }
};
