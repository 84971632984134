import ErrorHandler from './ErrorHandler';
import Consts from '../consts/Consts';
import axios from 'axios';
import Auth from '../auth/Auth';
import Events from '../consts/Events';
import { getCustomDimensions } from '../utils/appInsight';

export const successFailure = response => {
  return response.errors ||
    (response.data && response.data.errors) ||
    GraphQLHasError(response)
    ? true
    : false;
};

export const interceptorSuccessHandler = (
  response,
  setHasError,
  setErrorType
) => {
  if (successFailure(response)) {
    ErrorHandler.trackError(Events.ERRORS.TITLE.GRAPHQL, response);
    if (
      !(response.config.headers && response.config.headers.ignoreGraphQLError)
    ) {
      //If !ignoreGraphQLError, redirect to the generic error page.
      setHasError(true);
      setErrorType(Consts.GENERIC_ERROR);
    }
    return Promise.reject(response);
  } else {
    return response;
  }
};

export const interceptorErrorHandler = (error, setHasError, setErrorType) => {
  if (error && error.message && error.message === Consts.NETWORK_ERROR) {
    //Retry Network Errors
    if (error.config && error.config.headers) {
      if (error.config.headers.retryCount) {
        ++error.config.headers.retryCount;
      } else {
        error.config.headers.retryCount = 1;
      }
      if (error.config.headers.retryCount <= Consts.MAX_RETRY) {
        ErrorHandler.trackError(Events.ERRORS.TITLE.NETWORK_RETRY, error);
        return axios.request(error.config);
      }
    }
    //Make message enumerable, so it's passed to App Insights.
    Object.defineProperty(error, 'message', {
      enumerable: true,
      configurable: false,
      writable: false,
      value: error.message,
    });

    error.isNetworkError = true;

    ErrorHandler.trackError(Events.ERRORS.TITLE.NETWORK, error);
    //If !ignoreNetworkError, redirect to the network error page.
    if (
      !(
        (error.headers && error.headers.ignoreNetworkError) ||
        (error.config &&
          error.config.headers &&
          error.config.headers.ignoreNetworkError)
      )
    ) {
      setHasError(true);
      setErrorType(Consts.NETWORK_ERROR);
    }
  } else {
    const errorText = `${Events.ERRORS.TITLE.AXIOS} - ${error}`;
    ErrorHandler.trackError(errorText, {});

    //If it's a token error, redirect to the session timed out page.
    if (
      (error.response && error.response.status === 401) ||
      (error && error.message && error.message.startsWith(Consts.TOKEN_ERROR))
    ) {
      Auth.redirectToSSO(window.location.pathname);
    } else if (
      !(
        error.response &&
        error.response.config.headers &&
        error.response.config.headers.ignoreGenericError
      ) &&
      !(
        error &&
        axios.isCancel(error) &&
        error.message &&
        JSON.parse(error.message).ignoreGenericError
      )
    ) {
      //Else if !ignoreGenericError, redirect to the generic error page.
      setHasError(true);
      setErrorType(Consts.GENERIC_ERROR);
    }
  }

  return Promise.reject(error);
};

export const GraphQLHasError = response => {
  // This flag is set to true if response status conatins 'Error' string
  let isError = false;
  if (response.data && response.data.data) {
    const responseData = response.data.data;
    //Looping through as graphql sends response as per request query/mutation
    for (const property in responseData) {
      if (responseData[property] && responseData[property].status) {
        const responseStatus = responseData[property].status;
        isError =
          responseStatus.split(' ')[0].trim() === 'Error' ? true : false;
      }
      break;
    }
  }
  return isError;
};

export const addTokenToHeader = request => {
  const CancelToken = axios.CancelToken;
  if (Auth.isSessionActive(true)) {
    const token = Auth.getToken();
    request.headers.authorization = `Bearer ${token}`;
  } else {
    return {
      ...request,
      cancelToken: new CancelToken(cancel =>
        cancel(`${Consts.TOKEN_ERROR} - ${request.url}`)
      ),
    };
  }
  return request;
};

export const addTorbitHeader = request => {
  request.headers['x-tb-debug'] = 1;
  return request;
};

export const addSessionHeader = request => {
  const customDimensions = getCustomDimensions();
  for (const key in customDimensions) {
    request.headers[key] = customDimensions[key];
  }
  return request;
};
