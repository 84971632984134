import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { trackEvent } from '../../utils/appInsight';

//Passing mailto ingores href
//The to prop also sets href, so the URL shows on hover
/**
 *
 * @depreceated
 * Use MTLD3Link instead of MTLink
 */
const MTLink = ({
  children,
  label,
  mailto,
  onClick,
  to,
  href = to,
  linkState,
  ariaLabel,
}) => {
  const link = mailto ? `mailto:${mailto}` : href;
  const history = useHistory();
  const location = useLocation();
  const handleClick = event => {
    if (link) {
      trackEvent(`MTLink opened URL ${link} - ${location.pathname}`);
    }
    if (onClick) {
      onClick(event);
    }
    if (to) {
      trackEvent(`MTLink redirected to ${to} - ${location.pathname}`);
      history.push(to, { ...linkState });
      event.preventDefault();
    }
  };

  return (
    <a
      className="mtLink"
      href={link}
      target={href && href !== to ? '_blank' : null}
      rel="noreferrer noopener"
      onClick={handleClick}
      aria-label={ariaLabel}
    >
      {label ? label : children ? children : null}
      <style jsx>
        {`
          a {
            text-decoration: underline;
          }
          a:not([disabled]):not(.disabled) {
            cursor: pointer;
          }
        `}
      </style>
    </a>
  );
};

export default MTLink;
