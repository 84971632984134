import axios from 'axios';
import APIs from '../consts/APIs';
import {
  getLocalStorageItemWithTTL,
  setLocalStorageItemWithTTL,
} from './helpers';
import Consts from '../consts/Consts';

/**
 * Calls the Graph Gateway API to retrieve user devices and stores them in local storage if found.
 *
 * @param {string} associateId - The associate ID to query the devices for.
 * @param {string} quer - The query string to be sent to the Graph Gateway API.
 * @param {function} onSuccessFailure - A callback function to handle the response when there are errors.
 * @param {function} onFailure - A callback function to handle the failure case.
 * @returns {Promise} - A promise that resolves with the response from the Graph Gateway API.
 */
export const graphGateway = async (
  associateId,
  quer,
  onSuccessFailure,
  onFailure
) => {
  const data = JSON.stringify({
    query: quer,
    variables: { userPrincipalName: associateId },
  });
  return axios
    .post(APIs.getAPIUrl(APIs.GRAPH_GATEWAY), data, {
      headers: {
        'Content-Type': 'application/json',
        ignoreGenericError: true,
        ignoreNetworkError: true,
        ignoreGraphQLError: true,
      },
    })
    .then(res => {
      if (res.errors) {
        onSuccessFailure(res);
      } else {
        const validCachedDeviceData = getLocalStorageItemWithTTL(
          Consts.CACHED_DATA.DEVICE_DATA
        );
        if (!validCachedDeviceData) {
          const devices = res.data?.data?.getUser_v1 || [];
          const expirationTime = new Date().getTime() + 3 * 60 * 60 * 1000; // 3 hours in milliseconds
          setLocalStorageItemWithTTL(
            Consts.CACHED_DATA.DEVICE_DATA,
            devices,
            expirationTime
          );
        }
        return res;
      }
    })
    .catch(error => {
      console.error(error);
      onFailure();
    });
};
