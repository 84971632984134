import React, { useEffect, useState } from 'react';
import MTSearch from '../../../components/form/controls/MTSearch';
import Placeholders from '../../../consts/Placeholders';
import { useHistory } from 'react-router-dom';
import { trackEvent } from '../../../utils/appInsight';
import MTForm from '../../../components/form/MTForm';
import Config from '../../../utils/Config';
import Routes from '../../../consts/Routes';
import URLs from '../../../consts/URLs';
import CSS from '../../../consts/CSS';
import Colors from '../../../consts/Colors';
import APIs from '../../../consts/APIs';
import axios from 'axios';
import Consts from '../../../consts/Consts';
import VideoDialog from '../../../components/dialog/VideoDialog';
import { sortBy, removeTags } from '../../../utils/helpers';
import useFetchDynamicTextData from '../../../customHooks/useFetchDynamicTextData';
import { filterSearchResultsByUserType } from './utils/searchUtils';

const MTSearchFeature = ({
  setSearchResult,
  setShowSearchFeedback,
  searchIsFocused,
  setSearchIsFocused,
  setHideHeaderIcons,
}) => {
  const history = useHistory();
  const [isSearching, setIsSearching] = useState(false);
  const [resultsList, setResultsList] = useState(null);
  const [isSearchError, setSearchError] = useState(false);
  const [topSearchResults] = useFetchDynamicTextData(
    'topSearchResults',
    setSearchError,
    null //initialValue
  );
  const [showVideoDialog, setShowVideoDialog] = useState(false);
  const [currentVideoSrc, setCurrentVideoSrc] = useState(Consts.EMPTY);
  const [currentVideoTitle, setCurrentVideoTitle] = useState(Consts.EMPTY);
  const [resultedSearchTerm, setResultedSearchTerm] = useState(Consts.EMPTY);
  const [forceHideDropDown, setForceHideDropDown] = useState(true);

  const getResultsOnSearchTermChange = async searchTerm => {
    let searchFeatureList = [];
    searchTerm = searchTerm.toLowerCase().trim();

    if (
      searchTerm &&
      searchTerm.length >= Consts.SEARCH.MIN_CHAR_LENGTH &&
      searchTerm !== resultedSearchTerm
    ) {
      setResultsList(null); //clear only on a valid search
      setIsSearching(true);
      setSearchError(false);

      await axios
        .get(APIs.getAPIUrl(APIs.SITE_SEARCH_DATA), {
          params: {
            query: searchTerm,
          },
          headers: {
            ignoreGenericError: true,
            ignoreNetworkError: true,
          },
        })
        .then(res => {
          const searchResults = res.data?.searchResults || [];
          const returnSearchTerm = res.data?.searchTerm || '';
          const featureResults = [];
          const otherResults = [];
          if (searchTerm === returnSearchTerm) {
            searchResults.map(result => {
              //Sort feature pages first alphabetically and show them first in the results set
              if (result.isFeaturePage) {
                featureResults.push({
                  ...result,
                  titleEnPlainText: removeTags(result.title_en),
                });
              } else {
                otherResults.push(result);
              }
              return featureResults.sort(sortBy('titleEnPlainText'));
            });
            searchFeatureList = [...featureResults, ...otherResults];
            searchFeatureList = filterSearchResultsByUserType(
              searchFeatureList
            );
            setResultsList(searchFeatureList);
            setIsSearching(false);
            setResultedSearchTerm(returnSearchTerm);
          }
        })
        .catch(err => {
          searchFeatureList = filterSearchResultsByUserType(searchFeatureList);
          setResultsList(searchFeatureList);
          setIsSearching(false);
          setSearchError(true);
          setResultedSearchTerm(Consts.EMPTY);
        });
    } else if (searchTerm === Consts.EMPTY) {
      setResultsList(topSearchResults);
      setResultedSearchTerm(Consts.EMPTY);
    }
  };

  const setSelectedData = (data, searchTerm) => {
    if (data) {
      trackEvent(`Searched for ${searchTerm}`);
      if (data.path) {
        if (
          !Config.isHotspotOrderingEnabled &&
          data.path === Routes.MOBILE_HOTSPOT
        ) {
          window.open(URLs.HOTSPOT_SELF_SERVICE_URL, '_blank', 'noopener');
        } else if (data.mime_type === Consts.SITE_SEARCH.TYPE.PDF) {
          window.open(data.url, '_blank', 'noopener');
        } else if (data.mime_type === Consts.SITE_SEARCH.TYPE.VIDEO) {
          setShowVideoDialog(true);
          setCurrentVideoSrc(data.url);
          setCurrentVideoTitle(data.title_en);
        } else {
          history.push(data.path);
          setForceHideDropDown(true);
        }
        setSearchResult({
          searchTerm: searchTerm,
          url: data.path,
          title: data.keywords_en[0],
        });
        setShowSearchFeedback(true);
      }
    }
  };
  const getDisplayValue = data => {
    return data.title_en.toString();
  };
  const getResultType = data => {
    switch (data.mime_type) {
      case 'HTML':
        return 'Page';
      case 'pdf':
        return 'Document';
      case 'jpg':
        return 'Image';
      case 'mp4':
        return 'Video';
      default:
        return null;
    }
  };
  const onSearchSubmit = async formikValues => {
    // To be implemented in future release
    // history.push('/search/noresults');
  };

  return (
    <div className="featureSearch">
      <MTForm
        onSubmit={onSearchSubmit}
        showRequired={false}
        showSubmitButton={false}
      >
        <MTSearch
          placeholder={Placeholders.FEATURE_SEARCH}
          isSearching={isSearching}
          resultsList={resultsList}
          setResultsList={setResultsList}
          setSelectedData={setSelectedData}
          getResultsOnSearchTermChange={getResultsOnSearchTermChange}
          getDisplayValue={getDisplayValue}
          getResultType={getResultType}
          hideNoResults={false}
          resetOnSelect={true}
          includeSearchIcon={true}
          includeClearIcon={true}
          showLabel={false}
          searchIsFocused={searchIsFocused}
          setSearchIsFocused={setSearchIsFocused}
          setHideHeaderIcons={setHideHeaderIcons}
          isSearchError={isSearchError}
          forceHideDropDown={forceHideDropDown}
          setForceHideDropDown={setForceHideDropDown}
        />
      </MTForm>
      {showVideoDialog ? (
        <VideoDialog
          isDialogOpen={showVideoDialog}
          closeDialog={() => {
            setShowVideoDialog(false);
            setCurrentVideoSrc(Consts.EMPTY);
            setCurrentVideoTitle(Consts.EMPTY);
          }}
          videoSrc={currentVideoSrc}
          videoTitle={currentVideoTitle}
        />
      ) : null}
      <style jsx>
        {`
          .featureSearch {
            width: 100%;
            max-width: 720px;
            padding: 0;
          }

          .featureSearch :global(input) {
            height: 40px;
            position: relative;
            background: ${Colors.WHITE};
            border: 1px solid ${Colors.LIGHT_GREY};
            border-radius: ${CSS.BORDER_RADIUS};
            margin-top: 0px;
            font-size: 14px;
            padding-left: 28px;
            color: ${Colors.BLACK};
            z-index: 1;
          }

          .featureSearch :global(.mtForm) {
            max-width: inherit;
            padding: 0;
          }

          .featureSearch :global(.searchIcon > svg) {
            top: 12px;
            left: 8px;
            width: 16px;
            height: 16px;
            position: absolute;
            z-index: 2;
          }

          .featureSearch :global(input:focus::placeholder) {
            color: transparent;
          }
        `}
      </style>
    </div>
  );
};

export default MTSearchFeature;
