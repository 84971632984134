import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import Colors from '../../../consts/Colors';
import MTFormItem from '../item/MTFormItem';
import MTCheckbox from './MTCheckbox';

//NOTE: Use initialValue, not selected attribute.

let mtCheckboxIdCounter = 1;

//Any component that includes this, must define "name" and "initialValue" as defaultProps/attributes and optionally "yup", so they can be read by <MTForm> to setup <Formik> and Yup validationSchema.
const MTCheckboxes = ({
  name,
  yup,
  label,
  halfWidth,
  initialValue, //WARNING: For form validation, this must match any the initially selected value, including an empty string if blank.
  children,
  checkboxes = [
    /* Optional param to pass button objects, instead of passing children. NOTES: Ignored if has children. Also supports label prop if diff from value.
    { value: 'Verizon' },
    { value: 'AT&T'} */
  ],
  showLabel,
  twoColumn,
  note,
}) => {
  const className = 'mtCheckboxes';
  const isRequired = yup && yup._exclusive && Boolean(yup._exclusive.required);
  const checkedError = `Error: For MTCheckboxes, please use initialValue prop, not the checked prop/attribute. name: ${name}`;

  const getChildren = field => {
    return children
      ? React.Children.map(children, child => {
          return React.cloneElement(child, {
            ...field,
            field: field,
            id: `mtCheckbox${mtCheckboxIdCounter++}`,
            key: `mtCheckbox${child.props.value}`,
            value: child.props.value,
            label: child.props.label,
            groupName: name,
          });
        })
      : checkboxes.length
      ? checkboxes.map(checkbox => {
          if (checkbox.checked) {
            throw Error(checkedError);
          }

          return (
            <MTCheckbox
              {...field}
              field={field}
              id={`mtCheckbox${mtCheckboxIdCounter++}`}
              key={`mtCheckbox${checkbox.value}`}
              value={checkbox.value}
              label={checkbox.label}
              groupName={name}
            />
          );
        })
      : null;
  };

  React.Children.forEach(children, checkbox => {
    if (checkbox.props.checked) {
      throw Error(checkedError);
    }
  });

  return (
    <MTFormItem
      id={name}
      name={name}
      label={label}
      className={className}
      halfWidth={halfWidth}
      isRequired={isRequired}
      showLabel={showLabel}
      twoColumn={twoColumn}
      note={note}
    >
      <Field name={name}>
        {({ field }) => {
          return getChildren(field);
        }}
      </Field>

      <style jsx>
        {`
          :global(.${className}.error input) {
            border-color: ${Colors.ERROR_RED} !important;
          }

          :global(.${className}.error > label:first:child) {
            color: ${Colors.ERROR_RED} !important;
          }
        `}
      </style>
    </MTFormItem>
  );
};

export default MTCheckboxes;

MTCheckboxes.defaultProps = {
  showLabel: true,
};

MTCheckboxes.propTypes = {
  name: PropTypes.string.isRequired,
  yup: PropTypes.object,
  label: PropTypes.string.isRequired, //Needed for ADA, set showLabel to false to hide.
  halfWidth: PropTypes.bool,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired, //WARNING: Must match any pre-checked value for form validation
  children: PropTypes.node,
  checkboxes: PropTypes.array,
};
