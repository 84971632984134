import Consts from '../consts/Consts';

export default class TicketStatus {
  static isOpenTicket = status => {
    return !(
      TicketStatus.isClosed(status) ||
      TicketStatus.isResolved(status) ||
      TicketStatus.isCanceled(status)
    );
  };

  static isNew = status => {
    return status === TicketStatus.NEW;
  };

  static isOpen = status => {
    return status === TicketStatus.OPEN;
  };

  static isInProgress = status => {
    return status === TicketStatus.IN_PROGRESS;
  };

  static isActive = status => {
    return status === TicketStatus.ACTIVE;
  };

  static isAssigned = status => {
    return status === TicketStatus.ASSIGNED;
  };

  static isPending = status => {
    return status === TicketStatus.PENDING;
  };

  static isClosed = status => {
    return status.includes(TicketStatus.CLOSED);
  };

  static isResolved = status => {
    return status === TicketStatus.RESOLVED;
  };

  static isCanceled = status => {
    return status === TicketStatus.CANCELED;
  };

  static getOpenTicketsCount = ticketData => {
    return ticketData.filter(item => {
      return TicketStatus.isOpenTicket(item.status);
    }).length;
  };

  static isCommentsOrAttachmentsRestricted = (ticketType, ticketStatus) => {
    return (
      (ticketType === Consts.SNOW.TYPE.CONTACT ||
        ticketType === Consts.SNOW.TYPE.INCIDENT) &&
      [
        Consts.SNOW.STATUS.CANCELED,
        Consts.SNOW.STATUS.CLOSED,
        Consts.SNOW.STATUS.CLOSED_COMPLETE,
        Consts.SNOW.STATUS.CLOSED_INCOMPLETE,
      ].indexOf(ticketStatus) !== -1
    );
  };
}

TicketStatus.NEW = 'New';
TicketStatus.OPEN = 'Open';
TicketStatus.IN_PROGRESS = 'Work In Progress';
TicketStatus.ACTIVE = 'Active';
TicketStatus.ASSIGNED = 'Assigned';
TicketStatus.PENDING = 'Pending';
TicketStatus.CLOSED = 'Closed';
TicketStatus.RESOLVED = 'Resolved';
TicketStatus.CANCELED = 'Canceled';
