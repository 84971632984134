import React from 'react';
import { ReactComponent as Warning } from '../../assets/svg/warningTriangle.svg';
import Tooltip from '@material-ui/core/Tooltip';
import Consts from '../../consts/Consts';

const WarningIcon = ({ tooltipMessage, open, onOpen, onClose, className }) => {
  return (
    <>
      {tooltipMessage ? (
        <Tooltip
          title={tooltipMessage}
          placement="top"
          arrow
          open={open}
          onOpen={onOpen}
          onClose={onClose}
          interactive
        >
          <div
            className={`tooltip-ref-wrapper ${
              className ? className : Consts.EMPTY
            }`}
          >
            <Warning />
          </div>
        </Tooltip>
      ) : (
        <Warning />
      )}
    </>
  );
};
export default WarningIcon;
