import React from 'react';
import { connect, getIn, ErrorMessage } from 'formik';
import MTFormItemLabel from './MTFormItemLabel';
import MTFormItemError from './MTFormItemError';
import Consts from '../../../consts/Consts';
import { LightTheme } from '../../../consts/Colors';
import MTFormText from '../controls/MTFormText';
import CSS from '../../../consts/CSS';

/**
 *
 * @param {object} props - Props to be passed into the component
 * @param {string} props.id - Id for the field
 * @param {string} props.name - Name for the component in the form. Required for use with current custom Formik solution
 * @param {string} props.label - Label to be shown on the form component
 * @param {boolean} props.halfWidth - Should the field be hald the set width of its parent container
 * @param {boolean} props.isRequired - Is the field considered a required field
 * @param {React.ReactNode} props.children - Child to be shown in the error
 * @param {string} props.className - Classname for the field
 * @param {string} props.formik - Formik form id associated with this field
 * @param {string} props.showLabel - Should the label be shown for this component
 * @default true
 * @param {boolean} props.twoColumn - Should the component be set to be in a two column format on the parent container
 * @default false
 * @param {} props.shouldMoveLabel - Should the label adjust
 * @default false
 * @param {string} props.note - Standard sized note to be shown
 * @param {string} props.largeNote - Large sized note to be shown
 * @param {boolean} props.disabled - Should the field be disabled
 * @returns React form item component to be used with the current custom Formik solution
 */
const MTFormItem = ({
  id,
  name,
  label,
  halfWidth,
  isRequired,
  children,
  className,
  formik,
  showLabel = true,
  twoColumn = false,
  shouldMoveLabel = false,
  note,
  largeNote,
  disabled,
  ...props
}) => {
  const wasTouched = getIn(formik.touched, name);
  const hasError = wasTouched && getIn(formik.errors, name);

  return (
    <div
      {...props}
      className={`mtFormItem ${className} ${
        halfWidth ? 'halfWidth' : Consts.EMPTY
      } ${hasError ? 'error' : Consts.EMPTY} ${
        twoColumn ? 'twoColumn' : Consts.EMPTY
      } ${shouldMoveLabel ? 'moveLabel' : Consts.EMPTY} ${
        disabled ? 'disabled' : Consts.EMPTY
      }
      `}
      id={id}
    >
      {showLabel && (
        <MTFormItemLabel htmlFor={id} label={label} isRequired={isRequired} />
      )}
      {children}
      <ErrorMessage name={name} component={MTFormItemError} />
      {note ? <MTFormText reduceTopMargin>{note}</MTFormText> : null}
      {largeNote ? (
        <MTFormText className="mtLargeNote">{largeNote}</MTFormText>
      ) : null}

      <style jsx>
        {`
          .mtFormItem {
            flex-basis: 100%;
            position: relative;
          }

          .mtFormItem.twoColumn {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
          }

          .mtFormItem.twoColumn > :global(*:not(.formError)) {
            flex-basis: calc(50% - 10px);
          }

          .mtFormItem:not(:first-child) {
            margin-top: 25px;
          }

          .mtFormItem.moveLabel :global(label) {
            transform: translateY(15px);
            position: absolute;
            background-color: ${LightTheme.htmlBG};
            transition: transform 0.25s cubic-bezier(0.77, 0, 0.175, 1);
            transform-origin: 0;
            padding-left: 3px;
            padding-bottom: 1px;
            z-index: 1;
            border-radius: ${CSS.COMPONENT_BORDER_RADIUS};
          }

          .mtFormItem.shiftLabel :global(label) {
            transform: translate(3px, -12px) scale(0.75);
          }

          .mtFormItem.shiftDatePickerLabel :global(label) {
            margin-left: 40px;
          }

          .mtFormItem.disabled {
            opacity: 0.5;
          }

          .mtFormItem > :global(label) {
            pointer-events: none; /* Allows clicks to go thru when the label is over input/etc. */
          }

          .mtFormItem :global(.mtLargeNote) {
            font-size: 15px;
          }

          @media (min-width: 601px) {
            .mtFormItem.halfWidth {
              flex-basis: calc(50% - 10px);
            }
          }
        `}
      </style>
    </div>
  );
};

export default connect(MTFormItem);
