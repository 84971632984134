import axios from 'axios';
import APIs from '../../../../consts/APIs';
import { successFailure } from '../../../../utils/interceptorHelpers';
var parser = require('parse-address');

export const fetchDigitalTwinTechBarLocations = async loading => {
  try {
    const res = await axios.post(
      APIs.getAPIUrl(APIs.DIGITAL_TWIN_APIS.TECH_BAR_LOCATIONS),
      {
        headers: JSON.parse(
          JSON.stringify({
            ignoreGenericError: true,
            ignoreNetworkError: true,
          })
        ),
      }
    );

    loading(false);
    if (res?.data && res?.data?.length > 0) {
      localStorage.setItem(
        'techBarLocationsStorageData',
        JSON.stringify(res?.data)
      );
      return res.data;
    } else {
      return [];
    }
  } catch (err) {
    loading(false);
    return [];
  }
};

export const fetchTechBarLocations = async (
  upn,
  success,
  filterSuccess,
  failure,
  loading,
  unmountObj
) => {
  const query = {
    query: `{
        techBarLocations(userEmail:"${upn}") {
            number
            away_message
            name
            active
            description
            enable_away_state
        }
    }`,
  };
  return await axios
    .post(APIs.getAPIUrl(APIs.GRAPHQL), query, {
      headers: JSON.parse(
        JSON.stringify({
          ignoreGenericError: true,
          ignoreNetworkError: true,
        })
      ),
    })
    ?.then(res => {
      if (unmountObj.unmount) {
        return;
      }
      if (successFailure(res)) {
        failure(res);
      } else {
        //filters required locations
        const filteredRequiredLocations = getFilteredRequiredLocations(
          res.data.data.techBarLocations
        );
        const parsedTechBarLocations = getParsedTechBarLocations(
          filteredRequiredLocations
        );
        const sortedTechBarLocations = getSortedTechBarLocations(
          parsedTechBarLocations
        );
        success(sortedTechBarLocations);
        // updated filterTechBarLocations with the initial TechBarLocations
        filterSuccess(sortedTechBarLocations);
      }
      loading(false);
    })
    .catch(err => {
      if (unmountObj.unmount) {
        return;
      }
      failure(err);
      loading(false);
    });
};

export const getFilteredRequiredLocations = techBarLocations => {
  const requiredLocations = techBarLocations.filter(location => {
    const requiredDescriptionObject = isJsonObjectStructure(
      location?.description
    );
    return (
      Boolean(requiredDescriptionObject) &&
      requiredDescriptionObject.hasOwnProperty('mytech') &&
      requiredDescriptionObject.mytech === 'true'
    );
  });
  return requiredLocations;
};

export const getParsedTechBarLocations = techBarLocations => {
  const parsedLocations = techBarLocations.map(techBarLocation => {
    const techBarDescriptionObject = isJsonObjectStructure(
      techBarLocation?.description
    );
    const location = techBarDescriptionObject?.location;
    const parsedLocation = parser.parseLocation(location); //https://www.npmjs.com/package/parse-address
    return Object.assign(
      {},
      {
        ...techBarLocation,
        description: {
          ...techBarDescriptionObject,
          location: {
            ...parsedLocation,
          },
        },
      }
    );
  });
  return parsedLocations;
};

export const getSortedTechBarLocations = techBarLocations => {
  techBarLocations.sort(compareFn);
  const sortedTechBarLocations = techBarLocations.map(sortedLocation => {
    if (sortedLocation.name !== 'Virtual Tech Bar') {
      const address = Object?.values(
        sortedLocation?.description?.location
      )?.join(' '); //converting back the parsed location object to string here.
      return Object.assign(
        {},
        {
          ...sortedLocation,
          description: { ...sortedLocation.description, location: address },
        }
      );
    }
    return Object.assign({}, sortedLocation);
  });
  return sortedTechBarLocations;
};

export const isJsonObjectStructure = item => {
  item = typeof item !== 'string' ? JSON.stringify(item) : item;

  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }

  if (typeof item === 'object' && item !== null) {
    return item;
  }

  return false;
};

export const compareFn = (first, second) => {
  if (first.name === 'Virtual Tech Bar') {
    return -1;
  }
  if (second.name === 'Virtual Tech Bar') {
    return 1;
  }
  return (
    first?.description?.location?.state?.localeCompare(
      second?.description?.location?.state
    ) ||
    first?.description?.location?.city?.localeCompare(
      second?.description?.location?.city
    )
  );
};
