import React from 'react';
import NavItem from './NavItem';
import { Drawer, useMediaQuery, useTheme } from '@mui/material';
import { SideNavigation } from '@walmart-web/livingdesign-components';
import useSideNavigation from '../../customHooks/useSideNavigation';
import CSS from '../../consts/CSS';
import MTSideNavigation from './MTSideNavigation';

/*
Dev Notes:
LD3 does not have support for a Drawer component to handle the side nav opening and closing. 
Will continue use of Drawer from MUI until a component can be created.
*/

const customStyles = {
  drawerStyles: {
    width: CSS.NAV_MAX_WIDTH_MOBILE,
  },
};

const Nav = () => {
  const { isSideNavOpen, setIsSideNavOpen } = useSideNavigation();
  const theme = useTheme();
  const mobileNav = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      {mobileNav ? (
        <Drawer
          variant="temporary"
          open={isSideNavOpen}
          onClose={() => setIsSideNavOpen(!isSideNavOpen)}
          style={customStyles.drawerStyles}
        >
          <MTSideNavigation />
        </Drawer>
      ) : (
        <MTSideNavigation />
      )}
    </>
  );
};

export default Nav;
