import { Collapse } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import React, { useContext, useRef, useState } from 'react';
import AppContext from '../../app/context/AppContext';
import MicroFrontend from '../../components/misc/MicroFrontend';
import FlexPage from '../../components/page/FlexPage';
import EnvConfigs from '../../Configurations';
import MFEs from '../../consts/MFEs';
import Routes from '../../consts/Routes';
import UserType from '../../user/UserType';
import Consts from '../../consts/Consts';
import DashboardFeedbackDialog from '../../components/dialog/DashboardFeedbackDialog';
import {
  fetchOrderingFeedbackForUser,
  submitOrderingFeedbackForUser,
  hasUserSubmittedOrderingFeedback,
  hasUserClosedOrderingFeedback,
  setUserClosedOrderingFeedback,
  setFeedbackCollection,
} from './services/OrderingServices';
import FeedbackDialogV2 from '../../components/dialog/feedback/FeedbackDialogV2';
import Events from '../../consts/Events';

function Ordering() {
  const history = useHistory();
  const location = useLocation();

  const { enabledMFEs, setEnabledMFEs, setIsNewTicketCreated } = useContext(
    AppContext
  );

  const [feedbackDialog, toggleFeedbackDialog] = React.useState(false);
  const [starRatingDialog, toggleStarRatingDialog] = React.useState(false);
  const [ratingType, setRatingType] = useState(Consts.EMPTY);
  const [starRating, setStarRating] = useState(0);

  const orderSubmitted = useRef(false);

  const showMFE =
    location.pathname?.startsWith(Routes.ORDERING) &&
    enabledMFEs?.includes(MFEs.ORDERING);

  const openStarRating = async (ratingType, collection, isOrderSubmitted) => {
    const hasUserSubmittedFeedback = await fetchOrderingFeedbackForUser(
      collection
    );
    toggleStarRatingDialog(true);
    setRatingType(ratingType);
    setFeedbackCollection(collection);

    orderSubmitted.current = isOrderSubmitted;
    orderSubmitted.current &&
      hasUserSubmittedFeedback &&
      history.push(Routes.TICKETS);
  };

  const closeStarRating = () => {
    toggleStarRatingDialog(false);
    setFeedbackCollection(Consts.EMPTY);
    setRatingType(Consts.EMPTY);
    orderSubmitted.current && history.push(Routes.TICKETS);
  };

  const openFeedbackDialog = feedbackCollection => {
    setFeedbackCollection(feedbackCollection);
    toggleFeedbackDialog(true);
  };

  const closeFeedbackDialog = () => {
    setFeedbackCollection(Consts.EMPTY);
    toggleFeedbackDialog(false);
    setUserClosedOrderingFeedback(true);
  };

  return (
    <>
      <div className={showMFE ? 'main-content' : null}>
        <Collapse in={showMFE}>
          {feedbackDialog &&
          !hasUserSubmittedOrderingFeedback &&
          !hasUserClosedOrderingFeedback ? (
            <DashboardFeedbackDialog
              title={Consts.ORDERING_FEEDBACK.TITLE}
              question={Consts.ORDERING_FEEDBACK.QUESTION}
              isDialogOpen={feedbackDialog}
              closeDialog={closeFeedbackDialog}
              submitFeedback={(feedbackResponse, success, error) => {
                try {
                  submitOrderingFeedbackForUser(
                    feedbackResponse,
                    success,
                    error,
                    true
                  );
                } catch {}
              }}
              dashboard={Consts.ORDERING}
              isV2Design
            />
          ) : null}
          {starRatingDialog && !hasUserSubmittedOrderingFeedback && (
            <FeedbackDialogV2
              closeDialog={closeStarRating}
              starRating={starRating}
              setStarRating={setStarRating}
              upn={UserType.userData?.UserPrincipalName}
              dialogTrackingEvents={Events.ORDERING_FEEDBACK}
              ratingType={ratingType}
              submitFeedback={(feedbackResponse, success, error) => {
                try {
                  submitOrderingFeedbackForUser(
                    feedbackResponse,
                    success,
                    error,
                    true
                  );
                } catch {}
              }}
            />
          )}
          <FlexPage large hideHeader showHeader={false}>
            <MicroFrontend
              host={EnvConfigs.REACT_APP_ORDERING_HOST}
              proxyHost={EnvConfigs.REACT_APP_ORDERING_HOST}
              name={MFEs.ORDERING}
              mfeEnv={window.ORDERING.ENVIRONMENT}
              setMfeHost={() => {}}
              props={{
                history,
                fetchOrderingFeedbackForUser,
                openStarRating,
                setIsNewTicketCreated,
                openFeedbackDialog,
                impersonatedUser:
                  UserType?.userData?.isImpersonated &&
                  UserType.userData.UserPrincipalName
                    ? UserType.userData.UserPrincipalName
                    : null,
              }}
              handleSucess={() => {
                if (!enabledMFEs?.includes(MFEs.ORDERING)) {
                  setEnabledMFEs([...enabledMFEs, MFEs.ORDERING]);
                }
              }}
              handleError={() => {
                if (enabledMFEs?.includes(MFEs.ORDERING)) {
                  setEnabledMFEs(
                    enabledMFEs?.filter(fe => fe !== MFEs.ORDERING)
                  );
                }
              }}
            />
          </FlexPage>
        </Collapse>
        <style jsx>
          {`
            :global(main.mfe-container) {
              height: 100%;
            }
          `}
        </style>
      </div>
    </>
  );
}

export default Ordering;
