// Ignoring Sonarqube coverage for this file due to legacy code
import React, { useEffect, useState } from 'react';
import { Header } from './header/Header';
import Main from './main/Main';
import { GlobalStyles } from './styles/GlobalStyles';
import { Route, Switch, useLocation } from 'react-router-dom';
import LDLoading from '../components/misc/LDLoading';
import Config, { getEnabledMFEs } from '../utils/Config';
import WeDownErrorPage from '../components/error/WeDownErrorPage';
import ErrorBoundary from '../components/error/ErrorBoundary';
import { interceptRequests } from '../utils/interceptor';
import ErrorPage from '../components/error/ErrorPage';
import {
  initializeMyTechInsights,
  setUserContext,
  trackEvent,
} from '../utils/appInsight';
import axios from 'axios';
import APIs from '../consts/APIs';
import UserType from '../user/UserType';
import Auth from '../auth/Auth';
import {
  fetch2SVEnrollment,
  fetchSSPRVEnrollmentWithPasswordInfo,
} from '../pages/security/services/SecurityAccessServices';
import {
  fetchOrderData,
  fetchUserData,
  fetchDeviceDataGraph,
  fetchUserVDIData,
  fetchServiceNowTickets,
} from '../utils/fetchUsersData';
import { fetchVDIRequestData } from '../utils/fetchVDIRequestData';
import Events from '../consts/Events';
import ErrorHandler from '../utils/ErrorHandler';
import {
  set2SVWarning,
  isToShowSSPRWarning,
  setPasswordWarning,
} from '../utils/SecurityUtils';
import Login from '../pages/auth/Login';
import Routes from '../consts/Routes';
import AppContext from './context/AppContext';
import HowDidYouHearAboutUsDialog from './dialog/HowDidYouHearAboutUsDialog';
import Consts from '../consts/Consts';
import Errors from '../consts/Errors';
import MTLink from '../components/button/MTLink';
import fetchDynamicTextData from '../utils/fetchDynamicTextData';
import { isProd, getCurrentPhoneNumber } from '../utils/helpers';
import OrderStatus from '../utils/OrderStatus';
import TicketStatus from '../utils/TicketStatus';
import Filter from '../pages/tickets/components/Filter';
import _ from 'lodash';
import MFEs from '../consts/MFEs';
import URLs from '../consts/URLs';
import MicroFrontend from '../components/misc/MicroFrontend';
import Ld3ThemeProvider from './styles/Ld3ThemeProvider';
import ModuleProviders from './context/ModuleProviders';
import { setPageTitle } from '../utils/setPageTitle';

//JP: Seems "yup": "^0.32.8" doesn't support yup._exclusive.required anymore, used older verion from MT V1.
/* istanbul ignore next */
const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [guideError, setGuideError] = useState(false);
  const [errorType, setErrorType] = useState(Consts.GENERIC_ERROR);
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const [twoSVData, setTwoSVData] = useState(null);
  const [show2SVWarning, setShow2SVWarning] = useState(false);
  const [twoSVDataError, setTwoSVDataError] = useState(false);
  const [SSPRData, setSSPRData] = useState(null);
  const [showSSPRWarning, setShowSSPRWarning] = useState(false);
  const [showPasswordExpiryWarning, setShowPasswordExpiryWarning] = useState(
    false
  );

  const [SSPRDataError, setSSPRDataError] = useState(false);
  const [deviceDataGraph, setDeviceDataGraph] = useState(null);
  const [deviceDataError, setDeviceDataError] = useState(false);
  const [vdiData, setVDIData] = useState(null);
  const [vdiDataError, setVDIDataError] = useState(false);
  const [vdiRequestData, setVDIRequestData] = useState(null);
  const [isOnPremVDI, setIsOnPremVDI] = useState(true);
  const [isVDIRequestDataDirty, setIsVDIRequestDataDirty] = useState(true); //Start true to load the first time
  const [isVDIRequestDataLoading, setIsVDIRequestDataLoading] = useState(false);
  const [orderData, setOrderData] = useState(null);
  const [orderDataError, setOrderDataError] = useState(false);
  const [totalActiveOrders, setTotalActiveOrders] = useState(null);
  const [isOrderPlaced, setOrderPlaced] = useState(false);
  const [isNewTicketCreated, setIsNewTicketCreated] = useState(false);
  const [ticketFiltersData, setTicketFiltersData] = useState(null);
  const [ticketFiltersDataError, setTicketFiltersDataError] = useState(null);
  const [isHowDidYouHearAboutUsOpen, setHowDidYouHearAboutUsOpen] = useState(
    false
  );

  const [replacement, setReplacement] = useState(false);
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState(null);
  const [replacementType, setReplacementType] = useState('');
  const [deviceDetails, setDeviceDetails] = useState([]);
  const [totalActiveTickets, setTotalActiveTickets] = useState(null);
  const [ticketData, setTicketData] = useState(null);
  const [ticketDataError, setTicketDataError] = useState(false);
  const [ticketDataListError, setTicketDataListError] = useState([]);
  const [hasRequestedPrimaryData, setHasRequestedPrimaryData] = useState(false);
  const [
    hasAttemptedNewHireRedirect,
    setHasAttemptedNewHireRedirect,
  ] = useState(false);
  const [isOnInitialRoute, setOnInitialRoute] = useState(null);
  const [isInLiteMode, setIsInLiteMode] = useState(false);

  const [newHireTipsConfig, setNewHireTipsConfig] = useState(null);
  const [newHireTipsConfigError, setNewHireTipsConfigError] = useState(false);
  const [VDIConfig, setVDIConfig] = useState(null);
  const [VDIConfigError, setVDIConfigError] = useState(false);
  const [isVDIDataLoaded, setIsVDIDataLoaded] = useState(false);
  const [isNewHireDataLoaded, setIsNewHireDataLoaded] = useState(false);

  const [helpCenterVideoConfigData, setHelpCenterVideoConfigData] = useState(
    []
  );
  const [helpCenterVideoConfigError, setHelpCenterVideoConfigError] = useState(
    false
  );

  const { pathname, search } = window.location;

  const isVista =
    navigator.userAgent && navigator.userAgent.includes('Windows NT 6.0');

  const location = useLocation();

  const [searchResult, setSearchResult] = useState(null);
  const [showSearchFeedback, setShowSearchFeedback] = useState(false); //control to hide/show feedback on navigation to next page
  const [loadingGuideToggles, toggleLoadingGuideToggles] = useState(false);

  const [enabledMFEs, setEnabledMFEs] = useState(getEnabledMFEs());

  // useVideoCarousel Hook global state
  const [showVideoCarouselDialog, _setShowVideoCarouselDialog] = useState(
    false
  );
  const [videoCarouselCurrentVideo, _setVideoCarouselCurrentVideo] = useState(
    Consts.VIDEO_CAROUSEL_CURRENT_VIDEO_INIT_STATE
  );

  useEffect(() => {
    setEnabledMFEs(getEnabledMFEs());
  }, [Config.isAppStoreModuleEnabled, Config.isOrderingModuleEnabled]);

  const loadPrimaryData = async () => {
    const user = Auth.getUser();
    const upn = user[Consts.JWT_FIELD_NAMES.UPN];

    setUserContext(upn);

    //Load Config Features list
    await Config.fetchAppConfig().catch(() => {
      setHasError(true);
    });

    // Load Guide Toggles
    toggleLoadingGuideToggles(true);
    await Config.fetchGuideToggles(upn).catch(() => {
      setGuideError(true);
    });
    toggleLoadingGuideToggles(false);

    if (Config.isLiteModeSupportEnabled) {
      const userId = user.userId;
      const resolvedOUName = user.resolvedOUName;
      const domain = user.domain;
      const email = user.email;
      const displayName = user.displayName;

      await UserType.preInit(
        userId,
        upn,
        resolvedOUName,
        domain,
        email,
        displayName
      );
    }
    if (window.env.REACT_APP_IS_PROD) {
      setIsLoading(false);
    }
    //Load User data
    const fetchUserDataContinue = bypassLoadingDevices => {
      if (!window.env.REACT_APP_IS_PROD) {
        setIsLoading(false);
      }
      if (Config.isLiteModeSupportEnabled) {
        loadSecondaryData(bypassLoadingDevices);
      } else {
        loadSecondaryData(false);
      }
    };

    const fetchUserDataFailure = () => {
      if (Config.isLiteModeSupportEnabled) {
        setIsInLiteMode(true);
        fetchUserDataContinue(true); //Skip call, since My Devices relies on some non-Lite flows
        trackEvent('MyTech Lite - Activated');
      } else {
        setHasError(true);
      }
    };

    await fetchUserData(upn, fetchUserDataFailure).then(() => {
      fetchUserDataContinue();
    });
  };

  //bypassLoadingDevices is passed true when CIP is down, since there's no need to try to load the devices.
  const loadSecondaryData = async bypassLoadingDevices => {
    if (!isProd() && !Config.hasNonProdAccess) {
      window.location.href = `${URLs.MYTECH_PROD}${location.pathname}`;
    }
    if (!Config.isAppNotAvailable) {
      // Load User Devices
      if (bypassLoadingDevices) {
        //Show can't load data error
        setDeviceDataError(true);
      } else {
        fetchDeviceDataGraph(
          UserType.userPrincipalName,
          data => {
            // Decode number here
            data.AirWatchInformation.forEach(obj => {
              if (obj.PhoneNumber) {
                obj.PhoneNumber = atob(obj.PhoneNumber);
              }
            });
            setCurrentPhoneNumber(
              getCurrentPhoneNumber(data.AirWatchInformation)
            );
            setDeviceDataGraph(data);
          },
          () => {
            setDeviceDataError(true);
          }
        );
        // Call to gather and set VDIs to context
        fetchUserVDIData(
          UserType.userId,
          data => {
            setVDIData(data);
            data?.DWSInformation[0]?.Location?.Provider &&
              setIsOnPremVDI(
                data.DWSInformation[0].Location.Provider !== 'Azure'
              );
            UserType.setHasVDIDevices(data);
          },
          error => {
            setVDIDataError(true);
          }
        );
      }

      //Load User Orders
      setOrderPlaced(true);

      //Load Tickets filters config
      fetchDynamicTextData(
        'myTicketsFilters',
        res => {
          setTicketFiltersData(res);
          //Load serviceNow Tickets
          setIsNewTicketCreated(true);
        },
        err => {
          setTicketFiltersDataError(true);
        }
      );

      //WARNING: helpCenterVideosConfig is loaded globally, bc they need to be Searchable. If additional videos are added that don’t need to be searchable (like New Hire videos), the config shouldn’t be loaded in App.js to reduce on-load requests.

      //Load Video JSON Config - Help Center
      fetchDynamicTextData(
        'helpCenterVideosConfig',
        res => {
          // Update by appling existing video configs data with new hire video data
          setHelpCenterVideoConfigData(res);
        },
        err => {
          setHelpCenterVideoConfigError(true);
        }
      );

      //Load Security Access & Password data
      if (Config.is2SVEnabled) {
        fetch2SVEnrollment(
          data => {
            setTwoSVData(data);
            if (Config.is2SVLeftNavWarningEnabled) {
              const isVendor =
                data?.data === '2SV data is not available for vendors';
              if (!isVendor) {
                setShow2SVWarning(set2SVWarning(data));
              }
            }
          },
          error => {
            setTwoSVDataError(true);
          }
        );
      }
      if (Config.isSSPREnabled || Config.isPasswordInfoEnabled) {
        fetchSSPRVEnrollmentWithPasswordInfo(
          data => {
            setSSPRData(data);

            if (Config.isSSPRLeftNavWarningEnabled) {
              if (Config.isSSPREnabled) {
                setShowSSPRWarning(isToShowSSPRWarning(data.ssprData));
              }
              if (Config.isPasswordInfoEnabled) {
                setShowPasswordExpiryWarning(setPasswordWarning(data));
              }
            }
          },
          error => {
            setSSPRDataError(true);
          }
        );
      }

      //Fetch How Did You Hear About Us
      if (Config.isHowDidYouHearAboutUsEnabled) {
        axios
          .post(APIs.getAPIUrl(APIs.GRAPHQL), {
            query: `{
                  isHowDidYouHearAboutSubmitted(userEmail: "${UserType.userPrincipalName}")
                }`,
          })
          .then(res => {
            setHowDidYouHearAboutUsOpen(
              !res.data.data.isHowDidYouHearAboutSubmitted
            );
          })
          .catch();
      }
    }
  };

  const loadNewHireConfig = async () => {
    if (Config.isNewHireEnabled) {
      fetchDynamicTextData(
        'newHireGallery',
        config => {
          if (config) {
            setNewHireTipsConfig(config.filter(item => !item.disabled));
          }
        },
        () => {
          setNewHireTipsConfigError(true);
        }
      );
    }
  };

  const loadVDIConfig = async () => {
    if (Config.isVDIEnabled) {
      fetchDynamicTextData(
        'virtualPCs',
        config => {
          if (config) {
            setVDIConfig(config);
          }
        },
        () => {
          setVDIConfigError(true);
        }
      );
    }
  };

  //For New Hire, on page load only redirect to Routes.NEW_HIRE if on Routes.HOMEPAGE
  useEffect(() => {
    if (
      !isLoading && //Wait until loading is done, so the UserType object is setup.
      location &&
      !hasAttemptedNewHireRedirect && //Ensure only runs once
      location.pathname === Routes.HOMEPAGE
    ) {
      setHasAttemptedNewHireRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]); //Only using isLoading, so it happens just once when setIsLoading(false) is called

  useEffect(() => {
    if (!loadingGuideToggles) {
      if (Config.isAdminSiteEnabled || UserType?.userData?.isImpersonated) {
        Config.fetchQAUsers();
      }
    }
  }, [loadingGuideToggles]);

  useEffect(() => {
    if (
      !isLoading && //Wait until loading is done, so the Config object is setup.
      !loadingGuideToggles &&
      location &&
      location.pathname
    ) {
      //The first time /vdi route is accessed, fetch the VDI Requests data.
      if (isVDIRequestDataDirty && location.pathname.startsWith(Routes.VDI)) {
        setIsVDIRequestDataLoading(true);
        fetchVDIRequestData(
          UserType.userPrincipalName,
          data => {
            setVDIRequestData(data);
            setIsVDIRequestDataLoading(false);
          },
          error => {
            ErrorHandler.trackError(
              Events.ERRORS.TITLE.VDI_REQUEST_DATA,
              error
            );
            setIsVDIRequestDataLoading(false);
          }
        );
        setIsVDIRequestDataDirty(false);
      }

      //The first time a /newHire route is accessed, fetch the New Hire Gallery Config
      if (
        location.pathname.startsWith(Routes.NEW_HIRE_GALLERY) &&
        !isNewHireDataLoaded
      ) {
        loadNewHireConfig();
        setIsNewHireDataLoaded(true);
      }

      //The first time a /vdi route is accessed, fetch the VDI data.
      if (location.pathname.startsWith(Routes.VDI) && !isVDIDataLoaded) {
        loadVDIConfig();
        setIsVDIDataLoaded(true);
      } else {
        setVDIConfigError(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, loadingGuideToggles, location]);

  useEffect(() => {
    if (
      !hasRequestedPrimaryData &&
      isAuthenticated &&
      location.pathname &&
      location.pathname !== Routes.LOGIN
    ) {
      setIsLoading(true); //Defaults to true, but explicitly sets it before loading primary data
      setHasRequestedPrimaryData(true);
      loadPrimaryData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, location]);

  //handle initial route logic
  useEffect(() => {
    setPageTitle(location.pathname);
    if (location && location.pathname && location.pathname !== Routes.LOGIN) {
      if (isOnInitialRoute === null) {
        setOnInitialRoute(true);
      } else {
        setOnInitialRoute(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // Order list Update UE
  useEffect(() => {
    // If order data has not already been pulled before, set var to have spark show until data is pulled
    if (isOrderPlaced) {
      fetchOrderData(
        UserType.userPrincipalName,
        data => {
          if (data.order) {
            setOrderData(data.order);
            //Count active orders
            const activeOrders = OrderStatus.getTrackedOrdersArray(data.order);
            setTotalActiveOrders(activeOrders.length);
            setOrderPlaced(false);
          }
        },
        error => {
          setOrderDataError(true);
          setOrderPlaced(false);
        }
      );
    }
  }, [isOrderPlaced]);

  // Ticket list Update UE
  useEffect(() => {
    // If order data has not already been pulled before, set var to have spark show until data is pulled
    if (isNewTicketCreated) {
      const filterData = [];
      ticketFiltersData &&
        ticketFiltersData.forEach(filter => {
          filterData.push({
            id: filter.id,
            facets: Filter.getInitialValues(filter.facets),
          });
        });

      fetchServiceNowTickets(
        UserType.userPrincipalName,
        filterData.length ? filterData : null,
        data => {
          if (data.tickets) {
            setTicketData(data.tickets);
            setTotalActiveTickets(
              TicketStatus.getOpenTicketsCount(data.tickets)
            );
          }
          if (data.errors) {
            setTicketDataListError(data.errors);
          }
          setIsNewTicketCreated(false);
        },
        error => {
          setTicketDataError(true);
          setIsNewTicketCreated(false);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewTicketCreated]);

  useEffect(() => {
    initializeMyTechInsights();
    interceptRequests(setHasError, setErrorType);

    if (!isVista) {
      if (pathname !== Routes.LOGIN && Auth.isSessionActive()) {
        setIsAuthenticated(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GlobalStyles />
      <ErrorBoundary isVista={isVista}>
        <Ld3ThemeProvider>
          <AppContext.Provider
            value={{
              twoSVData: twoSVData,
              SSPRData: SSPRData,
              show2SVWarning: show2SVWarning,
              showSSPRWarning: showSSPRWarning,
              setShowSSPRWarning: setShowSSPRWarning,
              showPasswordExpiryWarning: showPasswordExpiryWarning,
              twoSVDataError: twoSVDataError,
              SSPRDataError: SSPRDataError,
              deviceDataGraph: deviceDataGraph,
              vdiData: vdiData,
              setVDIData: setVDIData,
              vdiRequestData: vdiRequestData,
              setVDIRequestData: setVDIRequestData,
              setIsVDIRequestDataDirty: setIsVDIRequestDataDirty,
              isVDIRequestDataLoading: isVDIRequestDataLoading,
              deviceDetails: deviceDetails,
              setDeviceDetails: setDeviceDetails,
              deviceDataError: deviceDataError,
              isOnPremVDI: isOnPremVDI,
              setIsOnPremVDI: setIsOnPremVDI,
              vdiDataError: vdiDataError,
              setVDIDataError: setVDIDataError,
              orderData: orderData,
              setOrderData: setOrderData,
              orderDataError: orderDataError,
              setOrderDataError: setOrderDataError,
              totalActiveOrders: totalActiveOrders,
              setTotalActiveOrders: setTotalActiveOrders,
              isOrderPlaced: isOrderPlaced,
              setOrderPlaced: setOrderPlaced,
              replacement: replacement,
              setReplacement: setReplacement,
              currentPhoneNumber: currentPhoneNumber,
              setCurrentPhoneNumber: setCurrentPhoneNumber,
              replacementType: replacementType,
              setReplacementType: setReplacementType,
              totalActiveTickets: totalActiveTickets,
              ticketData: ticketData,
              setTicketData: setTicketData,
              ticketDataError: ticketDataError,
              setTicketDataError: setTicketDataError,
              ticketDataListError: ticketDataListError,
              setTicketDataListError: setTicketDataListError,
              isNewTicketCreated: isNewTicketCreated,
              setIsNewTicketCreated: setIsNewTicketCreated,
              ticketFiltersData: ticketFiltersData,
              ticketFiltersDataError: ticketFiltersDataError,
              isOnInitialRoute: isOnInitialRoute,
              isInLiteMode: isInLiteMode,
              newHireTipsConfig: newHireTipsConfig,
              setNewHireTipsConfig: setNewHireTipsConfig,
              newHireTipsConfigError: newHireTipsConfigError,
              setNewHireTipsConfigError: setNewHireTipsConfigError,
              VDIConfig: VDIConfig,
              VDIConfigError: VDIConfigError,
              helpCenterVideoConfigData: helpCenterVideoConfigData,
              helpCenterVideoConfigError: helpCenterVideoConfigError,
              enabledMFEs,
              setEnabledMFEs,
              searchResult,
              isSideNavOpen,
              setIsSideNavOpen,
              showVideoCarouselDialog,
              _setShowVideoCarouselDialog,
              videoCarouselCurrentVideo,
              _setVideoCarouselCurrentVideo,
            }}
          >
            <main id="app">
              {isVista ? (
                <ErrorPage title={Errors.VISTA_ERROR} />
              ) : hasError && errorType === Consts.GENERIC_ERROR ? (
                <WeDownErrorPage
                  errorData={{ description: 'App.js Generic error' }}
                />
              ) : hasError && errorType === Consts.NETWORK_ERROR ? (
                <ErrorPage title={Errors.NETWORK_ERROR}>
                  <div className="error-link-wrapper">
                    <MTLink
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      Refresh
                    </MTLink>
                  </div>
                </ErrorPage>
              ) : hasError && errorType === Consts.TOKEN_ERROR ? (
                <ErrorPage title={Errors.TOKEN_401}>
                  <div className="error-link-wrapper">
                    <MTLink
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      Refresh
                    </MTLink>
                  </div>
                </ErrorPage>
              ) : Config.isAppNotAvailable === true ? (
                <ErrorPage title={Config.broadcastMessage} />
              ) : (
                <Switch>
                  <Route exact path={Routes.LOGIN}>
                    <Login
                      search={search}
                      setHasError={setHasError}
                      setIsAuthenticated={setIsAuthenticated}
                    />
                  </Route>
                  <Route>
                    {isLoading || loadingGuideToggles ? (
                      <LDLoading />
                    ) : (
                      <>
                        {pathname !==
                          `${Routes.APPS}${Routes.ONBOARD_LICENSE}` && (
                          <Header
                            setSearchResult={setSearchResult}
                            setShowSearchFeedback={setShowSearchFeedback}
                          />
                        )}
                        <ModuleProviders>
                          <Main
                            searchResult={searchResult}
                            setSearchResult={setSearchResult}
                            showSearchFeedback={showSearchFeedback}
                            setShowSearchFeedback={setShowSearchFeedback}
                          />
                          {isHowDidYouHearAboutUsOpen ? (
                            <HowDidYouHearAboutUsDialog
                              isHowDidYouHearAboutUsOpen={
                                isHowDidYouHearAboutUsOpen
                              }
                              setHowDidYouHearAboutUsOpen={
                                setHowDidYouHearAboutUsOpen
                              }
                            />
                          ) : null}
                        </ModuleProviders>
                      </>
                    )}
                  </Route>
                </Switch>
              )}

              {Config.isAppStoreModuleEnabled &&
                location.pathname.includes(Routes.APPS) && (
                  <MicroFrontend name={MFEs.APPSTORE} container />
                )}
              {Config.isOrderingModuleEnabled &&
                location.pathname.includes(Routes.ORDERING) && (
                  <MicroFrontend name={MFEs.ORDERING} container />
                )}
              {Config.isHolidayReadinessModuleEnabled &&
                location.pathname.includes(Routes.HOLIDAY_READINESS) && (
                  <MicroFrontend name={MFEs.HOLIDAYREADINESS} container />
                )}
            </main>
          </AppContext.Provider>
        </Ld3ThemeProvider>
      </ErrorBoundary>
    </>
  );
};

export default App;
