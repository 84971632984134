import React from 'react';
import * as Yup from 'yup';
import MTTextarea from '../controls/MTTextarea';
import Consts from '../../../consts/Consts';
import FormErrors from '../../../consts/FormErrors';

const MTOtherTextArea = ({ name, initialValue, halfWidth, yup, maxLength }) => {
  return (
    <React.Fragment>
      <MTTextarea
        label="Other"
        showLabel={false}
        name={name}
        initialValue={initialValue}
        halfWidth={halfWidth}
        maxLength={maxLength}
        yup={yup}
        showCharCount={false}
      />
      <style jsx>
        {`
          :global(.mtFormItem.mtTextarea) {
            flex-basis: 60%;
            margin-left: 20%;
          }
          :global(.mtTextarea textarea) {
            min-height: 60px;
            border: 1px solid #dadce0;
          }
          @media screen and (max-width: 480px) {
            :global(.mtFormItem.mtTextarea) {
              flex-basis: 94%;
              margin-left: 3%;
            }
          }
        `}
      </style>
    </React.Fragment>
  );
};

MTOtherTextArea.defaultProps = {
  name: 'otherText',
  initialValue: Consts.EMPTY,
  yup: Yup.string().trim().required(FormErrors.OTHER_TEXT),
};

export default MTOtherTextArea;
