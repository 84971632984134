import { lazy } from 'react';
import { Redirect, Route } from 'react-router-dom';
import 'array-flat-polyfill';
import Auth from '../auth/Auth';
import ErrorHandler from '../utils/ErrorHandler';
import Events from './Events';
import Consts from './Consts';

const Routes = {
  HOMEPAGE: '/',
  DEVICES: '/devices',
  MOBILE: '/mobile',
  SUBMITATICKET: '/submit-a-ticket',
  MOBILE_EAGLE_HOME_DEVICE: '/mobile/eaglehomedevice/',
  MOBILE_EAGLE_HOME_DEVICE_MYSELF:
    '/mobile/eagleHomeDevice/eaglehomedevicemyself',
  MOBILE_CORPORATE: '/mobile/corporate',
  MOBILE_CORPORATE_MYSELF: '/mobile/corporate/myself',
  MOBILE_CORPORATE_ANOTHER: '/mobile/corporate/another',
  MOBILE_LOANER: '/mobile/loaner',
  MOBILE_LOANER_MYSELF: '/mobile/loaner/myself',
  MOBILE_LOANER_ANOTHER: '/mobile/loaner/another',
  MOBILE_TABLET: '/mobile/tablet',
  MOBILE_TABLET_SUPPLY_CHAIN: '/mobile/tablet/supplychain',
  MOBILE_TABLET_HOME_OFFICE: '/mobile/tablet/homeoffice',
  MOBILE_TABLET_STORE_CLUB_DEPARTMENT: '/mobile/tablet/storeclubdepartment',
  MOBILE_HOTSPOT: '/mobile/hotspot',
  MOBILE_HOTSPOT_MYSELF: '/mobile/hotspot/myself',
  MOBILE_INTERNATIONAL: '/mobile/international',
  MOBILE_INTERNATIONAL_MYSELF: '/mobile/international/myself',
  MOBILE_INTERNATIONAL_ANOTHER: '/mobile/international/another',
  MOBILE_CANCEL: '/mobile/cancel',
  MOBILE_CANCEL_MYSELF: '/mobile/cancel/myself',
  MOBILE_CANCEL_ANOTHER: '/mobile/cancel/another',
  SECURITY: '/security',
  SSPR_SUPPORT: '/sspr/support',
  SHIPMENT_TRACKER: '/shipmenttracker',
  SIM: '/sim',
  SIM_ORDERING: '/sim/ordering',
  SIM_ACTIVATION: '/sim/activation',
  ADMIN: '/admin',
  ADMIN_MOCK_DATA_MYSELF: '/admin/mymockdata',
  ADMIN_MOCK_DATA_ANOTHER: '/admin/mockdata',
  ADMIN_ORDERS: '/admin/orders',
  ADMIN_CONFIGS: '/admin/configs',
  ADMIN_USERS: '/admin/users',
  ADMIN_IMPERSONATE_MYSELF: '/admin/impersonatemyself',
  LOGIN: '/login',
  APPS: '/apps',
  APPSPAGE: `/apps/${Consts.ROUTE_PARAM}?`,
  APPSTORE: 'store',
  APPREQUESTS: 'requests',
  APPAPPROVALS: 'approvals',
  APPLINKS: 'more-links',
  APPMANAGEMENT: 'manage',
  LICENSE_SETTINGS: `/apps/settings/${Consts.ROUTE_PARAM}`,
  ORDERING: '/ordering',
  ORDERING_HARDWARE: 'hardware',
  HARDWARE_ORDERING: '/ordering/hardware',
  HARWARE_ORDERING_LAPTOP: 'laptop',
  HARWARE_ORDERING_ASSISTIVE_TECH: 'assistive-tech',
  ONBOARD_LICENSE: '/onboardproduct', // NEW
  NEW_HIRE: '/newhire',
  NEW_HIRE_SECURITY_ONBOADRING: '/newhire/securityonboarding',
  NEW_HIRE_SECURITY_ONBOADRING_MAC_PASSWORD:
    '/newhire/securityonboarding/mac/passwordstrength',
  NEW_HIRE_SECURITY_ONBOADRING_WINDOWS_PASSWORD:
    '/newhire/securityonboarding/pc/passwordstrength',
  NEW_HIRE_SECURITY_ONBOADRING_SECURITY_SETTINGS:
    '/newhire/securityonboarding/securitysettings',
  NEW_HIRE_GALLERY: '/newhire/gallery',
  NEW_HIRE_GALLERY_IMAGE: `/newhire/gallery/${Consts.ROUTE_PARAM}`,
  NEW_HIRE_CISCO_ANYCONNECT: '/newhire/cisco_anyconnect',
  USER_LOOKUP: '/userlookup',
  VDI: '/vdi',
  VDI_REQUEST_FORM: '/vdi/request',
  VDI_REQUEST_COMPLETION: '/vdi/request/confirmation',
  HELP_CENTER: '/helpcenter',
  HELP_CENTER_CALL_US: '/helpcenter/call',
  HELP_CENTER_TALK_TO_EXPERT: '/helpcenter/tech-bar-locations',
  TICKETS: '/helpcenter/tickets',
  TICKETS_SUBMIT: '/helpcenter/submit-a-ticket',
  TOPIC_WINDOWS: '/helpcenter/pc',
  TOPIC_MAC: '/helpcenter/mac',
  TOPIC_MOBILE: '/helpcenter/mobile',
  // Decommissioned route. used for redirect to TOPIC_CONFERENCE_COLLABORATION_TOOLS
  TOPIC_HYBRID_MEETINGS: '/helpcenter/hybridmeetings',
  TOPIC_HYBRID_MEETINGS_MICROSOFT_TEAMS: '/helpcenter/hybridmeetings/teams',
  TOPIC_HYBRID_MEETINGS_ZOOM: '/helpcenter/hybridmeetings/zoom',
  TOPIC_HYBRID_MEETINGS_VIDEO_CONFERENCING:
    '/helpcenter/hybridmeetings/video-conferencing',
  // ---------------------------------------------
  TOPIC_WORKPLACE: '/helpcenter/hybridmeetings/workplace',
  TOPIC_CONFERENCE_COLLABORATION_TOOLS:
    '/helpcenter/conference-collaboration-tools',
  TOPIC_HOME_TOPIC_CONFERENCE_COLLABORATION_TOOLS:
    '/conference-collaboration-tools',
  TOPIC_WORK_IN_OFFICE: '/helpcenter/working-in-office',
  TOPIC_WORK_FROM_HOME: '/helpcenter/work-from-home',
  TOPIC_MICROSOFT_TEAMS: '/helpcenter/conference-collaboration-tools/teams',
  TOPIC_ZOOM: '/helpcenter/conference-collaboration-tools/zoom',
  TOPIC_VIDEO_CONFERENCING:
    '/helpcenter/conference-collaboration-tools/video-conferencing',
  TOPIC_WORKPLACE: '/helpcenter/conference-collaboration-tools/workplace',
  TOPIC_VDI: '/vdi',
  TOPIC_RETURN_TO_OFFICE: '/helpcenter/return-to-office',
  TOPIC_RETURN_TO_OFFICE_WINDOWS: '/helpcenter/return-to-office/pc',
  TOPIC_RETURN_TO_OFFICE_MAC: '/helpcenter/return-to-office/mac',
  TOPIC_RETURN_TO_OFFICE_MOBILE: '/helpcenter/return-to-office/mobile',
  TOPIC_RETURN_TO_OFFICE_VDI: '/helpcenter/return-to-office/vdi',
  TOPIC_HOLIDAY_READINESS: '/helpcenter/holiday-readiness',
  ARTICLE_TECH_SUPPORT: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/techsupport`,
  ARTICLE_TECH_BAR: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/techbar`,
  ARTICLE_LINKS: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/links`,
  ARTICLE_CONTACTS: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/contacts`,
  ARTICLE_SHAREPOINT_GENERAL: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/sharepoint/general`,
  ARTICLE_SHAREPOINT_SHARING: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/sharepoint/sharing`,
  ARTICLE_SHAREPOINT_PERMISSIONS: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/sharepoint/permissions`,
  ARTICLE_SHAREPOINT_MOBILE: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/sharepoint/mobile`,
  ARTICLE_SHAREPOINT_RECOVER: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/sharepoint/recover`,
  ARTICLE_ONEDRIVE_ABOUT: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/onedrive/about`,
  ARTICLE_ONEDRIVE_TIPS: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/onedrive/tips`,
  ARTICLE_ONEDRIVE_VS_SHAREPOINT: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/onedrive/vssharepoint`,
  ARTICLE_ONEDRIVE_RECOVER: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/onedrive/recover`,
  ARTICLE_ONEDRIVE_RESTORE: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/onedrive/restore`,
  ARTICLE_ONEDRIVE_SIGN_IN: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/onedrive/signin`,
  ARTICLE_ONEDRIVE_SHARING: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/onedrive/sharing`,
  ARTICLE_ONEDRIVE_SYNC_MAC: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/onedrive/syncmac`,
  ARTICLE_OFFICE_PROMPTS: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/office/prompts`,
  ARTICLE_OUTLOOK_ZOOM: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/outlook/zoom`,
  ARTICLE_OUTLOOK_LOGIN: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/outlook/login`,
  ARTICLE_OUTLOOK_MAP_MAILBOX_MAC: '/helpcenter/mac/outlook/mapmailbox',
  ARTICLE_OUTLOOK_MAP_MAILBOX_WINDOWS: '/helpcenter/pc/outlook/mapmailbox',
  ARTICLE_OUTLOOK_NOT_SYNCING: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/outlook/notsyncing`,
  ARTICLE_CONNECTIVITY_WFH: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/connectivity/wfh`,
  ARTICLE_CONNECTIVITY_ETHERNET: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/connectivity/ethernet`,
  ARTICLE_CONNECTIVITY_VPN: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/connectivity/vpn`,
  ARTICLE_CONNECTIVITY_WIFI_ISSUE_WINDOWS:
    '/helpcenter/pc/connectivity/wifiissue',
  ARTICLE_CONNECTIVITY_VPN_ISSUE_WINDOWS:
    '/helpcenter/pc/connectivity/vpnissue',
  ARTICLE_PASSWORD_CHANGE_MAC: '/helpcenter/mac/password/change',
  ARTICLE_PASSWORD_CHANGE_WINDOWS: '/helpcenter/pc/password/change',
  ARTICLE_PASSWORD_SSPR: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/password/sspr`,
  ARTICLE_PASSWORD_REQUIRMENTS: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/password/requirements`,
  ARTICLE_PASSWORD_SSPR_FAQS: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/password/ssprfaqs`,
  ARTICLE_PASSWORD_EXPIRED_WINDOWS: '/helpcenter/pc/password/expired',
  ARTICLE_CHROME_CACHE: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/chrome/cache`,
  ARTICLE_CHROME_DEFAULT_WINDOWS: '/helpcenter/pc/chrome/default',
  ARTICLE_THIRD_PARTY_TABLEAU: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/thirdparty/tableau`,
  ARTICLE_WINDOWS_COMPUTER_NAME: '/helpcenter/pc/computername',
  ARTICLE_WINDOWS_BITLOCKER: '/helpcenter/pc/bitlocker',
  ARTICLE_WINDOWS_MAP_PRINTER: '/helpcenter/pc/mapprinter',
  ARTICLE_WINDOWS_MAP_DRIVE: '/helpcenter/pc/mapdrive',
  ARTICLE_MAC_SELF_SERVICE: '/helpcenter/mac/selfservice',
  ARTICLE_MAC_MAP_PRINTER: '/helpcenter/mac/mapprinter',
  ARTICLE_MAC_ADMIN: '/helpcenter/mac/admin',
  ARTICLE_2FA_YUBIKEY: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/twofactor/yubikey`,
  ARTICLE_2FA_YUBIKEY_ERRORS: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/twofactor/yubikeyerrors`,
  ARTICLE_2FA_YUBIKEY_ENROLLMENT: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/twofactor/yubikeyenroll`,
  ARTICLE_2FA_SOFT_TOKEN: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/twofactor/softtoken`,
  ARTICLE_2FA_VPN: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/twofactor/vpn`,
  ARTICLE_2FA_SETUP: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/twofactor/setup`,
  ARTICLE_2FA_FAQS: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/twofactor/faqs`,
  ARTICLE_MOBILE_SETUP: '/helpcenter/mobile/setup',
  MANAGER_DASHBOARD: '/manager',
  HOLIDAY_READINESS: '/holiday-readiness',
  ARTICLE_HELPFUL_LINKS: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/helpfullinks`,
  ARTICLE_ADMIN_ACCESS_REQUEST: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/adminaccessrequest`,
  ARTICLE_PRINT_SUPPORT: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/printsupport`,
  ARTICLE_PASSWORD_AND_ACCOUNT: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/passwordandaccount`,
  ARTICLE_CLOUD_STORAGE: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/cloudstorage`,
  ARTICLE_CONNECTIVITY: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/connectivity`,
  ARTICLE_MESSAGING_AND_EMAIL: `/helpcenter/:name${Consts.ARTICLE_PARAM_RESTRICTION}/messagingandemail`,
};

const cachedPageMap = new Map();

const getLazyPage = (path, module) => {
  if (cachedPageMap.has(path)) {
    return cachedPageMap.get(path);
  }

  let LazyFunc = () => import(`../pages/${path}`);
  if (module) {
    LazyFunc = () => import(`../modules/${path}`);
  }
  const LazyPage = lazy(() => componentLoader(LazyFunc, Consts.MAX_RETRY));
  cachedPageMap.set(path, LazyPage);

  return LazyPage;
};

const componentLoader = (lazyComponent, retryLimit) => {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retryLimit === 0) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, retryLimit - 1).then(resolve, reject);
        }, 100);
      });
  });
};

export const getRoutes = pages => {
  return pages.map(page => {
    if (!page.disabled) {
      let LazyPage;
      if (page.path) {
        LazyPage = getLazyPage(page.path, false);
      }
      if (page.module) {
        LazyPage = getLazyPage(page.path, true);
      }
      return (
        <Route
          key={page.url}
          exact={!page.isMFE}
          path={page.url}
          render={() => {
            if (Auth.isSessionActive()) {
              if (LazyPage) {
                return <LazyPage {...page.props} />;
              } else {
                return null;
              }
            } else {
              ErrorHandler.trackError(
                Events.ERRORS.TITLE.SESSION_EXPIRED,
                `${Consts.TOKEN_ERROR} while trying to access ${page.url}`
              );
              return <></>;
            }
          }}
        />
      );
    } else {
      return null;
    }
  });
};

const getRedirect = (from, to, exact) => {
  return <Redirect key={to} from={from} to={to} exact={exact} />;
};

export const getRedirects = pages => {
  return pages.flatMap(page => {
    if (!page.disabled && page.redirect) {
      if (Array.isArray(page.redirect)) {
        return page.redirect.map(redirectObj => {
          return getRedirect(
            redirectObj.url,
            redirectObj.to ? redirectObj.to : page.url,
            redirectObj.exact
          );
        });
      } else {
        return getRedirect(
          page.redirect.url,
          page.redirect.to ? page.redirect.to : page.url,
          page.redirect.exact
        );
      }
    } else {
      return null;
    }
  });
};

export const updateRouteWithName = (
  route,
  name,
  removeParamRestriction = false
) => {
  let updatedRoute = route.replace(Consts.ROUTE_PARAM, name);
  if (removeParamRestriction) {
    updatedRoute = updatedRoute.replace(
      Consts.ARTICLE_PARAM_RESTRICTION,
      Consts.EMPTY
    );
  }
  return updatedRoute;
};

export const getPageByRoute = (pages, route) => {
  return pages.find(item => {
    return item.url === route;
  });
};

export const getParentPagesByRoute = (pages, route, currentURL) => {
  const parentPages = pages.filter(item => {
    if (item.disabled) {
      return false;
    }

    //For non-Homepage routes, add an ending / to make sure /page1/page2 doesn't match /page2/page22, since we use startsWith.
    const url = `${item.url}${
      item.url === Routes.HOMEPAGE ? Consts.EMPTY : '/'
    }`;
    //route handles routes with :name and currentURL handles where a parent route doesn't have :name (i.e. /helpcenter/mac)
    return (
      url !== route && (route.startsWith(url) || currentURL.startsWith(url))
    );
  });
  return parentPages;
};

export default Routes;
