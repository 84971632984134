import React from 'react';
import Auth from '../../auth/Auth';
import { trackEvent } from '../../utils/appInsight';
import Events from '../../consts/Events';
import { Button } from '@walmart-web/livingdesign-components';

const MTLogoutButton = ({ children = 'Logout', ...props }) => {
  return (
    <Button
      id="mtLogoutButton"
      data-testid="mtLogoutButton"
      onClick={() => {
        trackEvent(Events.LOGOUT);
        Auth.logout();
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default MTLogoutButton;
