import React, { useEffect } from 'react';
import { useFormikContext, useField } from 'formik';
import MTRadioButton from './MTRadioButton';
import Colors from '../../../consts/Colors';

let mtCustomRadioButtonIdCounter = 1;

const MTCustomRadioButton = ({
  groupName,
  value,
  label = value,
  setSelectedOption,
  selectedOption,
}) => {
  const [field] = useField(groupName);
  const { validateForm } = useFormikContext();
  const isChecked = field && field.value === value;
  const className = 'mtCustomRadioButton';

  useEffect(() => {
    setSelectedOption(field.value);
  }, [field.value, setSelectedOption]);

  useEffect(() => {
    validateForm();
  }, [selectedOption, validateForm]);

  return (
    <div className={isChecked ? 'checked' : null}>
      <MTRadioButton
        {...field}
        field={field}
        id={`${className}${mtCustomRadioButtonIdCounter++}`}
        key={`${className}${value}`}
        value={value}
        label={label}
        groupName={groupName}
        className={className}
      />

      <style jsx>
        {`
          div {
            position: relative;
            width: 60%;
            height: 30px;
            margin: 0 auto;
            border: 1px solid #dadce0;
            border-radius: 15px;
          }

          div:hover {
            background-color: ${Colors.BLUE};
            border: 1px solid ${Colors.BLUE};
            opacity: 0.82;
          }

          div:hover :global(label),
          div.checked :global(label) {
            color: #fff !important;
          }

          div.checked {
            font-weight: bold;
            background-color: ${Colors.PINK};
            border: 1px solid ${Colors.PINK};
          }

          div {
            margin-top: 20px;
          }

          /* Reset input[type=radio] existing styles and make 100% width/height, so it's fully clickable */
          :global(html .mtRadioButtons input.${className}) {
            appearance: none;
            border: none;
            background-color: transparent;
            position: absolute;
            margin: 0;
            padding: 0;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

          :global(html .mtRadioButtons input.${className}:focus) {
            outline: 1px dotted #202020;
            outline: -webkit-focus-ring-color auto 1px;
          }

          :global(html .mtRadioButtons input.${className}:before),
          :global(html .mtRadioButtons input.${className}:after) {
            content: none !important;
          }

          :global(html input.${className} + label) {
            position: absolute;
            top: 5px;
            left: 0;
            right: 0;
            text-align: center;
            pointer-events: none;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            padding: 0 10px;
            margin: 0;
          }

          @media screen and (max-width: 480px) {
            div {
              width: 95%;
            }
          }
        `}
      </style>
    </div>
  );
};

export default MTCustomRadioButton;
