import React from 'react';
import Colors from '../../consts/Colors';
import PropTypes from 'prop-types';
import CSS from '../../consts/CSS';
import { ReactComponent as DoubleWaves } from '../../assets/svg/waves.svg';
import { ReactComponent as WavesRight } from '../../assets/svg/wavesright.svg';
import { ReactComponent as WavesLeft } from '../../assets/svg/wavesleft.svg';
import { ReactComponent as Spark } from '../../assets/svg/spark.svg';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import AriaLabel from '../../consts/AriaLabel';
import Consts from '../../consts/Consts';
import { Body, Heading } from '@walmart-web/livingdesign-components';

const customStyles = {
  bannerHeading: {
    color: Colors.WHITE,
  },
  bannerSubtext: {
    color: Colors.WHITE,
  },
};

const PageHeaderBanner = ({
  title,
  subTitle,
  svgImage,
  mobileSvgImage,
  bannerProps = {},
}) => {
  const { v2Banner, waves, spark } = bannerProps;
  const PAGE_HEADER_HEIGHT = 182;
  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.down('sm'));
  if (!v2Banner) {
    return (
      <div
        className="pageHeader-banner"
        role={Consts.ARIA_ROLES.REGION}
        aria-label={AriaLabel.REGIONS.PAGE_BANNER}
      >
        <div className="bannerTextContainer">
          <Heading as="h1" UNSAFE_style={customStyles.bannerHeading}>
            {title}
          </Heading>
          {subTitle && (
            <Body UNSAFE_style={customStyles.bannerSubtext}>{subTitle}</Body>
          )}
        </div>
        {svgImage && (
          <div className="bannerImageContainer svg-lg">{svgImage}</div>
        )}
        {mobileSvgImage && (
          <div className="bannerImageContainer svg-sm">{mobileSvgImage}</div>
        )}
        <style jsx>{`
          .pageHeader-banner {
            background-color: ${Colors.DARK_BLUE};
            display: flex;
            justify-content: space-between;
            padding: 0 32px;
            border-radius: 8px;
            overflow: hidden;
            position: relative;
          }
          .bannerTextContainer {
            flex: 1 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin: 10px 10px 12px 0;
            flex-basis: 20%;
            z-index: 3;
          }
          .bannerImageContainer {
            flex: 1;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            z-index: 2;
          }
          .bannerImageContainer :global(svg) {
            max-height: 184px;
          }

          .bannerBackground {
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            z-index: 1;
          }

          .bannerBackground .start,
          .bannerBackground .end {
            margin-left: auto;
          }

          .bannerBackground .break {
            flex-basis: 100%;
            height: 0;
          }

          .svg-sm {
            display: none;
          }
          .svg-lg {
            display: flex;
          }

          @media (max-width: ${CSS.STANDARD_MAX_MOBILE_PHONE_VIEW}) {
            .pageHeader-banner {
              padding-right: 0;
              padding-left: 16px;
              margin: 16px 0;
            }
            .bannerImageContainer :global(svg) {
              max-height: 137px;
            }
            .bannerTextContainer {
              flex: 2;
            }
            .svg-sm {
              display: flex;
            }
            .svg-lg {
              display: none;
            }
          }
        `}</style>
      </div>
    );
  } else {
    const hasIcon = svgImage || spark; // display an image in right side
    return (
      <Box
        sx={{
          borderRadius: 2,
          minWidth: 300,
          overflow: 'hidden',
          height: PAGE_HEADER_HEIGHT,
          marginTop: 1.5,
          width: '100%',
          bgcolor: Colors.DARK_BLUE,
        }}
      >
        {waves && (
          <Box
            sx={{
              position: 'relative',
              '& svg': {
                zIndex: 0,
                position: 'absolute',
                right: 0,
              },
              '& svg:first-of-type': waves
                ? waves !== 'corner'
                  ? {
                      right: 0,
                    }
                  : {
                      left: 0,
                    }
                : {},
            }}
          >
            {waves === 'corner' ? (
              <Box
                sx={{
                  position: 'absolute',
                  height: PAGE_HEADER_HEIGHT,
                  width: '100%',
                  '& svg': {
                    bottom: 0,
                  },
                }}
              >
                <WavesLeft />
                <WavesRight />
              </Box>
            ) : (
              <DoubleWaves />
            )}
          </Box>
        )}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'relative',
            height: '100%',
            px: hasIcon ? (xsScreen ? 2 : 4) : 4,
            boxSizing: 'border-box',
          }}
        >
          <Box
            sx={{
              minWidh: 175,
              flex: 2,
              boxSizing: 'border-box',
            }}
          >
            <Typography variant={xsScreen ? 'h3' : 'h2'} color={Colors.WHITE}>
              {title}
            </Typography>
            {subTitle && (
              <Typography color={Colors.WHITE} variant="body">
                {subTitle}
              </Typography>
            )}
          </Box>
          {hasIcon ? (
            <Box
              sx={{
                flex: 1,
                display: xsScreen ? 'none' : 'flex', //will not show banner image in mobile for now
                alignItems: 'center',
                flexDirection: xsScreen ? 'column-reverse' : 'row',
                boxSizing: 'border-box',
                justifyContent: svgImage ? 'center' : 'flex-end',
                alignSelf: svgImage ? 'flex-end' : 'unset',
              }}
            >
              {svgImage && (
                <Box
                  sx={{
                    '& svg': {
                      height: xsScreen && spark ? 110 : 'auto',
                    },
                    maxWidth: xsScreen ? '100%' : 'unset',
                    height: xsScreen ? 'auto' : 184,
                    display: 'flex',
                    alignItems: !bannerProps?.centerAlignImage
                      ? 'flex-end'
                      : 'center',
                  }}
                >
                  {!xsScreen || !mobileSvgImage ? svgImage : mobileSvgImage}
                </Box>
              )}
              {spark && (
                <Box
                  sx={{
                    '& svg': {
                      height: xsScreen ? (svgImage ? 48 : 60) : 90,
                      width: xsScreen ? (svgImage ? 48 : 60) : 90,
                    },
                    pl: xsScreen ? 0 : 3,
                    pt: xsScreen && svgImage ? 1 : 0,
                  }}
                >
                  <Spark />
                </Box>
              )}
            </Box>
          ) : null}
        </Box>
      </Box>
    );
  }
};

export default PageHeaderBanner;

PageHeaderBanner.propTypes = {
  title: PropTypes.string.isRequired,
};
