import React from 'react';
import Consts from '../../consts/Consts';
import CSS from '../../consts/CSS';

const ButtonContainer = ({
  children,
  displaySideBySide,
  displayButtonsOnRight = false,
}) => {
  return (
    <div
      className={`buttonContainer${
        displaySideBySide ? ' sideBySide' : Consts.EMPTY
      }${displayButtonsOnRight ? ' buttonsOnRight' : Consts.EMPTY}`}
    >
      {children}
      <style jsx>
        {`
          div {
            display: flex;
            margin: 30px ${CSS.PAGE_PADDING_LEFT_RIGHT};
            justify-content: center;
            flex-basis: 100%;
          }

          div:last-child {
            margin-bottom: 0;
          }
          .buttonContainer.buttonsOnRight {
            justify-content: flex-end;
            margin: 30px 0 0;
          }

          @media (max-width: 515px) {
            .buttonContainer.sideBySide {
              display: block;
            }

            .buttonContainer.sideBySide :global(.mtButton) {
              width: 100%;
              display: inline-block;
            }

            .buttonContainer.sideBySide :global(.mtButton > button) {
              width: 100%;
              box-sizing: border-box;
            }

            .buttonContainer.sideBySide :global(.mtButton:last-child) {
              margin-left: 0;
              margin-top: 20px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default ButtonContainer;
