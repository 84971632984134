import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LoadingIcon from '../../../components/misc/LoadingIcon';
import Colors, { LightTheme } from '../../../consts/Colors';
import GenerateHTMLtoReact from '../../../utils/GenerateHTMLtoReact';
import CSS from '../../../consts/CSS';
import Consts from '../../../consts/Consts';
import { ReactComponent as Arrow } from '../../../assets/svg/arrowSearchResults.svg';
import Config from '../../../utils/Config';
import { getOSLabel } from '../../../utils/helpers';
import AriaLabel from '../../../consts/AriaLabel';

const MTSearchResults = ({
  resultsList,
  isSearching,
  handleResultSelect,
  hasSelectedValue,
  getDisplayValue,
  getResultType,
  hideNoResults,
  forceHideDropDown,
  setForceHideDropDown,
  isSearchError = false,
  query,
  showSearchDropdown,
  setShowSearchDropdown,
  onKeyDown,
  onKeyUp,
  activeResultIndex,
}) => {
  //If isSearching or resultsList is defined, show the drop down. Otherwise keep it from being rendered.
  useEffect(() => {
    const shouldShow = resultsList !== null || isSearching;
    if (forceHideDropDown) {
      setShowSearchDropdown(false);
    } else if (showSearchDropdown !== shouldShow) {
      setShowSearchDropdown(shouldShow);
    }
  }, [resultsList, isSearching, showSearchDropdown, forceHideDropDown]);

  //Allows keyboard user to navigate to search result by pressing ENTER/RETURN key
  const onResultKeyDown = (event, result) => {
    if (event.keyCode === Consts.KEY_CODES.ENTER_KEY) {
      handleResultSelect(result);
    }
  };

  return showSearchDropdown ? (
    <>
      <span
        className="searchResultsBG"
        onClick={() => {
          setForceHideDropDown(true);
        }}
      />
      <section
        className="searchResults"
        id="searchResults"
        role="listbox"
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
      >
        {query?.trim().length >= Consts.SEARCH.MIN_CHAR_LENGTH && (
          <div className="searchResultsHeading">{`Search results for '${query}'`}</div>
        )}
        {resultsList &&
          resultsList.length > 0 &&
          resultsList.map((result, key) => {
            const osLabel = getOSLabel(result.path);
            return (
              <div
                id={`searchResult${key}`}
                tabIndex={-1}
                aria-selected={key === activeResultIndex}
                key={key}
                className="result"
                onClick={() => {
                  handleResultSelect(result);
                }}
                aria-label={AriaLabel.SEARCH_RESULT(
                  getDisplayValue(result),
                  getResultType(result),
                  osLabel !== '' ? osLabel : null
                )}
                onKeyDown={e => {
                  onResultKeyDown(e, result);
                }}
              >
                {getDisplayValue(result) ? (
                  <div className="resultText">
                    <GenerateHTMLtoReact content={getDisplayValue(result)} />
                    <div className="category">
                      {getResultType(result)}
                      {Config.isSearchOSLabelEnabled && osLabel
                        ? ` - ${osLabel}`
                        : null}
                    </div>
                  </div>
                ) : null}
                <Arrow />
              </div>
            );
          })}
        {!hasSelectedValue && isSearching && !resultsList && (
          <div className="noResults">
            <LoadingIcon />
          </div>
        )}
        {!hideNoResults &&
          !hasSelectedValue &&
          resultsList &&
          resultsList.length === 0 &&
          !isSearchError && (
            <div
              className="noResults"
              id="noResults"
              aria-label={AriaLabel.SEARCH_NO_RESULT}
              tabIndex={-1}
              aria-selected={
                document.activeElement === document.getElementById('noResults')
              }
            >
              No Results
            </div>
          )}

        {!hideNoResults && isSearchError && (
          <div
            className="noResults"
            id="searchError"
            aria-label={AriaLabel.SEARCH_ERROR}
            tabIndex={-1}
            aria-selected={
              document.activeElement === document.getElementById('searchError')
            }
          >
            There's a problem loading your search results.
          </div>
        )}
      </section>
      <style jsx>
        {`
            section {
              width: 100%;
              max-height: 300px;
              background-color: ${Colors.WHITE};
              overflow: auto;
              position: absolute;
              z-index: 50;
              margin-top: -1px;
              font-size: 18px;
              line-height: 1.14;
              color: ${Colors.DARK_GREY};
              box-shadow: ${CSS.CARD_DROP_SHADOW_DIMENSIONS}
                ${LightTheme.cardDropShadowColor};
              border-radius: ${CSS.BORDER_RADIUS};
            }

            .result,
            .noResults {
              padding: 10px;
            }

            .result {
              cursor: pointer;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display: flex;
              justify-content: space-between;
            }

            .result:not(:last-child) {
              border-bottom: 1px solid ${LightTheme.border};
            }

            .resultText,
            .noResults {
              font-size: 16px;
              margin-left: 7px;
              line-height: 24px;
            }

            .category {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 12px;
              color: ${Colors.TEXT_LIGHT_GREY};
              line-height: 16px;
              font-weight: 400;
            }

            .result-wrap {
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;
            }

            .result:hover {
              background-color: ${Colors.LIGHT_GREY};
            }

            .result > :global(svg) {
              display: flex;
              right: 17px;
              margin-top: 10px;
              weight:: 13px;
              height: 13px;
            }

            .noResults :global(.loadingicon-container) {
              height: 100%;
              width: 100%;
            }

            .noResults :global(.loadingicon-loading) {
              width: 35px;
              height: 35px;
            }

            .searchResultsBG {
              float: left;
              background-color: transparent;
              position: fixed;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
            }

            .searchResults {
              margin-top: 4px;
            }

            .searchResults :global(em) {
              font-weight: 700;
            }

            .searchResultsHeading {
              font-size: 14px;
              padding: 16px 16px 0px;
              color: ${Colors.TEXT_GREY};
            }
          `}
      </style>
    </>
  ) : null;
};

export default MTSearchResults;

MTSearchResults.propTypes = {
  resultsList: PropTypes.array,
  isSearching: PropTypes.bool,
  handleResultSelect: PropTypes.func,
};

MTSearchResults.defaultProps = {
  isSearching: false,
};
