import React from 'react';

const MTCharacterCount = ({ totalCharCount, maxLength }) => {
  return (
    <>
      <div>
        {totalCharCount}/{maxLength}
      </div>
      <style jsx>
        {`
          div {
            position: absolute;
            right: 0;
            margin-top: 3px;
          }
        `}
      </style>
    </>
  );
};

export default MTCharacterCount;
