import { Star, StarOutline } from '@mui/icons-material';
import { Box, Rating, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Consts from '../../../consts/Consts';
import { trackEvent } from '../../../utils/appInsight';
import LDDialog from '../../../LDComponents/dialog/LDDialog';

/**
 * @todo Work on a single Feedback dialog comonent for the submodule and depreciate/remove this component and its related test/utility content once migration to new component has been completed
 */

const FeedbackDialogV2 = ({
  closeDialog,
  starRating,
  requestID,
  appID,
  setStarRating,
  upn,
  dialogTrackingEvents = {},
  ratingType,
  submitFeedback,
}) => {
  const [disabled, toggleDisabled] = useState(true);
  const [submitting, toggleSubmitting] = useState(false);
  const [comment, setComment] = useState('');
  const [charError, setCharError] = useState(false);
  const [enableStars, toggleEnableStars] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    trackEvent(dialogTrackingEvents.LANDING(ratingType));
  }, []);

  useEffect(() => {
    toggleDisabled(!starRating || charError);
  }, [starRating, charError]);

  const handleSubmitFeedback = async () => {
    const feedbackRatingType =
      ratingType === Consts.EMPTY ? Consts.EMPTY : ` - for ${ratingType}`;
    const feedback = {
      isUseful: Consts.EMPTY,
      detailedFeedback: `Rated ${starRating} stars - ${comment}${feedbackRatingType}`,
    };
    trackEvent(
      dialogTrackingEvents.SUBMITTED_STARS(ratingType, starRating),
      feedback
    );
    await submitFeedback(
      feedback,
      () => {},
      () => {}
    );
  };

  return (
    <LDDialog
      closeDialog={() => {
        closeDialog();
        trackEvent(dialogTrackingEvents.CLOSED(ratingType));
      }}
      title={
        !submitted
          ? Consts.APPS_FEEDBACK.STAR_RATING_TITLE
          : Consts.APPS_FEEDBACK.SUBMITTED_TITLE
      }
      maxWidth="sm"
      primaryButton={
        !submitted
          ? {
              label: 'Submit',
              onClick: async () => {
                toggleSubmitting(true);
                toggleEnableStars(false);
                handleSubmitFeedback();
                toggleSubmitting(false);
                setSubmitted(true);
                setStarRating(0);
                toggleEnableStars(true);
              },
              disabled: disabled,
              loading: submitting,
            }
          : {
              label: Consts.APPS_FEEDBACK.CLOSE_ON_SUBMIT,
              onClick: closeDialog,
            }
      }
      hideSecondaryButton={submitted}
    >
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {!submitted ? (
          <>
            <Typography>
              <b>{Consts.APPS_FEEDBACK.STAR_LABEL}</b>
            </Typography>
            <Box mt={1} mb={2}>
              <Rating
                name="feedback-star-rating"
                value={starRating}
                onChange={(event, newValue) => {
                  setStarRating(newValue);
                }}
                icon={<Star />}
                emptyIcon={<StarOutline />}
                readOnly={!enableStars}
              />
            </Box>
            <TextField
              fullWidth
              variant="outlined"
              error={charError}
              value={comment}
              onChange={e => {
                setComment(e.target.value);
                if (
                  e.target.value?.length > Consts.APPS_FEEDBACK.COMMENT_CHAR_MAX
                ) {
                  setCharError(true);
                } else {
                  setCharError(false);
                }
              }}
              multiline
              rows={4}
              placeholder={Consts.APPS_FEEDBACK.COMMENT_PLACEHOLDER}
              helperText={
                <>
                  {charError ? (
                    <Typography variant="caption">
                      {Consts.APPS_FEEDBACK.COMMENT_CHAR_MAX_TEXT}{' '}
                    </Typography>
                  ) : null}
                  <Typography variant="caption">
                    {comment?.length || 0}/
                    {Consts.APPS_FEEDBACK.COMMENT_CHAR_MAX}
                  </Typography>
                </>
              }
            />
          </>
        ) : (
          <Box width="100%" pb={1}>
            <Typography>{Consts.APPS_FEEDBACK.SUBMITTED_TEXT}</Typography>
          </Box>
        )}
      </Box>
    </LDDialog>
  );
};

export default FeedbackDialogV2;
