import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Close } from '../../assets/svg/close.svg';
import FocusLock from 'react-focus-lock';
import Consts from '../../consts/Consts';
import Colors, { LightTheme } from '../../consts/Colors';
import CSS from '../../consts/CSS';

const Dialog = ({
  children,
  isDialogOpen,
  closeDialog,
  showClose,
  size,
  className,
  keepOpen,
}) => {
  return (
    isDialogOpen && (
      <FocusLock>
        <span hidden={!isDialogOpen}>
          <section
            id="mytech-dialog"
            onClick={e => {
              if (!keepOpen && e.target.id === 'mytech-dialog') {
                closeDialog();
              }
            }}
            className={`dialog-wrapper ${className ? className : Consts.EMPTY}`}
          >
            <div className="content-wrapper">
              {showClose ? (
                <div
                  className="dialog-close"
                  onClick={closeDialog}
                  tabIndex={0}
                >
                  <Close />
                </div>
              ) : null}
              <div className="content-inner">{children}</div>
            </div>
            {/* component based styles below */}
            <style jsx>{`
              /* In order to prevent scrolling the page, set overflow on html. html over body, so page content doesn't shift to the top */
              :global(html) {
                overflow: hidden;
              }

              section {
                position: fixed;
                display: flex;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                align-items: center;
                justify-content: center;
                background: ${LightTheme.dialogUnderlayBG};
                z-index: 11000;
              }
              div.content-wrapper {
                max-width: ${size === 'default'
                  ? '600px'
                  : size === 'extra-large'
                  ? '840px'
                  : size === 'medium'
                  ? '500px'
                  : '415px'};
                max-height: calc(100vh - 40px);
                min-width: 250px;
                min-height: 100px;
                background: ${LightTheme.dialogBG};
                width: calc(100% - 40px);
                overflow-y: auto;
                -webkit-overflow-scrolling: touch;
                overflow-scrolling: touch;
                border-radius: ${CSS.BORDER_RADIUS};
                box-shadow: 1px 2px 1px 0 rgba(0, 0, 0, 0.25);
                border: 1px solid ${Colors.MEDIUM_GREY};
                position: relative;
                box-sizing: border-box;
              }

              .content-inner {
                padding: 30px ${CSS.PAGE_PADDING_LEFT_RIGHT};
              }

              div.dialog-close {
                cursor: pointer;
                border: none;
                position: absolute;
                right: 10px;
                top: 10px;
                z-index: 1000;
              }

              .content-inner > :global(.pageHeader) {
                margin-top: 15px;
                margin-bottom: 30px;
              }
            `}</style>
          </section>
        </span>
      </FocusLock>
    )
  );
};

export default Dialog;

Dialog.propTypes = {
  /** Children passed into component  */
  children: PropTypes.node.isRequired,
  /** Function for when the dialog is closed */
  closeDialog: PropTypes.func.isRequired,
  /** Bool that toggles the visibility of dialog */
  isDialogOpen: PropTypes.bool.isRequired,
  /** Optional value to hide Close */
  showClose: PropTypes.bool,
  /** The dialog size, default is the standard dialog width */
  size: PropTypes.oneOf(['default', 'small', 'medium', 'extra-large']),
};

Dialog.defaultProps = {
  showClose: true,
  size: 'default',
};

Dialog.SIZE_SMALL = 'small';
Dialog.SIZE_MEDIUM = 'medium';
Dialog.SIZE_EXTRA_LARGE = 'extra-large';
