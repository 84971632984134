import React from 'react';
import Errors from '../../consts/Errors';
import ErrorPage from './ErrorPage';
import MTLink from '../../components/button/MTLink';

const LiteModeErrorPage = () => {
  return (
    <ErrorPage
      title={Errors.LITE_MODE_UNSUPPORTED_ROUTE}
      subTitle={Errors.LITE_MODE_ACTIVE_WE_DOWN_SUB_TEXT}
      errorData={{ description: 'LiteModeErrorPage.js Lite Mode error' }}
      includeImage={false}
      includeErrorIcon={false}
    >
      <div className="error-link-wrapper">
        <MTLink
          onClick={() => {
            window.location.reload();
          }}
        >
          Refresh
        </MTLink>
      </div>
    </ErrorPage>
  );
};

export default LiteModeErrorPage;
