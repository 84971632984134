import axios from 'axios';
import APIs from '../consts/APIs';
import { successFailure } from './interceptorHelpers';

// Fetches available options for upgrading devices
export const fetchVDIRequestData = async (userId, success, failure) => {
  let vdiRequestData = {};

  const params = { userUPN: userId };
  const headers = {
    ignoreGenericError: true,
    ignoreNetworkError: true,
  };

  await axios
    .get(APIs.getAPIUrl(APIs.VDI_REQUEST_DATA), {
      params: params,
      headers: headers,
    })
    .then(res => {
      if (successFailure(res)) {
        failure(res);
      } else {
        if (res.data) {
          vdiRequestData = res.data;
        }
        success(vdiRequestData);
      }
    })
    .catch(error => {
      failure(error);
    });
};
