import React from 'react';
import AlertBannerActionLink from './AlertBannerActionLink';
import Colors from '../../consts/Colors';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  CardActions,
} from '@walmart-web/livingdesign-components';
import AriaLabel from '../../consts/AriaLabel';
import Consts from '../../consts/Consts';

const customStyles = {
  alert: {
    backgroundColor: Colors.VERY_LIGHT_BLUE,
  },
  container: {
    margin: '0px 2px', // makes sure the other alerts don't slightly show on sides
  },
};

const Alert = ({ alert, alertsLength, style }) => {
  const { content, title, slideId, isCurrent, actions = [] } = alert;
  return (
    <div
      role={Consts.ARIA_ROLES.GROUP} // Allows for screen readers to see they are in the alert
      aria-label={AriaLabel.GROUPS.ALERT_BANNER_ALERT_GROUP(
        slideId,
        alertsLength
      )}
    >
      <Grid
        UNSAFE_className="alert-container" // carousel related class
        UNSAFE_style={{ ...style, ...customStyles.container }}
      >
        <Card size="small" UNSAFE_style={customStyles.alert}>
          <CardHeader title={title} />
          <CardContent>{content}</CardContent>
          <CardActions>
            {actions.map((action, index) => {
              const { href, actionLabel } = action;
              const testId = `actionLink-${index + 1}`;
              return (
                <AlertBannerActionLink
                  key={actionLabel}
                  href={href}
                  label={actionLabel}
                  isCurrent={isCurrent}
                  data-testid={testId}
                />
              );
            })}
          </CardActions>
        </Card>
      </Grid>
    </div>
  );
};

export default Alert;
