import axios from 'axios';
import APIs from '../consts/APIs';

const fetchedTextData = {};

const handleResponse = (res, successCallback, failureCallback) => {
  const hasData =
    typeof res !== 'string' || !res.includes('Config Data Not found');
  if (hasData) {
    if (successCallback) {
      successCallback(res);
    } else {
      return res;
    }
  } else if (failureCallback) {
    failureCallback();
  } else {
    return null;
  }
};

export const hasDynamicTextData = textId => {
  return fetchedTextData[textId] ? true : false;
};

const fetchDynamicTextData = async (
  textId,
  successCallback,
  failureCallback
) => {
  if (!hasDynamicTextData(textId)) {
    try {
      const res = await axios.post(
        APIs.getAPIUrl(APIs.CONFIG_TEXT),
        {
          textId: textId,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (res.data) {
        fetchedTextData[textId] = res.data;
        handleResponse(res.data, successCallback, failureCallback);
      }
    } catch (err) {
      if (failureCallback) {
        failureCallback();
      } else {
        return null;
      }
    }
  } else {
    handleResponse(fetchedTextData[textId], successCallback, failureCallback);
  }
};

export default fetchDynamicTextData;
