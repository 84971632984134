import { SideNavigation } from '@walmart-web/livingdesign-components';
import NavItem from './NavItem';
import useSideNavigation from '../../customHooks/useSideNavigation';
import CSS from '../../consts/CSS';
import { useMediaQuery, useTheme } from '@mui/material';

const MTSideNavigation = () => {
  const { navigationPages } = useSideNavigation();
  const theme = useTheme();
  const mobileNav = useMediaQuery(theme.breakpoints.down('md'));

  const getNavItemProps = page => {
    return {
      url: page.url,
      key: page.url,
      supportsLiteMode: page.supportsLiteMode,
      isNewTab: page.isNewTab,
      leadingIcon: page.leadingIcon,
    };
  };

  const sideNavWidth = mobileNav
    ? CSS.NAV_MAX_WIDTH_MOBILE
    : CSS.NAV_MAX_WIDTH_DESKTOP;

  const customStyles = {
    sideNavigationContainerStyles: {
      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      padding: '8px 0px',
      width: sideNavWidth,
    },
  };

  const filteredSideNavPages = navigationPages.filter(
    page => (page.label || page.navLabel) && !page.disabled
  );

  return (
    <SideNavigation
      UNSAFE_style={customStyles.sideNavigationContainerStyles}
      aria-label={'Side navigation'}
    >
      {filteredSideNavPages.map(page => {
        const { navLabel, label, childPages } = page;
        const navigationLabel = navLabel ? navLabel : label;
        let filteredChildPages = childPages?.filter(page => !page.disabled);
        filteredChildPages = filteredChildPages?.filter(
          page => !page.ignoreNavLabel
        );
        const isParent = filteredChildPages?.length ? true : false;
        return (
          <NavItem
            label={navigationLabel}
            isParent={isParent}
            {...getNavItemProps(page)}
            childPages={filteredChildPages?.map(childPage => {
              return (
                <NavItem
                  {...getNavItemProps(childPage)}
                  label={childPage.label}
                />
              );
            })}
          />
        );
      })}
    </SideNavigation>
  );
};

export default MTSideNavigation;
