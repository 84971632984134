import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Consts from '../../../consts/Consts';
import MTFormItem from '../item/MTFormItem';
import Colors from '../../../consts/Colors';
import { Field, useField } from 'formik';
import MTSearchResults from './MTSearchResults';
import { ReactComponent as SearchIcon } from '../../../assets/svg/search.svg';
import { ReactComponent as ClearIcon } from '../../../assets/svg/close.svg';
import { addEnterListener } from '../../../utils/helpers';
import AriaLabel from '../../../consts/AriaLabel';

let mtSearchIdCounter = 1;

const MTSearch = ({
  name,
  yup,
  initialValue,
  label,
  halfWidth,
  showLabel,
  placeholder,
  disabled,
  isSearching,
  resultsList,
  setResultsList,
  setSelectedData,
  getResultsOnSearchTermChange,
  getDisplayValue,
  getResultType,
  hideNoResults,
  resetOnSelect,
  isResultsFromAPI = true,
  includeSearchIcon = false,
  includeClearIcon = false,
  searchIsFocused,
  setSearchIsFocused,
  setHideHeaderIcons,
  isSearchError = false,
  forceHideDropDown = false,
  setForceHideDropDown,
}) => {
  const className = 'MTSearch';
  const id = `${className}${mtSearchIdCounter++}`;
  const isRequired = yup && yup._exclusive && Boolean(yup._exclusive.required);

  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(name);
  const [hasSelectedValue, setHasSelectedValue] = useState(false);
  const [showSearchDropdown, setShowSearchDropdown] = useState(false);
  const [activeResultIndex, setActiveResultIndex] = useState(-1);

  const [keydownMap, setKeyDownMap] = useState({}); //Used to keep track of keys down while using combobox - necessary to recognize key combination commands
  const [inputCaretIndex, setInputCaretIndex] = useState(0);

  const timeoutId = useRef(); //Used for storing/clearing the timeout if one is in-progress

  const clearResults = () => {
    setResultsList(null);
  };

  const reset = () => {
    setSelectedData(null);
    setHasSelectedValue(false);
    clearResults();
    helpers.setValue(Consts.EMPTY);
  };

  const handleSearch = async searchCriteria => {
    getResultsOnSearchTermChange(searchCriteria);
  };

  const clearTimeoutIfExists = timeoutIdentifier => {
    if (timeoutIdentifier) {
      clearTimeout(timeoutIdentifier);
      timeoutIdentifier = null;
    }
  };

  useEffect(() => {
    return () => {
      if (isResultsFromAPI) {
        clearTimeoutIfExists(timeoutId.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isResultsFromAPI) {
      clearTimeoutIfExists(timeoutId.current);
    }
    if (!hasSelectedValue) {
      if (field.value.trim().length >= Consts.SEARCH.MIN_CHAR_LENGTH) {
        if (isResultsFromAPI) {
          timeoutId.current = setTimeout(() => {
            handleSearch(field.value);
          }, Consts.SEARCH.DELAY_MS);
        }
      } else if (searchIsFocused) {
        handleSearch(field.value);
      }
    }
  }, [field.value, searchIsFocused]);

  useEffect(() => {
    if (setHideHeaderIcons) {
      setHideHeaderIcons(!forceHideDropDown);
    }
  }, [forceHideDropDown]);

  //Moves focus between search results when arrow keys are used to navigate
  useEffect(() => {
    if (activeResultIndex >= 0) {
      document
        .getElementById(`searchResult${activeResultIndex}`)
        .focus({ focusVisible: true });
    }
  }, [activeResultIndex]);

  //Resets search result index when results div is hidden, so keyboard nav starts from the top each time
  useEffect(() => {
    if (!showSearchDropdown) {
      setActiveResultIndex(-1);
    }
  }, [showSearchDropdown]);

  //Function to set the caret in an input element to a given position
  const setCaretPosition = (elementId, caretPosition) => {
    const element = document.getElementById(elementId);

    if (element !== null) {
      if (element.selectionStart && caretPosition > 0) {
        element.setSelectionRange(caretPosition, caretPosition);
      } else if (element.selectionStart) {
        element.setSelectionRange(0, 0);
      }
    }
  };

  const onSelect = result => {
    setSelectedData(result, field.value);
    setHasSelectedValue(true);
    if (
      result.mime_type !== Consts.SITE_SEARCH.TYPE.VIDEO &&
      result.mime_type !== Consts.SITE_SEARCH.TYPE.PDF
    ) {
      if (resetOnSelect) {
        reset();
      } else {
        clearResults();
      }
    }
  };

  //Since typing any char when there's a selected value will clear it, select the text to show the user it's one entry.
  const onFocus = event => {
    setForceHideDropDown(false);
    if (setSearchIsFocused) {
      setSearchIsFocused(true);
    }
    if (hasSelectedValue) {
      event.target.select();
    }
    //When focus moves back to search bar, resets result index, so users can navigate with arrows from top of results
    setActiveResultIndex(-1);
  };

  const onBlur = event => {
    if (setSearchIsFocused) {
      setSearchIsFocused(false);
    }
  };

  //Function to record when keys are pressed to trigger various keyboard accessibility features
  const keyTracker = event => {
    const updatedKeydownMap = keydownMap;
    updatedKeydownMap[event.keyCode] = event.type === 'keydown';
    setKeyDownMap(updatedKeydownMap);
  };

  //To avoid losing the char that was typed to clear the hasSelectedValue, clear it during keydown, so it happens before onchange.
  const onKeyDown = event => {
    keyTracker(event);
    if (hasSelectedValue) {
      reset();
    }
    //If user presses escape key, hide search results
    if (event.keyCode === Consts.KEY_CODES.ESCAPE_KEY) {
      clearResults();
      document.getElementById(id).focus({ focusVisible: true });
      setForceHideDropDown(true);
      setShowSearchDropdown(false);
    }
    //Updates search bar caret index to help with right/left arrow accesibility features
    if (document.activeElement === document.getElementById(id)) {
      if (
        keydownMap[Consts.KEY_CODES.ARROW_DOWN] ||
        keydownMap[Consts.KEY_CODES.ARROW_UP]
      ) {
        event.preventDefault();
      }
      setInputCaretIndex(document.getElementById(id).selectionStart);
    }
    //If users tabs, close search results and reset search result index
    if (event.keyCode === Consts.KEY_CODES.TAB_KEY) {
      setForceHideDropDown(true);
      setShowSearchDropdown(false);
      if (
        document.getElementById(`searchResult${activeResultIndex}`) ===
        document.activeElement
      ) {
        setActiveResultIndex(-1);
      }
    }
    if (keydownMap[Consts.KEY_CODES.ALT_KEY]) {
      //If focus is in combobox and results are not showing, ALT + ARROW_DOWN expand results without moving focus
      if (
        keydownMap[Consts.KEY_CODES.ARROW_DOWN] &&
        document.activeElement === document.getElementById(id) &&
        !showSearchDropdown
      ) {
        setForceHideDropDown(false);
        setShowSearchDropdown(true);
      }
      //If ALT + ARROW_UP are pressed while user is in search results focus is moved to search bar and result index is reset
      if (showSearchDropdown && keydownMap[Consts.KEY_CODES.ARROW_UP]) {
        //If focus already in search bar, results are closed
        if (document.activeElement === document.getElementById(id)) {
          setForceHideDropDown(true);
          setShowSearchDropdown(false);
        }
        document.getElementById(id).focus({ focusVisible: true });
        setActiveResultIndex(-1);
      }
    }
    if (!keydownMap[Consts.KEY_CODES.ALT_KEY] && resultsList?.length > 0) {
      //Allows user to navigate between search results using arrow keys by updating the result index number
      if (keydownMap[Consts.KEY_CODES.ARROW_DOWN] && showSearchDropdown) {
        event.preventDefault();
        if (activeResultIndex < resultsList.length - 1) {
          setActiveResultIndex(activeResultIndex + 1);
        } else {
          setActiveResultIndex(-1);
          document.getElementById(id).focus({ focusVisible: true });
        }
      }
      if (keydownMap[Consts.KEY_CODES.ARROW_UP] && showSearchDropdown) {
        event.preventDefault();
        if (activeResultIndex > 0) {
          setActiveResultIndex(activeResultIndex - 1);
        } else {
          if (document.activeElement === document.getElementById(id)) {
            setActiveResultIndex(resultsList.length - 1);
          } else {
            document.getElementById(id).focus({ focusVisible: true });
          }
        }
      }
    } else if (
      //Handles navigating to and from result if no results are found or search error occurs
      !keydownMap[Consts.KEY_CODES.ALT_KEY] &&
      (keydownMap[Consts.KEY_CODES.ARROW_DOWN] ||
        keydownMap[Consts.KEY_CODES.ARROW_UP]) &&
      showSearchDropdown
    ) {
      if (document.activeElement === document.getElementById(id)) {
        if (resultsList?.length === 0) {
          document.getElementById('noResults').focus({ focusVisible: true });
        } else if (isSearchError) {
          document.getElementById('searchError').focus({ focusVisible: true });
        }
      } else {
        document.getElementById(id).focus({ focusVisible: true });
      }
    }
    //If focus is on a search result, right arrow key returns focus to the search bar - 1 character to the right of where caret was previously
    if (keydownMap[Consts.KEY_CODES.ARROW_RIGHT]) {
      setCaretPosition(document.getElementById(id), inputCaretIndex + 1);
      document.getElementById(id).focus({ focusVisible: true });
    }
    //If focus is on a search result, left arrow key returns focus to the search bar - 1 character to the left of where caret was previously
    if (keydownMap[Consts.KEY_CODES.ARROW_LEFT]) {
      setCaretPosition(document.getElementById(id), inputCaretIndex - 1);
      document.getElementById(id).focus({ focusVisible: true });
    }
  };

  //Keeps keydownMap accurate by recording when keys are no longer being pressed
  const onKeyUp = event => {
    keyTracker(event);
  };

  const classNameList = [className, 'mtSearch'];

  return (
    <MTFormItem
      id={id}
      name={name}
      label={label}
      className={classNameList.join(' ')}
      halfWidth={halfWidth}
      isRequired={isRequired}
      showLabel={showLabel}
    >
      {includeSearchIcon ? (
        <div className="searchIcon">
          <SearchIcon />
        </div>
      ) : null}
      <Field
        id={id}
        name={name}
        value={field.value}
        placeholder={disabled ? null : placeholder}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={e => {
          if (field.onBlur) {
            field.onBlur(e);
          }
          onBlur(e);
        }}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        autoComplete="off" // Seems to work for Safari and Chrome so far. doc on it here https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
        role="combobox"
        aria-controls="searchResults"
        aria-expanded={showSearchDropdown}
        aria-activedescendant={
          activeResultIndex >= 0 ? `searchResult${activeResultIndex}` : null
        }
        aria-label={AriaLabel.SEARCH_FEATURE}
        aria-autocomplete="list"
        tabIndex={0}
      />
      <MTSearchResults
        isSearching={isSearching}
        resultsList={resultsList}
        handleResultSelect={onSelect}
        hasSelectedValue={hasSelectedValue ? true : false}
        getDisplayValue={getDisplayValue}
        getResultType={getResultType}
        hideNoResults={hideNoResults}
        forceHideDropDown={forceHideDropDown}
        setForceHideDropDown={setForceHideDropDown}
        isSearchError={isSearchError}
        query={field.value}
        showSearchDropdown={showSearchDropdown}
        setShowSearchDropdown={setShowSearchDropdown}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        activeResultIndex={activeResultIndex}
        onBlur={onBlur}
      />
      {includeClearIcon && field.value ? (
        <div
          tabIndex="0"
          className="searchClearIcon"
          onClick={() => {
            helpers.setValue(Consts.EMPTY);
            clearResults();
            if (document.getElementById(id)) {
              document.getElementById(id).focus({ focusVisible: true });
            }
          }}
          onKeyDown={e => {
            addEnterListener(e);
          }}
          aria-label={AriaLabel.SEARCH_CLEAR}
        >
          <ClearIcon />
        </div>
      ) : null}
      <style jsx>
        {`
          :global(.${className} svg) {
            width: 22px;
            height: 22px;
            position: absolute;
          }

          :global(.${className} input) {
            border: none;
            border-bottom: 1px solid ${Colors.COMPONENT_LIGHT_GREY};
            min-height: 22px;
            padding-left: 10px;
            padding-right: 10px;
            padding-bottom: 4px;
            font-size: 18px;
            margin-top: 4px;
            box-shadow none;
            width: 100%;
            box-sizing: border-box;
            color: inherit;
            background-color: transparent;
            outline: none; /* Follows regular inputs that only show cursor */
          }

          :global(.${className}.error input) {
            border-color: ${Colors.ERROR_RED} !important;
          }

          :global(.${className} input[disabled]) {
            background-color: transparent;
            color: ${Colors.TEXT_GREY} !important;
            ${
              field.value === Consts.EMPTY
                ? 'cursor: not-allowed;'
                : Consts.EMPTY
            }
          }

          .searchClearIcon {
            top: 10px;
            right: 5px;
            width: 20px;
            height: 20px;
            position: absolute;
            z-index: 2;
          }

          .searchClearIcon :global(svg) {
            width: 20px;
            height: 20px;
          }

          .searchClearIcon:hover {
            cursor: pointer;
          }
        `}
      </style>
    </MTFormItem>
  );
};

export default MTSearch;

MTSearch.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

MTSearch.defaultProps = {
  name: 'search',
  initialValue: Consts.EMPTY,
  yup: Consts.EMPTY,
};
