import React, { useContext } from 'react';
import AppContext from '../../app/context/AppContext';
import { Grid } from '@walmart-web/livingdesign-components';
import MTSideNavigationItem from './MTSideNavigationItem';
import MTSideNavigationItemParent from './MTSideNavigationItemParent';

const NavItem = ({
  label,
  url,
  supportsLiteMode = false,
  isNewTab = false,
  isParent = false,
  leadingIcon,
  childPages = [],
}) => {
  const { isInLiteMode } = useContext(AppContext);
  const isInLiteModeNotSupported = isInLiteMode && !supportsLiteMode;

  return (
    <>
      {isInLiteModeNotSupported ? null : (
        <Grid
          UNSAFE_className="is-nav" /* is-nav is currently used for checkIfNavigatableElementClicked in DL and other hidding utilities. Need to review once DL has been offboarded. */
        >
          {isParent ? (
            <MTSideNavigationItemParent label={label} childPages={childPages} />
          ) : (
            <MTSideNavigationItem
              leadingIcon={leadingIcon}
              isNewTab={isNewTab}
              label={label}
              url={url}
            />
          )}
        </Grid>
      )}
    </>
  );
};

export default NavItem;
