import { toUpperCase } from '../utils/helpers';
import Consts from '../consts/Consts';
import MTOrder from '../utils/MTOrder';
import Config from '../utils/Config';

export default class UserType {
  static domain = '';
  static resolvedOUName = '';
  static userEmail = '';
  static displayName = '';
  static preferredName = '';
  static firstName = '';
  static userPrincipalName = '';
  static hasDevices = false;
  static hasVDIDevices = false;
  static moad = false;
  static encryptedInfo = null;
  static userData = null;
  static userId = '';
  static division = '';
  static department = '';
  static storeClubDC = '';
  static storeAddress = '';
  static isSIMUser = false;
  static isHomeOfficeNewHire = false;
  static employeeType = '';
  static isIDCUser = false;
  static isEagleOrderAllowed = true;
  static isSSPRSupport = false;
  static country = '';
  static manager = Consts.EMPTY;
  static userADType = Consts.EMPTY;
  static isDataLoaded = false;

  //Pre-Initialize with data returned from SSO
  static preInit = async (
    userId,
    upn,
    resolvedOUName,
    domain,
    email,
    displayName
  ) => {
    UserType.domain = toUpperCase(domain);
    UserType.resolvedOUName = toUpperCase(resolvedOUName);
    UserType.userEmail = email;
    UserType.displayName = displayName;
    UserType.preferredName = displayName; /* Gets overridden by init with userData, but set for MTL when init isn't called. */
    UserType.userPrincipalName = upn;
    UserType.userId = userId;
  };

  //Initialize - Overrides anything set in preInit, but if /userData fails (CIP is down), then the SSO values will be used. Done to keep the values as they have been, plus still support mockdata/impersonation.
  static init = async (data, user) => {
    const userADData = MTOrder.getADInfo(data);
    UserType.userADType = userADData.Type;
    UserType.domain = toUpperCase(userADData.Domain);
    UserType.resolvedOUName = toUpperCase(userADData.ResolvedOUName);
    UserType.userEmail = userADData.EmailAddress;
    UserType.displayName = userADData.DisplayName;
    UserType.preferredName = userADData.CN
      ? userADData.CN
      : userADData.DisplayName;
    UserType.firstName =
      UserType.preferredName && UserType.preferredName.split(' ')[0];
    UserType.userPrincipalName = userADData.UserPrincipalName;
    UserType.employeeType = userADData.EmployeeType;
    UserType.userId =
      userADData.UserPrincipalName &&
      userADData.UserPrincipalName.split('@')[0];
    UserType.moad = data.Moad;
    UserType.encryptedInfo = data.encryptedInfo;
    UserType.isSIMUser = data.isSIMUser;
    UserType.isHomeOfficeNewHire = data.isNewHire && UserType.isHomeOffice();
    UserType.userData = user;
    UserType.division = String(userADData.Division);
    UserType.department = String(userADData.Department);
    UserType.storeClubDC = userADData.StoreClubDC;
    UserType.isIDCUser = data.isIDCUser;
    UserType.isSSPRSupport = data.isSSPRSupport;
    UserType.isSeniorOrAbove = userADData.isSeniorOrAbove;
    UserType.country = userADData?.Country;
    /* check user is able to order eagle@home  */
    const response = await MTOrder.isOrderAllowed(
      UserType.userPrincipalName,
      Consts.MOBILE_FLOW.EAGLE_HOME
    );
    UserType.isEagleOrderAllowed =
      response?.data?.data?.isOrderAllowed === true;
    UserType.isDataLoaded = true;
  };

  static isHomeOffice = () => {
    return (
      UserType.domain === UserType.HOME_OFFICE ||
      UserType.domain === UserType.HO
    );
  };

  //check user belongs to homeoffice domain and gec associate
  static isGEC = () => {
    return (
      UserType.isHomeOffice() &&
      UserType.resolvedOUName &&
      UserType.resolvedOUName.startsWith(UserType.GEC)
    );
  };

  static isUS = () => {
    return UserType.domain === UserType.US;
  };

  static isMX = () => {
    return UserType.domain === UserType.MX;
  };

  static isWMSC = () => {
    return UserType.domain === UserType.WMSC;
  };

  static isStoreAssociate = () => {
    return UserType.userPrincipalName.includes('.s');
  };

  static isHourlyEmployee = () => {
    return UserType.employeeType && UserType.employeeType.toLowerCase() === 'h';
  };

  static isInternationalAssociate = () => {
    return !(
      UserType.country === UserType.US || UserType.country === UserType.IN
    );
  };

  static isStoreClubDC = () => {
    return (
      UserType.resolvedOUName === UserType.STORE_ASSOCIATE ||
      UserType.isStoreAssociate()
    );
  };

  static getUserType = (domain, resolvedOUName) => {
    let userType = Consts.EMPTY;
    domain = toUpperCase(domain);
    resolvedOUName = toUpperCase(resolvedOUName);
    switch (domain) {
      case UserType.HOME_OFFICE:
        userType = UserType.HOME_OFFICE;
        break;
      case UserType.HO:
        userType = UserType.HOME_OFFICE;
        break;
      case UserType.WMSC:
        userType = UserType.WMSC;
        break;
      case UserType.US:
        userType = UserType.US;
        break;
      default:
        userType = Consts.EMPTY;
        break;
    }
    if (
      userType === UserType.HOME_OFFICE &&
      resolvedOUName &&
      resolvedOUName.startsWith(UserType.GEC)
    ) {
      userType = UserType.GEC;
    }
    if (resolvedOUName === UserType.STORE_ASSOCIATE) {
      userType = UserType.STORE_ASSOCIATE;
    }
    return userType;
  };
  static setHasDevices = deviceData => {
    UserType.hasDevices =
      deviceData &&
      deviceData.AirWatchInformation &&
      deviceData.AirWatchInformation.length
        ? true
        : false;
  };

  static setHasVDIDevices = deviceData => {
    return (UserType.hasDevices =
      deviceData &&
      deviceData.DWSInfromation &&
      deviceData.DWSInfromation.length
        ? true
        : false);
  };

  static setIsEagleOrderAllowed = isAllowed => {
    UserType.isEagleOrderAllowed = isAllowed;
  };

  static isQAUser = () => {
    return Config.qaUsers && Config.qaUsers.indexOf(UserType.userId) !== -1;
  };
}

UserType.GEC = 'GEC';
UserType.GEC_ASSOCIATE = 'GEC ASSOCIATE';
UserType.HOME_OFFICE = 'HOMEOFFICE';
UserType.HO = 'HO';
UserType.US = 'US';
UserType.MX = 'MX';
UserType.IN = 'IN';
UserType.PR = 'PR';
UserType.STORES = 'STORES';
UserType.CLUBS = 'CLUBS';
UserType.WMSC = 'WMSC';
UserType.STORE_ASSOCIATE = 'STORE/CLUB/DC';
