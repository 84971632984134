import React from 'react';

const AppContext = React.createContext({
  //App
  isInLiteMode: false,
  isSideNavOpen: false,
  setIsSideNavOpen: () => {},
  //Secuirty Data
  twoSVData: null,
  SSPRData: null,
  show2SVWarning: false,
  showSSPRWarning: false,
  setShowSSPRWarning: () => {},
  showPasswordExpiryWarning: false,
  twoSVDataError: false,
  SSPRDataError: false,
  //Device Data
  deviceDataGraph: null,
  vdiData: null,
  setVDIData: () => {},
  deviceDataError: false,
  vdiDataError: false,
  setVDIDataError: () => {},
  vdiRequestData: null,
  setVDIRequestData: () => {},
  isOnPremVDI: false,
  setIsOnPremVDI: () => {},
  setIsVDIRequestDataDirty: () => {},
  isVDIRequestDataLoading: false,
  replacement: false,
  setReplacement: () => {},
  currentPhoneNumber: null,
  setCurrentPhoneNumber: () => {},
  replacementType: '',
  setReplacementType: () => {},
  //Order Data
  orderData: null,
  setOrderData: () => {},
  orderDataError: false,
  setOrderDataError: () => {},
  totalActiveOrders: null,
  setTotalActiveOrders: () => {},
  isOrderPlaced: false,
  setOrderPlaced: () => {},
  //Tickets Data
  totalActiveTickets: null,
  ticketData: null,
  setTicketData: () => {},
  ticketDataError: false,
  setTicketDataError: () => {},
  ticketDataListError: [],
  setTicketDataListError: () => {},
  isNewTicketCreated: false,
  setIsNewTicketCreated: () => {},
  ticketFiltersData: null,
  ticketFiltersDataError: false,
  //common
  isOnInitialRoute: false,
  //New hire tips
  newHireTipsConfig: null,
  setNewHireTipsConfig: () => {},
  newHireTipsConfigError: null,
  setNewHireTipsConfigError: () => {},
  // VDI
  VDIConfig: null,
  VDIConfigError: null,
  // Video Carousel Help Center
  helpCenterVideoConfigData: [],
  helpCenterVideoConfigError: false,
  enabledMFEs: [],
  setEnabledMFEs: () => {},
  // useVideoCarousel Hook
  _setShowVideoCarouselDialog: () => {},
  showVideoCarouselDialog: null,
  videoCarouselCurrentVideo: null,
  _setVideoCarouselCurrentVideo: () => {},
});

export default AppContext;
